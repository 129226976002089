import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TableCard } from 'components/common/card/TableCard';
import { ContentCard, HeaderTextBox } from 'components/common/card/Card';
import { StyledButton, StyledPlusButton } from 'components/common/styled';
import theme from 'theme';
import StyledSelect from 'components/common/input/BaseSelect';
import StyledCheckbox from 'components/common/input/BaseCheckbox';

export const CustomerPlusButton = styled(StyledPlusButton)`
  margin-top: -4px;
`;

export const CustomerRow = styled(Row)`
  margin-bottom: 16px;
`;

export const CustomerFlexCol = styled(Col)`
  display: flex;
  flex-flow: row wrap;
  input {
    min-width: max-content;
  }
`;

// nested column used for layout, no additional padding needed though
export const NestedCustomerFlexCol = styled(CustomerFlexCol)`
  padding: 0 !important;
`;

export const CustomerNoWrapCol = styled(Col)`
  white-space: nowrap;
`;

export const CustomerHeaderTextBox = styled(HeaderTextBox)`
  margin-right: 10px;
`;

export const CustomerButton = styled(StyledButton)`
  margin-bottom: 2px;
`;

export const StyledStateSelect = styled(StyledSelect)`
  display: flex;
  align-self: center;
`;

export const StyledPrimaryContactCheckbox = styled(StyledCheckbox)`
  margin-top: 0.8em;
`;

export const NationalAccountHeaderTextBox = styled(CustomerHeaderTextBox)`
  span {
    color: ${theme['@blue']} !important;
  }
`;

export const CustomerHzPad40Row = styled(CustomerRow)`
  padding-left: 40px;
  padding-right: 40px;
`;

export const CustomerContentCard = styled(ContentCard)`
  .contact-card-row-header {
    padding: 1em 40px 16px 56px;
    margin: 0px -16px 0px -16px;
  }

  .billing-contact-card-row-header {
    border-top: 1px solid #e5e5e5;
    padding: 1.5em 40px 16px 56px;
    margin: 0px -16px 0px -16px;
  }

  .contact-card-row {
    margin: 0 -16px 0 -16px;
    border-top: 1px solid #e5e5e5;
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .contact-card-row-even {
    background-color: rgba(217, 217, 217, 0.15);
  }

  .contact-card-row-first {
    border-top: 0px;
    padding-top: 0px;
  }
`;

export const FormLabel = styled(Typography)`
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Montserrat-SemiBold' !important;
  line-height: 24px;
`;

export const FormNoWrapLabel = styled(FormLabel)`
  white-space: nowrap;
`;

export const GreenCircle = styled.div`
  background-color: #79bc7f;
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const CustomerTableCard = styled(TableCard)`
  min-width: 700px;
`;

export function ContactEmailInfoIcon() {
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      style={{
        marginLeft: '1em',
        height: '19px',
        color: theme['@blue'],
        verticalAlign: 'middle',
      }}
    />
  );
}
