/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableHeader,
  TableHeaderLeft,
  TableHeaderText,
  TableCard,
  StyledTable,
  TableHeaderRight,
  TableSearch,
} from 'components/common/card/TableCard';
import { Text } from 'components/common/styled';
import { useState } from 'react';
import { useCustomerZipCodesQuery } from 'generated/graphql';
import useWindowSizeWatcher from 'hooks/useWindowSizeWatcher';
import { getSignedInUser } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';

function LocationZipCodes() {
  const [filter, setFilter] = useState('');
  const { loading, data } = useCustomerZipCodesQuery();
  const dimensions = useWindowSizeWatcher();

  // if a franchise user has ANY permissions, then they can see the list. (corporate users can see all)
  const user = getSignedInUser();
  const canView = user.userType === DbConstants.UserType.Corporate || (user.permissions || []).length > 0;
  if (!canView) return null;

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>ZIP CODES</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setFilter(value);
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  if (loading) {
    return <div>Loading</div>;
  }

  const columns = [
    {
      title: 'ZIP CODES',
      dataIndex: 'zip codes',
      render: (text: string, record: any) => <Text>{record.zipCode}</Text>,
      sorter: (a, b) => a.zipCode.localeCompare(b.zipCode),
    },
    {
      title: 'CITY',
      dataIndex: 'city',
      render: (text: string, record: any) => <Text>{record.cityName}</Text>,
      sorter: (a, b) => a.cityName.localeCompare(b.cityName),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'CUSTOMERS',
      dataIndex: 'customers',
      render: (text: string, record: any) => <Text>{record.customerCount}</Text>,
      sorter: (a, b) => a.customerCount - b.customerCount,
    },
  ];

  const zipCodes = data?.franchiseZipCodeSummaryViews || [];
  const scrollData =
    !loading && (zipCodes?.length || 0) > 10
      ? {
          y: dimensions.height > 700 ? Math.round(dimensions.height - 450) : 275,
        }
      : null;

  return (
    <TableCard title={header}>
      <StyledTable
        loading={loading}
        scroll={scrollData}
        dataSource={zipCodes.filter(zipCode => {
          const loweredFilter = filter.toLowerCase();
          return (
            !filter ||
            zipCode.zipCode.includes(loweredFilter) ||
            zipCode.cityName?.toLowerCase()?.includes(loweredFilter) ||
            zipCode.customerCount.toString().includes(loweredFilter)
          );
        })}
        columns={columns}
        pagination={false}
        rowKey='zipCode'
      />
    </TableCard>
  );
}

export default LocationZipCodes;
