/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import {
  CustomerGridRow,
  CustomerInvoiceGridRow,
  CustomerSearchState,
  CustomerWorkOrderGridRow,
} from './customer.models';
import {
  Address,
  CustomerWhereInput,
  GetCustomersForListQuery,
  InvoiceSummaryView,
  WorkOrderSummaryView,
} from 'generated/graphql';
import { getSelectedFranchise } from 'services/userService';
import { formatCurrency, formatAddress, formatDate, capitalizeFirst } from 'services/formattingService';
import { HorizontalCenter } from 'components/common/styled';
import { GreenCircle } from './customer.styled';
import { InvoiceStatuses } from 'services/invoiceService';
import { autoCompare, compareNumbers } from 'services/sortingService';

const { Text } = Typography;

/**
 * Assemble the graphql criteria for the customer search query.
 * @returns The where clause for the customer search query
 */
export function buildCustomersWhereClause(): CustomerWhereInput {
  let franchiseId = '00000000-0000-0000-0000-000000000000';
  const franchise = getSelectedFranchise();
  if (franchise) {
    franchiseId = franchise.id;
  }
  return {
    franchiseId: {
      equals: franchiseId,
    },
    AND: [
      {
        deleted: {
          equals: null,
        },
      },
    ],
  } as CustomerWhereInput;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredCustomerResults(
  state: CustomerSearchState,
  queryResults: GetCustomersForListQuery | undefined
): CustomerGridRow[] {
  // map and filter the search results
  let results: CustomerGridRow[] = [];
  const records = queryResults?.customers || [];
  if (records && records.length > 0) {
    const filter = (state.filter || '').toLowerCase();
    results = records
      .filter(c => c.locations.length > 0)
      .map(
        rec =>
          ({
            id: rec.id,
            number: `${rec?.franchise?.number?.toString()}-SM-${rec?.national ? 'N' : 'L'}-${rec.number?.toString()}`,
            billingAddress: formatAddress(rec?.address as Address),
            company: rec.name,
            monthlyValue: rec?.locations?.reduce(
              (accumulator, loc) => accumulator + Number(loc?.expectedMonthlyValue || 0),
              0
            ),
            serviceLocationCount: rec?._count?.locations || 0,
            active: rec.inactive === null || rec.inactive === undefined,
          } as CustomerGridRow)
      );
    results = results.filter(
      rec =>
        state.active === rec.active &&
        (rec.company?.toLowerCase()?.includes(filter) ||
          rec.billingAddress?.toLowerCase()?.includes(filter) ||
          rec.number?.toString()?.includes(filter) ||
          rec.monthlyValue?.toString()?.includes(filter))
    );
  }
  return results;
}

/**
 * Maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredCustomerWorkOrderResults(
  filter: string,
  workOrders: WorkOrderSummaryView[] | undefined
): CustomerWorkOrderGridRow[] {
  // map and filter the search results
  let results: CustomerWorkOrderGridRow[] = [];
  const records = workOrders || [];
  if (records && records.length > 0) {
    results = records.map(
      rec =>
        ({
          id: rec.workOrderId,
          number: rec.compositeNumber?.toString(),
          serviceLocation: rec.locationName,
          scheduleDate: rec?.scheduleDate,
          duration: `${rec?.durationMinutes} Min`,
          onSiteContact: `${rec?.contactFirstName} ${rec?.contactLastName}`,
          serviceType: rec?.serviceType,
        } as CustomerWorkOrderGridRow)
    );
    if (filter) {
      results = results.filter(
        workOrder =>
          workOrder?.number?.toString()?.includes(filter.toLowerCase()) ||
          workOrder?.serviceLocation?.includes(filter.toLowerCase()) ||
          workOrder?.scheduleDate?.toString().includes(filter.toLowerCase()) ||
          workOrder?.duration?.toLowerCase().includes(filter.toLowerCase()) ||
          formatDate(workOrder?.onSiteContact)?.includes(filter.toLowerCase()) ||
          formatDate(workOrder?.serviceType)?.includes(filter.toLowerCase())
      );
    }
  }
  return results;
}

/**
 * Maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredCustomerInvoiceResults(
  filter: string,
  invoices: InvoiceSummaryView[] | undefined
): CustomerInvoiceGridRow[] {
  // map and filter the search results
  let results: CustomerInvoiceGridRow[] = [];
  const records = invoices || [];
  if (records && records.length > 0) {
    results = records.map(
      rec =>
        ({
          id: rec?.invoiceId,
          number: rec?.number,
          sent: !!rec?.dateIssued,
          location: rec.locationName,
          issued: rec?.dateIssued,
          due: rec?.dateDue,
          total: rec?.totalCharge,
          balance: rec?.totalOwed,
          status: rec?.invoiceStatus,
          serviceType: rec?.serviceType,
        } as CustomerInvoiceGridRow)
    );
    if (filter) {
      results = results.filter(
        invoice =>
          invoice?.number?.toString()?.includes(filter.toLowerCase()) ||
          invoice?.location?.includes(filter.toLowerCase()) ||
          invoice?.due?.toString().includes(filter.toLowerCase()) ||
          invoice?.status?.toLowerCase().includes(filter.toLowerCase()) ||
          formatDate(invoice?.issued)?.includes(filter.toLowerCase()) ||
          formatDate(invoice?.due)?.includes(filter.toLowerCase()) ||
          formatDate(invoice?.serviceType)?.includes(filter.toLowerCase())
      );
    }
  }
  return results;
}

/**
 * Gets the columns for the grid
 * @returns an array of columns
 */
export function getCustomerColumns(): ColumnProps<CustomerGridRow>[] {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'number',
      width: '10%',
      render: (text: string, record: CustomerGridRow, idx: number) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.number, b.number),
    },
    {
      title: 'COMPANY',
      dataIndex: 'company',
      width: '20%',
      render: (text: string, record: CustomerGridRow, idx: number) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.company, b.company),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'SERVICE LOCATIONS',
      dataIndex: 'serviceLocationCount',
      width: '15%',
      render: (text: string, record: CustomerGridRow, idx: number) => <Text>{record.serviceLocationCount}</Text>,
      sorter: (a, b) => compareNumbers(a.serviceLocationCount, b.serviceLocationCount),
    },
    {
      title: 'BILLING ADDRESS',
      dataIndex: 'billingAddress',
      render: (text: string, record: CustomerGridRow, idx: number) => <Text>{record?.billingAddress}</Text>,
      sorter: (a, b) => autoCompare(a.billingAddress, b.billingAddress),
    },
    {
      title: 'MONTHLY VALUE',
      dataIndex: 'monthlyValue',
      width: '15%',
      render: (text: string, record: CustomerGridRow, idx: number) => (
        <Text>{formatCurrency(record?.monthlyValue)}</Text>
      ),
      sorter: (a, b) => compareNumbers(a.monthlyValue, b.monthlyValue),
    },
  ] as ColumnProps<CustomerGridRow>[];

  return columns;
}

/**
 * Get the scroll data for the customer search table. If there are less than 10 rows, don't do scrolling.
 * @param loading is the data still loading?
 * @param activeData the filtered data to be bound to the table
 * @param dimensions the window dimensions
 * @returns scroll data for the antd table
 */
export function getCustomerScrollData(
  loading: boolean,
  activeData: CustomerGridRow[],
  dimensions: { height: number; width: number }
) {
  return !loading && (activeData?.length || 0) > 10
    ? {
        y: dimensions.height > 500 ? Math.round(dimensions.height - 250) : 300,
      }
    : null;
}

/**
 * Gets the columns for the grid
 * @returns an array of columns
 */
export function getCustomerWorkOrderColumns(): ColumnProps<CustomerWorkOrderGridRow>[] {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'number',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.number, b.number),
    },
    {
      title: 'SERVICE LOCATION',
      dataIndex: 'serviceLocation',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.serviceLocation, b.serviceLocation),
    },
    {
      title: 'DATE/TIME',
      dataIndex: 'scheduleDate',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{formatDate(record?.scheduleDate)}</Text>,
      sorter: (a, b) => autoCompare(a.scheduleDate, b.scheduleDate),
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.duration, b.duration),
    },
    {
      title: 'ON-SITE CONTACT',
      dataIndex: 'onSiteContact',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.onSiteContact, b.onSiteContact),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'serviceType',
      render: (text: string, record: CustomerWorkOrderGridRow) => <Text>{capitalizeFirst(text)}</Text>,
      sorter: (a, b) => autoCompare(a.serviceType, b.serviceType),
    },
  ] as ColumnProps<CustomerWorkOrderGridRow>[];

  return columns;
}

/**
 * Gets the columns for the grid
 * @returns an array of columns
 */
export function getCustomerInvoiceColumns(): ColumnProps<CustomerInvoiceGridRow>[] {
  const columns = [
    {
      title: 'SENT',
      dataIndex: 'issued',
      render: (text: string, record: CustomerInvoiceGridRow) =>
        record?.issued ? (
          <HorizontalCenter>
            <GreenCircle />
          </HorizontalCenter>
        ) : (
          <div />
        ),
      sorter: (a, b) => autoCompare(a.sent, b.sent),
    },
    {
      title: 'INVOICE ID',
      dataIndex: 'number',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{record?.number}</Text>,
      sorter: (a, b) => autoCompare(a.number, b.number),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'SERVICE LOCATION',
      dataIndex: 'location',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{record?.location}</Text>,
      sorter: (a, b) => autoCompare(a.location, b.location),
    },
    {
      title: 'ISSUED',
      dataIndex: 'issued',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{formatDate(record?.issued)}</Text>,
      sorter: (a, b) => autoCompare(a.issued, b.issued),
    },
    {
      title: 'DUE',
      dataIndex: 'due',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{formatDate(record?.due)}</Text>,
      sorter: (a, b) => autoCompare(a.due, b.due),
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{formatCurrency(record?.total)}</Text>,
      sorter: (a, b) => autoCompare(a.total, b.total),
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      render: (text: string, record: CustomerInvoiceGridRow) => <Text>{formatCurrency(record?.balance)}</Text>,
      sorter: (a, b) => autoCompare(a.balance, b.balance),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (text: string, record: CustomerInvoiceGridRow) => {
        const invoiceStatus = InvoiceStatuses.find(x => x?.key?.toLowerCase() === record?.status) || InvoiceStatuses[0];
        return (
          <div style={{ fontFamily: 'Open Sans Bold', color: invoiceStatus.color || '#9f9f9f' }}>
            {invoiceStatus.status || 'none'}
          </div>
        );
      },
      sorter: (a, b) => autoCompare(a.status, b.status),
    },
  ] as ColumnProps<CustomerInvoiceGridRow>[];

  return columns;
}
