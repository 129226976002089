import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { CardColumn, CardRow } from 'components/common/card/Card';
import { DumpsterInput, GetDumpsterLookupsQuery, UpdateLocationDumpsterInput } from 'generated/graphql';
import { UPDATE_LOCATION_DUMPSTERS } from 'graphql/mutation/customer';
import { GET_DUMPSTER_LOOKUPS } from 'graphql/query/lead';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { uuidGenerator } from 'services/dataGenerationService';
import { autoCompare } from 'services/sortingService';
import { ChildFormActivator, CustomerDumpsterForm, LocationLookupRecord } from '../equipment.models';
import { mapDumpsterFormArray, mapDumpsterFormObject, populateDumpsterLookups } from '../equipment.service';
import Dumpster from './Dumpster';

function Dumpsters({
  readOnly,
  locationId,
  dumpsters,
  editMode,
  setFormActions,
  dumpstersUpdated,
}: {
  readOnly: boolean;
  locationId: string;
  dumpsters: CustomerDumpsterForm[];
  editMode: boolean;
  setFormActions: (actions: ChildFormActivator) => void;
  dumpstersUpdated?: (dumpsters: CustomerDumpsterForm[]) => void;
}) {
  let formList = mapDumpsterFormArray(dumpsters);

  const formRef = useRef(null);
  const saving = useRef(false);
  const newRecords = useRef<CustomerDumpsterForm[]>([]);
  const [newRecordMode, setNewRecordMode] = useState<number>(0);
  const haulers: LocationLookupRecord[] = [];
  const sizes: LocationLookupRecord[] = [];

  // set up the update dumpsters mutation
  const [updateLocationDumpsters, { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_LOCATION_DUMPSTERS);

  // load the haulers and sizes from the database.
  const { loading, data } = useQuery<GetDumpsterLookupsQuery>(GET_DUMPSTER_LOOKUPS);
  if (!loading && data) populateDumpsterLookups(data, haulers, sizes);

  // set up react-hook-form
  const {
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    register,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  if (setFormActions) {
    setFormActions({
      canAdd: true,
      add: () => {
        // if the parent form presses the add button, this is fired. We add a record to the new records collection.
        const newRecord = mapDumpsterFormObject({
          locationId,
          id: uuidGenerator.generate(),
          name: `Dumpster ${(dumpsters?.filter(x => !x.deleted)?.length || 0) + (newRecords.current?.length || 0) + 1}`,
        });
        newRecords.current = [...newRecords.current, newRecord];
        setNewRecordMode(newRecordMode + 1);
        return true;
      },
      submit: () => {
        // trigger the form submit (because the button isn't in the form)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formRef?.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      },
      reset: () => {
        // reset the form to the way it was before.
        newRecords.current = [];
        reset();
      },
      validate: async () => trigger(),
    });
  }

  if (saving.current && !mutationLoading) {
    if (mutationData && !mutationError) {
      saving.current = false;

      if (dumpstersUpdated) {
        newRecords.current = [];

        // update the parent async, after the render has completed.
        formList = mapDumpsterFormArray(mutationData?.updateLocationDumpsters?.dumpsters || []);
        const copyOfObjToSave = [...formList];
        setTimeout(() => {
          dumpstersUpdated(copyOfObjToSave);
          notification.open({
            message: 'Saved',
            description: 'Updated Dumpster(s) Successfully',
            duration: 5,
          });
          reset();
        }, 0);
      }
    } else if (mutationError) {
      saving.current = false;

      notification.open({
        message: 'Error Updating Record.',
        description: mutationError.message,
        duration: 5,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const onSubmit = (formData: any) => {
    const dumpstersToSave = formData.dumpsters.map(x => {
      if (newRecords.current.length > 0 && newRecords.current.find(y => y.id === x.id))
        return { ...x, id: undefined } as DumpsterInput;
      return x as DumpsterInput;
    });
    const outgoingRequestArgs = {
      variables: {
        data: {
          dumpsters: dumpstersToSave,
          locationId,
        } as unknown as UpdateLocationDumpsterInput,
        where: { locationId },
      },
    };

    updateLocationDumpsters(outgoingRequestArgs);
    saving.current = true;
  };

  // combine the new dumpsters with the existing ones and show them to the user.
  let allDumpsters = [...formList.filter(x => !x.deleted)];
  allDumpsters.sort((a, b) => autoCompare(a.name, b.name));
  if (newRecords.current.length > 0) allDumpsters = [...allDumpsters, ...newRecords.current];

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      {allDumpsters.length > 0 ? (
        allDumpsters.map((dumpster, idx) => (
          <React.Fragment key={dumpster.id}>
            <Dumpster
              index={idx}
              dumpster={dumpster}
              readOnly={readOnly}
              editMode={editMode}
              sizes={sizes}
              haulers={haulers}
              control={control}
              deleteDumpster={dumpsterId => {
                const dumpsterToDelete = formList.find(d => d.id === dumpsterId);
                if (dumpsterToDelete) {
                  dumpsterToDelete.deleted = new Date();
                  onSubmit({ dumpsters: formList });
                }
              }}
            />
            <input type='hidden' {...register(`dumpsters[${idx}].id`)} value={dumpster.id} />
            <input type='hidden' {...register(`dumpsters[${idx}].locationId`)} value={locationId} />
          </React.Fragment>
        ))
      ) : (
        <CardRow>
          <CardColumn>There are no dumpsters for this lead.</CardColumn>
        </CardRow>
      )}
    </form>
  );
}

export default Dumpsters;
