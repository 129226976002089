import { useRef } from 'react';

import { TableCard, TableHeader, TableHeaderLeft, TableHeaderText } from 'components/common/card/TableCard';
import { SampleGridRow } from './table.models';
import { getFilteredResults, getSampleRecords, getVirtualTableColumns } from './table.service';
import { LoadingContainer } from './table.styled';
import { ColumnProps } from 'antd/lib/table';
import useMockData from 'hooks/useMockData';
import { InfinityTable } from 'components/common/infinity-table/InfinityTable';

/**
 * This is a sample component that demonstrates one way to do grids cleanly
 * @returns a sample list component
 */
function InfinityTableView() {
  const columnsRef = useRef<ColumnProps<SampleGridRow>[]>();
  const rowsRef = useRef<SampleGridRow[]>();

  // get the search state, which includes everything
  const searchState = {
    filter: '',
    active: true,
    numberOfRecords: 10000,
  };

  // get the columns reference (similar to a class member)
  if (!rowsRef.current)
    rowsRef.current = getFilteredResults(searchState, getSampleRecords(searchState.numberOfRecords));

  // load the data from wherever and update the state.
  const { loading, data } = useMockData<SampleGridRow>({
    sampleData: rowsRef.current,
    sleepMilliseconds: 1,
  });
  // const [data, setData] = useState(getSampleRecords(searchState.numberOfRecords));
  // const loading = false;
  // only load the columns once? (probably overkill)
  if (!columnsRef.current) columnsRef.current = getVirtualTableColumns();

  const onFetch = () => {
    // do stuff
  };

  const infinityHeader = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>INFINITY TABLE EXAMPLE</TableHeaderText>
      </TableHeaderLeft>
    </TableHeader>
  );
  const infinity = (
    <InfinityTable
      key='id'
      rowKey='id'
      loading={loading}
      onFetch={onFetch}
      pageSize={100}
      columns={columnsRef.current}
      scroll={{ y: 600 }}
      dataSource={data}
      bordered
    />
  );
  /*
    loadingIndicator: element,
  onScroll: func, // 滚动事件
  onFetch: func, // 滚动到低部触发Fetch方法
  sumData: array, // 合计行
  dataSource: array.isRequired,
  columns: array.isRequired,
  forwardedRef: object,
  debug: bool,
  pageSize: number,
  loading: bool,
  */

  // INFINITY TABLE
  // see: https://codesandbox.io/s/e93xq?file=/mockData.js
  // see: https://github.com/Leonard-Li777/antd-table-infinity/blob/master/README_en.md
  // return the final chunk of jsx html for the component
  return (
    <LoadingContainer spinning={loading} delay={200}>
      <TableCard title={infinityHeader}>{!loading && infinity}</TableCard>
    </LoadingContainer>
  );
}

export default InfinityTableView;
