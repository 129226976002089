import {
  CardHeader,
  CardHeaderLeft,
  CardHeaderText,
  ContentCard,
  CardRow,
  CardColumn,
} from 'components/common/card/Card';
import { DetailsViewField, DetailsViewFieldLabel, DetailsFormSection, ScheduleSelect } from './schedule.styled';
import { Select } from 'antd';

const { Option } = Select;

export function SmashOrderDetails({
  customer,
  handleScheduleInput,
  formState,
  readOnly,
  locationId,
}: {
  customer: any;
  handleScheduleInput: any;
  formState: any;
  readOnly?: boolean;
  locationId?: string;
}) {
  const location = customer.locations.find(loc => loc.id === locationId) || customer.locations[0];

  const { line1, city, state, zipCode } = location.address;

  const {
    firstName: primaryContactFirstName,
    lastName: primaryContactLastName,
    phone: primaryContactPhone,
  } = customer.customerContacts.filter(contact => contact.primary)[0];

  const title = (
    <CardHeader>
      <CardHeaderLeft>
        <CardHeaderText>ORDER DETAILS</CardHeaderText>
      </CardHeaderLeft>
    </CardHeader>
  );

  return (
    <ContentCard title={title}>
      <CardRow>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Customer:</DetailsViewFieldLabel>
            <DetailsViewField>{customer.name}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Service Type:</DetailsViewFieldLabel>
            {!readOnly ? (
              <ScheduleSelect
                defaultValue={formState.orderType || 'demo'}
                onSelect={value => handleScheduleInput({ orderType: value })}
              >
                <Option value='demo'>Demo</Option>
                <Option value='trial'>Trial</Option>
              </ScheduleSelect>
            ) : (
              <DetailsViewField>
                {formState.orderType && formState.orderType[0].toUpperCase() + formState.orderType.substring(1)}
              </DetailsViewField>
            )}
          </DetailsFormSection>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Account Type:</DetailsViewFieldLabel>
            <DetailsViewField>{customer.national ? 'National' : 'Commercial'}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Number of Dumpsters:</DetailsViewFieldLabel>
            <DetailsViewField>{customer.locations[0].dumpsters.length}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Service Location:</DetailsViewFieldLabel>
            <DetailsViewField>{`${line1} ${city}, ${state} ${zipCode}`}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Duration:</DetailsViewFieldLabel>
            <DetailsViewField>{`${customer.locations[0].dumpsters.length * 0.5} hour${
              customer.locations[0].dumpsters.length * 0.5 > 1 ? 's' : ''
            }`}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Contact:</DetailsViewFieldLabel>
            <DetailsViewField>{`${primaryContactFirstName} ${primaryContactLastName}`}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Phone:</DetailsViewFieldLabel>
            <DetailsViewField>{primaryContactPhone}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <DetailsFormSection>
            <DetailsViewFieldLabel>Notes:</DetailsViewFieldLabel>
            <DetailsViewField>{customer.leadNotes}</DetailsViewField>
          </DetailsFormSection>
        </CardColumn>
      </CardRow>
    </ContentCard>
  );
}
