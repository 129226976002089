import { Checkbox, Input, Select } from 'antd';
import { RHFCheckboxGroupWrapper } from 'components/common/react-hook-form/RHFCheckboxGroupWrapper';
import { RHFCheckboxWrapper } from 'components/common/react-hook-form/RHFCheckboxWrapper';
import { RHFWrapper } from 'components/common/react-hook-form/RHFWrapper';
import { useFieldArray, useFormContext } from 'react-hook-form';

const { Option } = Select;

export function RHFPatternChild() {
  const { control } = useFormContext();

  const { fields: projectFields } = useFieldArray({
    name: 'projects',
    control,
  });

  const CheckboxGroup = Checkbox.Group;
  const projectOptions = ['billing', 'jira', 'product owner'];

  return (
    <section>
      <RHFWrapper name='name' rules={{ required: 'Name is required!' }}>
        <Input />
      </RHFWrapper>

      <RHFWrapper name='role' rules={{ required: 'Role is required!' }}>
        <Select>
          <Option value='business'>Business</Option>
          <Option value='IT'>IT</Option>
        </Select>
      </RHFWrapper>

      <RHFCheckboxWrapper name='primary'>
        <Checkbox>Primary</Checkbox>
      </RHFCheckboxWrapper>

      {projectFields.map((field, index) => (
        <div>
          <RHFWrapper name={`projects.[${index}].name`} rules={{ required: 'Project Name is required!' }}>
            <Input />
          </RHFWrapper>

          <RHFWrapper name={`projects.[${index}].length`} rules={{ required: 'Project Length is required!' }} debug>
            <Input />
          </RHFWrapper>

          <RHFCheckboxGroupWrapper debug name={`projects.[${index}].notifications`}>
            <CheckboxGroup options={projectOptions} />
          </RHFCheckboxGroupWrapper>
        </div>
      ))}
      <h1>TEST</h1>
    </section>
  );
}
