// import customerData from 'fixtures/customerFixture';
import { DbConstants } from 'generated/smt-constants';

// export function getCustomers() {
//   return customerData;
// }

// export function getCustomer(key) {
//   return customerData.find(c => c.key === key);
// }

export function getLeadStatus(key): { key?: string; status: string; color: string } {
  return LeadStatuses.find(c => c.key === key) || LeadStatuses[0];
}

/**
 * A collection of lead statuses along with the appropriate color
 */
export const LeadStatuses: { key?: string; status: string; color: string }[] = [
  {
    key: undefined,
    status: 'None',
    color: '#9f9f9f',
  },
  {
    key: DbConstants.LeadStatus.Lead,
    status: 'Lead',
    color: '#3B5998',
  },
  {
    key: DbConstants.LeadStatus.InDemo,
    status: 'In-Demo',
    color: '#5B5B5B',
  },
  {
    key: DbConstants.LeadStatus.DemoComplete,
    status: 'Demo Complete',
    color: '#79BC7F',
  },
  {
    key: DbConstants.LeadStatus.InTrial,
    status: 'In-Trial',
    color: '#BB1E10',
  },
  {
    key: DbConstants.LeadStatus.TrialComplete,
    status: 'Trial Complete',
    color: '#BB1E10', // TODO: This color wasn't defined.
  },
];
