import { Slider } from 'antd';
import { StyledCard } from 'components/common/card/Card';
import styled from 'styled-components';
import theme from 'theme';

export const DashboardCardStat = styled.div`
  /* In the mockup, the font is Montserrat - should we change it? */
  font-family: ${theme['@header-font-family']};
  color: ${({ $color }) => $color || theme['@green']} !important;
  font-size: 60px;
  font-weight: 300;
  line-height: 90px;
  letter-spacing: -0.06em;
`;

export const DashboardUnitSelect = styled(Slider)`
  margin-top: 0px;
  width: 140px;
  align-self: center;
  .ant-slider-mark-text {
    font-size: 10px;
  }
`;

export const DashboardCardIcon = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

export const DashboardCard = styled(StyledCard)`
  font-family: 'Montserrat-Bold';
  font-weight: 400;
  width: 210px;
  min-width: 210px;
  height: 183px;
  background: #ffffff;
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  margin-right: 10px;

  .ant-card-body {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    /* The "dashboard" classes are custom for the dashboard */
    .dashboard-card-layout {
      width: 100%;
    }

    .dashboard-action-card-footer {
      font-size: 14px;
      opacity: 50%;
    }

    .dashboard-action-card-enabled {
      opacity: 100%;
    }

    .dashboard-action-card-disabled {
      opacity: 50%;
    }

    .dashboard-card-header-footer {
      height: 22px;
      width: 100%;
      line-height: 22px;
      padding: 0;
      background: none;
      justify-content: center;
      align-content: center;
      text-transform: uppercase;
    }

    .dashboard-card-text-stat-content {
      margin-bottom: 10px;
    }

    .dashboard-card-slider-footer {
      height: 22px;
      width: 100%;
      padding: 0;
      background: none;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    /* These are the ones built into antd layout */
    .ant-layout-header {
      font-size: 14px;
      align-self: flex-start;
      white-space: nowrap;
    }

    .ant-layout-content {
      padding: 0;
      background: none;

      align-self: stretch;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      /* the default color of the SVG for an action card should be the text color at 50% */
      .dashboard-action-card-icon {
        svg {
          path {
            fill: ${theme['@text-color']};
            fill-opacity: .5;
          }
        }
      }
      .dashboard-action-card-enabled {
        svg {
          path {
            fill: ${theme['@text-color']};
            fill-opacity: 1.0;
          }
        }
      }
    }
    .ant-layout-footer {
      font-size: 12px;
      align-self: flex-end;
      white-space: nowrap;
    }

    .ant-progress-text {
      letter-spacing: -3px;
      font-family: 'Montserrat';
      font-size: 35px;
      sup {
        font-size: 18px;
        top: -0.75em;
      }
    }

    .move-percentage {
      .ant-progress-text {
        color: ${theme['@text-color']};
        padding-right: 5px;
      }
    }
`;
