// Copy the customer detail page here
import { useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
// import WorkOrdersCard from './WorkOrdersCard';
import LeadConvertForm from './LeadConvertForm';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CUSTOMER } from 'graphql/query/customer';
import { CONVERT_FROM_LEAD, UPDATE_CUSTOMER } from 'graphql/mutation/customer';
import { DbConstants } from 'generated/smt-constants';
import { hasPermission } from 'services/userService';
// import LocationTableView from 'components/location/LocationTableView';
// import LocationDetails from 'components/location/LocationDetails';
// import InvoicesCard from './InvoicesCard';

import { GetCustomerQuery, useGetLeadQuery } from 'generated/graphql';
import PageTitle from 'components/common/PageTitle';
import { useForm, FormProvider } from 'react-hook-form';

function LeadConvertDetails() {
  const match = useRouteMatch();
  const [locationEditMode, setLocationEditMode] = useState(false);
  const [customerEditMode, setCustomerEditMode] = useState(false);

  const canEdit = hasPermission(DbConstants.Permission.CustomerDetailsCrud);

  const params: any = useParams();

  const formHandler = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {},
  });
  const [updateCustomer, { data: d2, loading: l2, error: e2 }] = useMutation(UPDATE_CUSTOMER);
  const { customerId } = useParams<{ customerId }>();

  const { loading, data } = useGetLeadQuery({
    variables: {
      id: customerId,
    },
    context: { timeout: 20000 },
    onCompleted: response => {
      formHandler.reset(response.location as any);
    },
  });

  const [convertFromLeadEdit] = useMutation(CONVERT_FROM_LEAD);

  let customerDetails = <div>Loading...</div>;

  if (!loading && data) {
    const customerClone = { ...data.location?.customer };
    const locationClone = { ...data.location };
    // invoiceList = [...data.invoiceSummaryViews];
    // workOrderList = [...data.workOrderSummaryViews];

    customerDetails = (
      <FormProvider {...formHandler}>
        <LeadConvertForm
          customer={customerClone} // eslint-disable no-shadow
          location={locationClone}
          submitLeadConvertEdit={convertFromLeadEdit}
          editMode={locationEditMode}
          setEditMode={setLocationEditMode}
          key={locationClone.id}
        />
      </FormProvider>
    );
  }

  return (
    <Switch>
      {/* <Route path={`${match.path}/:locationId`}>
        <LocationDetails />
      </Route> */}
      <Route path={`${match.path}`}>
        <PageTitle>Convert Customer</PageTitle>
        {!loading && data && <FormProvider {...formHandler}>{customerDetails}</FormProvider>}
        {/*
        <LocationTableView />
        <WorkOrdersCard loading={loading} workOrders={workOrderList} />
        <InvoicesCard loading={loading} invoices={invoiceList} />
        */}
      </Route>
    </Switch>
  );
}

export default LeadConvertDetails;
