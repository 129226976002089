import { gql } from '@apollo/client';
import { CORE_AGREEMENT_FIELDS } from './agreement';
import { CORE_ASSET_FIELDS, CORE_BROKER_FIELDS, CORE_DUMPSTER_FIELDS } from './location';

export const CORE_LEAD_FIELDS = gql`
  ${CORE_BROKER_FIELDS}
  ${CORE_ASSET_FIELDS}
  ${CORE_DUMPSTER_FIELDS}
  ${CORE_AGREEMENT_FIELDS}
  fragment CoreLeadFields on Location {
    id
    customerId
    name
    dumpsterCount
    addressId
    archived
    expectedMonthlyValue
    demoScheduled
    demoCompleted
    trialScheduled
    trialCompleted
    convertedFromLead
    lastFollowUp
    salesRep
    salesRepId
    salesRepUser {
      firstName
      lastName
    }
    leadNotes
    hubspotDealId
    inactive
    deleted
    createdAt
    updatedAt
    address {
      line1
      line2
      city
      state
      zipCode
    }
    locationContacts(where: { customerContact: { is: { deleted: { equals: null } } } }) {
      customerContact {
        id
        firstName
        lastName
        phone
        email
        contactTypes
        preferredContactMethod
        contactCategories
      }
    }
    customer {
      name
      customerContacts(where: { deleted: { equals: null } }) {
        id
        firstName
        lastName
        phone
        email
        contactTypes
        preferredContactMethod
        contactCategories
        primary
      }
    }
    agreements {
      ...CoreAgreementFields
    }
    dumpsters {
      ...CoreDumpsterFields
    }
    locationAssets {
      ...CoreAssetFields
    }
    locationBrokers {
      ...CoreBrokerFields
    }
  }
`;
