import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { FranchiseGridRow, FranchiseSearchState } from './franchise.models';
import { GetFranchiseTableDataQuery } from 'generated/graphql';
import { getSignedInUser, hasAccessToAllFranchises } from 'services/userService';
import { getStateAbbr, formatPhone } from 'services/formattingService';
import { autoCompare } from 'services/sortingService';

const { Text } = Typography;

/**
 * Gets the columns for the grid
 * @returns an array of columns
 */
export function getFranchiseColumns(): ColumnProps<FranchiseGridRow>[] {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'number',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.number}</Text>,
      sorter: (a, b) => autoCompare(a.number, b.number),
    },
    {
      title: 'STATE',
      dataIndex: 'location',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.location}</Text>,
      sorter: (a, b) => autoCompare(a.location, b.location),
    },
    {
      title: 'FRANCHISE NAME',
      dataIndex: 'name',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.name}</Text>,
      sorter: (a, b) => autoCompare(a.name, b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'CUSTOMERS',
      dataIndex: 'customerCount',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.customerCount}</Text>,
      sorter: (a, b) => autoCompare(a.customerCount, b.customerCount),
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'contactName',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.contactName}</Text>,
      sorter: (a, b) => autoCompare(a.contactName, b.contactName),
    },
    {
      title: 'PHONE',
      dataIndex: 'contactPhone',
      render: (text: string, record: FranchiseGridRow) => <Text>{record.contactPhone}</Text>,
      sorter: (a, b) => autoCompare(a.contactPhone, b.contactPhone),
    },
  ] as ColumnProps<FranchiseGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredFranchiseResults(
  state: FranchiseSearchState,
  queryResults: GetFranchiseTableDataQuery | undefined
): FranchiseGridRow[] {
  // map and filter the search results
  let results: FranchiseGridRow[] = [];
  const records = queryResults?.franchises || [];
  if (records && records.length > 0) {
    const user = getSignedInUser();
    const canSeeAllFranchises = hasAccessToAllFranchises();

    // TODO: This security filtering needs to be done on the server. Temporarily adding it here to make it look correct.
    const franchises =
      queryResults?.franchises
        .filter(record => canSeeAllFranchises || !!user.franchises.find(x => x.id === record.id))
        .filter(record => !record.inactive === state.active) || [];

    const filter = (state.filter || '').toLowerCase();
    results = franchises.map(
      rec =>
        ({
          id: rec.id,
          number: `${rec.number?.toString().padStart(3, '0')}-${getStateAbbr(rec?.address?.state) || 'IN'}-SM`,
          // NOTE: I think putting the city and stat is better, but I'm a moron -> location: `${rec?.address?.city}, ${getStateAbbr(rec?.address?.state) || 'IN'}`,
          location: rec?.address?.state,
          name: rec?.name,
          customerCount: rec?._count?.customers,
          contactName: `${rec?.contactFirstName} ${rec?.contactLastName}`,
          contactPhone: formatPhone(rec.contactPhone),
          state: getStateAbbr(rec?.address?.state) || 'IN',
          active: rec.inactive === null || rec.inactive === undefined,
        } as FranchiseGridRow)
    );

    results = results?.filter(
      record =>
        (record?.number?.toLowerCase()?.includes(filter) ||
          record?.name?.toLowerCase()?.includes(filter) ||
          record?.contactName?.toLowerCase()?.includes(filter) ||
          record?.contactPhone?.toLowerCase()?.includes(filter) ||
          record?.location?.toLowerCase()?.includes(filter)) &&
        (!state?.stateFilter || record?.state?.toLowerCase() === getStateAbbr(state.stateFilter)?.toLowerCase())
    );
  }
  return results;
}

/**
 * Get the scroll data for the franchise search table. If there are less than 10 rows, don't do scrolling.
 * @param loading is the data still loading?
 * @param activeData the filtered data to be bound to the table
 * @param dimensions the window dimensions
 * @returns scroll data for the antd table
 */
export function getFranchiseScrollData(
  loading: boolean,
  activeData: FranchiseGridRow[],
  dimensions: { height: number; width: number }
) {
  return !loading && (activeData?.length || 0) > 10
    ? {
        y: dimensions.height > 500 ? Math.round(dimensions.height - 250) : 300,
      }
    : null;
}
