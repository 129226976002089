/**
 * This will try and compare two values of any type (and usually get it correct).
 * @param a the first value
 * @param b the second value
 * @returns 0 if equal, gt 0 if a > b, lt 0 if a < b
 */
export function autoCompare(a, b, ignoreCase = true) {
  if (isNada(a) && isNada(b)) return 0;

  if ((isNada(a) || a instanceof Date) && (isNada(b) || b instanceof Date)) {
    return (a?.getTime() || 0) - (b?.getTime() || 0);
  }
  if (
    (isNada(a) || typeof a === 'number' || typeof a === 'bigint') &&
    (isNada(b) || typeof b === 'number' || typeof b === 'bigint')
  ) {
    return compareNumbers(a, b);
  }
  if ((isNada(a) || typeof a === 'string') && (isNada(b) || typeof b === 'string')) {
    return ignoreCase
      ? (a || '').toLowerCase().localeCompare((b || '').toLowerCase())
      : (a || '').localeCompare(b || '');
  }
  if ((isNada(a) || typeof a === 'boolean') && (isNada(b) || typeof b === 'boolean')) {
    return (a ? 1 : 0) - (b ? 1 : 0);
  }
  return ignoreCase
    ? (a?.toString()?.toLowerCase() || '').localeCompare((b?.toString()?.toLowerCase() || '').toLowerCase())
    : (a?.toString() || '').localeCompare((b?.toString() || '').toLowerCase());
}

/**
 * This will try and compare two values as numbers, even if they are strings.
 * @param a the first value
 * @param b the second value
 * @returns 0 if equal, gt 0 if a > b, lt 0 if a < b
 */
export function compareNumbers(a, b) {
  if (isNada(a) && isNada(b)) return 0;
  if (isNada(a) && !isNada(b)) return -1;
  if (!isNada(a) && isNada(b)) return 1;
  const aFloat = typeof a !== 'number' ? parseFloat(a) : a;
  const bFloat = typeof b !== 'number' ? parseFloat(b) : b;
  return (aFloat || 0) - (bFloat || 0);
}

/**
 * Return true if null or undefined
 * @param x a variable
 * @returns true if the value is null or undefined
 */
function isNada(x): boolean {
  return x === null || x === undefined;
}
