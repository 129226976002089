import { Select } from 'antd';
import { CustomerWhereInput } from 'generated/graphql';
import { getSelectedFranchise } from 'services/userService';
import { ScheduleGridRow } from './schedule.models';
import { DetailsViewFieldLabel, ScheduleSelectButton, ScheduleTableHeaderP } from './schedule.styled';
import StyledSelect from 'components/common/input/BaseSelect';
import React from 'react';
import { differenceInCalendarWeeks, isPast, isToday } from 'date-fns';

const { Option } = Select;

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function isPastDate(dayOfWeek) {
  const date = new Date(dayOfWeek.date);
  console.log(`Date: ${date}`);
  console.log(`isPast(date): ${isPast(date)}`);
  if (isPast(date)) {
    if (isToday(date) && new Date().getHours() <= 17) {
      return false;
    }
    return true;
  }
  return false;
}
export function isPastTime(hourSelection) {
  const currentHour = new Date().getHours();
  console.log(currentHour);
  return hourSelection <= currentHour;
}
export function getDaysOfCurrentWeek(offset?: number) {
  const now = new Date();
  const today = now.getDate();
  const currentDay = now.getDay();
  const roundUp = now.getDay() === 0 ? offset || 0 + 1 : null;
  const firstOfWeek = now.setDate(today - ((currentDay || 7) - (roundUp || offset || 0) * 7));

  return [...Array(7)].map((_, index) => {
    const result = new Date(firstOfWeek);
    result.setDate(result.getDate() + index);

    return {
      day: days[index],
      date: new Date(result).toLocaleDateString(),
    };
  });
}

export function calculateWeekOffset(date: any) {
  return -1 * differenceInCalendarWeeks(new Date(), new Date(date));
}

const dateHasPassed = (date: string) => new Date(date).getTime() < new Date().getTime();

export function getScheduleColumns(
  weekOffset: number,
  trial: boolean,
  handleTimeSlotInput: any,
  handleTrialDayInput: any,
  readOnly: boolean,
  trialDays: string[],
  timeSlot: any
) {
  const daysOfWeek = getDaysOfCurrentWeek(weekOffset);

  const columns = daysOfWeek.map(dayOfWeek => {
    const title = (
      <React.Fragment key={dayOfWeek.date}>
        <ScheduleTableHeaderP>{dayOfWeek.day}</ScheduleTableHeaderP>
        <ScheduleTableHeaderP>{dayOfWeek.date}</ScheduleTableHeaderP>
      </React.Fragment>
    );

    return {
      key: dayOfWeek.day,
      title,
      dataIndex: dayOfWeek.day,
      render: (text: string, record: ScheduleGridRow, index: number) => {
        if (trial) {
          return (
            <ScheduleSelectButton
              disabled={isPastDate(dayOfWeek)}
              type='primary'
              onClick={event => {
                if (!isPast(new Date(dayOfWeek.date)) && !readOnly) {
                  handleTrialDayInput(dayOfWeek.date);
                }
              }}
              $selected={trialDays?.includes(dayOfWeek.date)}
            >
              Select
            </ScheduleSelectButton>
          );
        }
        return readOnly ? (
          <DetailsViewFieldLabel>{dayOfWeek.date === timeSlot?.date && timeSlot?.time}</DetailsViewFieldLabel>
        ) : (
          <StyledSelect
            disabled={isPastDate(dayOfWeek)}
            defaultValue={timeSlot?.date === dayOfWeek.date ? timeSlot.time : 'default'}
            value={timeSlot?.date === dayOfWeek.date ? timeSlot.time : 'default'}
            $editmode
            onSelect={value => {
              if (!isPast(new Date(dayOfWeek.date))) handleTimeSlotInput(dayOfWeek.date, value);
            }}
          >
            <Option value='default'>Select Time</Option>
            <Option disabled={isPastTime(9)} value='9:00 AM'>
              9:00 AM
            </Option>
            <Option disabled={isPastTime(10)} value='10:00 AM'>
              10:00 AM
            </Option>
            <Option disabled={isPastTime(11)} value='11:00 AM'>
              11:00 AM
            </Option>
            <Option disabled={isPastTime(12)} value='12:00 PM'>
              12:00 PM
            </Option>
            <Option disabled={isPastTime(13)} value='1:00 PM'>
              1:00 PM
            </Option>
            <Option disabled={isPastTime(14)} value='2:00 PM'>
              2:00 PM
            </Option>
            <Option disabled={isPastTime(15)} value='3:00 PM'>
              3:00 PM
            </Option>
            <Option disabled={isPastTime(16)} value='4:00 PM'>
              4:00 PM
            </Option>
            <Option disabled={isPastTime(17)} value='5:00 PM'>
              5:00 PM
            </Option>
          </StyledSelect>
        );
      },
    };
  });

  return columns;
}

export function buildLeadWhereClause(): CustomerWhereInput {
  let franchiseId = '00000000-0000-0000-0000-000000000000';
  const franchise = getSelectedFranchise();
  if (franchise) {
    franchiseId = franchise.id;
  }
  return {
    franchiseId: {
      equals: franchiseId,
    },
    AND: [
      {
        convertedFromLead: {
          equals: null,
        },
      },
      {
        inactive: {
          equals: null,
        },
      },
      {
        deleted: {
          equals: null,
        },
      },
    ],
  } as CustomerWhereInput;
}
