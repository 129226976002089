/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'antd';
import { StyledButton } from 'components/common/styled';
import { Customer } from 'generated/graphql';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation } from '@apollo/client';
import { CONVERT_FROM_LEAD } from 'graphql/mutation/customer';
import { useHistory } from 'react-router-dom';

const ActionBoxTitle = styled.div`
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  font-weight: 400;
`;

const ActionBoxBar = styled.div`
  height: 7px;
  border-radius: 10px;
  width: 100%;
  max-width: 200px;
`;

const CompleteBar = styled(ActionBoxBar)`
  background-color: ${theme['@green']};
  margin-top: 0.6em;
  margin-bottom: 0.9em;
  width: 375px;
`;

const IncompleteBar = styled(ActionBoxBar)`
  background-color: ${theme['@progress-bar-empty-color']};
  margin-top: 0.6em;
  margin-bottom: 0.9em;
  width: 375px;
`;

const LeadActionButton = styled(StyledButton)`
  margin-right: 0.3em;
  margin-left: 0.3em;
  .ant-btn {}
    span {
      padding-right: 1.5em;
      padding-left: 1.5em;
    }
  }
`;

export const DoubleActionButton = styled(LeadActionButton)`
  .ant-btn {}
    span {
      padding-right: 0.5em;
      padding-left: 0.5em;
    }
  }
`;

function LeadActions({ lead }: { lead: any }) {
  const [convertFromLead] = useMutation(CONVERT_FROM_LEAD);
  const history = useHistory();

  return (
    <Row gutter={24} justify='space-evenly' style={{ marginBottom: '2em' }}>
      <Col xl='1' md='24' xs='24'>
        <ActionBoxTitle>DEMO</ActionBoxTitle>
        {lead?.demoCompleted ? <CompleteBar /> : <IncompleteBar />}
        <Row justify='center'>
          <LeadActionButton
            $color={theme['@blue']}
            size='medium'
            onClick={() => {
              history.push(
                `/create-smash-order/?lead=true&orderType=demo&customerId=${lead?.customerId}&locationId=${lead?.locationId}&skipTo=order-details`
              );
            }}
          >
            SCHEDULE DEMO
          </LeadActionButton>
        </Row>
      </Col>
      <Col xl='1' md='24' xs='24'>
        <ActionBoxTitle>TRIAL</ActionBoxTitle>
        {lead?.trialCompleted ? <CompleteBar /> : <IncompleteBar />}
        <Row justify='center'>
          <LeadActionButton
            $color={theme['@blue']}
            size='medium'
            onClick={() => {
              history.push(
                `/create-smash-order/?lead=true&orderType=trial&customerId=${lead?.customerId}&locationId=${lead?.locationId}&skipTo=order-details`
              );
            }}
          >
            SCHEDULE TRIAL
          </LeadActionButton>
        </Row>
      </Col>
      <Col xl='1' md='24' xs='24'>
        <ActionBoxTitle>FINALIZE</ActionBoxTitle>
        <Row justify='space-evenly'>
          <IncompleteBar />
        </Row>
        <Row justify='center' wrap={false}>
          <DoubleActionButton
            $color={theme['@green']}
            size='medium'
            onClick={() => {
              history.push(`/leads/convert/${lead?.locationId}`); // Takes you to the ConvertLeadPage.
              // convertFromLead({ variables: { data: { hubspotDealId: lead.hubspotDealId, isDealWon: true } } });
            }}
          >
            CONVERT
          </DoubleActionButton>
          <DoubleActionButton
            $color={theme['@red']}
            size='medium'
            onClick={() => {
              convertFromLead({ variables: { data: { hubspotDealId: lead.hubspotDealId, isDealWon: false } } });
            }}
          >
            ARCHIVE
          </DoubleActionButton>
        </Row>
      </Col>
    </Row>
  );
}

export default LeadActions;
