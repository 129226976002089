import { gql } from '@apollo/client';

export const CORE_FRANCHISE_FIELDS = gql`
  fragment CoreFranchiseFields on Franchise {
    id
    number
    name
    dba
    contactFirstName
    contactLastName
    contactEmail
    contactPhone
    franchiseRegionId
    franchiseRegion {
      id
      description
    }
    websiteUrl
    updatedAt
  }
`;
