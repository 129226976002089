import { gql } from '@apollo/client';

export const SMASH_LIST = gql`
  query SmashList($where: WorkOrderWhereInput, $trucksWhere: TruckWhereInput) {
    workOrders(where: $where) {
      id
      number
      serviceType
      _count {
        smashOperations
      }
      scheduleEndTime
      scheduleStartTime
      scheduleDate
      location {
        name
        customer {
          id
          name
        }
      }
      truck {
        id
        number
        smashSerialNumber
      }
    }
    trucks(where: $trucksWhere) {
      id
      number
      smashSerialNumber
    }
  }
`;
