import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  justify-self: start;
  margin: 4px;
  background-color: inherit;

  ${props =>
    !props.$editmode &&
    css`
      pointer-events: none;
    `}
`;

export default StyledCheckbox;
