import { gql } from '@apollo/client';
import { CORE_CUSTOMER_FIELDS } from '../fragment/customer';

export const CREATE_CUSTOMER = gql`
  ${CORE_CUSTOMER_FIELDS}
  mutation CreateCustomer($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      ...CoreCustomerFields
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($data: UpdateCustomerDetailsInput!, $where: CustomerDetailsWhereInput!) {
    updateCustomerDetails(data: $data, where: $where) {
      id
      name
      franchiseId
      addressId
      inactive
      customerContacts {
        id
        firstName
        lastName
        phone
        email
        jobTitle
        preferredContactMethod
        contactTypes
        contactCategories
        visibleInCustomerList
      }
    }
  }
`;

export const UPDATE_LOCATION_BROKER = gql`
  mutation UpdateLocationBroker($data: UpdateLocationBrokerInput!, $where: UpdateLocationWhereInput!) {
    updateLocationBroker(data: $data, where: $where) {
      locationId
      brokerName
      notes
      contactFirstName
      contactLastName
      contactPhone
      contactEmail
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_LOCATION_DUMPSTERS = gql`
  mutation UpdateLocationDumpsters($data: UpdateLocationDumpsterInput!, $where: UpdateLocationWhereInput!) {
    updateLocationDumpsters(data: $data, where: $where) {
      id
      dumpsters {
        id
        name
        serialNumber
        dumpsterSizeId
        dumpsterHauler
        notes
        agreementManaged
        locationId
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_LOCATION_ASSETS = gql`
  mutation UpdateLocationAssets($data: UpdateLocationAssetInput!, $where: UpdateLocationWhereInput!) {
    updateLocationAssets(data: $data, where: $where) {
      id
      locationAssets {
        id
        locationId
        name
        assetTypeId
        provider
        wasteStreamId
        size
        unitCount
        contractExpiration
        underContract
        priceDetails
        locationNotes
        notes
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;

export const CONVERT_FROM_LEAD = gql`
  mutation ConvertFromLead($data: ConvertLeadInput) {
    convertFromLead(data: $data) {
      id
      hubspotDealId
      convertedFromLead
    }
  }
`;
