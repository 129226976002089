import { isEmpty } from '@aws-amplify/core';
import { userState, franchiseState } from 'apollo/context';
import { Franchise, GetUserQuery, User } from 'generated/graphql';
import { DbConstants } from 'generated/smt-constants';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';
import { SignedInUser } from 'models/signedInUser';

const FranchiseStorageKeyPrefix = 'franchise-';

/**
 *
 * @returns The currently signed in user.
 */
export function getSignedInUser(): SignedInUser {
  return userState();
}

/**
 * Set the currently signed in user.
 * @param userInfo the user that is currently signed in.
 */
export function setSignedInUser(userInfo: SignedInUser | null): void {
  if (userInfo) {
    const u = {
      id: userInfo.id,
      email: userInfo.email,
      userType: userInfo.userType,
      superAdmin: userInfo.superAdmin,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      region: userInfo.region,
      regionId: userInfo.regionId,
      roles: [...userInfo.roles],
      permissions: [...userInfo.permissions],
      createdAt: userInfo.createdAt,
      franchises: [],
    } as SignedInUser;
    if (userInfo?.franchises?.length > 0) {
      if (userInfo?.franchises[0]?.name) {
        u.franchises = userInfo?.franchises.map<FranchiseIdentifier>(f => ({ id: f.id, name: f.name }));
      }
    }

    userState(u);
  }
}

/**
 * Convert the user to a "SignedInUser"
 * @param user the user that is currently signed in.
 */
export function convertToSignedInUser(queryResult: GetUserQuery | undefined): SignedInUser | null {
  const user = queryResult?.user;
  if (user) {
    const u = {
      id: user?.id,
      email: user?.email,
      userType: user?.userType,
      superAdmin: user?.superAdmin,
      firstName: user?.firstName,
      lastName: user?.lastName,
      region: user?.franchiseRegion?.description,
      regionId: user?.franchiseRegionId,
      roles: [],
      permissions: [],
      createdAt: user?.createdAt,
      franchises: [],
    } as SignedInUser;

    const isCorporate = u.userType === DbConstants.UserType.Corporate;

    // add the default role permissions if the user type fits.
    if (!user.role.userType || user.role.userType === user.userType) {
      u.roles.push(user.roleId);
      user.role.permissions.forEach(p => u.permissions.push(p));
    }

    if (user.userRoles) {
      user.userRoles.forEach(ur => {
        // Only give users access to roles that apply to thier user type.
        if (!ur.role.userType || ur.role.userType === user.userType) {
          // Add the role if they don't already have it.
          if (u.roles.indexOf(ur.role.id) === -1) u.roles.push(ur.role.id);

          if (ur.role.permissions) {
            ur.role.permissions.forEach(p => {
              // add the permission if they don't already have it.
              if (u.permissions.indexOf(p) === -1) u.permissions.push(p);
            });
          }
        }
      });
    }

    if (!isCorporate && (user?.userFranchises?.length || 0) > 0) {
      u.franchises =
        user?.userFranchises?.map<FranchiseIdentifier>(f => ({ id: f.franchise.id, name: f.franchise.name })) || [];
    } else if (isCorporate && (user?.franchiseRegion?.franchises?.length || 0) > 0) {
      u.franchises =
        user?.franchiseRegion?.franchises?.map<FranchiseIdentifier>(f => ({ id: f.id, name: f.name })) || [];
    }

    return u;
  }
  return null;
}

/**
 * gets the selected franchise for the signed in user. This manages all state and storage.
 * @returns the selected franchise
 */
export function getSelectedFranchise(): FranchiseIdentifier | null {
  const u = userState();
  if (!u || isEmpty(u)) return null;

  let franchise2return: FranchiseIdentifier | null = franchiseState();
  // if the franchise isn't in state yet, grab it from local storage.
  if (!franchise2return?.id) {
    const currentFranchiseJson = localStorage.getItem(franchiseStorageKey(u.id));
    if (currentFranchiseJson) {
      franchise2return = JSON.parse(currentFranchiseJson);
      if (franchise2return?.id) franchiseState(franchise2return as FranchiseIdentifier);
    }
  }
  // if we found the franchise in state or local storage and this is a franchise user,
  // make sure it still belongs to the user in question.
  if (!hasAccessToAllFranchises() && !u.franchises.find(x => x.id === franchise2return?.id)) {
    franchise2return = null;
  }

  // if we still don't have the selected franchise, get it from the user object.
  if (!franchise2return?.id) {
    franchise2return = getFirstFranchiseFromUser();
    if (franchise2return?.id) {
      franchiseState(franchise2return as FranchiseIdentifier);
      localStorage.setItem(franchiseStorageKey(u.id), JSON.stringify(franchise2return));
    }
    // TODO: corporate users don't have to have any franchises assigned to them.
  }

  return franchise2return;
}

/**
 * Sets the current selected franchise into storage.
 * @param franchiseInfo
 * @returns
 */
export function setSelectedFranchise(franchiseInfo: FranchiseIdentifier | Franchise): void {
  const u = userState();
  if (!u) return;

  const f = { id: franchiseInfo.id, name: franchiseInfo.name } as FranchiseIdentifier;
  franchiseState(f);
  localStorage.setItem(franchiseStorageKey(u.id), JSON.stringify(f));
}

/**
 * Does this user have corporate level access?
 * @param user (optional) the user to check on.
 * @returns true if this is a corporate user.
 */
export function isCorporateUser(user?: SignedInUser): boolean {
  return (user?.userType || userState()?.userType) === DbConstants.UserType.Corporate;
}

export function hasAccessToAllFranchises(user?: SignedInUser): boolean {
  const u = user || userState();
  return u.userType === DbConstants.UserType.Corporate && !u.regionId;
}

/**
 * Does this user have the permission requested?
 * @param permission the permission to check for
 * @returns true if the use has this permission
 */
export function hasPermission(permission: string): boolean {
  const u = userState();
  if ((u?.permissions?.length || 0) === 0) return false;
  return !!u.permissions.find(p => p.toLowerCase() === permission?.toLowerCase());
}

/**
 * Clears all the session information for the current user.
 */
export function resetUserSessionData() {
  const u = userState();
  if (u) localStorage.removeItem(franchiseStorageKey(u.id));
  userState(undefined);
  franchiseState(undefined);
}

/**
 * Each user that logs into this computer will have a different default franchise, so add the userid to the key.
 * @param userId the current userId
 * @returns a key for use with local storage
 */
function franchiseStorageKey(userId: string) {
  return `${FranchiseStorageKeyPrefix}${userId}`;
}

/**
 * Returns the first franchise for the currently logged in user.
 * @returns the first franchise for this user
 */
function getFirstFranchiseFromUser(): FranchiseIdentifier | null {
  const u = userState();
  if (!u) return null;

  return u.franchises.length > 0 ? u.franchises[0] : null;
}
