/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { AutoComplete } from 'antd';
import { RHFAutoCompleteParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd input control using a react-hook-form controller.
 * see: https://ant.design/components/auto-complete/ (AntD Input reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 * @param params Options for the antd input control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFAutoComplete(params: RHFAutoCompleteParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName =
    params.viewModeClassName ||
    (params.hideBorder ? 'rhf-label-noborder rhf-label-single-line' : 'rhf-label rhf-label-single-line');
  const viewModeValue =
    params.options?.find(statusObj => statusObj.value === params.defaultValue)?.label || params.defaultValue;
  const viewModeElement = viewModeValue ? (
    <div className={labelClassName}>{viewModeValue}</div>
  ) : (
    <div className={labelClassName}>&nbsp;</div>
  );
  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => (
          <AutoComplete {...field} options={params.options} filterOption={params.filterOption} {...attributes} />
        )}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
