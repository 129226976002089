import { Col, Row } from 'antd';
import { CardHeader, ContentCard, StyledCard } from 'components/common/card/Card';

function TruckCard(props) {
  const { truck } = props;
  const workOrders = props.workOrders.filter(workOrder => workOrder.truck?.id === truck?.id);
  const customerIds = workOrders.map(wo => wo.location.customer.id);
  const customerCount = [...new Set(customerIds)].length;
  const smashTimeMilliseconds = workOrders
    .map(record => new Date(record.scheduleEndTime).getTime() - new Date(record.scheduleStartTime).getTime())
    .reduce((a, b) => a + b, 0);
  const title = (
    <CardHeader>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        {truck.smashSerialNumber}
      </div>
    </CardHeader>
  );

  return (
    <ContentCard title={title}>
      <Row>
        <Col span={12}>Hours: {Math.round(smashTimeMilliseconds / 360000) / 10}</Col>
        <Col span={12}>Customers: {customerCount}</Col>
      </Row>
    </ContentCard>
  );
}

function TruckCards(props) {
  return (
    <Row justify='space-between'>
      {props.data?.trucks?.map(truck => (
        <Col key={truck.id} xl={5} sm={11}>
          <TruckCard truck={truck} workOrders={props.data.workOrders} />
        </Col>
      ))}
    </Row>
  );
}

export default TruckCards;
