import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faCalendarDays,
  faChartLine,
  faFileInvoiceDollar,
  faGauge,
  faGears,
  faLocationDot,
  faStore,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSignedInUser, isCorporateUser } from 'services/userService';
import { Auth } from 'aws-amplify';
// import MenuItem from 'antd/lib/menu/MenuItem';

interface NavLink {
  icon?: IconDefinition;
  name: string;
  link?: string;
  sectionHeader?: boolean;
  className?: string;
}

type MenuItem = Required<MenuProps>['items'][number];

export function getMenuItems() {
  const navLinkData = getMenuItemNavLinks();
  const menuItems = navLinkData.map(item => {
    const i: MenuItem = {
      key: item.link || item.name,
      label: item.link ? <Link to={item.link!}>{item.name}</Link> : item.name,
      icon: item.icon ? <FontAwesomeIcon icon={item.icon!} /> : null,
      className: item.className,
    };

    return i;
  });

  menuItems.push({
    key: 'Logout',
    label: (
      <Link to='#API' title='API'>
        Logout
      </Link>
    ),
    onClick: () => Auth.signOut(),
    icon: <FontAwesomeIcon icon={faUser} />,
  });

  return menuItems;
}

function getMenuItemNavLinks() {
  const menuItems: NavLink[] = [];
  const isCorporate = isCorporateUser();
  const user = getSignedInUser();
  const isMultiFranchiseUser = !isCorporate && (user?.franchises?.length || 0) > 1;
  const isFranchiseUserWithNoPermissions = !isCorporate && (user?.permissions?.length || 0) === 0;

  if (isCorporate || isMultiFranchiseUser) {
    menuItems.push({
      icon: faStore,
      name: 'Franchise List',
      link: '/franchise',
    });
  }

  menuItems.push(
    ...[
      {
        icon: faGauge,
        name: 'Smashboard',
        link: '/',
      },
      {
        icon: faAddressCard,
        name: 'Customers',
        link: '/customers',
      },
      {
        icon: faUsers,
        name: 'Leads',
        link: '/leads',
      },
    ]
  );
  if (!isCorporate) {
    menuItems.push(
      ...[
        {
          icon: faCalendarDays,
          name: 'Schedule',
          link: '/schedule',
        },
        {
          icon: faLocationDot,
          name: 'Route',
          link: '/route',
        },
      ]
    );
  }
  menuItems.push(
    ...[
      {
        icon: faFileInvoiceDollar,
        name: 'Invoices',
        link: '/invoices',
      },
      {
        icon: faChartLine,
        name: 'Reports',
        link: '/reports',
      },
    ]
  );

  menuItems.push({
    name: 'Account',
    sectionHeader: true,
    className: 'menu-section-header',
  });

  if (!isFranchiseUserWithNoPermissions) {
    menuItems.push({
      icon: faGears,
      name: 'Settings',
      link: '/settings',
    });
  }

  return menuItems;
}

// const menuItems: NavLink[] = [
//   {
//     icon: faGauge,
//     name: 'Smashboard',
//     link: '/',
//   },
//   {
//     icon: faAddressCard,
//     name: 'Customers',
//     link: '/customers',
//   },
//   {
//     icon: faUsers,
//     name: 'Leads',
//     link: '/leads',
//   },
//   {
//     icon: faCalendarDays,
//     name: 'Schedule',
//     link: '/schedule',
//   },
//   {
//     icon: faFileInvoiceDollar,
//     name: 'Financials',
//     link: '/financials',
//   },
//   {
//     icon: faLocationDot,
//     name: 'Jobs',
//     link: '/jobs',
//   },
//   {
//     icon: faChartLine,
//     name: 'Reports',
//     link: '/reports',
//   },
//   {
//     icon: faList,
//     name: 'Directory',
//     link: '/directory',
//   },
//   {
//     name: 'Account',
//     sectionHeader: true,
//   },
//   {
//     icon: faStore,
//     name: 'Franchise',
//     link: '/franchise',
//   },
//   {
//     icon: faGears,
//     name: 'Settings',
//     link: '/settings',
//   },
//   {
//     icon: faBuilding,
//     name: 'Corporate',
//     link: '/corporate',
//   },
//   {
//     icon: faUser,
//     name: 'First Last',
//     link: '/profile',
//   },
// ];

// export default menuItems.map(item => {
//   const i: MenuItem = {
//     key: item.link!,
//     label: item.link ? <Link to={item.link!}>{item.name}</Link> : item.name,
//     icon: item.icon ? <FontAwesomeIcon icon={item.icon!} /> : null,
//   };
//   return i;
// });
