import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_LOCATIONS } from 'graphql/query/customer';
import {
  StyledSelectableTable,
  StyledTable,
  TableActiveToggleSwitch,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from '../common/card/TableCard';
import { StyledPlusButton } from 'components/common/styled';
import { PlusOutlined } from '@ant-design/icons';
import { GetCustomerLocationsQuery } from 'generated/graphql';
import { LocationGridRow, LocationSearchState } from './location.models';
import { buildLocationsWhereClause, getFilteredLocationResults, getLocationColumns } from './location.service';

function LocationTableView({
  gridHeight = 600,
  editable = true,
  redirect = true,
  selectable = false,
  setSelectedGridRow,
  customerIdProp,
  selectedLocationId,
}: {
  gridHeight?: number;
  editable?: boolean;
  redirect?: boolean;
  selectable?: boolean;
  setSelectedGridRow?: (row: LocationGridRow | null) => void;
  customerIdProp?: string;
  selectedLocationId?: string;
}) {
  const { customerId } = useParams<{ customerId: string }>();

  const history = useHistory();
  const [searchState, setSearchState] = useState<LocationSearchState>({
    filter: '',
    active: true,
    selectedRowKey: selectedLocationId || null,
  });

  const { loading, data } = useQuery<GetCustomerLocationsQuery>(GET_CUSTOMER_LOCATIONS, {
    variables: { where: buildLocationsWhereClause(customerIdProp || customerId) },
  });

  const activeData = getFilteredLocationResults(searchState, data);

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>SERVICE LOCATIONS</TableHeaderText>
        {editable && (
          <>
            <StyledPlusButton
              size='medium'
              shape='circle'
              icon={<PlusOutlined />}
              // eslint-disable-next-line no-console
              onClick={() => console.log('This will create a location')}
            />
            <TableActiveToggleSwitch
              active={searchState.active}
              toggleActiveData={() => setSearchState({ ...searchState, active: !searchState.active })}
            />
          </>
        )}
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch onChange={value => setSearchState({ ...searchState, filter: value })} />
      </TableHeaderRight>
    </TableHeader>
  );

  return (
    <TableCard title={header}>
      <StyledSelectableTable
        rowSelection={{
          selectedRowKeys: [searchState.selectedRowKey],
          type: 'radio',
          onChange: selRowKeys =>
            setSearchState({ ...searchState, selectedRowKey: selRowKeys?.length > 0 ? selRowKeys[0] : null }),
        }}
        loading={loading}
        dataSource={activeData}
        columns={getLocationColumns()}
        pagination={false}
        scroll={
          !loading && (activeData?.length || 0) > 10
            ? {
                y: gridHeight,
              }
            : null
        }
        rowKey='id'
        onRow={record => ({
          onDoubleClick: () => {
            if (redirect) history.push(`/customers/${record.customerId}/${record.id}`);
          },
          onClick: () => {
            if (selectable) {
              const isCurrentlySelected = searchState.selectedRowKey === record.id;
              setSearchState({
                ...searchState,
                selectedRowKey: isCurrentlySelected ? null : record.id,
              });
              if (record && setSelectedGridRow) {
                setSelectedGridRow(isCurrentlySelected ? null : (record as LocationGridRow));
              }
            }
          },
        })}
      />
    </TableCard>
  );
}

export default LocationTableView;
