import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

/**
 * The antd datepicker uses momentjs by default. Because we chose dateFns, we have to create this custom date picker
 * see: https://ant.design/docs/react/replace-moment
 */
const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export default DatePicker;
