/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { GetFranchiseEmployeeListQuery, UserWhereInput } from 'generated/graphql';
import { formatPhone } from 'services/formattingService';
import { autoCompare } from 'services/sortingService';
import { EmployeeForm, EmployeeGridRow, EmployeeSearchState } from './employee.models';

const { Text } = Typography;

export function buildEmployeeWhereClause(id: string): UserWhereInput {
  return {
    id: {
      equals: id,
    },
    userType: { equals: 'franchise' },
  };
}

/**
 * Get the employee columns for the list screen
 * @returns the columns
 */
export function getEmployeeColumns(): ColumnProps<EmployeeGridRow>[] {
  const columns = [
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      render: (text: string, record: EmployeeGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.firstName, b.firstName),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      render: (text: string, record: EmployeeGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.lastName, b.lastName),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      render: (text: string, record: EmployeeGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.title, b.title),
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      render: (text: string, record: EmployeeGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.phone, b.phone),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      render: (text: string, record: EmployeeGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.email, b.email),
    },
  ] as ColumnProps<EmployeeGridRow>[];

  return columns;
}

export function buildEmployeeFormObject(user: any | undefined): EmployeeForm {
  return {
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    title: user?.title || undefined,
    email: user?.email,
    phone: formatPhone(user?.phone) || undefined,
    primaryRole: user?.roleId,
    isDriver: user?.roleId === 'driver' || !!user?.userRoles.find(x => x.roleId === 'driver'),
    isFinance: user?.roleId === 'finance' || !!user?.userRoles.find(x => x.roleId === 'finance'),
    isManager: user?.roleId === 'manager' || !!user?.userRoles.find(x => x.roleId === 'manager'),
    isOperations: user?.roleId === 'operations' || !!user?.userRoles.find(x => x.roleId === 'operations'),
    isOwner: user?.roleId === 'owner' || !!user?.userRoles.find(x => x.roleId === 'owner'),
    isSales: user?.roleId === 'sales' || !!user?.userRoles.find(x => x.roleId === 'sales'),
  } as EmployeeForm;
}

/**
 * Get the scroll data for the employee search table. If there are less than 10 rows, don't do scrolling.
 * @param loading is the data still loading?
 * @param activeData the filtered data to be bound to the table
 * @param dimensions the window dimensions
 * @returns scroll data for the antd table
 */
export function getEmployeeScrollData(
  loading: boolean,
  activeData: EmployeeGridRow[],
  dimensions: { height: number; width: number }
) {
  return !loading && (activeData?.length || 0) > 10
    ? {
        y: dimensions.height > 500 ? Math.round(dimensions.height - 250) : 300,
      }
    : null;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredEmployeeResults(
  state: EmployeeSearchState,
  queryResult?: GetFranchiseEmployeeListQuery
): EmployeeGridRow[] {
  let results: EmployeeGridRow[] = [];
  const records = (queryResult?.franchise?.userFranchises.map(f => f.user) || []).filter(
    u => !u.deleted && !u.inactive
  );
  if (records && records.length > 0) {
    const filter = (state.filter || '').toLowerCase();
    results = records.map(
      rec =>
        ({
          id: rec.id,
          firstName: rec.firstName,
          lastName: rec.lastName,
          title: rec.title,
          phone: formatPhone(rec.phone),
          email: rec.email,
        } as EmployeeGridRow)
    );
    results = results.filter(
      rec =>
        rec.firstName?.toLowerCase()?.includes(filter) ||
        rec.lastName?.toLowerCase()?.includes(filter) ||
        rec.title?.toLowerCase()?.includes(filter) ||
        rec.phone?.toLowerCase()?.includes(filter) ||
        rec.email?.toLowerCase()?.includes(filter)
    );
  }
  return results;
}
