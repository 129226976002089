/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { format } from 'date-fns';
import DatePicker from 'components/common/DatePicker';
import { processAttributesAndValidator } from './rhf.service';
import { RHFDatePickerParams } from './rhf.models';

/**
 * An antd date-picker control using a react-hook-form controller.
 * see: https://ant.design/components/date-picker/ (AntD DatePicker reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd DatePicker control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFDatePicker(params: RHFDatePickerParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName = params.viewModeClassName || (params.hideBorder ? 'rhf-label-noborder' : 'rhf-label');
  let defVal = params.defaultValue || params.control?._defaultValues[params.name];
  if (params.editMode) {
    return (
      <>
        <Controller
          name={params.name}
          control={params.control}
          rules={params.rules}
          defaultValue={params.defaultValue}
          render={({ field }) => <DatePicker {...field} {...attributes} />}
        />
        {validator}
      </>
    );
  }

  let viewModeValue: string | null = null;
  if (defVal instanceof Date && Number.isNaN(defVal)) defVal = null;
  if (defVal) {
    if (typeof defVal === 'string') {
      defVal = new Date(defVal);
    }
    if (defVal instanceof Date && !Number.isNaN(defVal)) {
      viewModeValue = params.dateFormatFunction ? params.dateFormatFunction(defVal) : format(defVal, 'yyyy-MM-dd');
    }
  }
  const viewModeElement = viewModeValue ? (
    <div className={`${labelClassName} rhf-label-nofill`} style={params.viewModeStyle}>
      {viewModeValue}
    </div>
  ) : (
    <div className={labelClassName}>&nbsp;</div>
  );
  return viewModeElement;
}
