import { Col, Row, Tooltip } from 'antd';
import StyledInput from 'components/common/input/BaseInput';
import { ContactType } from 'models/customer';
import { CustomerContact } from 'generated/graphql';
import {
  CustomerRow,
  FormNoWrapLabel,
  StyledPrimaryContactCheckbox,
  ContactEmailInfoIcon,
  CustomerFlexCol,
  NestedCustomerFlexCol,
} from './customer.styled';
import { FormSectionTitle } from 'components/common/styled';
import { isValidEmail, isValidPhone } from '../../services/validationService';
import { RHFWrapper } from 'components/common/react-hook-form/RHFWrapper';
import { RHFCheckboxWrapper } from 'components/common/react-hook-form/RHFCheckboxWrapper';

function CustomerContactInfoForm({
  contact,
  editmode,
  validator,
  index,
  locationContact = false,
}: {
  contact: CustomerContact;
  editmode: boolean;
  validator: any;
  index?: number;
  locationContact?: boolean;
}) {
  const path =
    index !== undefined
      ? `${locationContact ? 'locationContacts' : 'customerContacts'}.${index}.${
          locationContact ? 'customerContact.' : ''
        }`
      : '';

  return (
    <CustomerRow gutter={[24, 12]}>
      {!locationContact && (
        <CustomerFlexCol xl={24} xs={24}>
          <FormSectionTitle>Job Title:</FormSectionTitle>
          <RHFWrapper name={`${path}jobTitle`} rules={{ required: 'Job Title is required' }}>
            <StyledInput placeholder='Contact Job Title' $editmode={editmode} readOnly={!editmode} />
          </RHFWrapper>
        </CustomerFlexCol>
      )}

      <CustomerFlexCol xl={24} xs={24}>
        <FormSectionTitle>First:</FormSectionTitle>
        <RHFWrapper name={`${path}firstName`} rules={{ required: 'First Name is required' }}>
          <StyledInput placeholder='Contact First Name' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
      </CustomerFlexCol>

      <CustomerFlexCol xl={24} xs={24}>
        <FormSectionTitle>Last:</FormSectionTitle>
        <RHFWrapper name={`${path}lastName`} rules={{ required: 'Last Name is required' }}>
          <StyledInput placeholder='Contact Last Name' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
      </CustomerFlexCol>

      <CustomerFlexCol xl={24} xs={24}>
        <FormSectionTitle>Email:</FormSectionTitle>
        <RHFWrapper
          name={`${path}email`}
          rules={{ required: 'Email is required', validate: value => isValidEmail(value) || 'Email format is invalid' }}
        >
          <StyledInput placeholder='Email' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
        {contact.contactTypes[0] === ContactType.BILLING ? (
          <Tooltip title='This is the billing email. Some text goes here...'>
            <ContactEmailInfoIcon />
          </Tooltip>
        ) : undefined}
      </CustomerFlexCol>

      <CustomerFlexCol xl={24} xs={24}>
        <NestedCustomerFlexCol xl={12} xs={24}>
          <FormSectionTitle>Phone:</FormSectionTitle>
          <RHFWrapper
            name={`${path}phone`}
            rules={{
              required: 'Phone is required',
              validate: value => isValidPhone(value) || 'Phone format is invalid',
            }}
          >
            <StyledInput placeholder='Phone' $editmode={editmode} readOnly={!editmode} />
          </RHFWrapper>
        </NestedCustomerFlexCol>
        <NestedCustomerFlexCol style={{ justifySelf: 'end' }} xl={12} xs={24}>
          {contact.contactTypes[0] === ContactType.RELATIONSHIP && !locationContact ? (
            <RHFCheckboxWrapper name={`${path}primary`}>
              <StyledPrimaryContactCheckbox value='primary' $editmode={editmode}>
                <FormNoWrapLabel>PRIMARY CONTACT</FormNoWrapLabel>
              </StyledPrimaryContactCheckbox>
            </RHFCheckboxWrapper>
          ) : undefined}
        </NestedCustomerFlexCol>
      </CustomerFlexCol>
    </CustomerRow>
  );
}

export default CustomerContactInfoForm;
