import { useState, useEffect, useRef } from 'react';
import { generateMockObject, getObjectMetaData, ObjectDataClass, ObjectMetaData } from 'services/dataGenerationService';

/**
 * This is a hook that will return data after a timeout and manage state similarly to the useQuery hook.
 * It can also generate data for you, modeled off of a template object (sampleData). Because typescript
 * doesn't support reflection, we need to have a template object to model off of. If the numberOfRecordsToAdd
 * is set to something over 0, the sampleData array will be replaced by generated objects.
 * @param sampleData params to tell the hook what to do (see the custom type)
 * @returns a MockQueryResult, which is { loading, error, data } - similar to useQuery
 */
function useMockData<TData = object>(params: MockQueryDataArguments<TData>): MockQueryData<TData> {
  const objectType = params?.objectType || ObjectDataClass.Unknown;
  const numberOfRecordsToAdd = params?.numberOfRecordsToAdd || 0;
  const sleepMilliseconds = params?.sleepMilliseconds || 1000;

  // managing the loading flag as a reference
  // const timeoutCalledRef = useRef<number | undefined>(undefined);
  // const loadingRef = useRef<boolean>(true);
  // const numberRef = useRef<number>(-1);
  const infoRef = useRef<{ numberOfRecords: number; loading: boolean; records: TData[]; timeoutHandle?: number }>({
    numberOfRecords: -1,
    loading: true,
    records: [],
    timeoutHandle: undefined,
  });

  // get the columns reference (similar to a class member)
  const [recordsLoaded, setRecordsLoaded] = useState<boolean>(false);

  if (
    infoRef.current.numberOfRecords > 0 &&
    infoRef.current.numberOfRecords !== numberOfRecordsToAdd &&
    infoRef.current.timeoutHandle &&
    (infoRef.current.records?.length || 0) === infoRef.current.numberOfRecords
  ) {
    infoRef.current.timeoutHandle = undefined;
  }

  // load the data from wherever and update the state.
  useEffect(() => {
    if (!infoRef.current.timeoutHandle && (params?.sampleData?.length || 0) > 0) {
      // set the timeout so we can wait to return the data and show the loading screen.
      infoRef.current.timeoutHandle = window.setTimeout(() => {
        let dataToReturn: TData[] = [];
        const wasSampleProvided = params?.sampleData && params?.sampleData.length > 0;
        if (numberOfRecordsToAdd > 0) {
          const metaData =
            params?.metaData ||
            (wasSampleProvided ? getObjectMetaData(params?.sampleData, undefined, objectType) : null);

          if (metaData) {
            for (let i = 0; i < numberOfRecordsToAdd; i += 1) {
              const newObj = generateMockObject(metaData) as TData;
              if (params?.customHandler) params.customHandler(newObj);
              dataToReturn.push(newObj);
            }
          } else if (wasSampleProvided) {
            dataToReturn = params.sampleData?.slice() as TData[];
          }
        } else if (wasSampleProvided) {
          dataToReturn = params.sampleData?.slice() as TData[];
        }
        infoRef.current.numberOfRecords = dataToReturn.length;
        infoRef.current.loading = false;

        infoRef.current.records = dataToReturn;
        setRecordsLoaded(true);
      }, sleepMilliseconds);
    }
  }, [numberOfRecordsToAdd, params, recordsLoaded, objectType, sleepMilliseconds]);

  return { data: infoRef.current.records, error: null, loading: infoRef.current.loading };
}

/**
 * The result of the useMockData hook.
 */
export interface MockQueryData<TData> {
  data: TData[] | undefined;
  error;
  loading: boolean;
}

/**
 * The parameters to the useMockData hook.
 */
export interface MockQueryDataArguments<TData> {
  /** This is the template object or sample data */
  sampleData?: TData[];
  /** You can alternatively pass in meta data and the objects will be generated off of that. */
  metaData?: ObjectMetaData;
  /** This classifies the object (defaults to ObjectDataClass.Unknown) */
  objectType?: ObjectDataClass;
  /** The number of records to add to the list. */
  numberOfRecordsToAdd?: number;
  /** The number of milliseconds to sleep (simulating fetch time) before returning the data. */
  sleepMilliseconds?: number;
  /** Customize the binding of fake data here. */
  customHandler?: (x: TData) => void;
}

export default useMockData;
