import { Col, Row } from 'antd';
import DashboardActionCard from 'components/common/card/DashboardActionCard';
import PageTitle from 'components/common/PageTitle';
import LocationZipCodes from './LocationZipCodes';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as PaymentsIcon } from 'assets/icons/payments.svg';
import { ReactComponent as EmployeesIcon } from 'assets/icons/employees.svg';
import { ReactComponent as SmashTruckIcon } from 'assets/icons/smash-truck.svg';
import { useHistory } from 'react-router-dom';
import { getSelectedFranchise } from 'services/userService';

function Settings() {
  const history = useHistory();
  const franchise = getSelectedFranchise();
  function buttonClicked(e) {
    const buttonText = e?.currentTarget?.innerText;
    if (buttonText === 'OPERATIONS') {
      history.push('/trucks');
    } else if (buttonText === 'EMPLOYEES') {
      history.push('/employees');
    } else {
      // eslint-disable-next-line no-alert
      alert(`The [buttonText] button has been clicked!`);
    }
  }
  return (
    <>
      <PageTitle>{franchise?.name?.toUpperCase()} SETTINGS</PageTitle>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DashboardActionCard
            text='FRANCHISE INFO'
            icon={<InfoIcon />}
            enabled={false}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            text='PAYMENT DETAILS & TAXES'
            icon={<PaymentsIcon />}
            enabled={false}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard text='EMPLOYEES' icon={<EmployeesIcon />} onClick={e => buttonClicked(e)} enabled />
          <DashboardActionCard text='OPERATIONS' icon={<SmashTruckIcon />} onClick={e => buttonClicked(e)} enabled />
        </Col>
      </Row>
      <Row>
        <Col xl={12} xs={24}>
          <LocationZipCodes />
        </Col>
      </Row>
    </>
  );
}

export default Settings;
