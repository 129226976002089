import '../agreement.less';

import { Row, Col, Space, Typography } from 'antd';

import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFInputNumber } from 'components/common/react-hook-form/RHFInputNumber';
import { RHFDatePicker } from 'components/common/react-hook-form/RHFDatePicker';
import { RHFRadioGroup } from 'components/common/react-hook-form/RHFRadioGroup';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';

import { DbConstants } from 'generated/smt-constants';
import { BillingDetailsForm } from '../agreement.models';
import { RspvSz } from '../agreement.service';
import { startOfDay } from 'date-fns';

const { Text } = Typography;

function AgreementBilling({
  billingDetails,
  readOnly,
  editMode,
  watch,
  control,
}: {
  billingDetails?: BillingDetailsForm;
  readOnly?: boolean;
  editMode?: boolean;
  watch?;
  control;
}) {
  const isEditMode = !readOnly && editMode;
  const isDraft = billingDetails?.status === DbConstants.AgreementStatus.Draft;
  const allowBackDating = false;

  let [watchedTaxExempt, watchedBillingPreference] = watch(['taxExempt', 'billingPreference']);
  if (watchedTaxExempt === undefined) watchedTaxExempt = billingDetails?.taxExempt;
  if (watchedBillingPreference === undefined) watchedBillingPreference = billingDetails?.billingPreference;
  const isTaxExempt = !!watchedTaxExempt;
  const isPaymentSystem = watchedBillingPreference === DbConstants.BillingPreference.PaymentSystem;

  return (
    <>
      {/* ****************************************************************************** */}
      {/* *********************************** BILLING ********************************** */}
      {/* ****************************************************************************** */}
      <Row>
        <Col lg={24}>
          <h2>Billing</h2>
        </Col>
      </Row>
      {/* *****************  Row 1 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text>PO #:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFInput
                name='poNumber'
                editMode={isEditMode}
                control={control}
                rules={{ maxLength: { value: 250, message: 'Maximum of 250 characters' } }}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'PO Number',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Billing Frequency:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFSelect
                name='billingFrequency'
                editMode={isEditMode}
                control={control}
                rules={{ required: 'Required' }}
                options={DbConstants.BillingFrequency._KeyValuePairs.map<RHFOption>(o => ({
                  label: o.value,
                  value: o.key,
                }))}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Billing Frequency',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 2 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Service Type:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFSelect
                name='serviceType'
                editMode={isEditMode}
                control={control}
                rules={{ required: 'Required' }}
                options={DbConstants.AgreementServiceType._KeyValuePairs.map<RHFOption>(o => ({
                  label: o.value,
                  value: o.key,
                }))}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Service Type',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Billing Start:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFDatePicker
                name='billingStartDate'
                editMode={isEditMode}
                control={control}
                rules={{
                  required: 'Required',
                  // TODO: this may need to allow "back dating" when backfilling old agreements
                  validate: date =>
                    allowBackDating || !isDraft || date >= startOfDay(new Date()) || 'Must be today or in the future.',
                }}
                attributes={{
                  placeholder: 'Billing Start Date',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 3 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Net:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space>
                <RHFInputNumber
                  name='paymentTermsNetDays'
                  editMode={isEditMode}
                  control={control}
                  rules={{ required: 'Required' }}
                  attributes={{
                    style: { width: '4em' },
                    placeholder: 'NET Days',
                    min: 0,
                    max: 365,
                    precision: 0,
                  }}
                />
                <Text className='text-adjacent'>Day(s)</Text>
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Billing Preferences:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFRadioGroup
                name='billingPreference'
                editMode={isEditMode}
                control={control}
                rules={{ required: 'Required' }}
                options={DbConstants.BillingPreference._KeyValuePairs.map<RHFOption>(o => ({
                  label: o.value,
                  value: o.key,
                }))}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Billing Preference',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 4 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Tax Exempt:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space align='start'>
                <span>
                  <RHFSelect
                    name='taxExempt'
                    editMode={isEditMode}
                    control={control}
                    options={[
                      { label: 'Taxable', value: false },
                      { label: 'Tax Exempt', value: true },
                    ]}
                    attributes={{
                      style: { width: '100%' },
                      placeholder: 'Tax Status',
                    }}
                  />
                </span>
                <span>
                  <RHFInput
                    name='taxId'
                    editMode={isEditMode}
                    control={control}
                    rules={{
                      maxLength: {
                        value: 25,
                        message: 'Maximum of 25 characters',
                      },
                      // TODO: this may need to allow "back dating" when backfilling old agreements
                      validate: taxId => !isTaxExempt || (taxId?.length || 0) > 0 || 'Tax ID is required if exempt.',
                    }}
                    attributes={{
                      style: { width: '100%' },
                      placeholder: 'Tax ID',
                      disabled: !isTaxExempt,
                    }}
                  />
                </span>
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              {' '}
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              {isPaymentSystem && (
                <RHFInput
                  name='paymentSystemNotes'
                  editMode={isEditMode}
                  control={control}
                  rules={{ maxLength: { value: 500, message: 'Maximum of 500 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Payment System Notes',
                    disabled: !isPaymentSystem,
                  }}
                />
              )}
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AgreementBilling;
