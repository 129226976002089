export const DbConstants = {
  AgreementServiceType: {
    OnDemand: 'on-demand',
    Recurring: 'recurring',
    Trial: 'trial',
    _KeyValuePairs: [
      { key: 'on-demand', value: 'On Demand' },
      { key: 'recurring', value: 'Recurring' },
      { key: 'trial', value: 'Trial' },
    ],
  },
  AgreementStatus: {
    Draft: 'draft',
    AwaitingApproval: 'awaiting-approval',
    Approved: 'approved',
    Rejected: 'rejected',
    Inactive: 'inactive',
    Active: 'active',
    _KeyValuePairs: [
      { key: 'draft', value: 'Draft' },
      { key: 'awaiting-approval', value: 'Awaiting Approval' },
      { key: 'approved', value: 'Approved' },
      { key: 'rejected', value: 'Rejected' },
      { key: 'inactive', value: 'Inactive' },
      { key: 'active', value: 'Active' },
    ],
  },
  BillingFrequency: {
    Monthly: 'monthly',
    BiWeekly: 'bi-weekly',
    Weekly: 'weekly',
    _KeyValuePairs: [
      { key: 'monthly', value: 'Monthly' },
      { key: 'bi-weekly', value: 'Bi-weekly' },
      { key: 'weekly', value: 'Weekly' },
    ],
  },
  BillingPreference: {
    Email: 'email',
    Paper: 'paper',
    PaymentSystem: 'payment-system',
    _KeyValuePairs: [
      { key: 'email', value: 'Email' },
      { key: 'paper', value: 'Paper' },
      { key: 'payment-system', value: 'Payment System' },
    ],
  },
  ContactCategory: {
    Billing: 'billing',
    Booking: 'booking',
    Hauling: 'hauling',
    OnSite: 'on-site',
    PreSmash: 'pre-smash',
    PostSmash: 'post-smash',
    _KeyValuePairs: [
      { key: 'billing', value: 'Billing' },
      { key: 'booking', value: 'Booking' },
      { key: 'hauling', value: 'Hauling' },
      { key: 'on-site', value: 'On Site' },
      { key: 'pre-smash', value: 'Upcoming Smash' },
      { key: 'post-smash', value: 'Post Smash' },
    ],
  },
  ContactMethod: {
    Email: 'email',
    Sms: 'sms',
    _KeyValuePairs: [
      { key: 'email', value: 'Email' },
      { key: 'sms', value: 'Sms' },
    ],
  },
  ContactType: {
    OnSite: 'on-site',
    Relationship: 'relationship',
    Billing: 'billing',
    Franchise: 'franchise',
    _KeyValuePairs: [
      { key: 'on-site', value: 'On Site' },
      { key: 'relationship', value: 'Relationship' },
      { key: 'billing', value: 'Billing' },
      { key: 'franchise', value: 'Franchise' },
    ],
  },
  DocumentType: {
    Agreement: 'agreement',
    Waiver: 'waiver',
    _KeyValuePairs: [
      { key: 'agreement', value: 'Agreement' },
      { key: 'waiver', value: 'Waiver' },
    ],
  },
  DurationUnit: {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year',
    _KeyValuePairs: [
      { key: 'day', value: 'Day' },
      { key: 'week', value: 'Week' },
      { key: 'month', value: 'Month' },
      { key: 'year', value: 'Year' },
    ],
  },
  FileOperationContext: {
    GateLockPreSmash: 'gate-lock-pre-smash',
    GateLockPostSmash: 'gate-lock-post-smash',
    PreSmash: 'pre-smash',
    PostSmash: 'post-smash',
    DamagePreSmash: 'damage-pre-smash',
    DamagePostSmash: 'damage-post-smash',
    BoomSecured: 'boom-secured',
    _KeyValuePairs: [
      { key: 'gate-lock-pre-smash', value: 'Gate Lock before Smash' },
      { key: 'gate-lock-post-smash', value: 'Gate Lock after Smash' },
      { key: 'pre-smash', value: 'Before Smash' },
      { key: 'post-smash', value: 'After Smash' },
      { key: 'damage-pre-smash', value: 'Damage Inspection before Smash' },
      { key: 'damage-post-smash', value: 'Damage Inspection after Smash' },
      { key: 'boom-secured', value: 'Boom Secured after Smash' },
    ],
  },
  FileType: {
    Image: 'image',
    Video: 'video',
    _KeyValuePairs: [
      { key: 'image', value: 'Image' },
      { key: 'video', value: 'Video' },
    ],
  },
  InvoiceStatus: {
    Pending: 'pending',
    Emailed: 'emailed',
    Outstanding: 'outstanding',
    OnHold: 'on-hold',
    Approved: 'approved',
    Paid: 'paid',
    BadDebt: 'bad-debt',
    _KeyValuePairs: [
      { key: 'pending', value: 'Pending' },
      { key: 'emailed', value: 'Emailed' },
      { key: 'outstanding', value: 'Outstanding' },
      { key: 'on-hold', value: 'On Hold' },
      { key: 'approved', value: 'Approved' },
      { key: 'paid', value: 'Paid' },
      { key: 'bad-debt', value: 'Bad Debt' },
    ],
  },
  LeadStatus: {
    Lead: 'lead',
    InDemo: 'in-demo',
    DemoComplete: 'demo-complete',
    InTrial: 'in-trial',
    TrialComplete: 'trial-complete',
    _KeyValuePairs: [
      { key: 'lead', value: 'Lead' },
      { key: 'in-demo', value: 'In Demo' },
      { key: 'demo-complete', value: 'Demo Complete' },
      { key: 'in-trial', value: 'In Trial' },
      { key: 'trial-complete', value: 'Trial Complete' },
    ],
  },
  PaymentType: {
    CreditCard: 'credit-card',
    Check: 'check',
    Eft: 'eft',
    Cash: 'cash',
    _KeyValuePairs: [
      { key: 'credit-card', value: 'Credit Card' },
      { key: 'check', value: 'Check' },
      { key: 'eft', value: 'EFT' },
      { key: 'cash', value: 'Cash' },
    ],
  },
  scheduleFrequencyInWeeks: {
    Weekly: 'weekly',
    BiWeekly: 'bi-weekly',
    _KeyValuePairs: [
      { key: 'weekly', value: 'Weekly' },
      { key: 'bi-weekly', value: 'Bi-weekly' },
    ],
  },
  ServiceType: {
    OnDemand: 'on-demand',
    Recurring: 'recurring',
    Demo: 'demo',
    Trial: 'trial',
    Project: 'project',
    _KeyValuePairs: [
      { key: 'on-demand', value: 'On Demand' },
      { key: 'recurring', value: 'Recurring' },
      { key: 'demo', value: 'Demo' },
      { key: 'trial', value: 'Trial' },
      { key: 'project', value: 'Project' },
    ],
  },
  SmashStatus: {
    Upcoming: 'upcoming',
    InProgress: 'in-progress',
    Complete: 'complete',
    Incomplete: 'incomplete',
    _KeyValuePairs: [
      { key: 'upcoming', value: 'Upcoming' },
      { key: 'in-progress', value: 'In Progress' },
      { key: 'complete', value: 'Complete' },
      { key: 'incomplete', value: 'Incomplete' },
    ],
  },
  UserType: {
    Corporate: 'corporate',
    Franchise: 'franchise',
    _KeyValuePairs: [
      { key: 'corporate', value: 'Corporate' },
      { key: 'franchise', value: 'Franchise' },
    ],
  },
  WorkOrderStatus: {
    Upcoming: 'upcoming',
    Scheduled: 'scheduled',
    InProgress: 'in-progress',
    Complete: 'complete',
    Incomplete: 'incomplete',
    Canceled: 'canceled',
    _KeyValuePairs: [
      { key: 'upcoming', value: 'Upcoming' },
      { key: 'scheduled', value: 'Scheduled' },
      { key: 'in-progress', value: 'In Progress' },
      { key: 'complete', value: 'Complete' },
      { key: 'incomplete', value: 'Incomplete' },
      { key: 'canceled', value: 'Canceled' },
    ],
  },
  Permission: {
    LeadSmashCrud: 'lead-smash-crud',
    LeadLocationCrud: 'lead-location-crud',
    LeadReleaseUpload: 'lead-release-upload',
    LeadArchive: 'lead-archive',
    LeadConvert: 'lead-convert',
    LeadAgreementCrud: 'lead-agreement-crud',
    CustomerDetailsCrud: 'customer-details-crud',
    CustomerLocationCrud: 'customer-location-crud',
    CustomerCurrentAgreementCrud: 'customer-current-agreement-crud',
    CustomerFutureAgreementCrud: 'customer-future-agreement-crud',
    CustomerAgreementApprove: 'customer-agreement-approve',
    CustomerSmashCrud: 'customer-smash-crud',
    ScheduleSmashTruckAssign: 'schedule-smash-truck-assign',
    ScheduleSmashRouting: 'schedule-smash-routing',
    InvoiceSmashCrud: 'invoice-smash-crud',
    InvoiceExport: 'invoice-export',
    InvoicePaymentsApply: 'invoice-payments-apply',
    SettingsConfigCrud: 'settings-config-crud',
    SettingsInfoCrud: 'settings-info-crud',
    SettingsPaymentCrud: 'settings-payment-crud',
    SettingsEmployeesCrud: 'settings-employees-crud',
    SettingsTrucksCrud: 'settings-trucks-crud',
    MobilePerformRoute: 'mobile-perform-route',
    _KeyValuePairs: [
      { key: 'lead-smash-crud', value: 'Schedule / Reschedule / Cancel Demo and Trial Smash Requests' },
      { key: 'lead-location-crud', value: 'Add / Update Service Location Equipment Tabs' },
      { key: 'lead-release-upload', value: 'Upload Release for Demo and Trial Smash Requests' },
      { key: 'lead-archive', value: 'Archive Lead (Closed-Lost)' },
      { key: 'lead-convert', value: 'Convert Lead to Customer (Closed-Won)' },
      { key: 'lead-agreement-crud', value: 'Create First Agreement for New Customer' },
      { key: 'customer-details-crud', value: 'Add / Update Company Details' },
      { key: 'customer-location-crud', value: 'Add / Update Service Location Details & Equipment' },
      { key: 'customer-current-agreement-crud', value: 'Update Current Customer Agreement' },
      { key: 'customer-future-agreement-crud', value: 'Add / Update Future Agreements' },
      { key: 'customer-agreement-approve', value: 'Approve / Activate Customer Agreements' },
      { key: 'customer-smash-crud', value: 'Schedule / Reschedule / Cancel Customer Smash Requests' },
      { key: 'schedule-smash-truck-assign', value: 'Assign / Unassign Trucks for Smash Requests' },
      { key: 'schedule-smash-routing', value: 'Set Route / Re-Route' },
      { key: 'invoice-smash-crud', value: 'Add / Update Invoices for Completed Smash Orders' },
      { key: 'invoice-export', value: 'Export Franchise Invoice Data to Xero / Quickbooks' },
      { key: 'invoice-payments-apply', value: 'Apply Payments from Customers to Franchise Invoices' },
      { key: 'settings-config-crud', value: 'Add / Update Franchise Settings' },
      { key: 'settings-info-crud', value: 'Add / Update Franchise Info' },
      { key: 'settings-payment-crud', value: 'Add / Update Franchise Payment Details & Taxes' },
      { key: 'settings-employees-crud', value: 'Add / Update Franchise Employees' },
      { key: 'settings-trucks-crud', value: 'Add / Update Franchise Operations (Trucks)' },
      { key: 'mobile-perform-route', value: 'Perform Smash Orders Route' },
    ],
  },
};

