import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Button, Card } from 'antd';
import styled from 'styled-components';
import theme from '../theme';
import smtLogoSvg from 'assets/logos/smt-stacked.svg';
import googleLogoSvg from 'assets/logos/google_logo.svg';

const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url('./loginImage.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TaglineBanner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 8%;

  background: rgba(187, 30, 16, 0.84);
`;

const TaglineText = styled.div`
  padding: 10px;
  font-family: ${theme['@header-font-family']};

  font-style: italic;
  font-weight: 500;
  font-size: 55px;
  line-height: 110%;

  text-align: center;
  color: #ffffff;
`;

const LoginCard = styled(Card)`
  max-width: 647px;
  width: 100%;
  height: 330px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.51);
  backdrop-filter: blur(14px);
  border-width: 0;
  flex-direction: row;

  .ant-card-body {
    height: 100%;
  }
  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const SmtLogo = styled.img`
  max-height: 161px;
  flex: 1;
`;

const SignInButton = styled(Button)`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: 350px;
  max-height: 3.5em;
  padding: 0;

  img {
    height: 100%;
    padding: 6px;
    border-right: 1px solid #d3d3d3;
  }
  div {
    justify-content: center;
    flex: 1;
    color: #a3a3a3;
    font-size: 20px;
    font-weight: 600;
  }

  > * {
    display: flex;
    align-self: center;
  }
`;

function SignIn() {
  return (
    <PageWrapper>
      <TaglineBanner>
        <TaglineText>
          <div>The More We Smash,</div>
          <div>The More You Save™</div>
        </TaglineText>
      </TaglineBanner>
      <LoginCard>
        <SmtLogo src={smtLogoSvg} alt='Smash My Trash Logo' />
        <SignInButton onClick={federatedSignIn}>
          <img src={googleLogoSvg} alt='Google Logo' />
          <div>SIGN IN WITH GOOGLE</div>
        </SignInButton>
      </LoginCard>
    </PageWrapper>
  );
}

function federatedSignIn() {
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });
}

export default SignIn;
