import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../fragment/user';

export const GET_USER = gql`
  ${CORE_USER_FIELDS}
  query GetUser($id: String) {
    user(where: { id: $id }) {
      ...CoreUserFields
    }
    franchises(where: { deleted: { equals: null }, AND: [{ inactive: { equals: null } }] }, take: 1) {
      id
      name
    }
  }
`;

export const GET_CORPORATE_FRANCHISES = gql`
  query GetCorporateFranchises {
    franchises(where: { deleted: { equals: null }, AND: [{ inactive: { equals: null } }] }) {
      id
      name
    }
  }
`;

export const GET_USER_FRANCHISES = gql`
  query GetUserFranchises($id: String) {
    user(where: { id: $id }) {
      userFranchises(
        where: { franchise: { is: { deleted: { equals: null }, AND: [{ inactive: { equals: null } }] } } }
      ) {
        franchise {
          id
          name
        }
      }
    }
  }
`;

export const GET_FRANCHISE_EMPLOYEE_LIST = gql`
  query GetFranchiseEmployeeList($id: String) {
    franchise(where: { id: $id }) {
      id
      name
      userFranchises(where: { user: { is: { userType: { equals: "franchise" } } } }) {
        user {
          id
          firstName
          lastName
          title
          phone
          email
          inactive
          deleted
        }
      }
    }
  }
`;

export const GET_FRANCHISE_EMPLOYEE = gql`
  query GetFranchiseEmployee($where: UserWhereInput) {
    users(where: $where) {
      id
      firstName
      lastName
      title
      phone
      email
      roleId
      userType
      userRoles {
        roleId
      }
    }
    roles {
      id
      description
      userType
    }
  }
`;
