import { format, parseISO } from 'date-fns';
import { Address } from 'generated/graphql';
import { number } from 'prop-types';

const IsoDateTimeRegex =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
const CurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Formats the address object as a string
 * @param address the address object
 * @returns a string representing the address
 */
export function formatAddress(address: Address | undefined | null): string {
  let s = '';
  if (address) {
    if (address.line1) s += `${address.line1}`;
    if (address.line2) s += `${s.length > 0 ? ', ' : ''}${address.line2}`;
    if (address.city) s += `${s.length > 0 ? ', ' : ''}${address.city}`;
    if (address.state) s += `${s.length > 0 ? ', ' : ''}${address.state}`;
    if (address.zipCode) s += `${s.length > 0 ? ' ' : ''}${address.zipCode}`;
  }
  return s;
}
/**
 * Formats the address object as a string
 * @param address the address object
 * @returns a string representing the address
 */
export function formatPhone(phoneNum: string | undefined | null): string {
  if (!phoneNum) return '';
  const numbers = (phoneNum.match(/[0-9]+/g) || []).join('');
  let formatted = '';
  if (numbers.length > 0 && numbers[0] === '1') formatted = numbers.substring(1);
  else formatted = numbers;
  if (formatted.length >= 10) {
    formatted = `(${formatted.substring(0, 3)}) ${formatted.substring(3, 6)}-${formatted.substring(6, 10)}${
      formatted.length > 10 ? ` x${formatted.substring(10)}` : ''
    }`;
  }
  return formatted;
}

/**
 * A null safe formatting function for dates
 * @param dateValue the date to format
 * @returns a formatted string
 */
export function formatDate(dateValue: string | number | Date | null | undefined, formatPattern = 'MM/dd/yyyy') {
  const date = convertToDate(dateValue);
  return date ? format(date, formatPattern || 'MM/dd/yyyy') : null;
}

/**
 * Convert from any numberish type to a number (nullish safe)
 * @param dateValue a string, number, or Date that is supposed to have a numeric value
 * @returns a number or undefined
 */
export function convertToNumber(numberValue: string | Date | number | null | undefined): number | undefined {
  if (typeof numberValue === 'number') return numberValue;
  if (numberValue && numberValue instanceof Date && Number.isNaN(numberValue)) return numberValue.getTime();
  if (typeof numberValue === 'string') {
    const chars: string[] = [];
    for (let i = 0; i < numberValue.length; i += 1)
      if ('-0123456789.'.indexOf(numberValue.charAt(i)) > 0) chars.push(numberValue.charAt(i));
    if (chars.length > 0) {
      const num = Number(chars.join(''));
      if (!Number.isNaN(num)) return num;
    }
  }
  return undefined;
}

/**
 * Convert from any dateish type to a Date object (nullish safe)
 * @param dateValue a string, number, or Date that is supposed to have a Date value
 * @returns a Date or undefined
 */
export function convertToDate(dateValue: string | number | Date | null | undefined): Date | undefined {
  if (dateValue) {
    if (dateValue instanceof Date) {
      if (!Number.isNaN(dateValue)) return dateValue;
    } else if (typeof dateValue === 'string' && dateValue.match(IsoDateTimeRegex)) {
      return parseISO(dateValue);
    } else {
      try {
        return new Date(dateValue);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`Failed to convert ${dateValue} (${typeof dateValue}) to a Date object.`);
      }
    }
  }
  return undefined;
}

/**
 * Returns a Date string and object from a date string (chopping the time off if the string has a time)
 * @param str the date or datetime string.
 * @returns a Date object for the given date string.
 */
export function dateOnlyFromDateTime(dateThingy?: string | Date | number | null): {
  dateString?: string;
  dateObj?: Date;
} {
  const returnData: { dateString?: string; dateObj?: Date } = { dateString: undefined, dateObj: undefined };
  if (!dateThingy) return returnData;

  let str = typeof dateThingy === 'string' ? dateThingy : undefined;
  if (!str) {
    const date = convertToDate(dateThingy);
    if (date) str = new Date(date?.getUTCDate())?.toISOString();
  }

  if (str && str.length >= 10 && /^\d{4}-\d{2}-\d{2}/.test(str)) {
    returnData.dateString = str.substring(0, 10);
    returnData.dateObj = new Date(
      Number(str.substring(0, 4)),
      Number(str.substring(5, 7)) - 1,
      Number(str.substring(8, 10))
    );
  }
  return returnData;
}

/**
 * A null safe formatting function for currency
 * @param dateValue the number to format
 * @returns a formatted string
 */
export function formatCurrency(moneyValue: number | bigint | null | undefined) {
  return moneyValue === null || moneyValue === undefined ? null : CurrencyFormatter.format(moneyValue);
}

/**
 * Capitalize the first letter of words
 * @param s the input string
 * @returns a string where the first character is capitalized.
 */
export function capitalizeFirst(s: string): string {
  if (!s) return s;
  let sout = '';
  const arr = s?.length > 1 && (s.includes(' ') || s.includes('-') || s.includes('_')) ? s.split(/[_\- ]+/) : [s];
  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i += 1) {
      const si = arr[i];
      sout +=
        (sout.length > 0 ? ' ' : '') + (si?.length > 1 ? `${si[0].toUpperCase()}${si.substring(1)}` : si.toUpperCase());
    }
  } else {
    sout = s?.length > 1 ? `${s[0].toUpperCase()}${s.substring(1)}` : s;
  }
  return sout;
}

/**
 * Since we're using state names, I wanted to get the 2 char state instead.
 * @param state the state name
 * @returns the state abbreviation
 */
export function getStateAbbr(state: string): string {
  const lcState = state.toLowerCase();
  if (!lcState.includes(' ')) {
    switch (state.toLowerCase()) {
      case 'alaska':
        return 'AK';
      case 'arizona':
        return 'AZ';
      case 'connecticut':
        return 'CT';
      case 'georgia':
        return 'GA';
      case 'hawaii':
        return 'HI';
      case 'iowa':
        return 'IA';
      case 'kentucky':
        return 'KY';
      case 'louisiana':
        return 'LA';
      case 'kansas':
        return 'KS';
      case 'maine':
        return 'ME';
      case 'maryland':
        return 'MD';
      case 'pennsylvania':
        return 'pa';
      case 'virginia':
        return 'VA';
      default: {
        const spIdx = state.indexOf(' ');
        if (spIdx > 0) return (state.substring(0, 1) + state.substring(spIdx, spIdx + 1)).toUpperCase();
        return state.substring(0, 2).toUpperCase();
      }
    }
  } else {
    switch (state.toLowerCase()) {
      case 'district of columbia':
      case 'washington dc':
        return 'DC';
      case 'kansas':
        return 'KS';
      default: {
        const parts = state.split(' ');
        return parts.length > 1 && parts[0].length > 0 && parts[1].length > 0
          ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
          : state.toUpperCase();
      }
    }
  }
}

export function deepFilterTypename(obj) {
  const objClone = Array.isArray(obj) ? [...obj] : { ...obj };
  delete objClone.__typename;
  Object.entries(objClone).forEach(([key, value]) => {
    if (value instanceof Object) {
      objClone[key] = deepFilterTypename(objClone[key]);
    }
  });
  return objClone;
}
