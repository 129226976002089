import { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  StyledTable,
  TableActiveToggleSwitch,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from 'components/common/card/TableCard';
import { SampleGridRow, SampleSearchState } from './example.models';
import { getColumns, getFilteredResults, getSampleRecords } from './example.service';
import { ColumnProps } from 'antd/lib/table';
import useMockData from 'hooks/useMockData';

/**
 * This is a sample component that demonstrates one way to do grids cleanly
 * @returns a sample list component
 */
function ExampleList() {
  // get the url to use when linking out to other components
  const { url } = useRouteMatch();

  // get the search state, which includes everything
  const [searchState, setSearchState] = useState<SampleSearchState>({
    // records: [],
    filter: '',
    active: true,
    // initialized: false,
  });

  // get the columns reference (similar to a class member)
  const columnsRef = useRef<ColumnProps<SampleGridRow>[]>();

  // load the data from wherever and update the state.
  const { loading, data } = useMockData({
    sampleData: getSampleRecords(),
    // numberOfRecordsToAdd: 100,
    sleepMilliseconds: 2000,
  });

  // only load the columns once? (probably overkill)
  if (!columnsRef.current) columnsRef.current = getColumns(url);

  // load the display records by filtering and mapping the data from the database
  const displayRecords = loading || data?.length === 0 ? data : getFilteredResults(searchState, data);

  // build the header out (I'm not sure why we have to do this up here?)
  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>POC LIST PAGE</TableHeaderText>
        <TableActiveToggleSwitch
          active={searchState.active}
          toggleActiveData={() => setSearchState({ ...searchState, active: !searchState.active })}
        />
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, filter: value });
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  // return the final chunk of jsx html for the component
  return (
    <TableCard title={header}>
      <StyledTable
        rowKey='id'
        dataSource={displayRecords}
        columns={columnsRef.current}
        pagination={false}
        loading={loading}
      />
    </TableCard>
  );
}

export default ExampleList;
