import styled, { css } from 'styled-components';
import theme from '../../../theme';
import { StyledButton } from '../styled';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const ScheduleNextButton = styled(StyledButton)`
  margin-left: 1em;

  ${props =>
    props.$submission &&
    css`
      background-color: ${theme['@green']};
      border-color: ${theme['@green']};
      :focus {
        background-color: ${theme['@green']};
        border-color: ${theme['@green']};
        color: white;
      }
    `}
`;

export const ScheduleBackButton = styled(StyledButton)`
  background-color: #a3a3a3;
  border-color: #a3a3a3;

  ${props =>
    props.$formStep === 0 &&
    css`
      visibility: hidden;
    `}
`;

export const ProgressBar = styled.div`
  display: flex;
  gap: 4em;
  justify-content: space-around;
  margin: 3em 0 2em 0;
`;

export const ProgressNode = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 20%;
`;

export const ProgressNodeLabel = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
`;
