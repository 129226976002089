/* eslint-disable @typescript-eslint/no-unused-vars */
import './agreement.less';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tabs } from 'antd';
import { useRef, useState } from 'react';

import AgreementEditForm from './AgreementEditForm';
import { AgreementForm, ChildFormActivator } from 'components/agreement/agreement.models';
import { mapAgreementFormObject } from './agreement.service';

/**
 * This component shows the active (or most recent) agreement, along with tabs that list past and future agreements.
 * It assumes that the container component already loaded all of the data along with the location.
 * @param param0 The parameters object (all optional):
 *    readOnly - show in readonly mode.
 *    locationId - the location id that these agreements belong to.
 *    dumpsterCount - for new agreements, the number of dumpsters associated with the location.
 *    currentAgreement - the current agreement that should be displayed or edited.
 *    agreementUpdated - when the child component updates the agreement record, this callback function is executed.
 * @returns the component elements for the location agreeements
 */
function LocationAgreements({
  readOnly,
  locationId,
  dumpsterCount,
  currentAgreement,
  agreementUpdated,
}: {
  readOnly?: boolean;
  locationId?: string; // TODO: this is def required
  dumpsterCount?: number;
  currentAgreement?;
  agreementUpdated?: (agreement: any) => void;
}) {
  const isInAddMode = !!locationId && currentAgreement && !currentAgreement?.id;
  const [editMode, setEditMode] = useState(isInAddMode);
  const [tab, setTab] = useState('active');
  const [agreementForm, setAgreementForm] = useState('active');
  const agreementRef = useRef<AgreementForm>();

  if (currentAgreement) {
    agreementRef.current = mapAgreementFormObject(locationId, dumpsterCount, currentAgreement);
  }

  // set the default implementation of the form actions.
  let formActions: ChildFormActivator = {
    canAdd: false,
    add: () => false,
    submit: () => false,
    reset: () => false,
    validate: () => Promise.resolve(false),
  };

  const agreementId: string | undefined = undefined;
  // if a tab has an implementation, it will call this.
  const setFormActionsHandler = (actions: ChildFormActivator) => {
    formActions = actions;
  };

  const recordCount = 1;

  const tabStripItems = [
    { label: <div className='tab-text'>ACTIVE AGREEMENT</div>, key: 'active' },
    { label: <div className='tab-text'>FUTURE AGREEMENTS</div>, key: 'future' },
    { label: <div className='tab-text'>PAST AGREEMENTS</div>, key: 'past' },
  ];

  const title = (
    <Row gutter={24} className='tab-container'>
      <Col className='tabs-col' xs={24} sm={19}>
        <Tabs
          className='tab-control'
          size='small'
          activeKey={tab}
          items={tabStripItems}
          onTabClick={(newTab, event) => {
            if (!editMode) setTab(newTab);
          }}
        />
      </Col>
      <Col className='buttons-right' xs={0} sm={5}>
        {!readOnly && (
          <Space size='small' align='start' direction='horizontal'>
            {tab !== 'broker' && !editMode && formActions?.canAdd && (
              <Button
                className='plus-add-button'
                size='middle'
                shape='circle'
                icon={<PlusOutlined />}
                onClick={async () => {
                  if (!editMode) setEditMode(true);
                  formActions.add();
                }}
              />
            )}
            {(editMode || recordCount > 0) && (
              <Button
                className={editMode ? 'save-button' : 'edit-button'}
                size='middle'
                onClick={async () => {
                  if (editMode && formActions) {
                    const x = await formActions?.validate();
                    if (x) {
                      formActions.submit();
                      setEditMode(false);
                    } else {
                      return false;
                    }
                  }
                  setEditMode(!editMode);
                  return true;
                }}
              >
                {editMode ? 'SAVE' : 'EDIT'}
              </Button>
            )}
            {editMode ? (
              <Button
                className={editMode ? 'cancel-button' : 'edit-button'}
                size='middle'
                onClick={() => {
                  if (editMode && formActions) {
                    formActions.reset();
                  }
                  setEditMode(false);
                }}
              >
                CANCEL
              </Button>
            ) : undefined}
          </Space>
        )}
      </Col>
    </Row>
  );

  let bodyCard: JSX.Element = <div>Please reload the page</div>;
  switch (tab) {
    case 'future':
      bodyCard = <div>FUTURE</div>;
      break;
    case 'past':
      bodyCard = <div>PAST</div>;
      break;
    case 'active':
    default:
      bodyCard = (
        <AgreementEditForm
          locationId={locationId || 'b751c85f-de57-46d5-bb95-108fe1894dff'} // 9c62f181-01e0-4ede-bdd0-f79b971f388f
          agreementForm={agreementRef.current}
          editMode={editMode}
          setFormActions={setFormActionsHandler}
          agreementUpdated={agreementUpdated}
        />
      );
      break;
  }

  return (
    <Card className='agreement-card' title={title}>
      {bodyCard}
    </Card>
  );
}

export default LocationAgreements;
