import { Avatar, Button, Input, Row, Spin, Typography } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import styled from 'styled-components';

const blue = '#3B5998';

export const LoadingContainer = styled<typeof Spin>(Spin)`
  max-height: 100% !important;
  position: fixed !important;
`;

export const StyledHeader = styled.div`
  padding: 0;
  display: block;
  vertical-align: middle;
`;

export const StyledTitle = styled(Title)`
  padding-top: 8px;
  font-size: 22px;
  font-weight: bolder;
`;

export const StyledButton = styled(Button)`
  width: 100px;
  border-radius: 10px;
  font-weight: bolder;
  background-color: ${blue};
  border-color: ${blue};
  color: #ffffff;
`;

export const StyledSubmitButton = styled(Input)`
  width: 100px;
  border-radius: 10px;
  font-weight: bolder;
  background-color: ${blue};
  border-color: ${blue};
  color: #ffffff;
`;

export const StyledPlusButton = styled(Button)`
  font-weight: bolder;
  background-color: ${blue};
  border-color: ${blue};
  color: #ffffff;
  margin-left: 20px;
`;

export const ActiveNumber = styled(Avatar)`
  font-weight: bolder;
`;

export const InactiveNumber = styled(Avatar)`
  font-weight: bolder;
`;

export const StyledRow = styled(Row)`
  margin: 15px;
`;

export const FormLabel = styled(Typography)`
  font-size: 16pt;
  margin-right: 20px;
  white-space: nowrap;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
`;
export const PrimaryFormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VirtualDiv = styled.div`
  text-align: center;
  border-collapse: separate;
  display: table-cell;
  font-size: 14px;
  line-height: 22.001px;
  padding: 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
`;
