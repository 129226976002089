import { Col, Row } from 'antd';
import { CardHeader, StyledCard } from 'components/common/card/Card';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SmashStop = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: #dfdfdf;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);

  margin-bottom: 6px;
  padding: 4px 8px;
`;

const TruckColumn = styled(Col)`
  padding: 16px;
`;

function DayScheduleCard(props) {
  const header = (
    <CardHeader>
      <Link to='route'>OPTIMIZE ROUTES</Link>
      <div>{format(props.selectedDate, 'MMM. d, y')}</div>
      <div />
    </CardHeader>
  );
  // Drag and drop to reorder

  // Send the new order to the backend

  // Reload the page/data

  return (
    <StyledCard title={header}>
      <Row>
        {props.data?.trucks?.map(truck => (
          <TruckColumn flex={1} key={truck.id}>
            <div>Truck {truck.number}</div>
            {truck.workOrders.length === 0 ? <div>No scheduled smashes for today</div> : <div />}
            <div>
              {truck.workOrders.map(wo => (
                <SmashStop key={wo.id}>
                  <Row>
                    <Col span={6}>{wo.dumpsterCount || 0} dumpsters</Col>
                    <Col span={12}>
                      <div>{wo.location.name}</div>
                      <div>{wo.location.address.line1}</div>
                    </Col>
                    <Col span={6}>
                      <div>
                        {(new Date(wo.scheduleEndTime).getTime() - new Date(wo.scheduleStartTime).getTime()) /
                          (1000 * 60)}{' '}
                        min.
                      </div>
                    </Col>
                  </Row>
                </SmashStop>
              ))}
            </div>
          </TruckColumn>
        ))}
      </Row>
    </StyledCard>
  );
}

export default DayScheduleCard;
