export const stateList = [
  { state: 'State', abr: null },
  { state: 'Alabama', abr: 'AL' },
  { state: 'Alaska', abr: 'AK' },
  { state: 'Arizona', abr: 'AZ' },
  { state: 'Arkansas', abr: 'AR' },
  { state: 'California', abr: 'CA' },
  { state: 'Colorado', abr: 'CO' },
  { state: 'Connecticut', abr: 'CT' },
  { state: 'Delaware', abr: 'DE' },
  { state: 'Florida', abr: 'FL' },
  { state: 'Georgia', abr: 'GA' },
  { state: 'Hawaii', abr: 'HI' },
  { state: 'Idaho', abr: 'ID' },
  { state: 'Illinois', abr: 'IL' },
  { state: 'Indiana', abr: 'IN' },
  { state: 'Iowa', abr: 'IA' },
  { state: 'Kansas', abr: 'KS' },
  { state: 'Kentucky', abr: 'KY' },
  { state: 'Louisiana', abr: 'LA' },
  { state: 'Maine', abr: 'ME' },
  { state: 'Maryland', abr: 'MD' },
  { state: 'Massachusetts', abr: 'MA' },
  { state: 'Michigan', abr: 'MI' },
  { state: 'Minnesota', abr: 'MN' },
  { state: 'Mississippi', abr: 'MS' },
  { state: 'Missouri', abr: 'MO' },
  { state: 'Montana', abr: 'MT' },
  { state: 'Nebraska', abr: 'NE' },
  { state: 'Nevada', abr: 'NV' },
  { state: 'New Hampshire', abr: 'NH' },
  { state: 'New Jersey', abr: 'NJ' },
  { state: 'New Mexico', abr: 'NM' },
  { state: 'New York', abr: 'NY' },
  { state: 'North Carolina', abr: 'NC' },
  { state: 'North Dakota', abr: 'ND' },
  { state: 'Ohio', abr: 'OH' },
  { state: 'Oklahoma', abr: 'OK' },
  { state: 'Oregon', abr: 'OR' },
  { state: 'Pennsylvania', abr: 'PA' },
  { state: 'Rhode Island', abr: 'RI' },
  { state: 'South Carolina', abr: 'SC' },
  { state: 'South Dakota', abr: 'SD' },
  { state: 'Tennessee', abr: 'TN' },
  { state: 'Texas', abr: 'TX' },
  { state: 'Utah', abr: 'UT' },
  { state: 'Vermont', abr: 'VT' },
  { state: 'Virginia', abr: 'VA' },
  { state: 'Washington', abr: 'WA' },
  { state: 'West Virginia', abr: 'WV' },
  { state: 'Wisconsin', abr: 'WI' },
  { state: 'Wyoming', abr: 'WY' },
];
