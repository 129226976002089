import {
  StyledTable,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from '../common/card/TableCard';
import { getCustomerWorkOrderColumns, getFilteredCustomerWorkOrderResults } from './customer.service';
import { WorkOrderSummaryView } from 'generated/graphql';
import { useState } from 'react';

function WorkOrdersCard({ loading, workOrders }: { loading: boolean; workOrders: WorkOrderSummaryView[] | undefined }) {
  const [filter, setFilter] = useState('');

  const workOrderTableData = getFilteredCustomerWorkOrderResults(filter, workOrders);

  const workOrdersHeader = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>SMASH ORDERS</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch onChange={setFilter} />
      </TableHeaderRight>
    </TableHeader>
  );

  return (
    <TableCard title={workOrdersHeader}>
      <StyledTable
        columns={getCustomerWorkOrderColumns()}
        dataSource={workOrderTableData}
        pagination={false}
        loading={loading}
        scroll={
          (workOrderTableData?.length || 0) > 10
            ? {
                y: 600,
              }
            : null
        }
        rowKey='id'
      />
    </TableCard>
  );
}

export default WorkOrdersCard;
