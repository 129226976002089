import { Layout, Progress } from 'antd';
import { useState } from 'react';
import { Content, Footer, Header } from 'antd/lib/layout/layout';

import { DashboardCardStat, DashboardCard, DashboardUnitSelect } from './cards.styled';
import theme from 'theme';

/**
 * The lead metric card has a header and footer, and revenue
 * @param param0 The lead metric parameters.
 * @returns a metric card.
 */
function DashboardMetricCard({
  headerText,
  displayType,
  footerText,
  enabled,
  unitTokenString = '{key}',
  value,
  color,
  selectedValueKey,
  values = [],
  style,
}: DashboardMetricParams) {
  let initialSelectedIndex = 0;
  let sliderMarks: any;
  if (values && values.length > 0) {
    sliderMarks = {};
    values.forEach((x, i) => {
      sliderMarks[i] = x.key; // add keys to the mark array (for the slider control)
      if (x.key === selectedValueKey) initialSelectedIndex = i;
    });
  }

  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

  const max = values ? values.length - 1 : 0;
  const sel = values.length > selectedIndex ? values[selectedIndex] : null;
  const cardIsEnabled = enabled || enabled === undefined || enabled === null;

  // build the header, and replace the token with the selected unit, if necessary.
  const token = unitTokenString || '{key}';
  let headerInnerds = <div>{headerText}</div>;

  // set the default footer css class and components
  let footerClassName = 'dashboard-card-header-footer';
  let footerInnerds;

  if (sliderMarks) {
    footerClassName = 'dashboard-card-slider-footer';
    footerInnerds = (
      <DashboardUnitSelect
        style={style}
        marks={sliderMarks}
        defaultValue={selectedIndex}
        min={0}
        max={max}
        onChange={selectedValue => setSelectedIndex(selectedValue)}
      />
    );
  } else {
    footerInnerds = footerText ? <div>{footerText}</div> : null;
  }

  // The body will be determined by the type of card this is.
  let contentClassName;
  let contentInnerds;
  let pctValue: number;
  let pctx;

  switch (displayType) {
    case DashboardMetricDisplayType.PercentageCircle:
      pctValue = Math.round((sel ? Number(sel.value) : Number(value)) * 100);
      pctx = (
        <>
          {pctValue.toString()}
          <sup>%</sup>
        </>
      );
      //--------------------------------------------------------------------
      // the percentage circle get's a progress thingy

      // eslint-disable-next-line no-case-declarations
      contentInnerds = (
        <Progress
          style={style}
          className={value < 1 ? undefined : 'move-percentage'}
          type='circle'
          width={sel ? 80 : 100}
          format={() => pctx}
          strokeColor={(sel?.color ? sel?.color : color) || theme['@green']}
          percent={pctValue}
        />
      );
      break;
    case DashboardMetricDisplayType.UnitSlider:
      //--------------------------------------------------------------------
      // The unit slider is the most complex of the metric cards. It has an array of metrics and values to display.
      // Build the slider marks object for the UnitSelect control

      // the header text can be transformed by the selected unit
      headerInnerds = <div>{sel ? headerText.replace(token, sel.key.toUpperCase()) : headerText}</div>;
      // the text looks better pushed up a little, so we give the content a special css class.
      contentClassName = 'dashboard-card-text-stat-content';
      // the selected text comes from the current selected value
      contentInnerds = (
        <Content>
          <DashboardCardStat $color={sel?.color ? sel?.color : color}>{sel?.value}</DashboardCardStat>
        </Content>
      );
      break;
    case DashboardMetricDisplayType.Value:
    default:
      //--------------------------------------------------------------------
      // the value card just spits out the value exactly as it came to us
      contentInnerds = <DashboardCardStat color={color}>{sel ? sel.value : value}</DashboardCardStat>;
      contentClassName = null;
      break;
  }

  return (
    <DashboardCard style={style}>
      <Layout className={`dashboard-card-layout${cardIsEnabled ? '' : ' dashboard-action-card-disabled'}`}>
        <Header className='dashboard-card-header-footer'>{headerInnerds}</Header>
        <Content className={contentClassName}>{contentInnerds}</Content>
        <Footer className={footerClassName}>{footerInnerds}</Footer>
      </Layout>
    </DashboardCard>
  );
}

export type DashboardMetricParams = {
  headerText: string;
  displayType: DashboardMetricDisplayType;
  footerText?: string;
  unitTokenString?: string;
  color?: string;
  value?: any;
  selectedValueKey?: string;
  values?: DashboardMetricValue[];
  enabled?: boolean;
  style?: any;
};

export type DashboardMetricValue = { key: string; value: number | string; color?: string };

export enum DashboardMetricDisplayType {
  PercentageCircle,
  UnitSlider,
  Value,
}

export default DashboardMetricCard;
