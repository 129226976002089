import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LeadDashboard from './LeadDashboard';
import LeadDetailsPage from './LeadDetailsPage';
import LeadConvertDetails from './LeadConvertPage';

function LeadManagement() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/convert/:customerId`}>
        <LeadConvertDetails />
      </Route>
      <Route path={`${match.path}/:leadId`}>
        <LeadDetailsPage />
      </Route>
      <Route path={`${match.path}`}>
        <LeadDashboard />
      </Route>
    </Switch>
  );
}

export default LeadManagement;
