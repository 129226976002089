import { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import {
  StyledTable,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableSearch,
} from 'components/common/card/TableCard';
import { ColumnProps } from 'antd/lib/table';

import { ExpiringAgreementRow } from './smashboard.models';
import {
  expiringAgreementData,
  getExpiringAgreementsColumns,
  getFilteredExpiringAgreementResults,
} from './smashboard.service';
import { CardTabs, StyledCard, TabCardHeaderText } from 'components/common/card/Card';
import { Row, Col, Tabs } from 'antd';

export const TableWithTabsCard = styled(StyledCard)`
  margin-bottom: 30px;

  .ant-card-body {
    padding: 0px 0px 10px;

    .ant-table-cell {
      a {
        text-decoration: solid underline;
      }
    }
  }
  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding-bottom: 0px;
      }
    }
  }
`;

/**
 * This is a lead component that demonstrates one way to do grids cleanly
 * @returns a lead list component
 */
function SmashboardTopList() {
  // get the url to use when linking out to other components
  const { url } = useRouteMatch();

  // get the search state, which includes everything
  const [filter, setFilter] = useState('');

  // get the columns reference (similar to a class member)
  const columnsRef = useRef<ColumnProps<ExpiringAgreementRow>[]>();
  const records: ExpiringAgreementRow[] = expiringAgreementData;
  // --------------------------------------------------------------------------

  // only load the columns once? (probably overkill)
  if (!columnsRef.current) columnsRef.current = getExpiringAgreementsColumns(url);

  // load the display records by filtering and mapping the data from the database
  const displayRecords = getFilteredExpiringAgreementResults(filter, records || []);

  const tabStripItems = [
    { label: <TabCardHeaderText>EXPIRING AGREEMENT</TabCardHeaderText>, key: 'expiring' },
    { label: <TabCardHeaderText>AGREEMENT APPROVALS</TabCardHeaderText>, key: 'approvals' },
  ];
  // build the header out (I'm not sure why we have to do this up here?)
  const header = (
    <TableHeader style={{ marginBottom: '0px', paddingBottom: '0px' }}>
      <TableHeaderLeft style={{ marginBottom: '0px', paddingBottom: '0px' }}>
        <Row gutter={24}>
          <Col span={24} style={{ display: 'flex' }}>
            <CardTabs size='small' items={tabStripItems} />
          </Col>
        </Row>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setFilter(value);
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  const scrollData =
    (displayRecords?.length || 0) > 10
      ? {
          y: 600,
        }
      : null;

  // return the final chunk of jsx html for the component
  return (
    <TableWithTabsCard title={header} style={{ minWidth: '100%' }}>
      <StyledTable
        dataSource={displayRecords}
        columns={columnsRef.current}
        pagination={false}
        scroll={scrollData}
        rowKey='id'
      />
    </TableWithTabsCard>
  );
}

export default SmashboardTopList;
