import { FormSection, FormSectionTitle, StyledRow, ViewField, ViewFieldLabel } from '../common/styled';
import { Checkbox, Col, notification, Row, Select, Space } from 'antd';
import { useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { CardActionButton, HeaderText } from 'components/common/card/Card';
import { TableHeader, TableHeaderLeft, TableHeaderRight, TableHeaderText } from 'components/common/card/TableCard';
import { useParams } from 'react-router-dom';
import { CONVERT_FROM_LEAD } from 'graphql/mutation/customer';
import { DoubleActionButton } from './LeadActions';
import StyledInput from 'components/common/input/BaseInput';
import StyledSelect from 'components/common/input/BaseSelect';
import { ContactType } from 'models/customer';
import theme from 'theme';
import {
  FormLabel,
  CustomerRow,
  CustomerHzPad40Row,
  CustomerContentCard,
  CustomerPlusButton,
  CustomerFlexCol,
  NationalAccountHeaderTextBox,
  CustomerHeaderTextBox,
  CustomerButton,
} from '../customer/customer.styled';
import CustomerContactInfoForm from '../customer/CustomerContactInfoForm';
import ContactPreferenceForm from '../customer/CustomerContactPreferenceForm';
import CustomerAddressForm from '../customer/CustomerAddressForm';
import { hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import CustomerDetailsForm from 'components/customer/CustomerDetailsForm';
import LocationDetailView from 'components/location/LocationDetailView';
import LeadConvertCustomerDetailsForm from './LeadConvertCustomerDetailsForm';
import { isDate } from 'date-fns/esm';
import Equipment from 'components/equipment/Equipment';

const { Option } = Select;

function LeadConvertForm({
  customer,
  location,
  submitLeadConvertEdit,
  editMode,
  setEditMode,
}: {
  customer: any;
  location: any;
  submitLeadConvertEdit: any;
  editMode: any;
  setEditMode: any;
}) {
  const [customerEditMode, setCustomerEditMode] = useState(false);
  const [convertFromLead] = useMutation(CONVERT_FROM_LEAD);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const onSubmitSuccess = formData => {
    const { name, inactive } = formData;
    let inactiveComputed: any = null;

    if (isDate(inactive)) inactiveComputed = inactive;
    else if (inactive === 'inactive') inactiveComputed = new Date();

    submitLeadConvertEdit({
      variables: {
        data: {
          name,
          inactive: inactiveComputed,
        },
        where: { id: formData.id },
      },
    });
    setEditMode(!editMode);
    console.log('SUCCESS!:', formData);
  };

  const onSubmitError = error => {
    console.log('ERROR!:', error);
  };

  return (
    <>
      <LeadConvertCustomerDetailsForm
        customer={customer}
        submitCustomerEdit={handleSubmit}
        editmode={customerEditMode}
        setEditmode={setCustomerEditMode}
        validator={() => null}
      />

      <LocationDetailView location={location} />

      <Equipment
        location={location}
        broker={(location?.locationBrokers?.length || 0) > 0 ? location.locationBrokers[0] : undefined}
        dumpsters={location?.dumpsters}
        assets={location?.locationAssets}
      />
      <DoubleActionButton
        $color={theme['@green']}
        size='medium'
        onClick={() => {
          console.log(location.hubspotDealId);
          convertFromLead({ variables: { data: { hubspotDealId: location.hubspotDealId, isDealWon: true } } });
          console.log('Converted to Customer');
        }}
      >
        CREATE CUSTOMER
      </DoubleActionButton>
    </>
  );
}

export default LeadConvertForm;
