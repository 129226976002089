import { gql } from '@apollo/client';
import { CORE_ASSET_FIELDS, CORE_BROKER_FIELDS, CORE_DUMPSTER_FIELDS } from 'graphql/fragment/location';
import { CORE_CUSTOMER_FIELDS } from '../fragment/customer';

export const GET_CUSTOMER = gql`
  ${CORE_CUSTOMER_FIELDS}
  query GetCustomer($id: String) {
    customer(where: { id: $id }) {
      ...CoreCustomerFields
    }

    invoiceSummaryViews(where: { customerId: { equals: $id } }) {
      invoiceId
      agreementId
      locationId
      franchiseId
      customerId
      hubspotDealId
      number
      locationName
      sent
      invoiceStatus
      dateIssued
      dateDue
      totalCharge
      totalPayment
      totalOwed
      serviceType
    }

    workOrderSummaryViews(where: { customerId: { equals: $id } }) {
      workOrderId
      invoiceId
      agreementId
      locationId
      customerId
      franchiseId
      locationContactId
      compositeNumber
      scheduleDate
      number
      locationName
      contactFirstName
      contactLastName
      durationMinutes
      serviceType
    }
  }
`;

export const GET_CUSTOMERS = gql`
  ${CORE_CUSTOMER_FIELDS}
  query GetCustomers($where: CustomerWhereInput) {
    customers(where: $where) {
      ...CoreCustomerFields
      franchise {
        number
      }
      _count {
        locations
      }
    }
  }
`;

export const GET_CUSTOMERS_FOR_LIST = gql`
  query GetCustomersForList($where: CustomerWhereInput) {
    customers(where: $where) {
      id
      number
      name
      national
      inactive
      locations(
        where: {
          deleted: { equals: null }
          AND: [{ inactive: { equals: null } }, { convertedFromLead: { not: null } }]
        }
      ) {
        expectedMonthlyValue
      }
      address {
        id
        line1
        line2
        city
        state
        zipCode
      }
      _count {
        locations
      }
      franchise {
        number
      }
    }
  }
`;

export const GET_CUSTOMER_LOCATIONS = gql`
  query GetCustomerLocations($where: LocationSummaryViewWhereInput) {
    locationSummaryViews(where: $where) {
      locationId
      customerId
      franchiseId
      inactive
      name
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZipCode
      agreementExpirationDate
      agreementCount
      lastServiced
      lastInvoiced
      pastDueAmount
      yearToDateRevenueAmount
    }
  }
`;

export const GET_CUSTOMER_FOR_SCHEDULER = gql`
  ${CORE_BROKER_FIELDS}
  ${CORE_ASSET_FIELDS}
  ${CORE_DUMPSTER_FIELDS}
  query GetCustomerForScheduler($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      name
      national
      customerContacts {
        id
        firstName
        lastName
        phone
        primary
        visibleInCustomerList
      }
      locations {
        id
        expectedMonthlyValue
        leadNotes
        address {
          line1
          city
          state
          zipCode
        }
        dumpsters {
          ...CoreDumpsterFields
        }
        locationAssets {
          ...CoreAssetFields
        }
        locationBrokers {
          ...CoreBrokerFields
        }
      }
    }
  }
`;
