import { Select } from 'antd';
import styled, { css } from 'styled-components';

const StyledSelect = styled(Select)`
  margin-left: 10px;
  background-color: inherit;
  ${props =>
    !props.$editmode &&
    css`
      pointer-events: none;
    `}
`;

export default StyledSelect;
