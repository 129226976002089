import { Select, Grid } from 'antd';
import { stateList } from 'components/common/StateList';

import StyledInput from 'components/common/input/BaseInput';
import { CustomerFlexCol, CustomerRow, StyledStateSelect } from './customer.styled';
import { FormSectionTitle } from 'components/common/styled';
import { RHFWrapper } from 'components/common/react-hook-form/RHFWrapper';

const { useBreakpoint } = Grid;

const { Option } = Select;

function CustomerAddressForm({
  id,
  address,
  editmode,
  asterisks = false,
  index,
  validator,
  locationContact = false,
}: {
  id: string;
  address: any;
  editmode: boolean;
  asterisks?: boolean;
  index?: number;
  validator: any;
  locationContact?: boolean;
}) {
  const path =
    index !== undefined ? `${locationContact ? 'locationContacts' : 'customerContacts'}.${index}.address.` : 'address.';

  const screens = useBreakpoint();

  return (
    <CustomerRow gutter={[24, 12]}>
      <CustomerFlexCol xl={24} xs={24}>
        <FormSectionTitle className={asterisks && 'asterisk'} $nopad={!screens.xs && screens.xl}>
          Street:
        </FormSectionTitle>
        <RHFWrapper name={`${path}line1`} rules={{ required: 'Street is required' }}>
          <StyledInput placeholder='Street' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
        {validator(`${path}line1`, { required: 'Street is required' })}
      </CustomerFlexCol>

      <CustomerFlexCol xl={9} xs={24}>
        <FormSectionTitle className={asterisks && 'asterisk'} $nopad={!screens.xs && screens.xl}>
          City:&nbsp;&nbsp;&nbsp;&nbsp;
        </FormSectionTitle>
        <RHFWrapper name={`${path}city`} rules={{ required: 'City is required' }}>
          <StyledInput placeholder='City' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
        {validator(`${path}city`, { required: 'City is required' })}
      </CustomerFlexCol>

      <CustomerFlexCol xl={7} xs={24}>
        <FormSectionTitle className={asterisks && 'asterisk'} $nopad={!screens.xs && screens.xl}>
          State:
        </FormSectionTitle>
        <StateSelect editmode={editmode} state={address?.state || ''} path={path} />
      </CustomerFlexCol>

      <CustomerFlexCol xl={8} xs={12}>
        <FormSectionTitle className={asterisks && 'asterisk'} $nopad={!screens.xs && screens.xl}>
          Zip:
        </FormSectionTitle>
        <RHFWrapper name={`${path}zipCode`} rules={{ required: 'Zip Code is required' }}>
          <StyledInput placeholder='zipCode' $editmode={editmode} readOnly={!editmode} />
        </RHFWrapper>
        {validator(`${path}zipCode`, { required: 'Zip Code is required' })}
      </CustomerFlexCol>
    </CustomerRow>
  );
}

export function StateSelect({ state, editmode, path = '' }: { state?: string; editmode: boolean; path?: string }) {
  return (
    <RHFWrapper name={`${path}state`}>
      <StyledStateSelect placeholder='State' size='large' $editmode={editmode} defaultValue={state}>
        {stateList.map(st => (
          <Option value={st.abr || ''} key={st.abr || ''}>
            {st.state}
          </Option>
        ))}
      </StyledStateSelect>
    </RHFWrapper>
  );
}

export default CustomerAddressForm;
