import { FormProvider, useForm } from 'react-hook-form';
import { RHFPatternChild } from './RHFPatternChild';

export function RHFPatternMain() {
  type FormValues = {
    name: string;
    role: 'business' | 'IT';
    primary: boolean;
    projects: {
      name: string;
      length: number;
      notifications: string[];
    }[];
  };

  const formHandler = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {
      name: 'Sean',
      role: 'IT',
      primary: true,
      projects: [
        { name: 'SMT', length: 12, notifications: ['billing', 'jira', 'product owner'] },
        { name: 'Captial One', length: 6, notifications: ['billing'] },
      ],
    },
  });

  return (
    <FormProvider {...formHandler}>
      <RHFPatternChild />
    </FormProvider>
  );
}
