import { Control, FieldValues } from 'react-hook-form';

declare type RHFBaseParams = {
  name: string;
  editMode?: boolean;
  hideBorder?: boolean;
  rules?;
  emptyLabel?: boolean;
  control: Control<FieldValues, any>;
  attributes?;
  viewModeClassName?: string;
  viewModeStyle?: React.CSSProperties;
};

export declare type RHFOption = {
  label;
  value;
};

export declare type RHFAutoCompleteParams = RHFBaseParams & {
  defaultValue;
  options?: RHFOption[];
  filterOption?: (inputValue, option: RHFOption) => boolean;
};

export declare type RHFDatePickerParams = RHFBaseParams & {
  defaultValue?: Date | null | undefined;
  dateFormatFunction?: (date: Date) => string;
};

export declare type RHFSelectParams = RHFBaseParams & {
  defaultValue?;
  options?: RHFOption[];
};

export declare type RHFRadioGroupParams = RHFSelectParams;

export declare type RHFCheckboxGroupParams = RHFSelectParams & {
  renderMode?: CheckboxGroupRenderMode;
};

export declare type RHFCheckboxParams = RHFBaseParams & {
  defaultValue?: boolean;
  readOnly?: boolean;
  checkedText?: string;
  uncheckedText?: string;
};

export declare type RHFSwitchParams = RHFCheckboxParams;

export declare type RHFInputParams = RHFBaseParams & {
  defaultValue?;
};

export declare type RHFTextAreaParams = RHFInputParams;

export declare type RHFInputNumberParams = RHFInputParams & {
  formatter?: RHFInputNumberFormat;
};

export enum CheckboxGroupRenderMode {
  SelectedItemsOnly,
  AllItems,
  ReadOnlyCheckboxes,
}

export enum RHFInputNumberFormat {
  None,
  Currency,
  PercentFrom100,
  PercentFromRatio,
}
