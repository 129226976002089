import { useRef, useState } from 'react';
import { Col, notification, Row } from 'antd';

import { LeadGridRow } from './lead.models';
import LeadList from './LeadList';
import DashboardActionCard from 'components/common/card/DashboardActionCard';
import DashboardMetricCard, { DashboardMetricDisplayType } from 'components/common/card/DashboardMetricCard';

import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule-11.svg';
import { ReactComponent as Schedule2Icon } from 'assets/icons/schedule-7.svg';
import { ReactComponent as SmashTruckIcon } from 'assets/icons/smash-truck.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { CONVERT_FROM_LEAD } from 'graphql/mutation/customer';
import { useMutation } from '@apollo/client';
import { useGetFranchiseLeadMetricsQuery } from 'generated/graphql';
import { getSelectedFranchise, hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';

/**
 * This is a lead component that demonstrates one way to do grids cleanly
 * @returns a lead list component
 */
function LeadDashboard() {
  const canCreateSmashFromLead = hasPermission(DbConstants.Permission.LeadSmashCrud);
  const canConvertLeadToCustomer = hasPermission(DbConstants.Permission.LeadConvert);
  const canArchiveLead = hasPermission(DbConstants.Permission.LeadArchive);

  const history = useHistory();
  const currentFranchise = getSelectedFranchise();

  // get the search state, which includes everything
  const [selectedGridRow, setSelectedGridRow] = useState<LeadGridRow | null>(null);
  const { loading: metricsLoading, data: metricData } = useGetFranchiseLeadMetricsQuery({
    variables: {
      franchiseId: currentFranchise?.id,
    },
    onCompleted: data => {
      mRef.current.demosInPast6Months = data.leadAggregateMetricView?.demosCompleted6Mo;
      mRef.current.demosToTrialInPast6Months = data.leadAggregateMetricView?.trialsScheduledAfterDemo6Mo;
      mRef.current.trialsCompleted6Mo = data.leadAggregateMetricView?.trialsCompleted6Mo;
      mRef.current.trialsCompletedAndConverted6Mo = data.leadAggregateMetricView?.trialsCompletedAndConverted6Mo;
      mRef.current.trialWorkOrders7Days = data.leadAggregateMetricView?.trialWorkOrders7Days;
      mRef.current.trialWorkOrders30Days = data.leadAggregateMetricView?.trialWorkOrders30Days;
      mRef.current.trialWorkOrders90Days = data.leadAggregateMetricView?.trialWorkOrders90Days;
      mRef.current.demoWorkOrders7Days = data.leadAggregateMetricView?.demoWorkOrders7Days;
      mRef.current.demoWorkOrders30Days = data.leadAggregateMetricView?.demoWorkOrders30Days;
      mRef.current.demoWorkOrders90Days = data.leadAggregateMetricView?.demoWorkOrders90Days;
    },
  });
  const mRef = useRef({
    demosToTrialInPast6Months: metricData?.leadAggregateMetricView?.trialsScheduledAfterDemo6Mo || 0,
    demosInPast6Months: metricData?.leadAggregateMetricView?.demosCompleted6Mo || 100,
    trialsCompletedAndConverted6Mo: metricData?.leadAggregateMetricView?.trialsCompletedAndConverted6Mo || 0,
    trialsCompleted6Mo: metricData?.leadAggregateMetricView?.trialsCompleted6Mo || 100,
    trialWorkOrders7Days: metricData?.leadAggregateMetricView?.trialWorkOrders7Days || 0,
    trialWorkOrders30Days: metricData?.leadAggregateMetricView?.trialWorkOrders30Days || 0,
    trialWorkOrders90Days: metricData?.leadAggregateMetricView?.trialWorkOrders90Days || 0,
    demoWorkOrders7Days: metricData?.leadAggregateMetricView?.demoWorkOrders7Days || 0,
    demoWorkOrders30Days: metricData?.leadAggregateMetricView?.demoWorkOrders30Days || 0,
    demoWorkOrders90Days: metricData?.leadAggregateMetricView?.demoWorkOrders90Days || 0,
  });

  const [archiveLead] = useMutation(CONVERT_FROM_LEAD, {
    onCompleted: data => {
      if (data.customer?.inactive === null) return;
      notification.open({
        message: 'Archived',
        description: 'Lead Archived Successfully',
        duration: 5,
      });
    },
  });

  function buttonClicked(e) {
    // eslint-disable-next-line no-alert
    alert(
      `The [${e?.currentTarget?.innerText}] button has been clicked for record "${selectedGridRow?.id} -  ${selectedGridRow?.company}"!`
    );
  }

  // return the final chunk of jsx html for the component
  return (
    <>
      {/* <span>
        <b>Selected Row:</b> {selectedGridRow ? `${selectedGridRow?.id} -  ${selectedGridRow?.company}` : 'None'}
      </span> */}
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.PercentageCircle}
            headerText='DEMO TO TRIAL'
            footerText='CONVERSION RATE'
            value={
              mRef.current.demosInPast6Months > 0
                ? mRef.current.demosToTrialInPast6Months / mRef.current.demosInPast6Months
                : 0
            }
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.PercentageCircle}
            headerText='TRIAL TO CUSTOMER'
            footerText='CONVERSION RATE'
            value={
              mRef.current.trialsCompleted6Mo > 0
                ? mRef.current.trialsCompletedAndConverted6Mo / mRef.current.trialsCompleted6Mo
                : 0
            }
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.Value}
            headerText='REVENUE PER LEAD'
            value='$500'
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.UnitSlider}
            headerText='DEMOS PER {key}'
            values={[
              { key: 'Week', value: mRef.current.demoWorkOrders7Days },
              { key: 'Month', value: mRef.current.demoWorkOrders30Days },
              { key: 'Quarter', value: mRef.current.demoWorkOrders90Days },
            ]}
          />
          <DashboardMetricCard
            style={{ marginRight: '0px' }}
            displayType={DashboardMetricDisplayType.UnitSlider}
            headerText='TRIALS PER {key}'
            values={[
              { key: 'Week', value: mRef.current.trialWorkOrders7Days },
              { key: 'Month', value: mRef.current.trialWorkOrders30Days },
              { key: 'Quarter', value: mRef.current.trialWorkOrders90Days },
            ]}
          />
        </Col>
      </Row>
      <Row justify='space-between' wrap>
        <Col span={24}>
          <LeadList setSelectedGridRow={(x: LeadGridRow | null) => setSelectedGridRow(x)} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DashboardActionCard
            text='Schedule Demo'
            icon={<ScheduleIcon />}
            enabled={!!selectedGridRow && canCreateSmashFromLead}
            // onClick={e => buttonClicked(e)}
            onClick={() =>
              history.push(
                `/create-smash-order/?lead=true&orderType=demo&customerId=${selectedGridRow?.customerId}&locationId=${selectedGridRow?.id}&skipTo=order-details`
              )
            }
          />
          <DashboardActionCard
            text='Schedule Trial'
            icon={<Schedule2Icon />}
            enabled={!!selectedGridRow && canCreateSmashFromLead}
            onClick={() =>
              history.push(
                `/create-smash-order/?lead=true&orderType=trial&customerId=${selectedGridRow?.customerId}&locationId=${selectedGridRow?.id}&skipTo=order-details`
              )
            }
          />
          <DashboardActionCard
            text='Convert To Customer'
            icon={<SmashTruckIcon />}
            enabled={!!selectedGridRow && canConvertLeadToCustomer}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            text='Archive'
            icon={<ArchiveIcon />}
            enabled={!!selectedGridRow && canArchiveLead}
            onClick={() => {
              archiveLead({ variables: { data: { hubspotDealId: selectedGridRow?.hubspotDealId, isDealWon: false } } });
            }}
          />
          <DashboardMetricCard
            style={{ marginRight: '0px' }}
            displayType={DashboardMetricDisplayType.Value}
            enabled={!!selectedGridRow}
            headerText='DAYS AS LEAD'
            value={selectedGridRow ? selectedGridRow.daysAsLead : null}
            color={theme['@red']}
          />
        </Col>
      </Row>
    </>
  );
}

export default LeadDashboard;
