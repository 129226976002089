/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { Input } from 'antd';
import { RHFInputParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd input control using a react-hook-form controller.
 * see: https://ant.design/components/input/ (AntD Input reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd input control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFInput(params: RHFInputParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName =
    params.viewModeClassName ||
    (params.hideBorder ? 'rhf-label-noborder rhf-label-single-line' : 'rhf-label rhf-label-single-line');
  let viewModeElement = <div className={labelClassName}>&nbsp;</div>;
  const defVal = params.defaultValue || params.control?._defaultValues[params.name];
  if (defVal)
    viewModeElement = (
      <div title={defVal} className={labelClassName} style={params.viewModeStyle}>
        {defVal}
      </div>
    );
  else if (params.emptyLabel && params?.attributes?.placeholder)
    viewModeElement = (
      <div className={`rhf-empty-label ${labelClassName}`} style={params.viewModeStyle}>
        {params?.attributes?.placeholder}
      </div>
    );

  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => <Input {...field} {...attributes} />}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
