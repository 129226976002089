import { GetDumpsterLookupsQuery, GetAssetLookupsQuery } from 'generated/graphql';
import { CustomerDumpsterForm, CustomerBrokerForm, CustomerAssetForm, LocationLookupRecord } from './equipment.models';

/**
 * Maps the typeless/odd typed object array to the strongly typed CustomerDumpsterForm
 * @param srcDumpster The source dumpster array from the query.
 * @returns a CustomerDumpsterForm array.
 */
export function mapDumpsterFormArray(srcDumpsters: any[]): CustomerDumpsterForm[] {
  if (!srcDumpsters) return [];
  return srcDumpsters.map(d => mapDumpsterFormObject(d));
}

/**
 * Maps the typeless/odd typed object array to the strongly typed CustomerDumpsterForm
 * @param srcDumpster The source dumpster array from the query.
 * @returns a CustomerDumpsterForm array.
 */
export function mapDumpsterFormObject(srcDumpster: any): CustomerDumpsterForm {
  return {
    id: srcDumpster?.id,
    locationId: srcDumpster?.locationId,
    name: srcDumpster?.name,
    serialNumber: srcDumpster?.serialNumber,
    dumpsterHauler: srcDumpster?.dumpsterHauler,
    dumpsterSizeId: srcDumpster?.dumpsterSizeId,
    notes: srcDumpster?.notes,
    deleted: srcDumpster?.deleted,
    agreementManaged:
      srcDumpster?.agreementManaged === undefined || srcDumpster?.agreementManaged === null
        ? true
        : srcDumpster?.agreementManaged,
  } as CustomerDumpsterForm;
}

/**
 * Maps the typeless/odd typed object to the strongly typed CustomerBrokerForm
 * @param srcBroker The source broker object from the query.
 * @returns a CustomerBrokerForm object.
 */
export function mapBrokerFormObject(srcBroker, locationId?: string): CustomerBrokerForm {
  return {
    locationId: srcBroker?.locationId || locationId,
    contactFirstName: srcBroker?.contactFirstName,
    contactLastName: srcBroker?.contactLastName,
    contactEmail: srcBroker?.contactEmail,
    contactPhone: srcBroker?.contactPhone,
    brokerName: srcBroker?.brokerName,
    notes: srcBroker?.notes,
    deleted: srcBroker?.deleted,
  } as CustomerBrokerForm;
}

/**
 * Maps the typeless/odd typed object array to the strongly typed CustomerAssetForm
 * @param srcAssets The source broker array from the query.
 * @returns a CustomerAssetForm array.
 */
export function mapAssetFormArray(srcAssets: any[]): CustomerAssetForm[] {
  if (!srcAssets) return [];
  return srcAssets.map(d => mapAssetFormObject(d));
}

/**
 * Maps the typeless/odd typed object array to the strongly typed CustomerAssetForm
 * @param srcAsset The source broker array from the query.
 * @returns a CustomerAssetForm array.
 */
export function mapAssetFormObject(srcAsset: any): CustomerAssetForm {
  return {
    id: srcAsset?.id,
    locationId: srcAsset?.locationId,
    name: srcAsset?.name,
    assetTypeId: srcAsset?.assetTypeId,
    contractExpiration: srcAsset?.contractExpiration,
    locationNotes: srcAsset?.locationNotes,
    priceDetails: srcAsset?.priceDetails,
    size: srcAsset?.size,
    underContract: srcAsset?.underContract,
    unitCount: srcAsset?.unitCount,
    provider: srcAsset?.provider,
    wasteStreamId: srcAsset?.wasteStreamId,
    notes: srcAsset?.notes,
    deleted: srcAsset?.deleted,
  } as CustomerAssetForm;
}

/**
 * Populate the haulers and sizes arrays from the query.
 * @param data the results of the get dumpster lookup query
 * @param haulers the haulers array to populate
 * @param sizes the sizes array to populate
 */
export function populateDumpsterLookups(
  data: GetDumpsterLookupsQuery,
  haulers: LocationLookupRecord[],
  sizes: LocationLookupRecord[]
): void {
  if (data?.dumpsterHaulers && data?.dumpsterSizes) {
    haulers.push(
      ...data.dumpsterHaulers.map(
        x =>
          ({
            id: x.id.toString(),
            description: x.description,
          } as { id: string; description: string })
      )
    );
    sizes.push(
      ...data.dumpsterSizes.map(
        x =>
          ({
            id: x.id,
            description: x.description,
          } as { id: string; description: string })
      )
    );
  }
}

/**
 * Populate the asset types ans waste streams from the query.
 * @param data the results of the get asset lookup query
 * @param wasteStreams the waste stream array to populate
 * @param assetTypes the asset/equipment type array to populate
 */
export function populateAssetLookups(
  data: GetAssetLookupsQuery,
  wasteStreams: LocationLookupRecord[],
  assetTypes: LocationLookupRecord[]
): void {
  if (data?.wasteStreams && data?.assetTypes) {
    assetTypes.push(
      ...data.assetTypes.map(
        x =>
          ({
            id: x.id.toString(),
            description: x.description,
          } as { id: string; description: string })
      )
    );
    wasteStreams.push(
      ...data.wasteStreams.map(
        x =>
          ({
            id: x.id.toString(),
            description: x.description,
          } as { id: string; description: string })
      )
    );
  }
}
