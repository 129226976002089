import {
  CardHeader,
  CardHeaderLeft,
  CardHeaderText,
  ContentCard,
  CardRow,
  CardColumn,
} from 'components/common/card/Card';
import {
  DetailsViewField,
  DetailsViewFieldLabel,
  ScheduleFormSection,
  ScheduleTopFormSection,
  ScheduleTableTitle,
  CardRowBlock,
  ScheduleSelectsContainer,
} from './schedule.styled';
import { Select } from 'antd';
import { StyledTable } from 'components/common/card/TableCard';
import { calculateWeekOffset, getScheduleColumns } from './schedule.service';
import { useState } from 'react';
import { WeekStepper } from 'components/common/WeekStepper';
import { isValid } from 'date-fns';

const { Option } = Select;

export function SmashOrderSchedule({
  trial,
  handleScheduleInput,
  handleTimeSlotInput,
  handleTrialDayInput,
  formState,
  readOnly,
}: {
  trial: boolean;
  handleScheduleInput: any;
  handleTimeSlotInput: any;
  handleTrialDayInput: any;
  formState: any;
  readOnly?: boolean;
}) {
  const offsetValid = offset => {
    const int = parseInt(offset, 10);
    return Number.isInteger(int) && int >= 0;
  };

  // find lowest offset property in trialDays[]
  const minOffsetInTrialDays = () => {
    const result =
      formState?.trialDays
        ?.filter(day => isValid(new Date(day)))
        .map(day => calculateWeekOffset(day))
        .sort()[0] || 0;

    return offsetValid(result) ? result : 0;
  };

  const [weekOffset, setWeekOffset] = useState(
    trial ? minOffsetInTrialDays() : calculateWeekOffset(formState?.timeSlot?.date) || 0
  );

  const title = (
    <CardHeader>
      <CardHeaderLeft>
        <CardHeaderText>SCHEDULE</CardHeaderText>
      </CardHeaderLeft>
    </CardHeader>
  );

  return (
    <ContentCard title={title}>
      <CardRow>
        <CardColumn xs={24} xl={24}>
          <ScheduleTopFormSection>
            <WeekStepper handleOffset={setWeekOffset} existingOffset={weekOffset} futureOnly />
            {!trial && (
              <ScheduleFormSection>
                {formState.orderType === 'recurring' && (
                  <ScheduleSelectsContainer>
                    <ScheduleFormSection>
                      <DetailsViewFieldLabel>Frequency:</DetailsViewFieldLabel>
                      <DetailsViewField>
                        {!readOnly ? (
                          <Select
                            onSelect={value => handleScheduleInput({ frequency: value })}
                            defaultValue='Every 2 Weeks'
                          >
                            <Option key={2} value='Every 2 Weeks'>
                              Every 2 Weeks
                            </Option>
                          </Select>
                        ) : (
                          'Every 2 Weeks'
                        )}
                      </DetailsViewField>
                    </ScheduleFormSection>
                    <ScheduleFormSection>
                      <DetailsViewFieldLabel>Duration:</DetailsViewFieldLabel>
                      <DetailsViewField>
                        {!readOnly ? (
                          <Select onSelect={value => handleScheduleInput({ duration: value })} defaultValue='1 Hour'>
                            <Option key={1} value='1 Hour'>
                              1 Hour
                            </Option>
                          </Select>
                        ) : (
                          '1 Hour'
                        )}
                      </DetailsViewField>
                    </ScheduleFormSection>
                  </ScheduleSelectsContainer>
                )}
              </ScheduleFormSection>
            )}
          </ScheduleTopFormSection>
        </CardColumn>
      </CardRow>
      <CardRowBlock>
        <ScheduleTableTitle>AVAILABLE TIMES</ScheduleTableTitle>
        <StyledTable
          columns={getScheduleColumns(
            weekOffset,
            trial,
            handleTimeSlotInput,
            handleTrialDayInput,
            readOnly || false,
            formState.trialDays,
            formState.timeSlot
          )}
          dataSource={[{ id: 0, value: true }]}
          pagination={false}
          rowKey='id'
        />
      </CardRowBlock>
    </ContentCard>
  );
}
