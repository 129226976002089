import { gql } from '@apollo/client';

export const CORE_CUSTOMER_FIELDS = gql`
  fragment CoreCustomerFields on Customer {
    id
    number
    name
    createdAt
    updatedAt
    franchiseId
    national
    inactive
    customerContacts {
      firstName
      lastName
      id
      phone
      email
      jobTitle
      contactTypes
      primary
      visibleInCustomerList
      preferredContactMethod
      contactCategories
      address {
        id
        line1
        line2
        city
        state
        zipCode
      }
    }
    locations {
      id
      customerId
      name
      expectedMonthlyValue
      address {
        id
        line1
        line2
        state
        city
        zipCode
      }
      agreements {
        invoices {
          id
          agreementId
          number
          dateIssued
          dateDue
          createdAt
          updatedAt
        }
      }
    }
    address {
      id
      line1
      line2
      city
      state
      zipCode
    }
  }
`;
