import { CustomerGridRow } from 'components/customer/customer.models';
import CustomersMain from 'components/customer/CustomersMain';
import Equipment from 'components/equipment/Equipment';
import { LeadGridRow } from 'components/leads/lead.models';
import LeadList from 'components/leads/LeadList';
import { LocationGridRow } from 'components/location/location.models';
import LocationTableView from 'components/location/LocationTableView';
import { SmashOrderConfirmation } from 'components/schedule/SmashOrderConfirmation';
import { SmashOrderDetails } from 'components/schedule/SmashOrderDetails';
import { SmashOrderSchedule } from 'components/schedule/SmashOrderSchedule';
import useWindowSizeWatcher from 'hooks/useWindowSizeWatcher';
import { useEffect, useState } from 'react';
import { useGetCustomerForSchedulerQuery } from 'generated/graphql';
import { FormRouter } from 'components/common/multi-form/FormRouter';
import { FormRoute } from 'components/common/multi-form/FormRoute';
import PageTitle from 'components/common/PageTitle';
import { useHistory } from 'react-router-dom';
import { isPast, isValid } from 'date-fns';
import { Modal } from 'antd';

enum OrderType {
  DEMO = 'demo',
  TRIAL = 'trial',
  DEMAND = 'on-demand',
  PROJECT = 'project',
  RECURRING = 'recurring',
}

enum SkipTo {
  CUSTOMER_SELECTION = 'customer-selection',
  LEAD_SELECTION = 'lead-selection',
  LOCATION_SELECTION = 'location-selection',
  ORDER_DETAILS = 'order-details',
  ORDER_SCHEDULE = 'order-schedule',
  ORDER_REVIEW = 'order-review',
  ORDER_CONFIRMATION = 'order-confirmation',
}

export function ScheduleManagement() {
  const queryParams = new URLSearchParams(window.location.search);

  const history = useHistory();

  const dimensions = useWindowSizeWatcher();

  interface FormState {
    lead: boolean;
    orderType: string | null;
    skipTo: string | null;
    timeSlot: any;
    trialDays: string[];
    selections: any;
  }

  /* QUERY STRING HANDLING */
  const validateQueryParam = (param: string, constraints: any, defaultOrUndefined = false) => {
    const queryParam = queryParams.get(param) || '';
    const values = Object.values<any>(constraints);
    const enumDefault = values[0];
    const valid = values.includes(queryParam);

    if (valid) {
      return queryParam;
    }
    // if query param is invalid, decide whether to return default (first value in enum) or undefined
    return defaultOrUndefined ? undefined : enumDefault;
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop.toString()),
  });

  const urlDecodedTimeSlot = () => {
    let parsed = (params as any).timeSlot;

    try {
      parsed = JSON.parse(parsed);
    } catch (e) {
      parsed = null;
    }
    return parsed;
  };

  const urlDecodedTrialDays = () => {
    let parsed = (params as any).trialDays;

    try {
      parsed = JSON.parse(parsed);
    } catch (e) {
      parsed = null;
    }

    return parsed;
  };

  const validateTimeSlot = timeSlot => {
    const timeValid = time => /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);

    if (timeSlot === null) return null;

    const selectedDate = new Date(timeSlot.date);

    // remove AM/PM suffix
    const time = timeSlot.time.split(' ')[0];

    if (timeValid(time) && isValid(selectedDate) && !isPast(selectedDate)) {
      return timeSlot;
    }
    return null;
  };

  const validateDatesArray = (dates: string[]) => {
    // confirm formatting is valid and dates are not in the past
    const validateDate = date => {
      if (date === null) return false;

      const selectedDate = new Date(date);

      return isValid(selectedDate) && !isPast(selectedDate) ? selectedDate : null;
    };

    if (dates === null || dates === undefined) return null;

    const filteredDates = dates.filter(date => validateDate(date));

    if (filteredDates.length === 0) {
      return null;
    }
    return filteredDates;
  };

  /* STATE HANDLING */

  const [formState, setFormState] = useState<FormState>({
    lead: queryParams.get('lead') === 'true',
    orderType: validateQueryParam('orderType', OrderType),
    skipTo: validateQueryParam('skipTo', SkipTo, true),
    timeSlot: validateTimeSlot(urlDecodedTimeSlot()),
    trialDays: validateDatesArray(urlDecodedTrialDays()) || [],
    selections: {
      customerId: queryParams.get('customerId') || null,
      locationId: queryParams.get('locationId') || null,
    },
  });

  useEffect(() => {
    const queryParamsLocal = new URLSearchParams(window.location.search);

    if (formState.timeSlot === null) {
      queryParamsLocal.delete('timeSlot');
    } else queryParamsLocal.set('timeSlot', JSON.stringify(formState.timeSlot));

    if (formState.trialDays === null || formState.trialDays.length === 0) {
      queryParamsLocal.delete('trialDays');
    } else queryParamsLocal.set('trialDays', JSON.stringify(formState.trialDays));

    if (formState.orderType === null) {
      queryParamsLocal.delete('orderType');
    } else queryParamsLocal.set('orderType', formState.orderType);

    history.replace({
      pathname: window.location.pathname,
      search: queryParamsLocal.toString(),
    });
  }, [formState, history]);

  const [formStep, setFormStep] = useState(0);

  const [selectedCustomer, setSelectedCustomer] = useState<any>();

  const selectedLocation = selectedCustomer?.locations?.find(loc => loc.id === formState.selections.locationId);

  const isTrial = () => formState.orderType === 'trial';

  const handleScheduleInput = input => {
    setFormState({ ...formState, ...input });
  };

  const handleTimeSelection = (date, time) => {
    const timeSlot = { date, time };

    // user explicitly deselected an option
    if (time === 'default' && date === formState?.timeSlot?.date) {
      setFormState({ ...formState, timeSlot: null });
      queryParams.delete('timeSlot');
      // user changed timeslot for previously selected day
    } else {
      setFormState({
        ...formState,
        timeSlot,
      });
      queryParams.set('timeSlot', JSON.stringify(timeSlot));
    }
    history.replace({
      pathname: window.location.pathname,
      search: queryParams.toString(),
    });
  };

  const handleIdSelection = (customerId?: string, locationId?: string) => {
    setFormState({ ...formState, selections: { customerId: customerId || null, locationId: locationId || null } });

    if (customerId) queryParams.set('customerId', customerId);
    else queryParams.delete('customerId');

    if (locationId) queryParams.set('locationId', locationId);
    else queryParams.delete('locationId');

    history.replace({
      pathname: window.location.pathname,
      search: queryParams.toString(),
    });
  };

  /* FETCH */
  const { loading: loadingSelectedCustomer, data: selectedCustomerData } = useGetCustomerForSchedulerQuery({
    onCompleted: data => {
      setSelectedCustomer(data?.customer);
      if (data?.customer?.locations.length === 1)
        handleIdSelection(formState?.selections?.customerId, data?.customer?.locations[0].id);
    },
    skip: !formState.selections?.customerId,
    variables: {
      where: { id: formState.selections?.customerId || queryParams.get('customerId') },
    },
  });

  const handleTrialDaySelection = day => {
    const newTrialDays = formState.trialDays.includes(day)
      ? formState.trialDays.filter(trialDay => trialDay !== day)
      : formState.trialDays.concat(day);

    const newState = { ...formState, trialDays: newTrialDays };

    setFormState(newState);

    if (newTrialDays.length === 0) {
      queryParams.delete('trialDays');
    } else {
      queryParams.set('trialDays', JSON.stringify(newTrialDays));
    }

    history.replace({
      pathname: window.location.pathname,
      search: queryParams.toString(),
    });
  };

  return (
    <>
      {formStep < 5 ? (
        <PageTitle>NEW {formState.orderType || 'SMASH'} REQUEST</PageTitle>
      ) : (
        selectedCustomer && <PageTitle>NEW {selectedCustomer.name} SMASH ORDER</PageTitle>
      )}
      <FormRouter
        rootRoute='create-smash-order'
        selectedFlow={formState.lead ? 'lead' : 'customer'}
        formState={formState}
        setFormStep={setFormStep}
      >
        <FormRoute
          name='lead-selection'
          flows={['lead']}
          progressLabel='SELECT LEAD'
          validate={stepState => {
            if (!stepState.selections.customerId) {
              Modal.warning({
                title: 'Validation Failed',
                content: 'Please select a lead to continue.',
              });
              return false;
            }
            return true;
          }}
        >
          <LeadList
            redirect={false}
            setSelectedGridRow={(lead: LeadGridRow | null) => {
              handleIdSelection(lead?.customerId, lead?.id);
            }}
            gridHeight={dimensions.height - 400}
            selectedLocationId={formState.selections.locationId || undefined}
          />
        </FormRoute>
        <FormRoute
          name='customer-selection'
          flows={['customer']}
          progressLabel='SELECT CUSTOMER'
          validate={stepState => {
            if (!stepState.selections.customerId) {
              Modal.warning({
                title: 'Validation Failed',
                content: 'Please select a customer to continue.',
              });
              return false;
            }
            return true;
          }}
        >
          <CustomersMain
            gridHeight={dimensions.height - 200}
            redirect={false}
            activeToggle={false}
            selectable
            setSelectedGridRow={(customer: CustomerGridRow | null) => {
              handleIdSelection(customer?.id, formState?.selections?.locationId);
            }}
            selectedCustomerId={formState.selections.customerId || undefined}
          />
        </FormRoute>
        <FormRoute
          name='location-selection'
          flows={['customer']}
          progressLabel='SELECT LOCATION'
          validate={stepState => {
            if (!stepState.selections.locationId) {
              Modal.warning({
                title: 'Validation Failed',
                content: 'Please select a location to continue.',
              });
              return false;
            }
            return true;
          }}
        >
          {selectedCustomer && (
            <LocationTableView
              editable={false}
              redirect={false}
              selectable
              customerIdProp={formState.selections.customerId || undefined}
              setSelectedGridRow={(location: LocationGridRow | null) => {
                handleIdSelection(formState?.selections?.customerId, location?.id);
              }}
              selectedLocationId={formState.selections.locationId || undefined}
            />
          )}
        </FormRoute>
        <FormRoute name='order-details' flows={['lead', 'customer']} progressLabel='ORDER DETAILS'>
          {selectedCustomer && (
            <>
              <SmashOrderDetails
                customer={selectedCustomer}
                handleScheduleInput={handleScheduleInput}
                formState={formState}
                locationId={formState.selections.locationId || undefined}
              />
              <Equipment
                readOnly
                location={selectedLocation}
                broker={
                  (selectedLocation?.locationBrokers?.length || 0) > 0 ? selectedLocation.locationBrokers[0] : undefined
                }
                dumpsters={selectedLocation?.dumpsters}
                assets={selectedLocation?.locationAssets}
              />
            </>
          )}
        </FormRoute>
        <FormRoute
          name='order-schedule'
          flows={['lead', 'customer']}
          progressLabel='SCHEDULE'
          validate={stepState => {
            let condition = null;

            if (stepState.orderType === 'demo') {
              condition = stepState.timeSlot;
            } else if (stepState.orderType === 'trial') {
              condition = stepState.trialDays.length > 0 ? stepState.trialDays : null;
            }

            if (condition === null) {
              Modal.warning({
                title: 'Validation Failed',
                content: 'Please select a time to continue.',
              });
              return false;
            }
            return true;
          }}
        >
          <SmashOrderSchedule
            trial={isTrial()}
            handleScheduleInput={handleScheduleInput}
            handleTrialDayInput={handleTrialDaySelection}
            handleTimeSlotInput={handleTimeSelection}
            formState={formState}
          />
        </FormRoute>
        <FormRoute
          submission='Create Smash Order'
          name='order-review'
          flows={['lead', 'customer']}
          progressLabel='CONFIRM JOB'
        >
          {selectedCustomer && (
            <>
              <SmashOrderDetails
                customer={selectedCustomer}
                handleScheduleInput={handleScheduleInput}
                formState={formState}
                readOnly
              />
              <SmashOrderSchedule
                readOnly
                trial={isTrial()}
                handleScheduleInput={handleScheduleInput}
                handleTrialDayInput={handleTrialDaySelection}
                handleTimeSlotInput={handleTimeSelection}
                formState={formState}
              />
            </>
          )}
        </FormRoute>
        <FormRoute
          confirmation
          name='order-confirmation'
          flows={['lead', 'customer']}
          progressLabel='ORDER CONFIRMATION'
        >
          {selectedCustomer && <SmashOrderConfirmation formState={formState} customer={selectedCustomer} />}
        </FormRoute>
      </FormRouter>
    </>
  );
}
