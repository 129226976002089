import { makeVar } from '@apollo/client';
import { Customer } from 'models/customer';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';
import { SignedInUser } from 'models/signedInUser';

export const cognitoUserVar = makeVar<any>(undefined);
export const userState = makeVar(<SignedInUser>{});
export const franchiseState = makeVar(<FranchiseIdentifier>{});

export const preselectedLead = makeVar(<Customer>{} || null);
