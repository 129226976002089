/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { TruckGridRow } from './truck.models';
import { TruckWhereInput, GetTrucksQuery } from 'generated/graphql';
import { getSelectedFranchise } from 'services/userService';
import { autoCompare } from 'services/sortingService';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFCheckbox } from 'components/common/react-hook-form/RHFCheckbox';
import { RHFInputNumber } from 'components/common/react-hook-form/RHFInputNumber';
import { uuidGenerator } from 'services/dataGenerationService';
import { StyledDeleteButtonNoMargin } from 'components/common/styled';
import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

/**
 * Assemble the graphql criteria for the truck search query.
 * @returns The where clause for the truck search query
 */
export function buildTrucksWhereClause(): TruckWhereInput {
  let franchiseId = '00000000-0000-0000-0000-000000000000';
  const franchise = getSelectedFranchise();
  if (franchise) {
    franchiseId = franchise.id;
  }
  return {
    franchiseId: {
      equals: franchiseId,
    },
    AND: [
      {
        deleted: {
          equals: null,
        },
      },
    ],
  } as TruckWhereInput;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredTruckResults(queryResults: GetTrucksQuery | undefined): TruckGridRow[] {
  // map and filter the search results
  let results: TruckGridRow[] = [];
  const records = queryResults?.trucks || [];
  if (records && records.length > 0) {
    results = records.map((rec, i) => mapFilteredTruckResult(rec, i));
  }
  return results;
}

export function mapFilteredTruckResult(rec, i) {
  return {
    internalIndex: i,
    id: rec?.id,
    number: rec?.number,
    make: rec?.make,
    model: rec?.model,
    year: rec?.year,
    smashSerialNumber: rec?.smashSerialNumber,
    outOfService: rec?.outOfService,
    vin: rec?.vin,
    deleted: rec?.deleted,
    plate: rec?.plate,
  } as TruckGridRow;
}

export function newTruckGridRow(newIndex: number): TruckGridRow {
  return {
    internalIndex: newIndex,
    id: uuidGenerator.generate(),
    number: undefined,
    make: undefined,
    model: undefined,
    year: undefined,
    smashSerialNumber: undefined,
    outOfService: false,
    vin: undefined,
    deleted: false,
    plate: undefined,
  } as TruckGridRow;
}

/**
 * This returns a list of columns for the editable grid.
 * @param readOnly Are we in readonly mode?
 * @param editMode Are we in edit mode?
 * @param control the RHF control object
 * @param register a function to register controls into the form
 * @param onDeleteHandler the handler for the delete function.
 * @returns a list of columns for the editable grid.
 */
export function getTruckColumns(readOnly, editMode, control, register, onDeleteHandler): ColumnProps<TruckGridRow>[] {
  const columns = [
    {
      title: <Text className='asterisk'>VIN</Text>,
      className: 'cell-align-top',
      dataIndex: 'vin',
      width: '20%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <>
          <input type='hidden' {...register(`trucks[${record.internalIndex}].id`)} value={record.id} />
          <RHFInput
            name={`trucks[${record.internalIndex}].vin`}
            editMode={editMode}
            control={control}
            defaultValue={text}
            hideBorder
            rules={{
              required: 'VIN is required',
              minLength: { value: 17, message: 'A VIN must be 17 characters long' },
              maxLength: { value: 17, message: 'A VIN must be 17 characters long' },
            }}
            attributes={{
              style: { width: '100%' },
              placeholder: 'VIN',
            }}
          />
        </>
      ),
      sorter: (a, b) => autoCompare(a.vin, b.vin),
    },
    {
      title: 'SMASH MACHINE SERIAL #',
      className: 'cell-align-top',
      dataIndex: 'smashSerialNumber',
      width: '15%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInput
          name={`trucks[${record.internalIndex}].smashSerialNumber`}
          editMode={editMode}
          control={control}
          defaultValue={text}
          viewModeStyle={{ wordBreak: 'break-word' }}
          rules={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Serial #',
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.smashSerialNumber, b.smashSerialNumber),
    },
    {
      title: <Text className='asterisk'>PLATE</Text>,
      className: 'cell-align-top',
      dataIndex: 'plate',
      width: '10%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInput
          name={`trucks[${record.internalIndex}].plate`}
          editMode={editMode}
          control={control}
          defaultValue={text}
          rules={{
            required: 'Plate is required',
            maxLength: { value: 10, message: 'Maximum of 10 characters' },
          }}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Plate*',
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.plate, b.plate),
    },
    {
      title: 'YEAR',
      className: 'cell-align-top',
      dataIndex: 'year',
      width: '10%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInputNumber
          name={`trucks[${record.internalIndex}].year`}
          editMode={editMode}
          control={control}
          defaultValue={record.year}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Year',
            min: 1900,
            max: new Date().getFullYear() + 2,
            precision: 0,
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.year, b.year),
    },
    {
      title: <Text className='asterisk'>MAKE</Text>,
      className: 'cell-align-top',
      dataIndex: 'make',
      width: '10%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInput
          name={`trucks[${record.internalIndex}].make`}
          editMode={editMode}
          control={control}
          defaultValue={text}
          rules={{ required: 'Make is required', maxLength: { value: 50, message: 'Maximum of 50 characters' } }}
          viewModeStyle={{ wordBreak: 'break-word' }}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Make*',
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.make, b.make),
    },
    {
      title: <Text className='asterisk'>MODEL</Text>,
      className: 'cell-align-top',
      dataIndex: 'model',
      width: '10%',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInput
          name={`trucks[${record.internalIndex}].model`}
          editMode={editMode}
          control={control}
          defaultValue={text}
          rules={{ required: 'Model is required', maxLength: { value: 50, message: 'Maximum of 50 characters' } }}
          viewModeStyle={{ wordBreak: 'break-word' }}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Model*',
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.model, b.model),
    },
    {
      title: <Text className='asterisk'>TRUCK #</Text>,
      className: 'cell-align-top',
      dataIndex: 'number',
      width: '10%',
      defaultSortOrder: 'ascend',
      render: (text: string, record: TruckGridRow, idx: number) => (
        <RHFInput
          name={`trucks[${record.internalIndex}].number`}
          editMode={editMode}
          control={control}
          defaultValue={text}
          rules={{ required: 'Truck # is required', maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
          viewModeStyle={{ wordBreak: 'break-word' }}
          hideBorder
          attributes={{
            style: { width: '100%' },
            placeholder: 'Truck #',
          }}
        />
      ),
      sorter: (a, b) => autoCompare(a.number, b.number),
    },
    {
      title: 'OUT OF SERVICE',
      className: 'cell-align-top',
      dataIndex: 'outOfService',
      width: '10%',
      render: (flagValue: boolean, record: TruckGridRow, idx: number) => (
        <RHFCheckbox
          name={`trucks[${record.internalIndex}].outOfService`}
          editMode={editMode}
          control={control}
          defaultValue={flagValue}
          checkedText='Yes'
          uncheckedText=''
          hideBorder
        />
      ),
      sorter: (a, b) => autoCompare(a.outOfService, b.outOfService),
    },
  ] as ColumnProps<TruckGridRow>[];

  if (!readOnly && !editMode) {
    columns.splice(0, 0, {
      title: <Text>&nbsp;</Text>,
      dataIndex: 'id',
      width: '1%',
      render: (text: string, record: TruckGridRow, idx: number) =>
        !readOnly && (
          <StyledDeleteButtonNoMargin
            size='small'
            shape='circle'
            icon={<DeleteOutlined />}
            onClick={() => {
              onDeleteHandler(record?.id);
            }}
          />
        ),
      sorter: false,
    });
  }

  return columns;
}

/**
 * Get the scroll data for the truck search table. If there are less than 10 rows, don't do scrolling.
 * @param loading is the data still loading?
 * @param activeData the filtered data to be bound to the table
 * @param dimensions the window dimensions
 * @returns scroll data for the antd table
 */
export function getTruckScrollData(
  loading: boolean,
  activeData: TruckGridRow[],
  dimensions: { height: number; width: number }
) {
  return !loading && (activeData?.length || 0) > 8
    ? {
        y: dimensions.height > 500 ? Math.round(dimensions.height - 250) : 300,
      }
    : null;
}
