import { EmployeeGridRow } from './employee.models';
import EmployeeList from './EmployeeList';

import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getSelectedFranchise } from 'services/userService';
import PageTitle from 'components/common/PageTitle';
import ScrollToTop from 'components/common/ScrollToTop';
import EmployeeDetail from './EmployeeDetail';

/**
 * This is a employee component that demonstrates one way to do grids cleanly
 * @returns a employee list component
 */
function EmployeeManagement() {
  const match = useRouteMatch();
  const history = useHistory();
  const currentFranchise = getSelectedFranchise();

  // return the final chunk of jsx html for the component
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path={`${match.path}/:employeeId`}>
          <EmployeeDetail />
        </Route>
        <Route path={`${match.path}`}>
          <PageTitle>{currentFranchise?.name?.toUpperCase()} EMPLOYEE SETTINGS</PageTitle>
          <EmployeeList />
        </Route>
      </Switch>
    </>
  );
}

export default EmployeeManagement;
