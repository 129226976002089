/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusOutlined } from '@ant-design/icons';
import { Row, Tabs } from 'antd';
import { CardHeader, CardTabs, ContentTabsCard, TabCardHeaderText } from 'components/common/card/Card';
import { StyledPlusButtonNoMargin, StyledButton } from 'components/common/styled';
import { useState } from 'react';
import theme from 'theme';
import DumpsterBroker from './sections/DumpsterBroker';
import Dumpsters from './sections/Dumpsters';
import OtherEquipmentList from './sections/OtherEquipmentList';
import { ChildFormActivator, CustomerAssetForm, CustomerBrokerForm, CustomerDumpsterForm } from './equipment.models';
import { LeadFlexCol, LeadSpaceWithLeftMargin } from '../leads/lead.styled';

function Equipment({
  readOnly,
  location,
  broker,
  dumpsters,
  assets,
  brokerUpdated,
  dumpstersUpdated,
  assetsUpdated,
}: {
  readOnly?: boolean;
  location;
  broker;
  dumpsters;
  assets;
  brokerUpdated?: (broker: CustomerBrokerForm) => void;
  dumpstersUpdated?: (dumpsters: CustomerDumpsterForm[]) => void;
  assetsUpdated?: (assets: CustomerAssetForm[]) => void;
}) {
  const [editMode, setEditMode] = useState(false);
  const [tab, setTab] = useState('dumpsters');

  // set the default implementation of the form actions.
  let formActions: ChildFormActivator = {
    canAdd: false,
    add: () => false,
    submit: () => false,
    reset: () => false,
    validate: () => Promise.resolve(false),
  };

  // if a tab has an implementation, it will call this.
  const setFormActionsHandler = (actions: ChildFormActivator) => {
    formActions = actions;
  };

  let recordCount = 1;
  if (tab === 'dumpsters') recordCount = dumpsters?.filter(d => !d?.deleted).length;
  else if (tab === 'all') recordCount = assets?.filter(a => !a?.deleted).length;

  const tabStripItems = [
    { label: <TabCardHeaderText>DUMPSTERS</TabCardHeaderText>, key: 'dumpsters' },
    { label: <TabCardHeaderText>BROKER DETAILS</TabCardHeaderText>, key: 'broker' },
    { label: <TabCardHeaderText>ALL EQUIPMENT</TabCardHeaderText>, key: 'all' },
  ];

  const title = (
    <CardHeader>
      <Row gutter={24}>
        <LeadFlexCol span={24}>
          <CardTabs
            size='small'
            activeKey={tab}
            items={tabStripItems}
            onTabClick={(newTab, event) => {
              if (!editMode) setTab(newTab);
            }}
          />
          {!readOnly && (
            <LeadSpaceWithLeftMargin size='small'>
              {tab !== 'broker' && !editMode && (
                <StyledPlusButtonNoMargin
                  size='medium'
                  shape='circle'
                  icon={<PlusOutlined />}
                  onClick={async () => {
                    if (!editMode) setEditMode(true);
                    formActions.add();
                  }}
                />
              )}
              {(editMode || recordCount > 0) && (
                <StyledButton
                  size='medium'
                  $color={theme[editMode ? '@green' : '@blue']}
                  onClick={async () => {
                    if (editMode && formActions) {
                      const x = await formActions?.validate();
                      if (x) {
                        formActions.submit();
                        setEditMode(false);
                      } else {
                        return false;
                      }
                    }
                    setEditMode(!editMode);
                    return true;
                  }}
                >
                  {editMode ? 'SAVE' : 'EDIT'}
                </StyledButton>
              )}
              {editMode ? (
                <StyledButton
                  size='medium'
                  $color={theme['@red']}
                  onClick={() => {
                    if (editMode && formActions) {
                      formActions.reset();
                    }
                    setEditMode(false);
                  }}
                >
                  CANCEL
                </StyledButton>
              ) : undefined}
            </LeadSpaceWithLeftMargin>
          )}
        </LeadFlexCol>
      </Row>
    </CardHeader>
  );

  function contentsSwitch() {
    switch (tab) {
      case 'dumpsters':
        return (
          <Dumpsters
            locationId={location?.id}
            dumpsters={dumpsters}
            readOnly={readOnly === undefined ? false : readOnly}
            editMode={editMode}
            setFormActions={setFormActionsHandler}
            dumpstersUpdated={dumpstersUpdated}
          />
        );
      case 'broker':
        return (
          <DumpsterBroker
            locationId={location?.id}
            broker={broker}
            readOnly={readOnly === undefined ? false : readOnly}
            editMode={editMode}
            setFormActions={setFormActionsHandler}
            brokerUpdated={brokerUpdated}
          />
        );
      case 'all':
        return (
          <OtherEquipmentList
            locationId={location?.id}
            assets={assets}
            readOnly={readOnly === undefined ? false : readOnly}
            editMode={editMode}
            setFormActions={setFormActionsHandler}
            assetsUpdated={assetsUpdated}
          />
        );
      default:
        return <div>Please reload the page</div>;
    }
  }

  return <ContentTabsCard title={title}>{contentsSwitch()}</ContentTabsCard>;
}

export default Equipment;
