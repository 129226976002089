import { Typography, Select, Button, Avatar, Row, Col } from 'antd';
import styled from 'styled-components';
import brandStandardColors from 'theme';
import { PlusCircleFilled } from '@ant-design/icons';

const { Title } = Typography;
export const Text = styled(Typography.Text)``;
export const { Option } = Select;

export const StyledHeader = styled.div`
  padding: 0;
  display: block;
  vertical-align: middle;
`;

export const StyledTitle = styled(Title)`
  padding-top: 8px;
  font-size: 22px;
  font-weight: bolder;
`;

export const StyledButton = styled(Button)`
  min-width: 90px;
  border-radius: 4px;
  font-weight: bolder;
  background-color: ${props => props.$color || brandStandardColors['@blue']};
  border-color: ${props => props.$color || brandStandardColors['@blue']};
  color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
  :visited,
  :active,
  :hover,
  :focus {
    color: #ffffff;
  }
  :visited,
  :active,
  :focus {
    color: #ffffff;
    background-color: ${props => props.$color || brandStandardColors['@blue']};
  }
  :hover {
    color: ${props => props.$color || brandStandardColors['@blue']};
    background-color: #ffffff;
  }
`;

export const StyledPlusButtonNoMargin = styled(Button)`
  font-weight: bolder;
  background-color: ${brandStandardColors['@blue']};
  border-color: ${brandStandardColors['@blue']};
  color: #ffffff;
`;

export const StyledPlusButton = styled(StyledPlusButtonNoMargin)`
  margin-left: 20px;
`;

export const StyledDeleteButtonNoMargin = styled(Button)`
  font-weight: bolder;
  background-color: ${brandStandardColors['@red']};
  border-color: ${brandStandardColors['@red']};
  color: #ffffff;
`;

export const ActiveNumber = styled(Avatar)`
  font-weight: bolder;
`;

export const InactiveNumber = styled(Avatar)`
  font-weight: bolder;
`;

export const StyledRow = styled(Row)`
  margin: 15px;
`;

export const FormSectionTitle = styled(Typography)`
  align-self: center;
  margin-right: 1em;
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
  font-weight: 400;
  min-width: ${props => (props.$nopad ? '0em' : '6em')};
`;

export const FormSectionShorterTitle = styled(FormSectionTitle)`
  min-width: 3em;
`;

export const FormSectionBiggerTitle = styled(Typography)`
  margin-right: 1em;
  margin-bottom: 0.8em;
  font-family: 'Montserrat-Bold';
  font-size: 22px;
  font-weight: 600;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 5px;
`;

export const SpecialFormBox = styled.div`
  padding: 16px;
  background-color: #f0f0f0;
  border-radius: 10px;
`;

export const PrimaryFormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ViewFieldLabel = styled.div`
  width: 100%;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  font-weight: 400;
`;

export const ViewField = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-top: ${props => (props?.$hideBorder ? '0px' : '4px')};
  padding-bottom: ${props => (props?.$hideBorder ? '0px' : '5px')};
  border-bottom: ${props => (props?.$hideBorder ? '0px' : '1px solid #e5e5e5')};
`;

const StyledAddButtonIcon = styled(PlusCircleFilled)`
  color: ${brandStandardColors['@blue']};
`;

export function StyledAddButton(props) {
  return <StyledAddButtonIcon {...props} />;
}

export const StyledDropdown = styled(Select)`
  display: inline;
`;

export const HorizontalCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
