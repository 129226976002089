import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ExampleEdit from './ExampleEdit';
import ExampleList from './ExampleList';

function ExampleManagement() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:recordId`}>
        <ExampleEdit />
      </Route>
      <Route path={`${match.path}`}>
        <ExampleList />
      </Route>
    </Switch>
  );
}

export default ExampleManagement;
