/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */ import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { differenceInDays, parseISO } from 'date-fns';

import { LeadGridRow, LeadSearchState } from './lead.models';
import { Address, LocationWhereInput, GetLeadsForListQuery } from 'generated/graphql';
import { getSelectedFranchise } from 'services/userService';
import { formatCurrency, formatAddress } from 'services/formattingService';
import { getLeadStatus } from 'services/customerService';
import { DbConstants } from 'generated/smt-constants';
import { autoCompare, compareNumbers } from 'services/sortingService';

const { Text } = Typography;

export function buildLeadWhereClause(): LocationWhereInput {
  let franchiseId = '00000000-0000-0000-0000-000000000000';
  const franchise = getSelectedFranchise();
  if (franchise) {
    franchiseId = franchise.id;
  }
  return {
    convertedFromLead: { equals: null },
    AND: [
      { deleted: { equals: null } },
      { inactive: { equals: null } },
      { customer: { is: { franchiseId: { equals: franchiseId } } } },
    ],
  } as LocationWhereInput;
}

/**
 * Gets the columns for the grid
 * @param url The url needed for links in the grid
 * @returns an array of columns
 */
export function getLeadColumns(): ColumnProps<LeadGridRow>[] {
  const columns = [
    {
      title: 'COMPANY',
      dataIndex: 'company',
      width: '20%',
      render: (text: string, record: LeadGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.company, b.company),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'CONTACT',
      dataIndex: 'contact',
      width: '20%',
      render: (text: string, record: LeadGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.contact, b.contact),
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      render: (text: string, record: LeadGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => autoCompare(a.address, b.address),
    },
    {
      title: 'EXP. MONTHLY VALUE',
      dataIndex: 'expectedMonthlyValue',
      width: '15%',
      render: (text: string, record: LeadGridRow) => <Text>{formatCurrency(record?.expectedMonthlyValue)}</Text>,
      sorter: (a, b) => compareNumbers(a.expectedMonthlyValue, b.expectedMonthlyValue),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      render: (text: string, record: LeadGridRow) => {
        const leadStatus = getLeadStatus(record.status);
        return (
          <Text style={{ fontFamily: 'Open Sans Bold', color: leadStatus.color }}>
            {leadStatus?.status?.toUpperCase()}
          </Text>
        );
      },
      sorter: (a, b) => autoCompare(a.status, b.status),
    },
  ] as ColumnProps<LeadGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredLeadResults(state: LeadSearchState, queryResult?: GetLeadsForListQuery): LeadGridRow[] {
  let results: LeadGridRow[] = [];
  const records = queryResult?.locations || [];
  if (records && records.length > 0) {
    const filter = (state.filter || '').toLowerCase();
    results = records.map(rec => {
      // TODO: the contact shown for the lead will probably be the location contact that came across with the deal. FIX?
      const primaryContact =
        rec.locationContacts.length > 0
          ? rec.locationContacts[0].customerContact
          : rec.customer.customerContacts.find(c => c?.primary);
      let status = DbConstants.LeadStatus.Lead;
      if (rec.trialCompleted) status = DbConstants.LeadStatus.TrialComplete;
      else if (rec.trialScheduled) status = DbConstants.LeadStatus.InTrial;
      else if (rec.demoCompleted) status = DbConstants.LeadStatus.DemoComplete;
      else if (rec.demoScheduled) status = DbConstants.LeadStatus.InDemo;
      return {
        id: rec.id,
        address: formatAddress(rec.address as Address),
        company: rec?.customer?.name,
        contact: `${primaryContact?.firstName} ${primaryContact?.lastName}`,
        contactId: primaryContact?.id,
        expectedMonthlyValue: rec?.expectedMonthlyValue,
        status: status.toString(),
        daysAsLead: differenceInDays(new Date(), parseISO(rec?.createdAt)),
        active: rec.inactive === null || rec.inactive === undefined,
        hubspotDealId: rec.hubspotDealId,
        customerId: rec?.customerId,
      } as LeadGridRow;
    });
    results = results.filter(
      rec =>
        state.active === rec.active &&
        (rec.company?.toLowerCase()?.includes(filter) ||
          rec.contact?.toLowerCase()?.includes(filter) ||
          rec.status?.toString()?.includes(filter))
    );
  }
  return results;
}
