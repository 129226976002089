/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableHeader,
  TableHeaderLeft,
  TableHeaderText,
  StyledTable,
  TableCard,
} from 'components/common/card/TableCard';
import { useQuery } from '@apollo/client';
import { SMASH_LIST } from 'graphql/query/smash';
import { StyledButton, Text } from 'components/common/styled';
import { useState } from 'react';
import { startOfDay, endOfDay, subDays, addDays, format, compareAsc, setMilliseconds } from 'date-fns';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Checkbox, Col, DatePicker, Grid, Row, Select, Space } from 'antd';
import { getSelectedFranchise } from 'services/userService';
import StyledSelect from 'components/common/input/BaseSelect';
import { useUpdateWorkOrderTruckMutation, useRescheduleWorkOrderMutation } from 'generated/graphql';
import { utcToZonedTime } from 'date-fns-tz';

import './SmashList.less';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

const { Option } = Select;
const { useBreakpoint } = Grid;

const BlueCircleButton = styled.button`
  background-color: #3b5998;
  border-width: 0px;
  border-radius: 100%;
  padding: 0px 12px;
  margin: 0px 10px;
  color: #ffffff;
  :disabled {
    background-color: #aaaaaa;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  padding: 9px 11px;
  margin-left: 16px;
  .ant-picker-input {
    input {
      display: none;
    }
    .ant-picker-suffix {
      margin: 0;
    }
  }

  :disabled {
    background-color: #aaaaaa;
  }
`;

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const FIRST_ALLOWED_DAY = setMilliseconds(subDays(new Date(), 0), 0);
const LAST_ALLOWED_DAY = setMilliseconds(addDays(new Date(), 14), 0);

function typeMap(type, time) {
  if (type === 'demo' && !!time) {
    return `DEMO - ${format(new Date(time), 'h:mmaaa')}`;
  }
  return type.toUpperCase();
}

function disableTimePicker(selectedSmashes: any[]) {
  if (selectedSmashes.length === 0) {
    return true;
  }
  if (selectedSmashes.length > 1 && selectedSmashes.find(smash => smash.serviceType === 'demo')) {
    return true;
  }
  return false;
}

function SmashList(props) {
  const [selectedSmashes, setSelectedSmashes] = useState<any[]>([]);
  const screens = useBreakpoint();
  const history = useHistory();
  const [updateWorkOrderTruckMutation, { data: newTruckData, loading: saveTruckAssignmentLoading, error }] =
    useUpdateWorkOrderTruckMutation({
      refetchQueries: ['SmashList'],
    });
  const [rescheduleWorkOrderMutation, { data: rescheduledData, loading: rescheduleLoading, error: rescheduleError }] =
    useRescheduleWorkOrderMutation({
      refetchQueries: 'all',
    });

  const header = (
    <div>
      <Row>
        <Col span={8} lg={{ span: 4 }}>
          <TableHeaderText>TODAY'S SMASHES</TableHeaderText>
        </Col>
        <Col span={8} lg={{ span: 8 }}>
          <Row>
            <BlueCircleButton
              disabled={compareAsc(FIRST_ALLOWED_DAY, setMilliseconds(props.selectedDate, 0)) >= 0}
              onClick={() => {
                setSelectedSmashes([]);
                props.setSelectedDate(subDays(props.selectedDate, 1));
              }}
              type='button'
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </BlueCircleButton>
            <Text>
              {screens.lg ? format(props.selectedDate, 'EEEE LLLL d, yyyy') : format(props.selectedDate, 'M/d/yyyy')}
            </Text>
            <BlueCircleButton
              disabled={compareAsc(props.selectedDate, setMilliseconds(LAST_ALLOWED_DAY, 0)) >= 0}
              onClick={() => {
                setSelectedSmashes([]);
                props.setSelectedDate(addDays(props.selectedDate, 1));
              }}
              type='button'
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </BlueCircleButton>
          </Row>
        </Col>
        <Col span={8} lg={{ span: 6 }}>
          ASSIGN TO:
          <StyledSelect
            disabled={selectedSmashes.length < 1}
            allowClear
            style={{ width: '150px' }}
            onChange={c => {
              updateWorkOrderTruckMutation({
                variables: {
                  data: {
                    truckId: c === 'unassigned' ? null : c,
                  },
                  where: {
                    ids: selectedSmashes.map(s => s.id),
                  },
                },
              });
              setSelectedSmashes([]);
            }}
            $editmode
          >
            {props.data?.trucks?.map(truck => (
              <Option key={truck.id} value={truck.id}>
                {truck.smashSerialNumber}
              </Option>
            ))}

            <Option value='unassigned'>Unassigned</Option>
          </StyledSelect>
          <DatePickerStyled
            disabled={disableTimePicker(selectedSmashes)}
            showTime={
              selectedSmashes.find(smash => smash.serviceType === 'demo')
                ? { format: 'HH:mm A', use12Hours: true }
                : null
            }
            showNow={false}
            allowClear={false}
            value={moment(
              selectedSmashes[0]?.scheduleStartTime ||
                utcToZonedTime(selectedSmashes[0]?.scheduleDate || new Date(), 'UTC')
            )}
            onChange={(change, selectionResult) => {
              const mutationPromise = rescheduleWorkOrderMutation({
                variables: {
                  data: {
                    scheduleDate: selectionResult,
                  },
                  where: {
                    ids: selectedSmashes.map(s => s.id),
                  },
                },
              });
              mutationPromise.then(mutationResult => {
                setSelectedSmashes([]);
              });
            }}
            popupClassName='assign-smash-picker'
          />
        </Col>
        <Col>
          <StyledButton
            onClick={() => {
              history.push('route');
            }}
          >
            SET ROUTE
          </StyledButton>
        </Col>
      </Row>
    </div>
  );

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      render: (text: string, record: any) => (
        <Checkbox
          checked={!!selectedSmashes.find(smash => smash.id === record.id)}
          onChange={e => {
            if (e.target.checked) {
              setSelectedSmashes([...selectedSmashes, record]);
            } else {
              setSelectedSmashes(selectedSmashes.filter(smash => smash.id !== record.id));
            }
          }}
        />
      ),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'service type',
      render: (text: string, record: any) => <Text>{typeMap(record.serviceType, record.scheduleStartTime)}</Text>,
      sorter: (a, b) => a.serviceType.localeCompare(b.serviceType),
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'customer',
      render: (text: string, record: any) => <Text>{record.location.customer.name}</Text>,
      sorter: (a, b) => a.location.customer.name.localeCompare(b.location.customer.name),
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      render: (text: string, record: any) => <Text>{record.location.name}</Text>,
      sorter: (a, b) => a.location.name.localeCompare(b.location.name),
    },
    {
      title: 'DUMPSTERS',
      dataIndex: 'dumpsters',
      render: (text: string, record: any) => <Text>{record._count.smashOperations}</Text>,
      sorter: (a, b) => a._count.smashOperations - b._count.smashOperations,
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      render: (text: string, record: any) => (
        <Text>
          {Math.round(
            (new Date(record.scheduleEndTime).getTime() - new Date(record.scheduleStartTime).getTime()) / 60000
          )}{' '}
          min
        </Text>
      ),
      sorter: (a, b) =>
        new Date(a.scheduleEndTime).getTime() -
        new Date(a.scheduleStartTime).getTime() -
        (new Date(b.scheduleEndTime).getTime() - new Date(b.scheduleStartTime).getTime()),
    },
    {
      title: 'ASSIGNMENT',
      dataIndex: 'assignment',
      render: (text: string, record: any) => <Text>{record.truck?.smashSerialNumber}</Text>,
      sorter: (a, b) => (a.truck?.smashSerialNumber || '').localeCompare(b.truck?.smashSerialNumber),
    },
  ];

  if (props.loading || !props.data) {
    return <div>Loading...</div>;
  }

  return (
    <TableCard title={header}>
      <StyledTable
        loading={props.loading}
        dataSource={props.data.workOrders}
        columns={columns}
        pagination={false}
        rowKey='id'
      />
    </TableCard>
  );
}

export default SmashList;
