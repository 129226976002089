import { Row, Col, Typography, notification } from 'antd';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AgreementInput, useGetFranchiseEmployeeListQuery, useUpsertAgreementMutation } from 'generated/graphql';

import { DbConstants } from 'generated/smt-constants';
import { autoCompare } from 'services/sortingService';
import { getSelectedFranchise, getSignedInUser } from 'services/userService';

import { AgreementStatusColors, mapAgreementFormObject, mapFormObjectForMutation } from './agreement.service';
import { AgreementForm, ChildFormActivator, SalesRepOption } from './agreement.models';
import AgreementBilling from './sections/AgreementBilling';
import AgreementRecurringDetails from './sections/AgreementRecurringDetails';

const { Text } = Typography;

function AgreementEditForm({
  locationId,
  agreementForm,
  readOnly,
  editMode,
  setFormActions,
  agreementUpdated,
}: {
  locationId?: string;
  agreementForm?: AgreementForm;
  readOnly?: boolean;
  editMode?: boolean;
  setFormActions?: (actions: ChildFormActivator) => void;
  agreementUpdated?: (agreement: any) => void;
}) {
  const inEditMode = !readOnly && editMode;
  const currentFranchise = getSelectedFranchise();
  const currentUser = getSignedInUser();

  const formRef = useRef(null);
  const franchiseUsersRef = useRef<SalesRepOption[]>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useGetFranchiseEmployeeListQuery({
    variables: {
      id: currentFranchise?.id,
    },
    onCompleted: data => {
      franchiseUsersRef.current = data.franchise?.userFranchises?.map(uf => ({
        userId: uf.user.id,
        fullName: `${uf.user.firstName} ${uf.user.lastName}`,
      }));
      if (!franchiseUsersRef.current?.find(u => u.userId === currentUser.id)) {
        franchiseUsersRef.current?.push({
          fullName: `${currentUser.firstName} ${currentUser.lastName}`,
          userId: currentUser.id,
        });
      }
      franchiseUsersRef.current?.sort((a, b) => autoCompare(a.fullName, b.fullName, true));
    },
  });

  if (setFormActions) {
    // because the buttons are not in this component, we need to give the parent component a way to send us messages.
    setFormActions({
      canAdd: false,
      add: () => false,
      submit: () => {
        // this submits the form by triggering the form submit event (the button isn't in the form)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formRef?.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      },
      reset: (form?: AgreementForm) => reset(form),
      validate: async () => trigger(),
    } as ChildFormActivator);
  }

  // const [newRecordMode, setNewRecordMode] = useState<number>(0);

  //   // load the haulers and sizes from the database.
  //   const { loading, data } = useQuery<GetDumpsterLookupsQuery>(GET_DUMPSTER_LOOKUPS);
  //   if (!loading && data) populateDumpsterLookups(data, haulers, sizes);

  // set up react-hook-form
  const {
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    watch,
    reset,
    trigger,
    register,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: agreementForm,
  });

  // set up the update dumpsters mutation
  const [upsertAgreement, { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useUpsertAgreementMutation({
      onCompleted: returnData => {
        // this is the data is ready...
        // mapAgreementFormObject(returnData?.upsertAgreement);
        if (agreementUpdated) {
          // update the parent component async - after the render has completed.
          const copyOfObjToSave = { ...returnData?.upsertAgreement };
          agreementUpdated(copyOfObjToSave);
          reset(mapAgreementFormObject(locationId, copyOfObjToSave?.dumpsterCount, copyOfObjToSave));
          setTimeout(() => {
            notification.open({
              message: 'Saved',
              description: 'Updated Agreement Successfully',
              duration: 5,
            });
          }, 0);
        }
      },
      onError: error => {
        notification.error({
          message: 'Error Updating Record.',
          description: error.message,
          duration: 5,
        });
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const onSubmit = async (formData: any) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    const agreementInput: AgreementInput = mapFormObjectForMutation(formData);
    agreementInput.dumpsterCount = agreementForm?.dumpsterCount || 1;
    await upsertAgreement({
      variables: {
        data: agreementInput,
        where: { agreementId: agreementInput?.id, locationId },
      },
    });
    // saving.current = true;
  };

  const statusDescription = DbConstants.AgreementStatus._KeyValuePairs.find(
    x => x.key === agreementForm?.status
  )?.value;

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <Row>
        <Col className='label-cell' span={24}>
          <Text>
            Status:{' '}
            <span
              style={{ color: AgreementStatusColors.find(x => x.status === agreementForm?.status)?.color || 'black' }}
            >
              {statusDescription}
            </span>
          </Text>
        </Col>
      </Row>
      <AgreementBilling billingDetails={agreementForm} control={control} watch={watch} editMode={inEditMode} />
      <AgreementRecurringDetails
        agreementDetails={agreementForm}
        control={control}
        watch={watch}
        franchiseSalesReps={franchiseUsersRef.current || []}
        editMode={inEditMode}
      />
    </form>
  );
}

export default AgreementEditForm;
