import { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  StyledTable,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderText,
} from 'components/common/card/TableCard';
import { SampleGridRow, SampleSearchState } from './table.models';
import { getColumns, getFilteredResults, getSampleRecords } from './table.service';
import { LoadingContainer } from './table.styled';
import { ColumnProps } from 'antd/lib/table';
import useMockData from 'hooks/useMockData';
import { Input } from 'antd';

/**
 * This is a sample component that demonstrates one way to do grids cleanly
 * @returns a sample list component
 */
function TablePagingAndScrolling() {
  // get the url to use when linking out to other components
  const { url } = useRouteMatch();

  // get the search state, which includes everything
  const [searchState, setSearchState] = useState<SampleSearchState>({
    // records: [],
    filter: '',
    active: true,
    numberOfRecords: 100,
    // initialized: false,
  });

  // get the columns reference (similar to a class member)
  const columnsRef = useRef<ColumnProps<SampleGridRow>[]>();

  // load the data from wherever and update the state.
  let id = 1;
  const { loading, data } = useMockData({
    sampleData: getSampleRecords(),
    numberOfRecordsToAdd: searchState.numberOfRecords,
    sleepMilliseconds: 2000,
    customHandler: r => {
      // eslint-disable-next-line no-param-reassign
      r.id = id;
      id += 1;
    },
  });

  // only load the columns once? (probably overkill)
  if (!columnsRef.current) columnsRef.current = getColumns(url);

  // load the display records by filtering and mapping the data from the database
  const displayRecords = loading || data?.length === 0 ? data : getFilteredResults(searchState, data);

  // build the header out (I'm not sure why we have to do this up here?)
  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>SCROLLING AFTER 10 RECORDS</TableHeaderText>
        <Input
          name='numberOfRecords'
          type='number'
          value={searchState.numberOfRecords}
          style={{ marginLeft: '10px' }}
          onChange={value => {
            const val = value?.currentTarget?.value;
            const nVal: number = Number.isNaN(val) ? 100 : Number(val);
            setSearchState({ ...searchState, numberOfRecords: nVal });
          }}
        />
      </TableHeaderLeft>
    </TableHeader>
  );

  // build the header out (I'm not sure why we have to do this up here?)
  const pagingHeader = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>PAGING WITH SCROLLING</TableHeaderText>
      </TableHeaderLeft>
    </TableHeader>
  );

  // FIXED HEADERS WITH SCROLLING
  // see: https://ant.design/components/table/#components-table-demo-sticky
  // see: https://codesandbox.io/s/tm3zjh?file=/demo.js

  // PAGING
  // see: https://ant.design/components/table/#components-table-demo-pagination
  // see: https://codesandbox.io/s/uxdgeb?file=/demo.js:504-516
  const scrollData =
    !loading && (data?.length || 0) > 10
      ? {
          y: 500,
        }
      : null;

  // return the final chunk of jsx html for the component
  return (
    <LoadingContainer spinning={loading} delay={200}>
      <TableCard title={header}>
        <StyledTable
          dataSource={displayRecords}
          rowKey='id'
          columns={columnsRef.current}
          pagination={false}
          scroll={scrollData}
        />
      </TableCard>
      <TableCard title={pagingHeader}>
        <StyledTable
          dataSource={displayRecords}
          rowKey='id'
          columns={columnsRef.current}
          pagination={{
            position: ['topCenter', 'bottomCenter'],
          }}
          scroll={scrollData}
        />
      </TableCard>
    </LoadingContainer>
  );
}

export default TablePagingAndScrolling;
