/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { InputNumber } from 'antd';
import { RHFInputNumberFormat, RHFInputNumberParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd input control using a react-hook-form controller.
 * see: https://ant.design/components/input/ (AntD Input reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd input control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFInputNumber(params: RHFInputNumberParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName = params.viewModeClassName || (params.hideBorder ? 'rhf-label-noborder' : 'rhf-label');
  let defVal = params.defaultValue || params.control?._defaultValues[params.name];

  if (params.formatter === RHFInputNumberFormat.Currency) {
    attributes.formatter = attributes?.formatter || (v => `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    attributes.parser = attributes?.parser || (v => v!.replace(/\$\s?|(,*)/g, ''));
  } else if (params.formatter === RHFInputNumberFormat.PercentFromRatio) {
    attributes.formatter = attributes?.formatter || (v => `${v * 100.0}%`);
    attributes.parser = attributes?.parser || (v => Number(v!.replace('%', '')) / 100.0);
  }
  if (defVal && attributes.formatter) defVal = attributes.formatter(defVal);

  const viewModeElement = defVal ? (
    <div className={`${labelClassName} rhf-label-nofill`} style={params.viewModeStyle}>
      {defVal}
    </div>
  ) : (
    <div className={labelClassName} style={params.viewModeStyle}>
      &nbsp;
    </div>
  );
  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => <InputNumber {...field} {...attributes} />}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
