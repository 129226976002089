import { Layout } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';

import { DashboardCardIcon, DashboardCard } from './cards.styled';

/**
 * The lead metric card has a header and footer, and revenue
 * @param param0 The lead metric parameters.
 * @returns a metric card.
 */
function DashboardActionCard({ text, icon, enabled = true, onClick, style }: DashboardActionParams) {
  const classNames = `dashboard-card-header-footer dashboard-action-card-footer ${
    enabled ? 'dashboard-action-card-enabled' : ''
  }`;
  return (
    <DashboardCard
      hoverable={enabled}
      onClick={e => {
        if (enabled && onClick) onClick(e);
      }}
      style={style}
    >
      <Layout className='dashboard-card-layout'>
        <Content>
          <DashboardCardIcon className={enabled ? 'dashboard-action-card-icon-enabled' : 'dashboard-action-card-icon'}>
            {icon}
          </DashboardCardIcon>
        </Content>
        <Footer className={classNames}>
          <div>{text}</div>
        </Footer>
      </Layout>
    </DashboardCard>
  );
}

export type DashboardActionParams = {
  text: string;
  icon: any;
  enabled?: boolean;
  style?: any;
  onClick?: (e: any) => void;
};

export default DashboardActionCard;
