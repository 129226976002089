import { CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { SampleAddress, SampleGridRow, SampleRecord, SampleSearchState } from './table.models';
import { generateMockObject, getObjectMetaData, ObjectMetaData } from 'services/dataGenerationService';

const { Text } = Typography;

const CurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Gets the columns for the grid
 * @param url The url needed for links in the grid
 * @returns an array of columns
 */
export function getColumns(url: string): ColumnProps<SampleGridRow>[] {
  const columns = [
    {
      title: 'RecordId',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'NAME',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '20%',
      sorter: (a, b) => a?.fullName?.localeCompare(b?.fullName),
      render: (text: string, record: SampleGridRow) => <Link to={`${url}/${record.id}`}>{text}</Link>,
    },
    {
      title: 'BIRTH DATE',
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: '10%',
      sorter: (a, b) => {
        if (a?.birthDate?.getTime() === b?.birthDate?.getTime()) return 0;
        return a?.birthDate?.getTime() < b?.birthDate?.getTime() ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{format(record.birthDate, 'yyyy-MM-dd')}</Text>,
    },
    {
      title: 'SALARY',
      dataIndex: 'annualSalary',
      key: 'annualSalary',
      width: '10%',
      sorter: (a, b) => {
        if ((a?.annualSalary || 0) === (b?.annualSalary || 0)) return 0;
        return (a?.annualSalary || 0) < (b?.annualSalary || 0) ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{CurrencyFormatter.format(record?.annualSalary)}</Text>,
    },
    {
      title: 'US CITIZEN',
      dataIndex: 'isUsCitizen',
      key: 'isUsCitizen',
      width: '5%',
      sorter: (a, b) => {
        if (a?.isUsCitizen === b?.isUsCitizen) return 0;
        return !a?.isUsCitizen && a?.isUsCitizen ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{record.isUsCitizen ? <CheckOutlined /> : null}</Text>,
    },
    {
      title: 'ADDRESS',
      dataIndex: 'homeAddress',
      key: 'homeAddress',
      width: '50%',
      sorter: (a, b) => a?.homeAddress?.localeCompare(b?.homeAddress),
      render: (text: string, record: SampleGridRow) => (
        <a href={`https://maps.google.com/?q=${record.homeLatitude},${record.homeLongitude}`}>{text}</a>
      ),
    },
  ] as ColumnProps<SampleGridRow>[];

  return columns;
}
export function getVirtualTableColumns(): ColumnProps<SampleGridRow>[] {
  const columns = [
    {
      title: 'RecordId',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'NAME',
      dataIndex: 'fullName',
      width: 250,
    },
    {
      title: 'BIRTH DATE',
      dataIndex: 'birthDateAsString',
      width: 150,
    },
    {
      title: 'SALARY',
      dataIndex: 'annualSalary',
      width: 100,
    },
    {
      title: 'US CITIZEN',
      dataIndex: 'isUsCitizenAsString',
      width: 100,
    },
    {
      title: 'ADDRESS',
      dataIndex: 'homeAddress',
    },
  ] as ColumnProps<SampleGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredResults(state: SampleSearchState, records: SampleRecord[] | undefined): SampleGridRow[] {
  let results: SampleGridRow[] = [];
  if (records && records.length > 0) {
    // const filter = (state.filter || '').toLowerCase();
    results = records.map(
      rec =>
        ({
          id: rec.id,
          fullName: `${rec.lastName}, ${rec.firstName}`,
          birthDate: rec.birthDate,
          birthDateAsString: format(rec.birthDate, 'yyyy-MM-dd'),
          homeAddress: formatAddress(rec.homeAddress),
          isUsCitizen: rec.isUsCitizen,
          isUsCitizenAsString: rec.isUsCitizen ? 'Yes' : 'No',
          annualSalary: rec.annualSalary,
          homeLatitude: rec.homeAddress.lat,
          homeLongitude: rec.homeAddress.lng,
          active: rec.active,
        } as SampleGridRow)
    );
  }
  return results;
}

/**
 * Formats the address object as a string
 * @param address the address object
 * @returns a string representing the address
 */
export function formatAddress(address: SampleAddress): string {
  let addrString = '';
  if (address) {
    if (address.name) addrString += `${address.name}, `;
    addrString += `${address.address1}, `;
    if (address.address2) addrString += `${address.address2}, `;
    addrString += `${address.city}, ${address.state} ${address.zip}`;
  }
  return addrString;
}

export function getSampleRecords(count?: number): SampleRecord[] {
  if (!count) return cloneDeep(sampleRecords);

  const sampleData = cloneDeep(sampleRecords);
  const metaData = getObjectMetaData(sampleData, undefined);
  const data: SampleRecord[] = [];
  for (let i = 0; i < count; i += 1) {
    const newObj = generateMockObject(metaData as ObjectMetaData) as SampleRecord;
    newObj.id = i + 1; // make sure the id is unique
    data.push(newObj);
  }
  return data;
}

// These are sample records used for testing.
const sampleRecords: SampleRecord[] = [
  {
    id: 1,
    firstName: 'Bob',
    lastName: 'Baggins',
    birthDate: new Date(1975, 10, 28),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 12, 30),
    homeAddress: {
      name: 'Dr Pepper Inc',
      address1: '123 Hometown way',
      address2: null,
      city: 'Indianapolis',
      state: 'IN',
      zip: 46030,
      lat: 39.7816455,
      lng: -86.0966122,
    },
    isUsCitizen: true,
    annualSalary: 93000,
    uniqueId: 'd8926ec5-b630-46bb-9a1c-9db42fbe9831',
    active: true,
  },
  {
    id: 2,
    firstName: 'Jerry',
    lastName: 'Flopsypants',
    birthDate: new Date(1988, 2, 12),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 1, 15),
    homeAddress: {
      name: "Jerry's Manufacturing",
      address1: '123 Factory Blvd way',
      address2: null,
      city: 'Beverly Hills',
      state: 'CA',
      zip: 90210,
      lat: 34.0713337,
      lng: -118.4194645,
    },
    isUsCitizen: true,
    annualSalary: 22312.43,
    uniqueId: '2ffa5ad5-6576-42ca-8f29-91546cdcab72',
    active: true,
  },
  {
    id: 3,
    firstName: 'Fred',
    lastName: 'Jetson',
    birthDate: new Date(2001, 5, 4),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 1, 15),
    homeAddress: {
      name: 'Honky Engineering',
      address1: '665 Dr Martin Luthor St',
      address2: 'Apt. 12',
      city: 'Knoxville',
      state: 'TN',
      zip: 37919,
      lat: 35.944969,
      lng: -84.0708764,
    },
    isUsCitizen: false,
    annualSalary: 16999.99,
    uniqueId: '787638b9-062e-415a-87e4-550d4693b373',
    active: true,
  },
];
