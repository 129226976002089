import '../agreement.less';

import { Row, Col, Space, Typography } from 'antd';
import { addMonths, startOfDay } from 'date-fns';

import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFInputNumber } from 'components/common/react-hook-form/RHFInputNumber';
import { RHFDatePicker } from 'components/common/react-hook-form/RHFDatePicker';
import { RHFCheckboxGroup } from 'components/common/react-hook-form/RHFCheckboxGroup';
import { RHFCheckbox } from 'components/common/react-hook-form/RHFCheckbox';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { CheckboxGroupRenderMode, RHFInputNumberFormat, RHFOption } from 'components/common/react-hook-form/rhf.models';

import { formatCurrency, formatDate } from 'services/formattingService';
import { RecurringAgreementForm, SalesRepOption } from '../agreement.models';
import { DaysOfTheWeek, RspvSz } from '../agreement.service';
import { DbConstants } from 'generated/smt-constants';

const { Text } = Typography;

function AgreementRecurringDetails({
  agreementDetails,
  readOnly,
  editMode,
  watch,
  control,
  franchiseSalesReps,
}: {
  agreementDetails?: RecurringAgreementForm;
  readOnly?: boolean;
  editMode?: boolean;
  watch?;
  control;
  franchiseSalesReps: SalesRepOption[];
}) {
  const isEditMode = !readOnly && editMode;
  const isDraft = agreementDetails?.status === DbConstants.AgreementStatus.Draft;
  const allowBackDating = false;

  const dumpsterCount = agreementDetails?.dumpsterCount || 0;
  let agreementTotal = 0;
  let expirationDate = agreementDetails?.expirationDate;

  let [
    watchedMonthlyFee,
    watchedDurationInMonths,
    watchedFuelChargePercentage,
    watchedServiceStartDate,
    watchedAgreementDate,
  ] = watch(['monthlyFee', 'durationInMonths', 'fuelChargePercentage', 'serviceStartDate', 'agreementDate']);
  if (watchedMonthlyFee === undefined) watchedMonthlyFee = agreementDetails?.monthlyFee;
  if (watchedDurationInMonths === undefined) watchedDurationInMonths = agreementDetails?.durationInMonths;
  if (watchedFuelChargePercentage === undefined)
    watchedFuelChargePercentage = agreementDetails?.fuelChargePercentage || 0.0;
  if (watchedServiceStartDate === undefined) watchedServiceStartDate = agreementDetails?.serviceStartDate;
  if (watchedAgreementDate === undefined) watchedAgreementDate = agreementDetails?.agreementDate;

  if (watchedServiceStartDate && watchedDurationInMonths > 0) {
    expirationDate = addMonths(watchedServiceStartDate, watchedDurationInMonths);
  }
  if (watchedMonthlyFee && watchedDurationInMonths > 0) {
    agreementTotal = watchedMonthlyFee * watchedDurationInMonths;
    agreementTotal =
      Math.round(100.0 * (agreementTotal + (watchedFuelChargePercentage / 100.0) * agreementTotal)) / 100.0;
  }

  return (
    <>
      {/* ****************************************************************************** */}
      {/* *********************************** DETAILS ********************************** */}
      {/* ****************************************************************************** */}
      <Row>
        <Col lg={24}>
          <h2>Agreement Details</h2>
        </Col>
      </Row>
      {/* *****************  Row 1 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Agreement Date:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFDatePicker
                name='agreementDate'
                editMode={isEditMode}
                control={control}
                rules={{
                  required: 'Required',
                  // TODO: this may need to allow "back dating" when backfilling old agreements
                  validate: date =>
                    allowBackDating || !isDraft || date >= startOfDay(new Date()) || 'Must be today or in the future.',
                }}
                attributes={{
                  placeholder: 'Billing Start Date',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='text-adjacent'>Dumpsters:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Text>{dumpsterCount}</Text>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 2 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Service Start:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFDatePicker
                name='serviceStartDate'
                editMode={isEditMode}
                control={control}
                rules={{
                  required: 'Required',
                  // TODO: this may need to allow "back dating" when backfilling old agreements
                  validate: date =>
                    date >= startOfDay(watchedAgreementDate) || 'Must be on or after the agreement date.',
                }}
                attributes={{
                  placeholder: 'Service Start Date',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Monthly Fee:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFInputNumber
                name='monthlyFee'
                editMode={isEditMode}
                control={control}
                formatter={RHFInputNumberFormat.Currency}
                rules={{ required: 'Required' }}
                attributes={{
                  style: { width: '8em' },
                  placeholder: 'Monthly Fee',
                  min: 0,
                  max: 999999999,
                  precision: 2,
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 3 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='asterisk'>Duration:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space align='start'>
                <RHFInputNumber
                  name='durationInMonths'
                  editMode={isEditMode}
                  control={control}
                  rules={{ required: 'Required' }}
                  attributes={{
                    style: { width: '5em' },
                    placeholder: 'Duration',
                    min: 1,
                    max: 60,
                    precision: 0,
                  }}
                />
                <Text className='text-adjacent'>Month(s)</Text>
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text>Extra Smash:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space align='start'>
                <RHFInputNumber
                  name='extraSmashJobRate'
                  editMode={isEditMode}
                  control={control}
                  formatter={RHFInputNumberFormat.Currency}
                  attributes={{
                    style: { width: '7em' },
                    placeholder: 'Extra Smash',
                    min: 0,
                    max: 999999999,
                    precision: 0,
                  }}
                />
                <Text className='text-adjacent'>per dumpster</Text>
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 4 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text>Expiration:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <div title={formatDate(expirationDate) || ''} className='rhf-label rhf-label-single-line'>
                {formatDate(expirationDate)}
              </div>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text className='text-adjacent'>Project Rate:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space align='start'>
                <RHFInputNumber
                  name='projectRate'
                  editMode={isEditMode}
                  control={control}
                  formatter={RHFInputNumberFormat.Currency}
                  attributes={{
                    style: { width: '7em' },
                    placeholder: 'Project',
                    min: 0,
                    max: 999999999,
                    precision: 0,
                  }}
                />
                <RHFSelect
                  name='projectRateUnit'
                  editMode={isEditMode}
                  control={control}
                  options={[
                    { label: 'per hour', value: 'hour' },
                    { label: 'per day', value: 'day' },
                  ]}
                  attributes={{
                    placeholder: 'Project Rate Unit',
                  }}
                />
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 5 **************** */}
      <Row>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text>Sales Rep:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <RHFSelect
                name='salesRepUserId'
                editMode={isEditMode}
                control={control}
                options={franchiseSalesReps.map<RHFOption>(o => ({
                  label: o.fullName,
                  value: o.userId,
                }))}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Sales Rep',
                }}
              />
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col className='label-cell' {...RspvSz.lblCol}>
              <Text>Fuel Charge:</Text>
            </Col>
            <Col className='form-cell' {...RspvSz.ctrlCol}>
              <Space align='start'>
                <RHFInputNumber
                  name='fuelChargePercentage'
                  editMode={isEditMode}
                  control={control}
                  attributes={{
                    style: { width: '5em' },
                    placeholder: 'Fuel Charge',
                    min: 0,
                    max: 200,
                    step: 1,
                    precision: 2,
                  }}
                />
                {(!!watchedFuelChargePercentage || isEditMode) && <Text className='text-adjacent'>%</Text>}
              </Space>
            </Col>
            <Col {...RspvSz.spacerCol} />
          </Row>
        </Col>
      </Row>
      {/* *****************  Row 6 **************** */}
      <Row>
        <Col {...RspvSz.formCol}> </Col>
        <Col {...RspvSz.formCol}>
          <Row>
            <Col
              className='label-cell'
              {...{ ...RspvSz.lblCol, xs: 12 }}
              style={{ paddingTop: '0.55em', paddingLeft: '1em', backgroundColor: 'wheat' }}
            >
              Agreement Total:
            </Col>
            <Col className='form-cell' style={{ paddingRight: '1em', backgroundColor: 'wheat' }}>
              <div title={formatCurrency(agreementTotal) || ''} className='rhf-label rhf-label-single-line'>
                {formatCurrency(agreementTotal)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ****************************************************************************** */}
      {/* ********************************** FREQUENCY ********************************* */}
      {/* ****************************************************************************** */}
      <Row>
        <Col lg={24}>
          <h2>SCHEDULE FREQUENCY</h2>
        </Col>
      </Row>
      <Row>
        <Col className='schedule-days-cell' xs={24} xl={18}>
          <RHFCheckboxGroup
            name='daysOfWeek'
            control={control}
            editMode={isEditMode}
            renderMode={CheckboxGroupRenderMode.ReadOnlyCheckboxes}
            hideBorder
            options={DaysOfTheWeek.map((dayName, dayIndex) => ({ label: dayName, value: dayIndex } as RHFOption))}
          />
        </Col>
        <Col xs={24} xl={6}>
          <Space align='start'>
            <Text className='text-adjacent'>Every</Text>
            <RHFInputNumber
              name='scheduleFrequencyInWeeks'
              editMode={isEditMode}
              control={control}
              attributes={{
                style: { width: '4em' },
                placeholder: 'Frequency',
                min: 1,
                max: 4,
                precision: 0,
              }}
            />
            <Text className='text-adjacent'>Week(s)</Text>
          </Space>
        </Col>
      </Row>
      {/* ****************************************************************************** */}
      {/* ****************************** SETTINGS AND NOTES **************************** */}
      {/* ****************************************************************************** */}
      <Row>
        <Col xs={24} md={16} xl={12}>
          <Row>
            <Col span={24}>
              <h2>AGREEMENT SETTINGS</h2>
            </Col>
          </Row>
          <Row>
            <Col className='label-cell' xs={24} md={12}>
              <Space>
                <Text>Automated Invoices:</Text>
                <RHFCheckbox name='automatedInvoices' editMode readOnly={!isEditMode} control={control} />
              </Space>
            </Col>
            <Col className='label-cell' xs={24} md={12}>
              <Space>
                <Text>Convert to Month to Month:</Text>
                <RHFCheckbox name='convertMonthToMonth' editMode readOnly={!isEditMode} control={control} />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8} lg={11}>
          <Row>
            <Col span={24}>
              <h2>NOTES</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RHFTextArea
                name='notes'
                editMode={isEditMode}
                control={control}
                attributes={{
                  style: { width: '100%' },
                  rows: 1,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} lg={1} />
      </Row>

      {/*  */}
    </>
  );
}

export default AgreementRecurringDetails;
