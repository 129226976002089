import { DaysOfTheWeek } from 'components/agreement/agreement.service';
import { CardHeader, CardHeaderLeft, CardHeaderText, ContentCard } from 'components/common/card/Card';
import { getDaysOfCurrentWeek } from './schedule.service';
import {
  ConfirmationContainer,
  ConfirmationDetailsContainer,
  ConfirmationIcon,
  ConfirmationTimeContainer,
} from './schedule.styled';

export function SmashOrderConfirmation({ formState, customer }: { formState: any; customer: any }) {
  const longDateFormat = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  } as const;

  const shortDateFormat = {
    year: undefined,
    month: 'numeric',
    day: 'numeric',
    weekday: undefined,
  } as const;

  const arrayToList = array => array.join(', ').replace(/, ((?:.(?!, ))+)$/, `${array.length > 2 ? ',' : ''} and $1`);

  const dateRangeOfCurrentWeek = () => {
    const daysOfWeek = getDaysOfCurrentWeek();
    return (
      <span>
        {new Date(daysOfWeek[0].date).toLocaleDateString('en-us', shortDateFormat)} -{' '}
        {new Date(daysOfWeek[6].date).toLocaleDateString('en-us', shortDateFormat)}
      </span>
    );
  };

  const selectedTrialDays = () => {
    const weekDays = formState.trialDays
      .sort((a, b) => new Date(a).getDay() - new Date(b).getDay())
      .map(day => DaysOfTheWeek[new Date(day).getDay()]);

    return arrayToList(weekDays);
  };

  /* map multiple timeslots */
  // const selectedTimeSlots = () =>
  //   formState.timeSlots
  //     .sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime())
  //     .map(slot => {
  //       const date = new Date(slot.day).toLocaleString('en-US', longDateFormat);
  //       return (
  //         <ConfirmationTimeContainer key={date.toString()}>
  //           {date.toString().replace(',', '')} @ {slot.time}
  //         </ConfirmationTimeContainer>
  //       );
  //     });

  const selectedTimeSlots = () => {
    const date = new Date(formState.timeSlot.date).toLocaleString('en-US', longDateFormat);
    return (
      <ConfirmationTimeContainer key={date.toString()}>
        {date.toString().replace(',', '')} @ {formState.timeSlot.time}
      </ConfirmationTimeContainer>
    );
  };

  const title = (
    <CardHeader>
      <CardHeaderLeft>
        <CardHeaderText>SMASHORDER #1234</CardHeaderText>
      </CardHeaderLeft>
    </CardHeader>
  );

  return (
    <ContentCard title={title}>
      <ConfirmationContainer>
        <ConfirmationIcon size='large' />
        <ConfirmationDetailsContainer>
          <h1>{customer.name}</h1>
          <h2>{formState.orderType} SMASH ORDER IS ON THE SCHEDULE FOR</h2>
          {formState.orderType === 'trial' ? (
            <>
              <ConfirmationTimeContainer>{selectedTrialDays()}</ConfirmationTimeContainer>
              <ConfirmationTimeContainer>{dateRangeOfCurrentWeek()}</ConfirmationTimeContainer>{' '}
            </>
          ) : (
            <>{selectedTimeSlots()}</>
          )}
        </ConfirmationDetailsContainer>
      </ConfirmationContainer>
    </ContentCard>
  );
}
