import PageTitle from 'components/common/PageTitle';
import DayScheduleCard from './DayScheduleCard';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { getSelectedFranchise } from 'services/userService';
import { DAY_SCHEDULE_CARD } from 'graphql/query/routes';

function Routes() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { loading, data, refetch } = useQuery(DAY_SCHEDULE_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        scheduleDate: {
          equals: selectedDate,
        },
        location: {
          is: {
            customer: {
              is: {
                franchiseId: {
                  equals: getSelectedFranchise()?.id,
                },
              },
            },
          },
        },
      },
      trucksWhere: {
        franchiseId: {
          equals: getSelectedFranchise()?.id,
        },
      },
    },
  });

  console.log(data);

  return (
    <div>
      <PageTitle>ROUTE</PageTitle>
      <DayScheduleCard data={data} selectedDate={selectedDate} />
    </div>
  );
}

export default Routes;
