import { gql } from '@apollo/client';
import { CORE_FRANCHISE_FIELDS } from '../fragment/franchise';

export const GET_FRANCHISE_FRAG = gql`
  ${CORE_FRANCHISE_FIELDS}
  query GetFranchiseFrag($id: String) {
    franchise(where: { id: $id }) {
      ...CoreFranchiseFields
    }
  }
`;

export const GET_FRANCHISES_BY_USER = gql`
  query GetFranchisesByUser($id: String) {
    user(where: { id: $id }) {
      userFranchises {
        franchise {
          id
          number
          name
          _count {
            customers
          }
          contactPhone
          contactEmail
        }
      }
    }
  }
`;

export const GET_FRANCHISE_TABLE_DATA = gql`
  query GetFranchiseTableData {
    franchises {
      id
      name
      number
      contactFirstName
      contactLastName
      contactEmail
      contactPhone
      contactEmail
      inactive
      address {
        line1
        line2
        city
        state
        zipCode
      }
      _count {
        customers
      }
    }
  }
`;

export const GET_FRANCHISE = gql`
  query GetFranchise($id: String) {
    franchise(where: { id: $id }) {
      id
      dba
      name
      contactFirstName
      contactLastName
      contactEmail
      contactPhone
      contactEmail
      franchiseRegion {
        id
        description
      }
      websiteUrl
      createdAt
      updatedAt
    }
  }
`;

export const GET_TRUCKS = gql`
  query GetTrucks($where: TruckWhereInput) {
    trucks(where: $where) {
      createdAt
      deleted
      franchiseId
      id
      make
      model
      notes
      number
      outOfService
      plate
      size
      smashSerialNumber
      vin
      updatedAt
      year
    }
  }
`;
