/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom';
import {
  StyledTable,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderText,
} from 'components/common/card/TableCard';
import { Text } from '../common/styled';
import { useQuery } from '@apollo/client';
import { WorkOrdersQuery } from 'generated/graphql';
import { GET_WORK_ORDERS } from 'graphql/query/lead';

function LeadSmashOrdersList(props) {
  const { leadId } = useParams<{ leadId: string }>();

  const { loading, data } = useQuery<WorkOrdersQuery>(GET_WORK_ORDERS, {
    variables: {
      where: {
        AND: [
          {
            OR: [
              {
                serviceType: {
                  equals: 'demo',
                },
              },
              {
                serviceType: {
                  equals: 'trial',
                },
              },
              {
                serviceType: {
                  equals: 'recurring',
                },
              },
            ],
          },
        ],
        location: {
          is: {
            customer: {
              is: {
                id: {
                  equals: leadId,
                },
              },
            },
          },
        },
      },
    },
  });

  let workOrdersData;

  if (!loading && data) {
    const leadClone = { ...data };

    workOrdersData = leadClone.workOrders.map(workOrder => {
      const structuredForTable = {
        id: workOrder.number.toString(),
        serviceLocation: workOrder.location?.name || '',
        date: new Date(workOrder.createdAt).toLocaleDateString(),
        duration: `${(workOrder.smashOperations.length + 1) * 30} min`,
        onSiteContact: `${workOrder.location?.locationContacts[0]?.customerContact?.firstName} ${workOrder.location?.locationContacts[0]?.customerContact?.lastName}`,
        serviceType: workOrder.serviceType[0].toUpperCase() + workOrder.serviceType.substring(1),
      };

      return structuredForTable;
    });
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string, record: any) => (
        <Text style={{ whiteSpace: 'nowrap' }} ellipsis>
          {text}
        </Text>
      ),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'SERVICE LOCATION',
      dataIndex: 'serviceLocation',
      render: (text: string, record: any) => <Text>{text}</Text>,
      sorter: (a, b) => a.serviceLocation.localeCompare(b.serviceLocation),
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      render: (text: string, record: any) => <Text>{text}</Text>,
      sorter: (a, b) => (new Date(a.date).getTime() || 0) - (new Date(b.date).getTime() || 0),
      defaultSortOrder: 'descend',
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      render: (text: string, record: any) => <Text>{text}</Text>,
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: 'ON-SITE CONTACT',
      dataIndex: 'onSiteContact',
      render: (text: string, record: any) => <Text>{text}</Text>,
      sorter: (a, b) => a.onSiteContact.localeCompare(b.onSiteContact),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'serviceType',
      render: (text: string, record: any) => <Text>{text}</Text>,
      sorter: (a, b) => a.serviceType.localeCompare(b.serviceType),
    },
  ];

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>SMASH ORDERS</TableHeaderText>
      </TableHeaderLeft>
    </TableHeader>
  );

  return (
    <TableCard title={header}>
      <StyledTable dataSource={workOrdersData} columns={columns} pagination={false} rowKey='id' />
    </TableCard>
  );
}

export default LeadSmashOrdersList;
