/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardActionButton } from 'components/common/card/Card';
import LocationDetailView, { LocationViewPerspective } from 'components/location/LocationDetailView';
import { GetLeadQuery, useGetLeadQuery } from 'generated/graphql';
import { DbConstants } from 'generated/smt-constants';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { hasPermission } from 'services/userService';
import Equipment from '../equipment/Equipment';
import LeadActions from './LeadActions';
import LeadDetails, { LeadDetailForm } from './LeadDetails';
import LeadSmashOrdersList from './LeadSmashOrdersList';

export interface LeadEntityState {
  queryResults: GetLeadQuery;
  lead: any;
  location: any;
  dumpsters: any[];
  broker: any;
  assets: any[];
}

function LeadDetailsPage() {
  // set up react hook forms
  const formHandler = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {},
  });

  const { leadId } = useParams<{ leadId: string }>();

  const [entityState, setEntityState] = useState<LeadEntityState>();
  const { loading, data, error } = useGetLeadQuery({
    variables: {
      id: leadId,
    },
    context: { timeout: 20000 },
    onCompleted: response => {
      formHandler.reset(response.location as any);
    },
  });

  if (error) {
    console.log('error');
    console.log(error);
  }

  const location = data?.location;
  const contact =
    location?.customer?.customerContacts?.find(cc => cc.primary) ||
    ((location?.locationContacts?.length || 0) > 0 ? location?.locationContacts[0].customerContact : undefined);

  if (data && !loading) {
    if (!entityState) {
      setEntityState({
        queryResults: data,
        lead: {
          customerId: location?.customerId,
          locationId: location?.id,
          name: location?.customer?.name,
          lastFollowUp: location?.lastFollowUp,
          leadNotes: location?.leadNotes,
          hubspotDealId: location?.hubspotDealId,
          demoCompleted: location?.demoCompleted,
          demoScheduled: location?.demoScheduled,
          trialCompleted: location?.trialCompleted,
          trialScheduled: location?.trialScheduled,
          salesRepFullName: location?.salesRepUser
            ? `${location?.salesRepUser?.firstName} ${location?.salesRepUser?.lastName}`
            : location?.salesRep,
          contact: {
            id: contact?.id,
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            email: contact?.email,
            phone: contact?.phone,
          },
        } as LeadDetailForm,
        location,
        dumpsters: location?.dumpsters || [],
        broker: (location?.locationBrokers?.length || 0) > 0 ? location?.locationBrokers[0] : undefined,
        assets: location?.locationAssets || [],
      });
    }
  }
  const updateContextWithLocation: (updatedLocation: any) => void = (updatedLocation: any) => {
    if (updatedLocation && entityState)
      setEntityState({
        ...entityState,
        location: { ...entityState.location, inactive: updatedLocation?.inactive || null },
      });
  };
  const updateContextWithDumpsters: (dumpsters: any[]) => void = (dumpsters: any[]) => {
    if (dumpsters && entityState) setEntityState({ ...entityState, dumpsters: [...dumpsters] });
  };
  const updateContextWithBroker: (broker: any) => void = (broker: any) => {
    if (broker && entityState) {
      setEntityState({ ...entityState, broker });
    }
  };
  const updateContextWithAssets: (assets: any[]) => void = (assets: any[]) => {
    if (assets && entityState) setEntityState({ ...entityState, assets: [...assets] });
  };

  // console.log(lead);
  // console.log(lead?.locations);
  // console.log(location);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FormProvider {...formHandler}>
      <LeadActions lead={entityState?.lead} />
      <LeadDetails lead={entityState?.lead} />
      {entityState?.lead?.locations?.length > 1 ? (
        <div>Warning! This lead has multiple locations, which is not yet supported.</div>
      ) : (
        <div />
      )}
      <LocationDetailView
        perspective={LocationViewPerspective.Lead}
        location={{ ...entityState?.location }}
        editPermission={DbConstants.Permission.LeadLocationCrud}
        secondaryActions={
          <CardActionButton size='medium' onClick={null}>
            <FontAwesomeIcon icon={faUpload} />
            &nbsp;UPLOAD RELEASE
          </CardActionButton>
        }
      />
      <Equipment
        location={entityState?.location}
        broker={entityState?.broker}
        dumpsters={entityState?.dumpsters}
        assets={entityState?.assets}
        brokerUpdated={updateContextWithBroker}
        dumpstersUpdated={updateContextWithDumpsters}
        assetsUpdated={updateContextWithAssets}
        readOnly={!hasPermission(DbConstants.Permission.LeadLocationCrud)}
      />
      <LeadSmashOrdersList />
    </FormProvider>
  );
}

export default LeadDetailsPage;
