import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { format, subYears } from 'date-fns';

import { JobTitleEnum, SampleFormState, StatusEnum } from './example.models';
import { loadSampleRecordByIdFromDataSource, mapFormObject } from './example.service';
import {
  LoadingContainer,
  StyledHeader,
  StyledRow,
  FormLabel,
  FormSection,
  StyledSubmitButton,
  StyledButton,
} from './example.styled';
import { Col, Row, Switch } from 'antd';

import { ContentCard } from 'components/common/card/Card';
import Title from 'antd/lib/typography/Title';
import { RHFDatePicker } from 'components/common/react-hook-form/RHFDatePicker';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFSwitch } from 'components/common/react-hook-form/RHFSwitch';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { RHFAutoComplete } from 'components/common/react-hook-form/RHFAutoComplete';

function ExampleEdit() {
  // pull the recordId from params
  const { recordId } = useParams<{ recordId: string }>();

  // get the search state, which includes everything
  const [sampleFormState, setFormState] = useState<SampleFormState>({
    record: null,
    isNew: !recordId,
    inEditMode: true,
    initialized: false,
  });

  const loadingRef = useRef<boolean>(true);

  // load the data from wherever and update the state.
  useEffect(() => {
    if (loadingRef.current && !sampleFormState.isNew && !sampleFormState.record) {
      // The loadingRef guarantees that the state will only be set once. Not sure how
      loadingRef.current = false;
      loadSampleRecordByIdFromDataSource(Number(recordId))
        .then(theRecord => {
          setFormState({ ...sampleFormState, record: theRecord, initialized: true });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  });

  // set up react-hook-form stuff - see: https://react-hook-form.com/get-started#IntegratingwithUIlibraries
  // Here's a good example of antd controls and validation... https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js
  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const formObj = mapFormObject(sampleFormState);

  const onSubmit = data => {
    // eslint-disable-next-line no-console
    console.log(data);
    // eslint-disable-next-line no-alert
    alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation

  const isFormLoading = loadingRef.current && !sampleFormState.initialized;
  if (isFormLoading) {
    return (
      <LoadingContainer spinning={isFormLoading} delay={200}>
        Loading the form...
      </LoadingContainer>
    );
  }

  const minBirthDate = subYears(new Date(), 120);
  const now = new Date();

  // These watch fields are for comparison in the start and end date range datepickers (just for a sample)
  let [watchedStartDate] = watch(['startDate']);
  if (!watchedStartDate) watchedStartDate = formObj?.startDate;

  const formHeader = (
    <StyledHeader>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Title style={{ marginRight: '10px' }} level={4}>
            EDIT SAMPLE RECORD
          </Title>
        </Col>
        <Col>
          <StyledButton style={{ marginRight: '10px' }} onClick={() => reset()}>
            RESET
          </StyledButton>
          <Switch
            style={{ marginRight: '10px' }}
            checkedChildren='EDIT'
            unCheckedChildren='VIEW'
            defaultChecked={sampleFormState.inEditMode}
            onChange={e => setFormState({ ...sampleFormState, inEditMode: e })}
          />
          <StyledSubmitButton type='submit' size='large' value='SAVE' />
        </Col>
      </Row>
    </StyledHeader>
  );
  const validator = (name: string, messages: { [x: string]: string }) => {
    if (errors[name]) {
      const type: string = errors[name]?.type?.toString() || '';
      const msg: string = (messages[type] ? messages[type] : errors[name]?.message?.toString()) || '';
      return <div className='ant-form-explain'>{msg}</div>;
    }
    return null;
  };

  // make a function for this
  const enumKeys = Object.keys(StatusEnum);
  const enumValues = Object.values(StatusEnum);
  const jobEnumKeys = Object.keys(JobTitleEnum);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentCard title={formHeader}>
        <StyledRow>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>First Name</FormLabel>
                <RHFInput
                  name='firstName'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.firstName}
                  rules={{ required: true }}
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'First Name',
                  }}
                />
                {validator('firstName', { required: 'First Name is required' })}
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Last Name</FormLabel>
                <RHFInput
                  name='lastName'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.lastName}
                  rules={{ required: true }}
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'Last Name',
                  }}
                />
                {validator('lastName', { required: 'Last Name is required' })}
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Birth Date</FormLabel>
                <RHFDatePicker
                  name='birthDate'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.birthDate}
                  rules={{ required: true, validate: birthDate => birthDate < now && birthDate > minBirthDate }}
                  attributes={{
                    placeholder: 'Birth Date',
                  }}
                />
                {validator('birthDate', {
                  required: 'Birth Date is required',
                  validate: 'Please enter a valid Birth Date',
                })}
              </div>
            </FormSection>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Address Name</FormLabel>
                <RHFInput
                  name='homeAddressName'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.homeAddressName}
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'Home Address Name',
                  }}
                />
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Address Line 1</FormLabel>
                <RHFInput
                  name='homeAddressLine1'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.homeAddressLine1}
                  rules={{ required: true }}
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'Home Address Line 1',
                  }}
                />
                {validator('homeAddressLine1', { required: 'Home Street Address is required' })}
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Status</FormLabel>
                {/* Another example of a custom react-hook-form component we could create. */}
                <RHFSelect
                  name='status'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={formObj?.status}
                  options={enumKeys.map<RHFOption>((o, index) => ({ label: o, value: enumValues[index] }))}
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'Status',
                  }}
                />
                {validator('status', { required: 'Status is required' })}
              </div>
            </FormSection>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>US Citizen?</FormLabel>
                <RHFSwitch
                  name='isUsCitizen'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.isUsCitizen || false}
                  checkedText='Yes'
                  uncheckedText='No'
                />
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Start Time</FormLabel>
                <RHFDatePicker
                  name='startDate'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.startDate}
                  dateFormatFunction={d => format(d, 'yyyy-MM-dd HH:mm:ss')}
                  rules={{ required: true }}
                  attributes={{
                    placeholder: 'Start Time',
                    showTime: true,
                  }}
                />
                {validator('startDate', {
                  required: 'Start Date is required',
                })}
              </div>
            </FormSection>
          </Col>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>End Time</FormLabel>
                <RHFDatePicker
                  name='endDate'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  defaultValue={formObj?.endDate}
                  dateFormatFunction={d => format(d, 'yyyy-MM-dd HH:mm:ss')}
                  rules={{ required: true, validate: endDate => endDate > watchedStartDate }}
                  attributes={{
                    placeholder: 'End Time',
                    showTime: true,
                  }}
                />
                {validator('endDate', {
                  required: 'End Date is required',
                  validate: 'Verify that the start date is less than the end date',
                })}{' '}
                {/* {formObj?.endDate?.toTimeString()} */}
              </div>
            </FormSection>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col span={8}>
            <FormSection>
              <div className='input-group'>
                <FormLabel>Job Title</FormLabel>
                <RHFAutoComplete
                  name='jobTitle'
                  editMode={sampleFormState.inEditMode}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={formObj?.jobTitle}
                  options={jobEnumKeys.map<RHFOption>(o => ({ label: o, value: o }))}
                  filterOption={(inputValue, option: RHFOption) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  attributes={{
                    style: { width: 300, marginRight: '20px' },
                    placeholder: 'Job Title',
                  }}
                />
                {validator('jobTitle', { required: 'Job Title is required' })}
              </div>
            </FormSection>
          </Col>
          <Col span={8} />
          <Col span={8} />
        </StyledRow>
      </ContentCard>
    </form>
  );
}

export default ExampleEdit;
