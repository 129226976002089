import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Table, Typography } from 'antd';
import { StyledAddButton } from '../styled';
import styled from 'styled-components';
import ActiveToggleSwitch from '../ActiveToggleSwitch';
import { CardHeader, CardHeaderLeft, CardHeaderRight, CardHeaderText, StyledCard } from './Card';
import theme from 'theme';

const { Text } = Typography;

export const TableCard = styled(StyledCard)`
  margin-bottom: 30px;

  .ant-card-body {
    padding: 0px 0px 10px;

    .ant-table-cell {
      a {
        text-decoration: solid underline;
      }
    }
  }

  th.ant-table-column-sort {
    background: linear-gradient(360deg, #a9a9a9 -88.33%, rgba(217, 217, 217, 0) 71.67%) !important;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const TableHeader = styled(CardHeader)``;

export const TableHeaderLeft = styled(CardHeaderLeft)``;

export const TableHeaderRight = styled(CardHeaderRight)``;

export const StyledSearch = styled(Input)`
  background: #ffffff;
  // width: 292px;
  height: 33px;
  margin-left: 50px;
  & svg {
    box-sizing: border-box;
    padding-top: 1px;
    color: #262626;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  padding-bottom: 4px;
  gap: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
`;

export function TableSearch(props) {
  return (
    <StyledSearch
      suffix={<FontAwesomeIcon icon={faMagnifyingGlass} />}
      placeholder={props.placeholder || 'Search'}
      onChange={e => {
        props.onChange(e.target.value);
      }}
    />
  );
}

export const TableHeaderText = styled(CardHeaderText)``;

export const TableActiveToggleSwitchContainer = styled.div`
  margin-left: 24px;
  margin-top: -1px;
`;

export function TableActiveToggleSwitch(props) {
  return (
    <TableActiveToggleSwitchContainer>
      <ActiveToggleSwitch {...props} />
    </TableActiveToggleSwitchContainer>
  );
}

export function StyledTable(props) {
  return <Table {...props} columns={props.columns.map(column => ({ ...column, align: column.align || 'center' }))} />;
}

export const TableAddButton = styled(StyledAddButton)`
  margin-top: 7px;
  margin-left: 20px;
`;

export const StyledSelectableTable = styled<typeof StyledTable>(StyledTable)`
  tbody tr:hover {
    cursor: pointer;
    cursor: hand;
  }
  .ant-table-selection-col,
  .ant-table-selection-column {
    /* This is a total hack - You cannot display:none here because it will shift column styles (like width) */
    overflow: hidden;
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
    > * {
      display: none;
    }
  }
  
  .ant-table-thead {
    tr {
      th {
        linear-gradient(360deg, ${theme['@progress-bar-empty-color']} -88.33%, rgba(217, 217, 217, 0) 71.67%);
        border-top: 1px solid ${theme['@another-gray-df']};
        border-bottom: 1px solid ${theme['@another-gray-df']};
      }
    }
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  .ant-table-tbody {
    .ant-table-row-selected {
      td {
        background-color: rgba(242, 242, 242, 0.5);
        background: rgba(242, 242, 242, 0.5);
        :nth-child(2) {
          border-left: 13px solid ${theme['@blue']};
        }
      }
    }
  }
`;
