import { gql } from '@apollo/client';

export const CORE_AGREEMENT_FIELDS = gql`
  fragment CoreAgreementFields on Agreement {
    id
    agreementStatus
    poNumber
    updatedAt
    locationId
    documentId
    agreementServiceType
    paymentTermsNetDays
    billingFrequency
    billingStart
    taxExempt
    taxId
    billingPreference
    paymentSystemNotes
    agreementDate
    serviceStart
    duration
    durationUnit
    expirationDate
    salesRep
    salesRepId
    dumpsterCount
    monthlyRate
    hourlyRate
    dailyRate
    jobRate
    fuelSurcharge
    fuelSurchargePct
    agreementTotal
    scheduleFrequencyInWeeks
    automatedInvoices
    convertMonthToMonth
    daysOfWeek
    notes
    inactive
    deleted
    approvedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;
