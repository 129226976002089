import styled from 'styled-components';
import { TableCard } from 'components/common/card/TableCard';

export const GreenCircle = styled.div`
  background-color: #79bc7f;
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const TruckTableCard = styled(TableCard)`
  min-width: 700px;
  .cell-align-top {
    vertical-align: top;
  }
`;
