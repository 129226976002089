/* eslint-disable @typescript-eslint/no-unused-vars */
import './agreement.less';
import { Button, Card, Col, Row, Space, Tabs } from 'antd';
import { useRef, useState } from 'react';

import AgreementEditForm from './AgreementEditForm';
import { AgreementForm, ChildFormActivator } from 'components/agreement/agreement.models';
import { mapAgreementFormObject } from './agreement.service';
import { useGetAgreementDetailsQuery } from 'generated/graphql';
import { useHistory } from 'react-router-dom';

function Agreement({ readOnly }: { readOnly?: boolean }) {
  // const { locationId, agreementId } = useParams<{ locationId?: string; agreementId?: string }>();
  const queryParams = new URLSearchParams(window.location.search);
  let locationId = queryParams.get('locationId') || undefined;
  let agreementId = queryParams.get('agreementId') || undefined;
  const isInAddMode = !agreementId && !!locationId;

  const history = useHistory();
  const [editMode, setEditMode] = useState(isInAddMode);
  const [queryResults, setQueryResults] = useState<{ agreement: any }>();
  const agreementRef = useRef<AgreementForm>();

  // set the default implementation of the form actions.
  let formActions: ChildFormActivator = {
    canAdd: false,
    add: () => false,
    submit: () => false,
    reset: () => false,
    validate: () => Promise.resolve(false),
  };

  const { loading } = useGetAgreementDetailsQuery({
    variables: {
      id: agreementId || '00000000-0000-0000-0000-000000000000',
      dumpsterWhere: {
        locationId: { equals: locationId },
        AND: [{ deleted: { equals: null } }],
      },
    },
    onCompleted: response => {
      if (response.agreement) {
        setQueryResults({
          agreement: response?.agreement,
        });
      } else {
        setEditMode(true);
      }
      agreementRef.current = mapAgreementFormObject(
        response?.agreement?.locationId || locationId,
        response?.dumpsters?.length || 1,
        response?.agreement
      );
      formActions.reset(agreementRef.current);
    },
  });

  // if a tab has an implementation, it will call this.
  const setFormActionsHandler = (actions: ChildFormActivator) => {
    formActions = actions;
  };

  const updateContextWithAgreement: (updatedAgreement: any) => void = (updatedAgreement: any) => {
    if (updatedAgreement) {
      setQueryResults({
        agreement: updatedAgreement,
      });
      const queryParamsLocal = new URLSearchParams(window.location.search);

      agreementId = updatedAgreement.id;
      locationId = updatedAgreement.locationId;
      if (!queryParamsLocal.has('agreementId')) {
        queryParamsLocal.set('agreementId', updatedAgreement.id);
      }
      if (!queryParamsLocal.has('locationId')) {
        queryParamsLocal.set('locationId', updatedAgreement.locationId);
      }
      history.replace({
        pathname: window.location.pathname,
        search: queryParamsLocal.toString(),
      });
    }
  };

  const title = (
    <Row className='tab-container'>
      <Col span={12}>
        <h2 className='card-header-text'>{isInAddMode ? 'Add ' : 'Edit'} Agreement</h2>
      </Col>
      <Col className='buttons-right' span={12}>
        {!readOnly && (
          <Space size='small' align='start' direction='horizontal'>
            {(!readOnly || editMode) && (
              <Button
                className={editMode ? 'save-button' : 'edit-button'}
                size='middle'
                onClick={async () => {
                  if (editMode && formActions) {
                    const x = await formActions?.validate();
                    if (x) {
                      formActions.submit();
                      setEditMode(false);
                    } else {
                      return false;
                    }
                  }
                  setEditMode(!editMode);
                  return true;
                }}
              >
                {editMode ? 'SAVE' : 'EDIT'}
              </Button>
            )}
            {editMode ? (
              <Button
                className='cancel-button'
                size='middle'
                onClick={() => {
                  if (editMode && formActions) {
                    formActions.reset();
                  }
                  setEditMode(false);
                }}
              >
                CANCEL
              </Button>
            ) : undefined}
          </Space>
        )}
      </Col>
    </Row>
  );

  return (
    <Card className='agreement-card' title={title}>
      <AgreementEditForm
        locationId={locationId || 'b751c85f-de57-46d5-bb95-108fe1894dff'} // 9c62f181-01e0-4ede-bdd0-f79b971f388f
        agreementForm={agreementRef.current}
        editMode={editMode}
        setFormActions={setFormActionsHandler}
        agreementUpdated={updateContextWithAgreement}
      />
    </Card>
  );
}

export default Agreement;
