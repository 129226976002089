import { useHistory, useParams } from 'react-router-dom';
import { Col, notification, Row, Space } from 'antd';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useGetFranchiseEmployeeQuery, useUpdateUserMutation } from 'generated/graphql';
import theme from 'theme';
import { getSelectedFranchise, hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';
import { buildEmployeeFormObject, buildEmployeeWhereClause } from './employee.service';
import { CardColumn, CardRow, HeaderText } from 'components/common/card/Card';
import {
  EmployeeButton,
  EmployeeCardRow,
  EmployeeContentCard,
  EmployeeFlexCol,
  EmployeeHeaderTextBox,
} from './employee.styled';
import { FormLabel } from 'components/customer/customer.styled';
import { ViewFieldLabel } from 'components/common/styled';
import { RHFCheckbox } from 'components/common/react-hook-form/RHFCheckbox';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { EmployeeForm } from './employee.models';

function EmployeeDetail() {
  const { employeeId } = useParams<{ employeeId: string }>();
  const canEdit = hasPermission(DbConstants.Permission.SettingsEmployeesCrud); // TODO: add security
  const currentFranchise = getSelectedFranchise(); // TODO: add security

  const formRef = useRef(null);
  const roleRef = useRef<
    {
      id?: string;
      name?: string;
    }[]
  >([]);
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);
  const [formObj, setFormObj] = useState<EmployeeForm>();

  // set up react hook forms
  const {
    handleSubmit,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    control,
    register,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const { loading } = useGetFranchiseEmployeeQuery({
    variables: { where: buildEmployeeWhereClause(employeeId) },
    onCompleted: data => {
      const user = (data?.users?.length || 0) > 0 ? data?.users[0] : undefined;
      roleRef.current =
        (data?.roles?.length || 0) > 0
          ? data?.roles?.filter(o => o?.userType !== 'corporate')?.map(r => ({ id: r.id, name: r.description }))
          : [];
      if (user) {
        setFormObj(buildEmployeeFormObject(user));

        reset();
      }
    },
  });

  const [updateUser] = useUpdateUserMutation({
    onCompleted: returnData => {
      buildEmployeeFormObject(returnData?.updateUser);
      reset();
      notification.open({
        message: 'Employee Record Updated.',
        description: 'Employee Record Updated.',
        duration: 5,
      });
    },
    onError: error => {
      notification.error({
        message: 'Error Updating Record.',
        description: error.message,
        duration: 5,
      });
    },
  });

  const onSubmit = formSubmitObj => {
    const roleIds: string[] = [formSubmitObj.primaryRole];
    if (formSubmitObj.isDriver && !roleIds.includes('driver')) roleIds.push('driver');
    if (formSubmitObj.isFinance && !roleIds.includes('finance')) roleIds.push('finance');
    if (formSubmitObj.isManager && !roleIds.includes('manager')) roleIds.push('manager');
    if (formSubmitObj.isOperations && !roleIds.includes('operations')) roleIds.push('operations');
    if (formSubmitObj.isOwner && !roleIds.includes('owner')) roleIds.push('owner');
    if (formSubmitObj.isSales && !roleIds.includes('sales')) roleIds.push('sales');

    // when the form is submitted, call the mutation with the current data.
    updateUser({
      variables: {
        data: {
          title: formSubmitObj.title,
          primaryRoleId: formSubmitObj.primaryRole,
          roleIds,
        },
        where: {
          userId: employeeId,
          franchiseId: currentFranchise?.id,
        },
      },
    });
  };

  const formHeader = (
    <Row>
      <EmployeeFlexCol>
        <Space size='middle'>
          <EmployeeHeaderTextBox>
            <HeaderText level={2}>
              {formObj?.firstName} {formObj?.lastName}
            </HeaderText>
          </EmployeeHeaderTextBox>
          {canEdit && (
            <EmployeeButton
              size='medium'
              $color={theme[editMode ? '@green' : '@blue']}
              onClick={async () => {
                if (editMode) {
                  const isValidFormData = await trigger();
                  if (!isValidFormData) return;

                  (formRef?.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
                setEditMode(!editMode);
              }}
            >
              {editMode ? 'SAVE' : 'EDIT'}
            </EmployeeButton>
          )}
          {canEdit && editMode && (
            <EmployeeButton
              size='medium'
              $color={theme['@red']}
              onClick={() => {
                reset();
                setEditMode(false);
              }}
            >
              CANCEL
            </EmployeeButton>
          )}
        </Space>
      </EmployeeFlexCol>
      <Col flex='auto' />
    </Row>
  );

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <EmployeeContentCard title={formHeader}>
        <CardRow>
          <FormLabel>PREFERENCES</FormLabel>
        </CardRow>
        <CardRow>
          <CardColumn lg={12} xs={24}>
            <EmployeeCardRow>
              <Col xl={4} md={24} sm={24} xs={24}>
                <ViewFieldLabel>Name:</ViewFieldLabel>
              </Col>
              <Col xl={20} md={24} sm={24} xs={24}>
                <Space>
                  <RHFInput
                    name='firstName'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.firstName}
                    rules={{ maxLength: { value: 100, message: 'Maximum of 100 characters' } }}
                    viewModeStyle={{ minWidth: '10em' }}
                    attributes={{
                      style: { width: '100%' },
                      placeholder: 'First Name',
                      readOnly: true,
                    }}
                  />
                  <RHFInput
                    name='lastName'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.lastName}
                    rules={{ maxLength: { value: 100, message: 'Maximum of 100 characters' } }}
                    viewModeStyle={{ minWidth: '12em' }}
                    attributes={{
                      style: { width: '100%' },
                      placeholder: 'Last Name',
                      readOnly: true,
                    }}
                  />
                </Space>
              </Col>
            </EmployeeCardRow>
            <EmployeeCardRow>
              <Col xl={4} md={24} sm={24} xs={24}>
                <ViewFieldLabel>Title:</ViewFieldLabel>
              </Col>
              <Col xl={20} md={24} sm={24} xs={24}>
                <RHFInput
                  name='title'
                  editMode={editMode}
                  control={control}
                  defaultValue={formObj?.title}
                  rules={{ maxLength: { value: 256, message: 'Maximum of 256 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Title',
                  }}
                />
              </Col>
            </EmployeeCardRow>
          </CardColumn>
          <CardColumn lg={12} xs={24}>
            <EmployeeCardRow>
              <Col xl={4} md={24} sm={24} xs={24}>
                <ViewFieldLabel>Email:</ViewFieldLabel>
              </Col>
              <Col xl={20} md={24} sm={24} xs={24}>
                <RHFInput
                  name='email'
                  editMode={editMode}
                  control={control}
                  defaultValue={formObj?.email}
                  rules={{ maxLength: { value: 256, message: 'Maximum of 256 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Email',
                    readOnly: true,
                  }}
                />
              </Col>
            </EmployeeCardRow>
            <EmployeeCardRow>
              <Col xl={4} md={24} sm={24} xs={24}>
                <ViewFieldLabel>Phone:</ViewFieldLabel>
              </Col>
              <Col xl={20} md={24} sm={24} xs={24}>
                <RHFInput
                  name='phone'
                  editMode={editMode}
                  control={control}
                  defaultValue={formObj?.phone}
                  rules={{ maxLength: { value: 100, message: 'Maximum of 100 characters' } }}
                  attributes={{
                    style: { width: '16em' },
                    placeholder: 'Phone',
                    readOnly: true,
                  }}
                />
              </Col>
            </EmployeeCardRow>
          </CardColumn>
        </CardRow>
        <CardRow>
          <FormLabel>ROLES</FormLabel>
        </CardRow>
        <CardRow>
          <CardColumn xl={6} md={8} xs={24}>
            <CardRow>
              <Col xl={8} md={24} sm={24} xs={24}>
                <ViewFieldLabel>Primary:</ViewFieldLabel>
              </Col>
              <Col xl={16} md={24} sm={24} xs={24}>
                <RHFSelect
                  name='primaryRole'
                  editMode={editMode}
                  control={control}
                  defaultValue={formObj?.primaryRole}
                  options={roleRef.current?.map<RHFOption>(o => ({ label: o.name, value: o.id })) || []}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Dumpster Size',
                  }}
                />
              </Col>
            </CardRow>
          </CardColumn>
          <CardColumn xl={18} md={12} xs={24}>
            <CardRow>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isOwner'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isOwner || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>Owner</ViewFieldLabel>
                </Space>
              </Col>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isManager'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isManager || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>GM</ViewFieldLabel>
                </Space>
              </Col>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isFinance'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isFinance || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>Finance</ViewFieldLabel>
                </Space>
              </Col>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isSales'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isSales || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>Sales</ViewFieldLabel>
                </Space>
              </Col>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isOperations'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isOperations || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>Operations</ViewFieldLabel>
                </Space>
              </Col>
              <Col xl={4} lg={8}>
                <Space>
                  <RHFCheckbox
                    name='isDriver'
                    editMode={editMode}
                    control={control}
                    defaultValue={formObj?.isDriver || false}
                    hideBorder
                    checkedText='✓'
                  />
                  <ViewFieldLabel>Driver</ViewFieldLabel>
                </Space>
              </Col>
            </CardRow>
          </CardColumn>
        </CardRow>
      </EmployeeContentCard>
    </form>
  );
}

export default EmployeeDetail;
