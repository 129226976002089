import { gql } from '@apollo/client';
import { CORE_AGREEMENT_FIELDS } from './agreement';

export const CORE_DUMPSTER_FIELDS = gql`
  fragment CoreDumpsterFields on Dumpster {
    id
    name
    serialNumber
    dumpsterSizeId
    dumpsterHauler
    notes
    agreementManaged
    locationId
    deleted
    createdAt
    updatedAt
    dumpsterSize {
      id
      description
      notes
      deleted
    }
    smashOperations {
      id
    }
  }
`;

export const CORE_ASSET_FIELDS = gql`
  fragment CoreAssetFields on LocationAsset {
    id
    name
    locationId
    assetTypeId
    wasteStreamId
    size
    unitCount
    provider
    underContract
    contractExpiration
    priceDetails
    locationNotes
    notes
    deleted
    createdAt
    updatedAt
    assetType {
      id
      description
      notes
      deleted
    }
    wasteStream {
      description
      notes
      id
      deleted
    }
  }
`;

export const CORE_BROKER_FIELDS = gql`
  fragment CoreBrokerFields on LocationBroker {
    locationId
    brokerName
    notes
    contactFirstName
    contactLastName
    contactPhone
    contactEmail
    deleted
    createdAt
    updatedAt
  }
`;

export const CORE_LOCATION_FIELDS = gql`
  ${CORE_BROKER_FIELDS}
  ${CORE_ASSET_FIELDS}
  ${CORE_DUMPSTER_FIELDS}
  ${CORE_AGREEMENT_FIELDS}
  fragment CoreLocationFields on Location {
    id
    customerId
    name
    dumpsterCount
    addressId
    archived
    expectedMonthlyValue
    demoScheduled
    demoCompleted
    trialScheduled
    trialCompleted
    convertedFromLead
    lastFollowUp
    salesRep
    salesRepId
    salesRepUser {
      firstName
      lastName
    }
    leadNotes
    hubspotDealId
    inactive
    deleted
    createdAt
    updatedAt
    address {
      line1
      line2
      city
      state
      zipCode
    }
    locationContacts {
      customerContact {
        id
        firstName
        lastName
        phone
        email
        contactTypes
        preferredContactMethod
        contactCategories
      }
    }
    customer {
      name
    }
    agreements {
      ...CoreAgreementFields
    }
    dumpsters {
      ...CoreDumpsterFields
    }
    locationAssets {
      ...CoreAssetFields
    }
    locationBrokers {
      ...CoreBrokerFields
    }
  }
`;
