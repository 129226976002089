import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GQL_ENDPOINT } from '../env';
import { setContext } from '@apollo/client/link/context';

const httpLink = new BatchHttpLink({
  uri: GQL_ENDPOINT,
  batchMax: 6,
  batchInterval: 50,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('smt-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  resolvers: {},
  link: authLink.concat(httpLink),
});

export { apolloClient };
