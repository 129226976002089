/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { Checkbox, Switch } from 'antd';
import { RHFCheckboxParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd checkbox control using a react-hook-form controller.
 * see: https://ant.design/components/checkbox/ (AntD Switch reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd checkbox control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFCheckbox(params: RHFCheckboxParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName = params.viewModeClassName || (params.hideBorder ? 'rhf-label-noborder' : 'rhf-label');
  let defVal = params.defaultValue;
  defVal = defVal === undefined || defVal === null ? params.control?._defaultValues[params.name] : defVal;
  const yesTxt = params.checkedText || '';
  const noTxt = params.uncheckedText || '';
  if (params.readOnly)
    attributes.className = attributes?.className
      ? `${attributes?.className} rhf-disable-pointer`
      : 'rhf-disable-pointer';
  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field: { onChange, value } }) => <Checkbox onChange={onChange} checked={value} {...attributes} />}
      />
      {validator}
    </>
  ) : (
    <div className={labelClassName}>{defVal ? yesTxt : noTxt}</div>
  );
}
