import { Row, Spin } from 'antd';
import { StyledCard } from 'components/common/card/Card';
import { FormSectionTitle, StyledPlusButton, ViewField, Text } from 'components/common/styled';
import styled from 'styled-components';

export const LocationContentCard = styled(StyledCard)`
  .contact-row-alt {
    background-color: rgba(217, 217, 217, 0.15);
  }

  .contact-row-top-border {
    border-top: 1px solid #e5e5e5;
    padding-top: 16px;
  }
`;

export const LoadingContainer = styled<typeof Spin>(Spin)`
  max-height: 100% !important;
  position: fixed !important;
`;

export const LocationSectionTitle = styled(FormSectionTitle)`
  width: 500px;
  margin-right: 0;
  margin-bottom: 0;
`;

export const LocationContactRow = styled(Row)`
  margin: 0px -16px 0px -16px;
  padding: 1em 16px 0px 16px;
  border-top: 1px solid #e5e5e5;
`;

export const LocationContactPlusButton = styled(StyledPlusButton)`
  margin-top: -2px;
`;

export const LocationContactFormSectionTitle = styled(FormSectionTitle)`
  width: 175px;
  margin: 0px;
  margin-bottom: 0px;
`;

export const LocationContactFirstViewField = styled(ViewField)`
  width: 240px;
  margin-right: 50px;
  white-space: nowrap;
`;

export const LocationContactLastViewField = styled(ViewField)`
  width: 240px;
  margin-right: 0px;
  white-space: nowrap;
`;

export const LocationContactPhoneViewField = styled(ViewField)`
  width: 143px;
  margin-right: 50px;
  white-space: nowrap;
`;

export const LocationContactEmailViewField = styled(ViewField)`
  width: 330px;
  margin-right: 0px;
`;

export const LocationNameViewField = styled(ViewField)`
  width: 25em;
  margin-right: 2em;
`;

export const LocationAddressSectionTitleField = styled(FormSectionTitle)`
  width: 300px;
  margin-right: 50px;
  margin-bottom: 0px;
`;

export const LocationStreetViewField = styled(ViewField)`
  width: 20em;
  margin-right: 2em;
`;

export const LocationCityViewField = styled(ViewField)`
  width: 15em;
  margin-right: 1.5em;
`;

export const LocationStateViewField = styled(ViewField)`
  width: 6em;
  margin-right: 1.5em;
`;

export const LocationZipViewField = styled(ViewField)`
  width: 10em;
`;

export const LocationServiceTypeSectionTitle = styled(FormSectionTitle)`
  width: 300px;
  margin-left: 50px;
  margin-right: 115px;
  margin-top: 50px;
`;

export const LocationServiceTypeText = styled(Text)`
  width: 76px;
  margin-left: 50px;
  margin-right: 50px;
`;

export const LocationStatusSectionTitle = styled(FormSectionTitle)`
  width: 115px;
  margin-right: 0px;
  margin-top: 50px;
`;

export const LocationStatusViewField = styled(ViewField)`
  width: 140px;
  margin-right: 0px;
`;
