const copyPastaRemovoLater = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id: 'us-east-2:abf7d795-f1be-4602-858f-5ccb267bb02f',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_Dkop2GoEo',
  aws_user_pools_web_client_id: '5snt5d7rvs6bhmrsue7tsvelbq',
  oauth: {
    domain: 'smashmytrash-kurtis.auth.us-east-2.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:19006/',
    redirectSignOut: 'http://localhost:19006/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
  aws_appsync_graphqlEndpoint: 'https://ehfpjxfdp5cbncrq7aibgzrxzy.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

const oauthRedirectUrl = `${window.location.origin}/`;

export default {
  ...copyPastaRemovoLater,
  Analytics: {
    disabled: true,
  },
  oauth: {
    ...copyPastaRemovoLater.oauth,
    redirectSignIn: oauthRedirectUrl,
    redirectSignOut: oauthRedirectUrl,
  },
};
