import { Menu } from 'antd';
import { getPathKey } from 'components/styleGuide/listAndEditExample/example.service';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getSignedInUser } from 'services/userService';
import CustomizeUserForm from './customizeUser/CustomizeUserForm';
import GraphQLTool from './graphQLUtility/GraphQLTool';
import QueryTool from './queryUtility/QueryTool';

function UtilitiesHome() {
  const currentUser = getSignedInUser();
  if (!currentUser.superAdmin) window.document.location = '/';

  const match = useRouteMatch();
  const history = useHistory();
  const menuItemData = [
    { key: 'customize-user', label: 'Customize User' },
    { key: 'query-tool', label: 'SQL Query Tool' },
    { key: 'graphql-tool', label: 'GraphQL Query Tool' },
    { key: '/style-guide', label: 'Style Guide', style: { backgroundImage: 'linear-gradient(white, lightGreen)' } },
  ];
  return (
    <div>
      <h2>Team Utilities</h2>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[getPathKey('style-guide', 'customize-user')]}
        onClick={i => {
          history.push(i.key.charAt(0) === '/' ? i.key : `${match.url}/${i.key}`);
        }}
        style={{ marginBottom: '1em ' }}
        items={menuItemData}
      />
      <Switch>
        <Route path={`${match.path}/query-tool`}>
          <QueryTool />
        </Route>
        <Route path={`${match.path}/graphql-tool`}>
          <GraphQLTool />
        </Route>
        <Route path={[`${match.path}`, `${match.path}/customize-user`]}>
          <CustomizeUserForm />
        </Route>
      </Switch>
    </div>
  );
}

export default UtilitiesHome;
