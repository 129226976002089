/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { Checkbox } from 'antd';
import { CheckboxGroupRenderMode, RHFCheckboxGroupParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd select control using a react-hook-form controller.
 * see: https://ant.design/components/select/ (AntD Select reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd select control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFCheckboxGroup(params: RHFCheckboxGroupParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName = params.viewModeClassName || (params.hideBorder ? 'rhf-label-noborder' : 'rhf-label');
  let viewModeValue: string | undefined;
  const defVal = params.defaultValue || params.control?._defaultValues[params.name];
  if (Array.isArray(defVal)) {
    viewModeValue = defVal
      .map(x => params.options?.find(option => option.value === x)?.label)
      .filter(x => !!x)
      .join(', ');
  } else if (defVal) {
    viewModeValue = `${defVal}`;
  }
  let viewInEditMode = params.editMode;
  let viewModeElement: JSX.Element = <span />;
  if (params.renderMode === CheckboxGroupRenderMode.SelectedItemsOnly) {
    viewModeElement = viewModeValue ? (
      <div className={labelClassName}>{viewModeValue}</div>
    ) : (
      <div className={labelClassName}>&nbsp;</div>
    );
  } else if (params.renderMode === CheckboxGroupRenderMode.ReadOnlyCheckboxes) {
    if (!params.editMode) {
      attributes.className = attributes?.className
        ? `${attributes?.className} rhf-disable-pointer`
        : 'rhf-disable-pointer';
      viewInEditMode = true;
    }
  } else {
    const selectedValues = Array.isArray(defVal) ? defVal : [];
    viewModeElement = viewModeValue ? (
      <div className={labelClassName}>
        {params.options?.map(x => (
          <div className='rhf-checkboxgroup-item-label' key={x.value}>
            {selectedValues.includes(x.value) ? '✓ ' : ''}
            {x.label}
          </div>
        ))}
      </div>
    ) : (
      <div className={labelClassName}>&nbsp;</div>
    );
  }
  return viewInEditMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => <Checkbox.Group {...field} {...attributes} options={params.options} />}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
