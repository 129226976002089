import { DeleteOutlined } from '@ant-design/icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Tooltip } from 'antd';
import { CardRow, CardColumn } from 'components/common/card/Card';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { RHFAutoComplete } from 'components/common/react-hook-form/RHFAutoComplete';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { StyledDeleteButtonNoMargin } from 'components/common/styled';
import theme from 'theme';
import { CustomerDumpsterForm, LocationLookupRecord } from '../equipment.models';
import {
  FormSectionTitleUpper,
  LeadEquipmentRow,
  LeadSpaceWithSmallLeftGutter,
  ViewFieldLabelNoWrapRP,
} from '../../leads/lead.styled';

function Dumpster({
  index,
  dumpster,
  readOnly,
  editMode,
  control,
  sizes,
  haulers,
  deleteDumpster,
}: {
  index: number;
  dumpster: CustomerDumpsterForm;
  readOnly: boolean;
  editMode: boolean;
  control;
  sizes: LocationLookupRecord[];
  haulers: LocationLookupRecord[];
  deleteDumpster: (id?: string) => void;
}) {
  return (
    <LeadEquipmentRow
      gutter={[32, 0]}
      className={`card-form-row ${index === 0 ? 'card-form-row-first' : ''} ${index % 2 ? 'card-form-row-even' : ''}`}
    >
      <Col span={24}>
        <CardRow>
          <CardColumn xl={12} md={24} xs={24}>
            {editMode ? (
              <RHFInput
                name={`dumpsters[${index}].name`}
                editMode={editMode}
                control={control}
                defaultValue={dumpster?.name}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Name',
                }}
              />
            ) : (
              <LeadSpaceWithSmallLeftGutter $noGutter={readOnly} size='small'>
                {!readOnly && (
                  <StyledDeleteButtonNoMargin
                    size='small'
                    shape='circle'
                    icon={<DeleteOutlined />}
                    onClick={async () => {
                      // TODO: Use antd modal
                      // eslint-disable-next-line no-alert
                      if (deleteDumpster && window.confirm(`Are you sure you want to delete this record?`)) {
                        deleteDumpster(dumpster?.id);
                      }
                    }}
                  />
                )}
                <FormSectionTitleUpper>{dumpster?.name}</FormSectionTitleUpper>
              </LeadSpaceWithSmallLeftGutter>
            )}
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn xl={8} lg={12} xs={24}>
            <Row>
              <Col flex='10%'>
                <ViewFieldLabelNoWrapRP>Dumpster Serial:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFInput
                  name={`dumpsters[${index}].serialNumber`}
                  editMode={editMode}
                  control={control}
                  defaultValue={dumpster?.serialNumber}
                  rules={{ maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Serial Number',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={8} lg={12} xs={24}>
            <Row>
              <Col flex='10%'>
                <ViewFieldLabelNoWrapRP>Size:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFSelect
                  name={`dumpsters[${index}].dumpsterSizeId`}
                  editMode={editMode}
                  control={control}
                  defaultValue={dumpster?.dumpsterSizeId}
                  options={sizes.map<RHFOption>(o => ({ label: o.description, value: o.id }))}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Dumpster Size',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={8} lg={12} xs={24}>
            <Row>
              <Col flex='10%'>
                <ViewFieldLabelNoWrapRP>Hauler:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFAutoComplete
                  name={`dumpsters[${index}].dumpsterHauler`}
                  editMode={editMode}
                  control={control}
                  defaultValue={dumpster?.dumpsterHauler}
                  options={haulers.map<RHFOption>(o => ({ label: o.description, value: o.description }))}
                  filterOption={(inputValue, option: RHFOption) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Dumpster Hauler',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn xl={24}>
            <Row>
              <Col flex='10%' style={{ paddingRight: '1em' }}>
                <Tooltip title='Enter details about the location of the dumpster so that the driver can find it when on location.'>
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} pull='left' color={theme['@blue']} />
                  </div>
                </Tooltip>
                <ViewFieldLabelNoWrapRP>Description/Location Notes:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFTextArea
                  name={`dumpsters[${index}].notes`}
                  editMode={editMode}
                  control={control}
                  defaultValue={dumpster?.notes}
                  rules={{ maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    rows: 2,
                    placeholder: 'Description/Location Notes',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
        </CardRow>
      </Col>
    </LeadEquipmentRow>
  );
}

export default Dumpster;
