import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

export function RHFWrapper({
  children,
  rhfEnabled = true,
  name,
  rules,
  value,
  debug = false,
  errorTips = true,
}: {
  children: React.ReactNode;
  rhfEnabled?: boolean;
  name?: string;
  rules?: RegisterOptions;
  value?: string | ((formData: any) => string | null);
  debug?: boolean;
  errorTips?: boolean;
}) {
  const formHandler = useFormContext();

  const { formState } = formHandler;

  function resolveDotNotation(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev?.[curr], obj);
  }

  if (React.isValidElement(children)) {
    /*
     * if RHFWrapper is used within a FormProvider context,
     * formHandler will not be null. As long as formHandler
     * is present and RHF is not explicitly disabled, inject RHF
     */
    if (formHandler !== null && rhfEnabled && name) {
      return (
        <Controller
          name={name || 'invalid'}
          control={formHandler.control}
          rules={rules}
          render={({ field }) => {
            const fieldCopy: any = { ...field };

            if (typeof value === 'string') {
              fieldCopy.value = value;
            } else if (typeof value === 'function') {
              fieldCopy.value = value(fieldCopy.value);
            }

            const currentError: any = resolveDotNotation(name, formState.errors);

            return (
              <>
                {React.cloneElement(children, {
                  ...fieldCopy,
                })}

                <div style={{ flexBasis: '100%' }} />
                <div className='ant-form-explain'>{currentError?.message}</div>
              </>
            );
          }}
        />
      );
    }
    return <>{children}</>;
  }
  return <p>This RHFWrapper is wrapping an invalid JSX element!</p>;
}
