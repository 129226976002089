import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

export function RHFCheckboxGroupWrapper({
  children,
  rhfEnabled = true,
  name,
  rules,
  debug = false,
}: {
  children: React.ReactNode;
  rhfEnabled?: boolean;
  name?: string;
  rules?: RegisterOptions;
  debug?: boolean;
}) {
  const formHandler = useFormContext();

  if (React.isValidElement(children)) {
    /*
     * if RHFWrapper is used within a FormProvider context,
     * formHandler will not be null. As long as formHandler
     * is present and RHF is not explicitly disabled, inject RHF
     */
    if (formHandler !== null && rhfEnabled && name) {
      return (
        <Controller
          name={name || 'invalid'}
          control={formHandler.control}
          defaultValue={formHandler.getFieldState}
          render={({ field }) => React.cloneElement(children, { ...field })}
          rules={rules}
        />
      );
    }
    return <>{children}</>;
  }
  return <p>This RHFWrapper is wrapping an invalid JSX element!</p>;
}
