import { getDaysOfCurrentWeek } from 'components/schedule/schedule.service';
import {
  ScheduleWeekRangeSection,
  ScheduleWeekSelectionLeftButton,
  ScheduleWeekSelectionRightButton,
  ScheduleWeekSelectionSection,
} from 'components/schedule/schedule.styled';
import { useState } from 'react';

export function WeekStepper({
  handleOffset,
  futureOnly = false,
  existingOffset,
}: {
  handleOffset: (offset: number) => void;
  futureOnly?: boolean;
  existingOffset?: number | null;
}) {
  const [offset, setOffset] = useState(existingOffset || 0);

  const dateFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: undefined,
  } as const;

  const daysOfWeek = getDaysOfCurrentWeek(offset);

  const weekRange = (
    <span>
      {new Date(daysOfWeek[0].date).toLocaleDateString('en-us', dateFormat)} -{' '}
      {new Date(daysOfWeek[6].date).toLocaleDateString('en-us', dateFormat)}
    </span>
  );

  return (
    <ScheduleWeekSelectionSection>
      <ScheduleWeekSelectionLeftButton
        onClick={() => {
          if (futureOnly && offset <= 0) return;
          setOffset(offset - 1);
          handleOffset(offset - 1);
        }}
      />
      <ScheduleWeekRangeSection>{weekRange}</ScheduleWeekRangeSection>
      <ScheduleWeekSelectionRightButton
        onClick={() => {
          setOffset(offset + 1);
          handleOffset(offset + 1);
        }}
      />
    </ScheduleWeekSelectionSection>
  );
}
