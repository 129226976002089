import { Col, Row } from 'antd';

import DashboardActionCard from 'components/common/card/DashboardActionCard';
import DashboardMetricCard, { DashboardMetricDisplayType } from 'components/common/card/DashboardMetricCard';

import { ReactComponent as SmashTruckIcon } from 'assets/icons/smash-truck.svg';

function CardExamples() {
  function buttonClicked(e) {
    // eslint-disable-next-line no-alert
    alert(`The [${e?.currentTarget?.innerText}] button has been clicked!`);
  }

  // return the final chunk of jsx html for the component
  return (
    <>
      <Row justify='space-between' wrap>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.PercentageCircle}
            headerText='percentage card'
            footerText='footer text'
            value={0.69}
          />
        </Col>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.PercentageCircle}
            headerText='period pct card'
            selectedValueKey='Month'
            values={[
              { key: 'Week', value: 0.65 },
              { key: 'Month', value: 0.75 },
              { key: 'Year', value: 0.18 },
            ]}
          />
        </Col>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.Value}
            headerText='without footer'
            value='$500'
          />
        </Col>
      </Row>
      <Row justify='space-between' wrap>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.Value}
            headerText='metric card'
            value='$500'
            footerText='with footer text'
          />
        </Col>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.UnitSlider}
            headerText='UNIT SLIDER PER {key}'
            values={[
              { key: 'Week', value: 3 },
              { key: 'Month', value: 11 },
              { key: 'Year', value: 124 },
            ]}
          />
        </Col>
        <Col span='6' style={{ display: 'flex', justifyContent: 'center' }}>
          <DashboardActionCard text='Button Card' icon={<SmashTruckIcon />} onClick={e => buttonClicked(e)} enabled />
        </Col>
      </Row>
    </>
  );
}

export default CardExamples;
