import { CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { addMinutes } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import {
  JobTitleEnum,
  SampleAddress,
  SampleFormObject,
  SampleFormState,
  SampleGridRow,
  SampleRecord,
  SampleSearchState,
  StatusEnum,
} from './example.models';

const { Text } = Typography;

const CurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Takes the sample records and returns them with a delay
 * @returns a promise that will return the data from the data source.
 */
export function loadSampleRecordsFromDataSource(): Promise<SampleRecord[]> {
  const promise = new Promise<SampleRecord[]>(res => {
    setTimeout(() => {
      res(cloneDeep(sampleRecords));
    }, 1000);
  });
  return promise;
}

/**
 * Finds one of the sample records and returns it by id.
 * @param id the id of the record.
 * @returns one SampleRecord.
 */
export function loadSampleRecordByIdFromDataSource(id: number): Promise<SampleRecord | undefined> {
  const promise = new Promise<SampleRecord | undefined>(res => {
    setTimeout(() => {
      res(cloneDeep(sampleRecords.find(rec => rec.id === id)));
    }, 1000);
  });
  return promise;
}

/**
 * Gets the columns for the grid
 * @param url The url needed for links in the grid
 * @returns an array of columns
 */
export function getColumns(url: string): ColumnProps<SampleGridRow>[] {
  const columns = [
    {
      title: 'RecordId',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'NAME',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a?.fullName?.localeCompare(b?.fullName),
      render: (text: string, record: SampleGridRow) => <Link to={`${url}/${record.id}`}>{text}</Link>,
    },
    {
      title: 'BIRTH DATE',
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: (a, b) => {
        if (a?.birthDate?.getTime() === b?.birthDate?.getTime()) return 0;
        return a?.birthDate?.getTime() < b?.birthDate?.getTime() ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{record.birthDate.toDateString()}</Text>,
    },
    {
      title: 'SALARY',
      dataIndex: 'annualSalary',
      key: 'annualSalary',
      sorter: (a, b) => {
        if ((a?.annualSalary || 0) === (b?.annualSalary || 0)) return 0;
        return (a?.annualSalary || 0) < (b?.annualSalary || 0) ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{CurrencyFormatter.format(record?.annualSalary)}</Text>,
    },
    {
      title: 'US CITIZEN',
      dataIndex: 'isUsCitizen',
      key: 'isUsCitizen',
      sorter: (a, b) => {
        if (a?.isUsCitizen === b?.isUsCitizen) return 0;
        return !a?.isUsCitizen && a?.isUsCitizen ? -1 : 1;
      },
      render: (text: string, record: SampleGridRow) => <Text>{record.isUsCitizen ? <CheckOutlined /> : null}</Text>,
    },
    {
      title: 'ADDRESS',
      dataIndex: 'homeAddress',
      key: 'homeAddress',
      sorter: (a, b) => a?.homeAddress?.localeCompare(b?.homeAddress),
      render: (text: string, record: SampleGridRow) => (
        <a href={`https://maps.google.com/?q=${record.homeLatitude},${record.homeLongitude}`}>{text}</a>
      ),
    },
  ] as ColumnProps<SampleGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredResults(state: SampleSearchState, records: SampleRecord[] | undefined): SampleGridRow[] {
  let results: SampleGridRow[] = [];
  if (records && records.length > 0) {
    const filter = (state.filter || '').toLowerCase();
    results = records.map(
      rec =>
        ({
          id: rec.id,
          fullName: `${rec.lastName}, ${rec.firstName}`,
          birthDate: rec.birthDate,
          homeAddress: formatAddress(rec.homeAddress),
          isUsCitizen: rec.isUsCitizen,
          annualSalary: rec.annualSalary,
          homeLatitude: rec.homeAddress.lat,
          homeLongitude: rec.homeAddress.lng,
          active: rec.active,
        } as SampleGridRow)
    );
    results = results.filter(
      rec =>
        state.active === rec.active &&
        (rec.fullName?.toLowerCase()?.includes(filter) ||
          rec.homeAddress?.toLowerCase()?.includes(filter) ||
          rec.annualSalary?.toString()?.includes(filter))
    );
  }
  return results;
}

/**
 * Maps the model from the data source to a flat form object.
 * @param state the form state maintained by the component
 * @returns the form object
 */
export function mapFormObject(state: SampleFormState): SampleFormObject | null | undefined {
  if (state.initialized && state.record) {
    const r = state.record;
    const jobEnumKeys = Object.keys(JobTitleEnum);
    return {
      id: r.id,
      firstName: r.firstName,
      lastName: r.lastName,
      birthDate: r.birthDate,
      homeAddressName: r.homeAddress.name,
      homeAddressLine1: r.homeAddress.address1,
      homeAddressLine2: r.homeAddress?.address2,
      homeAddressCity: r.homeAddress.city,
      homeAddressState: r.homeAddress.state,
      homeAddressZip: r.homeAddress.zip,
      isUsCitizen: r.isUsCitizen,
      annualSalary: r.annualSalary,
      active: r.active,
      endDate: addMinutes(addMonths(r.timeStamp, 3), 90),
      startDate: addMinutes(addMonths(r.timeStamp, -1), 1023),
      status: r.id % 2 === 0 ? StatusEnum.InProcess : StatusEnum.OnHold,
      jobTitle: jobEnumKeys[r.id % 9] as JobTitleEnum,
    };
  }
  return null;
}

/**
 * Formats the address object as a string
 * @param address the address object
 * @returns a string representing the address
 */
export function formatAddress(address: SampleAddress): string {
  let addrString = '';
  if (address) {
    if (address.name) addrString += `${address.name}, `;
    addrString += `${address.address1}, `;
    if (address.address2) addrString += `${address.address2}, `;
    addrString += `${address.city}, ${address.state} ${address.zip}`;
  }
  return addrString;
}

export function getSampleRecords(): SampleRecord[] {
  return cloneDeep(sampleRecords);
}

// These are sample records used for testing.
const sampleRecords: SampleRecord[] = [
  {
    id: 1,
    firstName: 'Bob',
    lastName: 'Baggins',
    birthDate: new Date(1975, 10, 28),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 12, 30),
    homeAddress: {
      name: 'Dr Pepper Inc',
      address1: '123 Hometown way',
      address2: null,
      city: 'Indianapolis',
      state: 'IN',
      zip: 46030,
      lat: 39.7816455,
      lng: -86.0966122,
    },
    isUsCitizen: true,
    annualSalary: 93000,
    uniqueId: 'd8926ec5-b630-46bb-9a1c-9db42fbe9831',
    active: true,
  },
  {
    id: 2,
    firstName: 'Jerry',
    lastName: 'Flopsypants',
    birthDate: new Date(1988, 2, 12),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 1, 15),
    homeAddress: {
      name: "Jerry's Manufacturing",
      address1: '123 Factory Blvd way',
      address2: null,
      city: 'Beverly Hills',
      state: 'CA',
      zip: 90210,
      lat: 34.0713337,
      lng: -118.4194645,
    },
    isUsCitizen: true,
    annualSalary: 22312.43,
    uniqueId: '2ffa5ad5-6576-42ca-8f29-91546cdcab72',
    active: true,
  },
  {
    id: 3,
    firstName: 'Fred',
    lastName: 'Jetson',
    birthDate: new Date(2001, 5, 4),
    timeStamp: new Date(),
    appointmentTime: new Date(1900, 1, 1, 1, 15),
    homeAddress: {
      name: 'Honky Engineering',
      address1: '665 Dr Martin Luthor St',
      address2: 'Apt. 12',
      city: 'Knoxville',
      state: 'TN',
      zip: 37919,
      lat: 35.944969,
      lng: -84.0708764,
    },
    isUsCitizen: false,
    annualSalary: 16999.99,
    uniqueId: '787638b9-062e-415a-87e4-550d4693b373',
    active: true,
  },
];

export function getPathKey(redirectPath: string, defaultPath: string) {
  let path = document.location.pathname;
  if (path.endsWith('/')) path = path.substring(0, path.length - 1);
  if (path.indexOf('/') >= 0) path = path.substring(path.lastIndexOf('/') + 1);
  if (path === redirectPath) path = `/${path}`;
  return path || defaultPath;
}
