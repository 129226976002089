import { Col, Row } from 'antd';
import { ReactComponent as SmashTruckIcon } from 'assets/icons/smash-truck.svg';
import { ReactComponent as AgreementIcon } from 'assets/icons/agreements.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/invoice.svg';
import { ReactComponent as WorkOrderIcon } from 'assets/icons/work-order-location.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule-11.svg';
import PageTitle from 'components/common/PageTitle';
import DashboardActionCard from 'components/common/card/DashboardActionCard';
import DashboardMetricCard, { DashboardMetricDisplayType } from 'components/common/card/DashboardMetricCard';
import theme from 'theme';
import SmashboardTopList from './SmashboardTopList';
import SmashboardBottomList from './SmashboardBottomList';

function Dashboard() {
  const selectedGridRow = true;

  function buttonClicked(e) {
    // eslint-disable-next-line no-alert
    alert(`The [${e?.currentTarget?.innerText}] button has been clicked!`);
  }

  return (
    <>
      <PageTitle>THE SMASHBOARD</PageTitle>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.UnitSlider}
            color='#f2994a'
            headerText='DEMOS PER {key}'
            values={[
              { key: 'Week', value: 14 },
              { key: 'Month', value: 43 },
              { key: 'Quarter', value: 120 },
              { key: 'Year', value: 209 },
            ]}
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.UnitSlider}
            color={theme['@green']}
            headerText='TRIALS PER {key}'
            values={[
              { key: 'Week', value: 7 },
              { key: 'Month', value: 25 },
              { key: 'Quarter', value: 86 },
              { key: 'Year', value: 336 },
            ]}
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.UnitSlider}
            color={theme['@red']}
            headerText='SMASHES PER {key}'
            values={[
              { key: 'Hour', value: 4 },
              { key: 'Day', value: 52 },
            ]}
          />
          <DashboardMetricCard
            displayType={DashboardMetricDisplayType.Value}
            color={theme['@green']}
            headerText='REVENUE PER Hour'
            value='$100'
          />
          <DashboardMetricCard
            style={{ marginRight: '0px' }}
            displayType={DashboardMetricDisplayType.UnitSlider}
            color='#f2994a'
            headerText='REVENUE PER TRUCK'
            values={[
              { key: 'Week', value: '$1,200' },
              { key: 'Month', value: '$5,320' },
              { key: 'Quarter', value: 86 },
              { key: 'Year', value: '$83,010' },
            ]}
          />
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={24}>
          <SmashboardTopList />
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={24}>
          <SmashboardBottomList />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DashboardActionCard
            text='Schedule'
            icon={<ScheduleIcon />}
            enabled={!!selectedGridRow}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            text='Trucks'
            icon={<SmashTruckIcon />}
            enabled={!!selectedGridRow}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            text='Invoices'
            icon={<InvoiceIcon />}
            enabled={!!selectedGridRow}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            text='Agreements'
            icon={<AgreementIcon />}
            enabled={!!selectedGridRow}
            onClick={e => buttonClicked(e)}
          />
          <DashboardActionCard
            style={{ marginRight: '0px' }}
            text='New Smash Order'
            icon={<WorkOrderIcon />}
            enabled={!!selectedGridRow}
            onClick={e => buttonClicked(e)}
          />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
