/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import { Card, Col, Row, Tabs } from 'antd';
import { StyledButton, Text } from '../styled';

export const StyledCard = styled(Card)`
  box-shadow: 8px 9px 24px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;

  .ant-card-head {
    margin-bottom: 0;

    border-bottom: 0px;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: #dfdfdf;

    overflow: visible;

    .ant-card-head-wrapper {
      overflow: visible;

      .ant-card-head-title {
        overflow: visible;
      }
    }
  }

  .ant-card-body {
    position: relative;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const CardHeader = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0;
`;

export const CardHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentCard = styled(StyledCard)``;
export const ContentTabsCard = styled(StyledCard)`
  .ant-card-head-title {
    padding-bottom: 0px;
  }
`;

export const HeaderText = styled(Text)`
  font-weight: 700;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const TabText = styled(Text)`
  font-family: 'Montserrat-SemiBold';
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const HeaderTextBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const CardRow = styled(Row)``;

export const CardCol = styled(Col)`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export function CardColumn({
  children,
  style,
  xl,
  lg,
  md,
  sm,
  xs,
}: {
  children: any;
  style?: any;
  xl?: any;
  lg?: any;
  md?: any;
  sm?: number;
  xs?: number;
}) {
  return (
    <CardCol style={style} xl={xl || 12} xs={xs || 24} lg={lg} md={md} sm={sm}>
      {children}
    </CardCol>
  );
}

export function CardHeaderText(props) {
  return (
    <HeaderTextBox>
      <HeaderText level={2} style={{ color: props.color }}>
        {props.children}
      </HeaderText>
    </HeaderTextBox>
  );
}

export function TabCardHeaderText(props) {
  return (
    <HeaderTextBox>
      <TabText style={{ color: props.color }}>{props.children}</TabText>
    </HeaderTextBox>
  );
}

export const CardTabs = styled(Tabs)`
  overflow: visible;

  //margin-bottom: -10px;

  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      overflow: visible;

      .ant-tabs-nav-list {
        overflow: visible;
      }
    }

    //margin-bottom: -10px;

    overflow: visible;

    .ant-tabs-ink-bar {
      height: 0px;
    }

    .ant-tabs-tab {
      padding: 14px;
      background-color: #f2f2f2;
      border-color: #dfdfdf;
      border-style: solid;
      border-width: 1px;
      border-bottom-width: 0px;
      margin-left: 0px;
      border-radius: 7px 7px 0 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background-color: #ffffff;
      border-bottom-width: 0px;

      .ant-typography {
        color: #3b5998;
      }

      overflow-y: visible;
      box-shadow: 0px 3px 0px -1px #ffffff;
      overflow: visible;
    }
  }
`;

const LeftPadding = styled.div`
  margin-left: 16px;
`;

export function CardActionButton(props) {
  return (
    <LeftPadding>
      <StyledButton size='medium' onClick={props.onClick}>
        {props.children}
      </StyledButton>
    </LeftPadding>
  );
}
