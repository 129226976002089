import { gql } from '@apollo/client';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    email
    firstName
    lastName
    userType
    superAdmin
    lastName
    roleId
    franchiseRegionId
    #      profileImage
    userFranchises(where: { franchise: { is: { deleted: { equals: null }, AND: [{ inactive: { equals: null } }] } } }) {
      franchise {
        id
        name
      }
    }
    role {
      id
      userType
      permissions
    }
    franchiseRegion {
      id
      description
      franchises(where: { deleted: { equals: null }, AND: [{ inactive: { equals: null } }] }) {
        id
        name
      }
    }
    userRoles {
      role {
        id
        userType
        permissions
      }
    }
    createdAt
    updatedAt
  }
`;
