/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Col, Input, notification, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { usePerformQueryMutation, UtilityQueryResult } from 'generated/graphql';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { getSignedInUser } from 'services/userService';

function QueryTool() {
  const currentUser = getSignedInUser();
  if (!currentUser.superAdmin) window.document.location = '/';

  const result = useRef<UtilityQueryResult>({ columns: [], rows: [] } as UtilityQueryResult);
  const hasError = useRef(false);
  const [performQuery, performQueryResult] = usePerformQueryMutation({
    onCompleted: data => {
      if ((data?.performQuery?.results?.length || 0) >= 0) {
        result.current = (data?.performQuery?.results || [])[0] as UtilityQueryResult;
        hasError.current = false;
      }
      notification.info({
        message: 'Success',
        description: 'Executed Query Successfully',
        duration: 5,
      });
    },
    onError: err => {
      hasError.current = true;
      const props = Object.getOwnPropertyNames(err);
      const vals: string[] = [];
      props.forEach(c => {
        if (typeof err[c] === 'object') vals.push(JSON.stringify(err[c], null, 2));
        else vals.push(err[c]?.toString() || '');
      });
      result.current = {
        columns: props,
        rows: [{ values: vals }],
      } as UtilityQueryResult;
      notification.info({
        message: 'Success',
        description: 'Executed Query Successfully',
        duration: 5,
      });
    },
  });
  const formObj: {
    query: string;
    password: string;
  } = {
    query: `select id, description from dumpster_hauler`,
    password: ``,
  };

  const {
    handleSubmit,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = formData => {
    // when the form is submitted, call the mutation with the current data.
    let xyz = '';
    [109, 111, 110, 107, 101, 121].forEach(x => {
      xyz += String.fromCharCode(x);
    });
    if (formData.password === xyz) {
      performQuery({
        variables: {
          data: {
            query: formData.query,
          },
        },
      });
    } else {
      notification.error({
        message: 'PASSWORD INCORRECT!',
        description: 'This is a dangerous feature, so put the password in and say a prayer.',
        duration: 5,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <Card>
        <Row>
          <Col md={20} xs={24} style={{ padding: '0.25em' }}>
            <Title level={2}>SQL Query Utility</Title>
          </Col>
          <Col md={4} xs={24} style={{ padding: '0.25em' }}>
            <Input type='submit' size='large' value='EXECUTE QUERY' style={{ alignSelf: 'flex-end' }} />
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24} style={{ padding: '0.25em' }}>
            <RHFInput
              name='password'
              editMode
              control={control}
              defaultValue={formObj?.password}
              attributes={{
                type: 'password',
                style: {
                  width: '25em',
                  color: 'lightGreen',
                  backgroundColor: 'black',
                  fontFamily: 'consolas, courier new',
                },
                placeholder: 'PASSWORD TO EXECUTE SQL',
              }}
            />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col span={24}>
            <Typography>Query SQL:</Typography>
            <RHFTextArea
              name='query'
              editMode
              control={control}
              defaultValue={formObj?.query}
              attributes={{
                style: {
                  width: '100%',
                  color: 'lightGreen',
                  backgroundColor: 'black',
                  fontFamily: 'consolas, courier new',
                },
                placeholder: 'Database Query',
                rows: 6,
              }}
            />
          </Col>
        </Row>
        {hasError.current && result.current && (
          <Row>
            <Col span={24} style={{ overflowX: 'auto', overflowY: 'auto', height: '600px' }}>
              <table style={{ border: '1px black solid', borderCollapse: 'collapse' }}>
                <tbody>
                  {result.current?.rows?.map(r =>
                    r?.values?.map((c, i) => (
                      <tr key={i}>
                        <th
                          style={{
                            border: '1px black solid',
                            borderCollapse: 'collapse',
                            padding: '0.25em',
                            backgroundColor: 'lightGray',
                          }}
                        >
                          {result?.current?.columns && result?.current?.columns[i]}
                        </th>
                        <td
                          style={{
                            border: '1px black solid',
                            borderCollapse: 'collapse',
                            padding: '0.25em',
                            verticalAlign: 'top',
                          }}
                        >
                          <pre>{c}</pre>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        )}
        {!hasError.current && result.current && (
          <Row>
            <Col span={24} style={{ overflowX: 'auto', overflowY: 'auto', height: '600px' }}>
              <table style={{ border: '1px black solid', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    {result.current?.columns?.map(c => (
                      <th
                        key={c}
                        style={{
                          border: '1px black solid',
                          borderCollapse: 'collapse',
                          padding: '0.25em',
                          backgroundColor: 'lightGray',
                        }}
                      >
                        {c}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {result.current?.rows?.map((r, ridx) => (
                    <tr key={ridx}>
                      {r?.values?.map((c, cidx) => (
                        <td
                          key={`${ridx}.${cidx}`}
                          style={{
                            border: '1px black solid',
                            borderCollapse: 'collapse',
                            padding: '0.25em',
                            verticalAlign: 'top',
                          }}
                        >
                          {hasError.current ? <pre>{c}</pre> : c}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        )}
      </Card>
    </form>
  );
}

export default QueryTool;
