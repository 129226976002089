import { addMonths } from 'date-fns';
import { AgreementInput } from 'generated/graphql';
import { DbConstants } from 'generated/smt-constants';
import { dateOnlyFromDateTime, convertToNumber, formatDate } from 'services/formattingService';
import { getSignedInUser } from 'services/userService';
import { AgreementForm } from './agreement.models';

/**
 * The days of the week for the agreement recurring schedule checkboxes.
 */
export const DaysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * The colors that correspond the the different agreement statuses.
 */
export const AgreementStatusColors = [
  { status: DbConstants.AgreementStatus.Draft, color: '#000000' },
  { status: DbConstants.AgreementStatus.AwaitingApproval, color: '#eb8948' },
  { status: DbConstants.AgreementStatus.Approved, color: '#62a14a' },
  { status: DbConstants.AgreementStatus.Rejected, color: '#ae2c27' },
  { status: DbConstants.AgreementStatus.Active, color: '#4392c7' },
  { status: DbConstants.AgreementStatus.Inactive, color: '9f9f9f' },
];

/**
 * The responsize column spans for the form columns in the Agreement page.
 */
export const RspvSz = {
  formCol: { xs: 24, lg: 12 },
  spacerCol: { xs: 0, lg: 1 },
  lblCol: { xs: 24, md: 12, lg: 11, xl: 8 },
  ctrlCol: { xs: 24, md: 12, lg: 11, xl: 14 },
};

/**
 * Projects the data from the resolver into the form object.
 * @param locationId The service location id that this agreement belongs to.
 * @param agreement (optional) The agreement to project - could be undefined for a new record.
 * @returns The form object, populated with defaults for a new record.
 */
export function mapAgreementFormObject(
  locationId?: string,
  dumpsterCount?: number,
  agreement?
): AgreementForm | undefined {
  if (!agreement && !locationId) return undefined;

  return {
    locationId,
    id: agreement?.id,
    status: agreement?.agreementStatus || DbConstants.AgreementStatus.Draft,
    serviceType: agreement?.agreementServiceType || DbConstants.AgreementServiceType.Recurring,
    billingPreference: agreement?.billingPreference || DbConstants.BillingPreference.Email,
    paymentTermsNetDays: agreement?.paymentTermsNetDays,
    paymentSystemNotes: agreement?.paymentSystemNotes || undefined,
    taxExempt: agreement?.taxExempt || false,
    taxId: agreement?.taxId || undefined,
    billingFrequency: agreement?.billingFrequency || DbConstants.BillingFrequency.Monthly,
    poNumber: agreement?.poNumber || undefined,
    billingStartDate: dateOnlyFromDateTime(agreement?.billingStart).dateObj,
    agreementDate: dateOnlyFromDateTime(agreement?.agreementDate).dateObj,
    serviceStartDate: dateOnlyFromDateTime(agreement?.serviceStart).dateObj,
    expirationDate: dateOnlyFromDateTime(agreement?.expirationDate).dateObj,
    durationInMonths: agreement?.duration,
    salesRepUserId: agreement?.salesRepId || getSignedInUser()?.id || undefined,
    salesRepFullName: agreement?.salesRep || undefined,
    dumpsterCount: agreement?.dumpsterCount || dumpsterCount || 1,
    monthlyFee: agreement?.monthlyRate,
    extraSmashJobRate: agreement?.jobRate,
    projectRate: agreement?.dailyRate ? agreement?.dailyRate : agreement?.hourlyRate,
    projectRateUnit: agreement?.dailyRate ? 'day' : 'hour',
    agreementTotal: agreement?.agreementTotal,
    // convert the decimal percentage to a standard percentage
    fuelChargePercentage: (agreement?.fuelSurchargePct || 0) * 100.0,
    scheduleFrequencyInWeeks: agreement?.scheduleFrequencyInWeeks || 1,
    daysOfWeek: agreement?.daysOfWeek,
    automatedInvoices:
      agreement?.automatedInvoices === undefined || agreement?.automatedInvoices === null
        ? true
        : agreement?.automatedInvoices,
    convertMonthToMonth:
      agreement?.convertMonthToMonth === undefined || agreement?.convertMonthToMonth === null
        ? true
        : agreement?.convertMonthToMonth,
    notes: agreement?.notes || undefined,
  };
}

/**
 * Projects the data from the form into the data structure required by the mutation.
 * @param form The form object
 * @returns the AgreementInput object required by the mutation.
 */
export function mapFormObjectForMutation(form: AgreementForm): AgreementInput {
  // the agreement total for a recurring agreement is: ([Monthly Mee] * [Duration In Months]) * (100% + [Fuel Charge Percentage])
  let agreementTotal = (convertToNumber(form?.monthlyFee) || 0) * (convertToNumber(form?.durationInMonths) || 0);
  agreementTotal =
    Math.round(
      100.0 * (agreementTotal + ((convertToNumber(form?.fuelChargePercentage) || 0) / 100.0) * agreementTotal)
    ) / 100.0;

  // return the projected object for the mutation.
  return {
    locationId: form.locationId,
    id: form?.id,
    agreementStatus: form.status,
    agreementServiceType: form?.serviceType,
    billingPreference: form?.billingPreference,
    paymentTermsNetDays: form?.paymentTermsNetDays,
    paymentSystemNotes: form?.paymentSystemNotes || undefined,
    taxExempt: form?.taxExempt,
    taxId: form?.taxId || undefined,
    billingFrequency: form?.billingFrequency || undefined,
    poNumber: form?.poNumber || undefined,
    billingStart: formatDate(form?.billingStartDate, 'yyyy-MM-dd'),
    agreementDate: formatDate(form?.agreementDate, 'yyyy-MM-dd'),
    serviceStart: formatDate(form?.serviceStartDate, 'yyyy-MM-dd'),
    // The expiration data is the service start date plus the duration in months.
    expirationDate: formatDate(
      form?.serviceStartDate && form?.durationInMonths
        ? addMonths(form?.serviceStartDate, form?.durationInMonths)
        : undefined,
      'yyyy-MM-dd'
    ),
    duration: form?.durationInMonths,
    durationUnit: DbConstants.DurationUnit.Month,
    salesRepId: form?.salesRepUserId || getSignedInUser()?.id || undefined,
    salesRep: form?.salesRepFullName || undefined,
    dumpsterCount: form?.dumpsterCount,
    monthlyRate: convertToNumber(form?.monthlyFee),
    jobRate: convertToNumber(form?.extraSmashJobRate),
    hourlyRate: form?.projectRateUnit === 'hour' ? convertToNumber(form?.projectRate) : undefined,
    dailyRate: form?.projectRateUnit === 'day' ? convertToNumber(form?.projectRate) : undefined,
    // convert the percentage to a decimal
    fuelSurchargePct: (convertToNumber(form?.fuelChargePercentage) || 0) / 100.0,
    scheduleFrequencyInWeeks: form?.scheduleFrequencyInWeeks || 1,
    daysOfWeek: form?.daysOfWeek,
    automatedInvoices: form?.automatedInvoices,
    convertMonthToMonth: form?.convertMonthToMonth,
    notes: form?.notes || undefined,
    agreementTotal,
  } as AgreementInput;
}
