// ------------------------------ These are supposed to be data model types ------------------------------
export interface SampleRecord {
  id: number;
  firstName: string;
  lastName: string;
  birthDate: Date;
  timeStamp: Date;
  appointmentTime: Date;
  homeAddress: SampleAddress;
  isUsCitizen: boolean;
  annualSalary: number;
  uniqueId: string;
  active: boolean;
}

export interface SampleAddress {
  name: string | undefined | null;
  address1: string;
  address2?: string | undefined | null;
  city: string;
  state: string;
  zip: number;
  lat: number;
  lng: number;
}

// ------------------------------ These are state types ------------------------------
export interface SampleSearchState {
  // records: SampleRecord[];
  filter: string;
  active: boolean;
  // initialized: boolean;
}

export interface SampleFormState {
  record?: SampleRecord | null | undefined;
  isNew: boolean;
  inEditMode: boolean;
  initialized: boolean;
}

// ------------------------------ These are view model types ------------------------------
export interface SampleGridRow {
  id: number;
  fullName: string;
  birthDate: Date;
  homeAddress: string;
  isUsCitizen: boolean;
  annualSalary: number;
  homeLatitude: number;
  homeLongitude: number;
  active: boolean;
}

export type SampleFormObject = {
  id: number;
  firstName: string;
  lastName: string;
  birthDate: Date;
  homeAddressName: string | null | undefined;
  homeAddressLine1: string;
  homeAddressLine2: string | null | undefined;
  homeAddressCity: string;
  homeAddressState: string;
  homeAddressZip: number;
  isUsCitizen: boolean;
  annualSalary: number;
  jobTitle: JobTitleEnum;
  active: boolean;
  startDate: Date;
  endDate: Date;
  status: StatusEnum;
};

export enum JobTitleEnum {
  Engineer = 'ENG',
  Programmer = 'PROG',
  ServiceTechnician = 'SVCTECH',
  HumanResources = 'HR',
  ProjectManager = 'PM',
  AdministrativeAssistant = 'AA',
  ChiefOperatingOfficer = 'COO',
  ChiefTechnicalOfficer = 'CTO',
  ChiefExecutiveOfficer = 'CEO',
}

export enum StatusEnum {
  Open = 'OPEN',
  Closed = 'CLOSED',
  InProcess = 'INPROCESS',
  OnHold = 'HOLD',
}
