import {
  StyledSelectableTable,
  TableActiveToggleSwitch,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from 'components/common/card/TableCard';
import { useQuery } from '@apollo/client';
import { GET_FRANCHISE_TABLE_DATA } from 'graphql/query/franchise';
import { stateList } from 'components/common/StateList';
import { useState } from 'react';
import { Option } from '../common/styled';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';
import { getSelectedFranchise, setSelectedFranchise } from 'services/userService';
import { GetFranchiseTableDataQuery } from 'generated/graphql';
import useWindowSizeWatcher from 'hooks/useWindowSizeWatcher';
import { FranchiseSearchState } from './franchise.models';
import { getFilteredFranchiseResults, getFranchiseColumns, getFranchiseScrollData } from './franchise.service';
import { StateFilterDropdown, TableAddFranchiseButton } from './franchise.styled';
import PageTitle from 'components/common/PageTitle';

function FranchisesMain({ setUpdatedFranchise }: { setUpdatedFranchise: (FranchiseIdentifier) => void }) {
  const dimensions = useWindowSizeWatcher();
  const currentFranchise = getSelectedFranchise();

  // get the search state, which includes everything
  const [searchState, setSearchState] = useState<FranchiseSearchState>({
    filter: '',
    stateFilter: null,
    active: true,
    selectedRowKey: currentFranchise?.id,
  });

  const { loading, data } = useQuery<GetFranchiseTableDataQuery>(GET_FRANCHISE_TABLE_DATA);

  const activeData = getFilteredFranchiseResults(searchState, data);

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>FRANCHISE LIST</TableHeaderText>
        <TableAddFranchiseButton
          onClick={() =>
            // eslint-disable-next-line no-console
            console.log('This will create a franchise')
          }
        />
        <TableActiveToggleSwitch
          active={searchState.active}
          toggleActiveData={() => {
            setSearchState({ ...searchState, active: !searchState.active });
          }}
        />
      </TableHeaderLeft>
      <TableHeaderRight>
        <StateFilterDropdown
          defaultValue='State'
          onChange={value => {
            setSearchState({ ...searchState, stateFilter: value !== 'State' ? value : null });
          }}
        >
          {stateList.map(stateObj => (
            <Option key={stateObj.abr} value={stateObj.state}>
              {stateObj.state}
            </Option>
          ))}
        </StateFilterDropdown>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, filter: value });
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  return (
    <>
      <PageTitle>FRANCHISES</PageTitle>
      <TableCard title={header}>
        <StyledSelectableTable
          loading={loading}
          dataSource={activeData}
          columns={getFranchiseColumns()}
          pagination={false}
          rowKey='id'
          scroll={getFranchiseScrollData(loading, activeData, dimensions)}
          rowSelection={{
            selectedRowKeys: [searchState.selectedRowKey],
            type: 'radio',
            onChange: selRowKeys =>
              setSearchState({ ...searchState, selectedRowKey: selRowKeys?.length > 0 ? selRowKeys[0] : null }),
          }}
          onRow={record => ({
            onClick: () => {
              if (!record) return;

              const selectedFranchise = { id: record.id, name: record.name } as FranchiseIdentifier;
              const isCurrentlySelected = searchState.selectedRowKey === record.id;

              if (!isCurrentlySelected) {
                setSearchState({ ...searchState, selectedRowKey: record.id });
                setSelectedFranchise(selectedFranchise);
                if (setUpdatedFranchise) {
                  setUpdatedFranchise(selectedFranchise);
                }
              }
            },
          })}
        />
      </TableCard>
    </>
  );
}

export default FranchisesMain;
