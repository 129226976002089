import { gql } from '@apollo/client';

export const DAY_SCHEDULE_CARD = gql`
  query DayScheduleCard($trucksWhere: TruckWhereInput, $where: WorkOrderWhereInput) {
    trucks(where: $trucksWhere) {
      id
      number
      smashSerialNumber
      workOrders(where: $where) {
        id
        serviceType
        scheduleDate
        scheduleEndTime
        scheduleStartTime
        location {
          dumpsterCount
          name
          address {
            line1
          }
        }
      }
    }
  }
`;
