import { gql } from '@apollo/client';
import { CORE_LOCATION_FIELDS } from 'graphql/fragment/location';

export const GET_LOCATION_DETAILS = gql`
  ${CORE_LOCATION_FIELDS}
  query GetLocationDetails($id: String) {
    location(where: { id: $id }) {
      ...CoreLocationFields
    }

    locationContacts(where: { locationId: { equals: $id } }) {
      customerContact {
        id
        firstName
        lastName
        phone
        email
        preferredContactMethod
        contactTypes
        contactCategories
      }
    }
  }
`;
