import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetCustomersForListQuery } from 'generated/graphql';
import {
  StyledSelectableTable,
  TableActiveToggleSwitch,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from 'components/common/card/TableCard';
import {
  buildCustomersWhereClause,
  getCustomerColumns,
  getCustomerScrollData,
  getFilteredCustomerResults,
} from './customer.service';
import { CustomerGridRow, CustomerSearchState } from './customer.models';
import useWindowSizeWatcher from 'hooks/useWindowSizeWatcher';
import { CustomerTableCard } from './customer.styled';

function CustomerMain({
  gridHeight,
  activeToggle = true,
  redirect = true,
  selectable = false,
  setSelectedGridRow,
  selectedCustomerId,
}: {
  gridHeight?: number;
  activeToggle?: boolean;
  redirect?: boolean;
  selectable?: boolean;
  setSelectedGridRow?: (row: CustomerGridRow | null) => void;
  selectedCustomerId?: string;
}) {
  const dimensions = useWindowSizeWatcher();
  const history = useHistory();

  // get the search state, which includes everything
  const [searchState, setSearchState] = useState<CustomerSearchState>({
    filter: '',
    active: true,
    selectedRowKey: selectedCustomerId || null,
  });

  const { loading, error, data } = useGetCustomersForListQuery({
    variables: { where: buildCustomersWhereClause() },
  });

  const dataIsLoaded = !loading && !error;

  const displayRecords = dataIsLoaded ? getFilteredCustomerResults(searchState, data) : [];

  const searchHeaderSection = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>CUSTOMER LIST</TableHeaderText>
        {activeToggle && (
          <TableActiveToggleSwitch
            active={searchState.active}
            toggleActiveData={() => {
              setSearchState({ ...searchState, active: !searchState.active });
            }}
          />
        )}
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, filter: value });
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  return (
    <CustomerTableCard title={searchHeaderSection}>
      <StyledSelectableTable
        rowSelection={{
          selectedRowKeys: [searchState.selectedRowKey],
          type: 'radio',
          onChange: selRowKeys =>
            setSearchState({ ...searchState, selectedRowKey: selRowKeys?.length > 0 ? selRowKeys[0] : null }),
        }}
        loading={loading}
        dataSource={displayRecords}
        columns={getCustomerColumns()}
        pagination={false}
        rowKey='id'
        scroll={getCustomerScrollData(loading, displayRecords, {
          height: gridHeight || dimensions.height,
          width: dimensions.width,
        })}
        onRow={record => ({
          onDoubleClick: () => {
            if (redirect) history.push(`/customers/${record.id}`);
          },
          onClick: () => {
            if (selectable) {
              const isCurrentlySelected = searchState.selectedRowKey === record.id;
              setSearchState({
                ...searchState,
                selectedRowKey: isCurrentlySelected ? null : record.id,
              });
              if (record && setSelectedGridRow) {
                setSelectedGridRow(isCurrentlySelected ? null : (record as CustomerGridRow));
              }
            }
          },
        })}
      />
    </CustomerTableCard>
  );
}

export default CustomerMain;
