import invoicesData from 'fixtures/invoiceFixture';
import { DbConstants } from 'generated/smt-constants';

export function getInvoices() {
  return invoicesData;
}

export function getInvoice(key) {
  return invoicesData.find(c => c.key === key);
}

/**
 * A collection of invoice statuses along with the appropriate color
 */
export const InvoiceStatuses: { key?: string; status: string; color: string }[] = [
  {
    key: undefined,
    status: 'None',
    color: '#9f9f9f',
  },
  {
    key: DbConstants.InvoiceStatus.Pending,
    status: 'Pending',
    color: '#000000',
  },
  {
    key: DbConstants.InvoiceStatus.Emailed,
    status: 'Emailed',
    color: '#cb5c82',
  },
  {
    key: DbConstants.InvoiceStatus.Outstanding,
    status: 'Outstanding',
    color: '#eb8948',
  },
  {
    key: DbConstants.InvoiceStatus.OnHold,
    status: 'On Hold',
    color: '#8b5e9f',
  },
  {
    key: DbConstants.InvoiceStatus.Approved,
    status: 'Approved',
    color: '#62a14a',
  },
  {
    key: DbConstants.InvoiceStatus.Paid,
    status: 'Paid',
    color: '#4392c7',
  },
  {
    key: DbConstants.InvoiceStatus.BadDebt,
    status: 'Bad Debt',
    color: '#ae2c27',
  },
];
