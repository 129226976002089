import { Input } from 'antd';
import styled, { css } from 'styled-components';

const StyledInput = styled(Input).attrs(props => ({
  onFocus: event => {
    if (!props.$editmode) event.preventDefault();
  },
}))`
  padding: 10px;
  background-color: inherit;
  flex: 1;

  ${props =>
    !props.$editmode &&
    css`
      border-radius: 0;
      border: 1px solid transparent;
      border-bottom: 1px solid #e5e5e5;

      &:hover {
        border: 1px solid transparent;
        border-bottom: 1px solid #e5e5e5;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
        border-bottom: 1px solid #e5e5e5;
      }
    `}

  ${props =>
    props.$error &&
    css`
      ::placeholder {
        font-weight: bold;
        opacity: 0.6;
        color: red;
      }
    `}
`;

export default StyledInput;
