import { Progress } from 'antd';
import { ProgressBar, ProgressNode, ProgressNodeLabel } from 'components/common/multi-form/form.styled';
import theme from 'theme';

export function ScheduleProgressBar({ flow, step }: { flow: any; step: number }) {
  return (
    <ProgressBar>
      {flow.map(({ progressLabel, submission, confirmation }, index) => {
        if (progressLabel === undefined) return null;
        const last = index + 1 === flow.length;
        return (
          <ScheduleProgressNode
            key={index}
            label={progressLabel}
            step={index}
            currentStep={step}
            last={last}
            submission={submission}
            confirmation={confirmation}
          />
        );
      })}
    </ProgressBar>
  );
}

function ScheduleProgressNode({
  label,
  step,
  currentStep,
  last,
  submission,
  confirmation,
}: {
  label: string;
  step: number;
  currentStep: number;
  last: boolean;
  submission: boolean;
  confirmation: boolean;
}) {
  let barColor = theme['@progress-bar-empty-color'];
  if (step === currentStep) {
    if (last && confirmation) {
      barColor = theme['@green'];
    } else barColor = theme['@blue'];
  }
  if (step < currentStep) barColor = theme['@green'];

  return (
    <ProgressNode>
      <ProgressNodeLabel>{label}</ProgressNodeLabel>
      <Progress percent={100} showInfo={false} strokeColor={barColor} />
    </ProgressNode>
  );
}
