import { Typography } from 'antd';
import styled from 'styled-components';
import {
  StyledTable,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from '../common/card/TableCard';
import { useState } from 'react';
import { getCustomerInvoiceColumns, getFilteredCustomerInvoiceResults } from './customer.service';

export const Text = styled(Typography.Text)``;

function InvoicesCard({ loading, invoices }: { loading: boolean; invoices? }) {
  const [filter, setFilter] = useState('');

  const filteredInvoices = getFilteredCustomerInvoiceResults(filter, invoices);

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>INVOICES</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch onChange={setFilter} />
      </TableHeaderRight>
    </TableHeader>
  );

  return (
    <TableCard title={header}>
      <StyledTable
        columns={getCustomerInvoiceColumns()}
        dataSource={filteredInvoices}
        pagination={false}
        scroll={
          (filteredInvoices?.length || 0) > 10
            ? {
                y: 600,
              }
            : null
        }
        loading={loading}
        rowKey='id'
      />
    </TableCard>
  );
}

export default InvoicesCard;
