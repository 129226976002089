import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  font-family: 'Montserrat-Bold';
  font-weight: 600;
  font-size: 22px !important;
  margin-bottom: 0;
  color: #5b5b5b;
`;

function PageTitle({ children }) {
  return <StyledTitle level={2}>{children}</StyledTitle>;
}

export default PageTitle;
