import { gql } from '@apollo/client';
import { CORE_LEAD_FIELDS } from '../fragment/lead';

// export const GET_LEAD = gql`
//   ${CORE_LEAD_FIELDS}
//   query GetLead($id: String) {
//     customer(where: { id: $id }) {
//       ...CoreLeadFields
//     }
//   }
// `;

export const GET_LEAD = gql`
  query GetLead($id: String) {
    location(where: { id: $id }) {
      ...CoreLeadFields
    }
  }
`;

export const GET_LEADS = gql`
  ${CORE_LEAD_FIELDS}
  query GetLeads($where: LocationWhereInput) {
    locations(where: $where) {
      ...CoreLeadFields
    }
  }
`;
export const GET_LEADS_FOR_LIST = gql`
  query GetLeadsForList($where: LocationWhereInput) {
    locations(where: $where) {
      id
      customerId
      name
      addressId
      expectedMonthlyValue
      demoScheduled
      demoCompleted
      trialScheduled
      trialCompleted
      hubspotDealId
      inactive
      archived
      createdAt
      address {
        id
        line1
        line2
        city
        state
        zipCode
      }
      locationContacts(where: { customerContact: { is: { deleted: { equals: null } } } }) {
        customerContact {
          id
          firstName
          lastName
          deleted
        }
      }
      customer {
        name
        customerContacts(where: { deleted: { equals: null } }) {
          id
          firstName
          lastName
          primary
        }
      }
    }
  }
`;

export const GET_LEAD_METRICS = gql`
  query GetFranchiseLeadMetrics($franchiseId: String) {
    leadAggregateMetricView(where: { franchiseId: $franchiseId }) {
      franchiseId
      trialsScheduledAfterDemo6Mo
      demosCompleted6Mo
      trialsCompletedAndConverted6Mo
      trialsCompleted6Mo
      trialWorkOrders7Days
      trialWorkOrders30Days
      trialWorkOrders90Days
      demoWorkOrders7Days
      demoWorkOrders30Days
      demoWorkOrders90Days
    }
  }
`;

export const GET_LEADS_FOR_SCHEDULER = gql`
  query GetLeadsForScheduler($where: CustomerWhereInput) {
    customers(where: $where) {
      id
      name
      customerContacts {
        firstName
        lastName
        phone
        primary
        visibleInCustomerList
      }
      locations {
        id
        name
        expectedMonthlyValue
        leadNotes
        address {
          line1
          city
          state
          zipCode
        }
        locationContacts {
          customerContact {
            firstName
            lastName
            phone
            primary
          }
        }
      }
    }
  }
`;

export const GET_WORK_ORDERS = gql`
  query WorkOrders($where: WorkOrderWhereInput) {
    workOrders(where: $where) {
      number
      location {
        name
        id
        locationContacts {
          customerContact {
            firstName
            lastName
          }
        }
      }
      createdAt
      smashOperations {
        dumpster {
          id
        }
      }
      serviceType
    }
  }
`;

export const GET_DUMPSTER_LOOKUPS = gql`
  query GetDumpsterLookups {
    dumpsterHaulers(where: { deleted: { equals: null } }) {
      id
      description
    }

    dumpsterSizes(where: { deleted: { equals: null } }) {
      id
      description
    }
  }
`;

export const GET_ASSET_LOOKUPS = gql`
  query GetAssetLookups {
    wasteStreams(where: { deleted: { equals: null } }) {
      id
      description
    }

    assetTypes(where: { deleted: { equals: null } }) {
      id
      description
    }
  }
`;
