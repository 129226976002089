// eslint-disable-next-line prettier/prettier, no-useless-escape
const EmailValidationRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

/**
 * A loose phone validation routine that just checks the digits, and makes sure
 * there are 10 (xxx.xxx.xxxx), and that the area code doesn't start with a one.
 * @param phone the phone number to validate
 * @returns true if it's valid.
 */
export function isValidPhone(phone) {
  let digits = [...phone].filter(c => /^\d$/.test(c)).join('');
  digits = digits.length > 0 && digits[0] === '1' ? digits.substring(1) : digits;
  if (digits.length < 10 || digits.charAt(0) === '1') return false;
  return true;
}

/**
 * Checks an email address to see if it's valid.
 * @param email the email address to validate
 * @returns true if it's a valid email address
 */
export function isValidEmail(email) {
  return EmailValidationRegEx.test(email);
}

/**
 * Returns an email validation function for controls that returns a message if invalid and true otherwise.
 * @param message the message to return if invalid
 * @returns a validation function that returns a message instead of false.
 */
export function getIsValidEmailValidator(message) {
  return email => (isValidEmail(email) ? true : message);
}

/**
 * Returns a phone validation function for controls that returns a message if invalid and true otherwise.
 * @param message the message to return if invalid
 * @returns a validation function that returns a message instead of false.
 */
export function getIsValidPhoneValidator(message) {
  return phone => (isValidPhone(phone) ? true : message);
}
