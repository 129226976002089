/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import TextArea from 'antd/lib/input/TextArea';
import { RHFTextAreaParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

/**
 * An antd textarea control using a react-hook-form controller.
 * see: https://ant.design/components/input/ (AntD Input reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd input control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFTextArea(params: RHFTextAreaParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName = params.viewModeClassName || (params.hideBorder ? 'rhf-label-noborder' : 'rhf-label');
  const defVal = params.defaultValue || params.control?._defaultValues[params.name];
  const viewModeElement = defVal ? (
    <div className={labelClassName}>{defVal}</div>
  ) : (
    <div className={labelClassName}>&nbsp;</div>
  );
  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => <TextArea {...field} {...attributes} />}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
