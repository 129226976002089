export interface Customer {
  id: string;
  name: string;
  active: string;

  // address
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;

  contacts: Contact[];
}

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle?: string;
  type: ContactType;

  // preferences
  preferredContactMethod: ContactMethodType;

  contactCategories: Array<CustomerContactCategory>;

  // billing address
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
}

export enum ContactType {
  RELATIONSHIP = 'relationship',
  BILLING = 'billing',
}

export enum ContactMethodType {
  EMAIL = 'email',
  PHONE = 'sms',
}

export enum CustomerContactCategory {
  BILLING = 'billing',
  BOOKER = 'booking',
  HAULING = 'hauling',
  ONSITE = 'on-site',
}

export enum LocationContactCategory {
  UPCOMING = 'upcoming-smash',
  ONSITE = 'on-site',
  POST = 'post-smash',
}
