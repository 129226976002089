import { useHistory } from 'react-router-dom';

import { useGetTrucksQuery, useUpdateFranchiseTrucksMutation } from 'generated/graphql';
import { StyledTable, TableHeader, TableHeaderLeft, TableHeaderText } from 'components/common/card/TableCard';
import {
  buildTrucksWhereClause,
  getTruckColumns,
  getFilteredTruckResults,
  newTruckGridRow,
  mapFilteredTruckResult,
} from './truck.service';
import { TruckTableCard } from './truck.styled';
import { Modal, notification, Space } from 'antd';
import { useRef, useState } from 'react';
import theme from 'theme';
import { StyledButton, StyledPlusButtonNoMargin } from 'components/common/styled';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { TruckGridRow } from './truck.models';
import { autoCompare } from 'services/sortingService';
import { getSelectedFranchise, hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';

function Trucks({ readOnly }: { readOnly?: boolean }) {
  const canEdit = hasPermission(DbConstants.Permission.SettingsTrucksCrud); // TODO: add security
  const currentFranchise = getSelectedFranchise(); // TODO: add security

  const formRef = useRef(null);
  const existingRecords = useRef<TruckGridRow[]>([]);
  const newRecords = useRef<TruckGridRow[]>([]);
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null | undefined>(null);

  const { loading } = useGetTrucksQuery({
    variables: { where: buildTrucksWhereClause() },
    onCompleted: data => {
      existingRecords.current = getFilteredTruckResults(data);
    },
  });

  // set up react hook forms
  const {
    handleSubmit,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    control,
    register,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const [updateFranchiseTrucks] = useUpdateFranchiseTrucksMutation({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onCompleted: returnData => {
      newRecords.current.length = 0;
      existingRecords.current =
        returnData?.updateFranchiseTrucks?.trucks?.map((rec, i) => mapFilteredTruckResult(rec, i)) || [];
      reset();
    },
    onError: error => {
      notification.open({
        message: 'Error Updating Record.',
        description: error.message,
        duration: 5,
      });
    },
  });

  const onSubmit = formSubmitObj => {
    // // eslint-disable-next-line no-console
    // console.log(formSubmitObj);
    // // eslint-disable-next-line no-alert
    // alert(JSON.stringify(formSubmitObj));
    // when the form is submitted, call the mutation with the current data.
    updateFranchiseTrucks({
      variables: {
        data: {
          franchiseId: currentFranchise?.id,
          trucks: formSubmitObj.trucks
            .filter(x => !!x)
            .map(truck => ({
              id: truck?.id, // '00000000-0000-0000-0000-000000000000',
              franchiseId: currentFranchise?.id,
              smashSerialNumber: truck?.smashSerialNumber,
              vin: truck?.vin,
              plate: truck?.plate,
              make: truck?.make,
              model: truck?.model,
              year: truck?.year,
              size: truck?.size,
              number: truck?.number,
              notes: truck?.notes,
              outOfService: truck?.outOfService ? new Date() : null,
              deleted: truck?.deleted ? new Date() : null,
            })),
        },
        where: { franchiseId: currentFranchise?.id },
      },
    });
  };

  const onDeleteTruck = async (truckId: string) => {
    setIdToDelete(null);
    const truckToDelete = existingRecords.current.find(d => d.id === truckId);
    if (truckToDelete) {
      truckToDelete.deleted = true;
      onSubmit({ trucks: existingRecords.current });
    }
  };

  const deleteModal = (
    <Modal
      title='Delete Confirmation'
      visible={!!idToDelete}
      onOk={() => onDeleteTruck(idToDelete || '')}
      onCancel={() => setIdToDelete(null)}
    >
      <p>Are you sure you want to delete this truck?</p>
    </Modal>
  );

  const searchHeaderSection = (
    <TableHeader>
      <TableHeaderLeft>
        <Space size='middle'>
          <TableHeaderText>TRUCKS</TableHeaderText>
          {!readOnly && (
            <>
              {canEdit && !editMode && (
                <StyledPlusButtonNoMargin
                  size='medium'
                  shape='circle'
                  icon={<PlusOutlined />}
                  onClick={async () => {
                    if (!editMode) setEditMode(true);
                    const newRecord = newTruckGridRow(existingRecords.current.length);
                    newRecords.current = [...newRecords.current, newRecord];
                  }}
                />
              )}
              {canEdit && (
                <StyledButton
                  size='medium'
                  $color={theme[editMode ? '@green' : '@blue']}
                  onClick={async () => {
                    if (editMode) {
                      const isValidFormData = await trigger();
                      if (!isValidFormData) return;

                      (formRef?.current as any)?.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                      );
                    } else {
                      newRecords.current = [];
                    }
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? 'SAVE' : 'EDIT'}
                </StyledButton>
              )}
              {canEdit && editMode && (
                <StyledButton
                  size='medium'
                  $color={theme['@red']}
                  onClick={() => {
                    // reset the form to the way it was before.
                    newRecords.current = [];
                    reset();
                    setEditMode(false);
                  }}
                >
                  CANCEL
                </StyledButton>
              )}
            </>
          )}
        </Space>
      </TableHeaderLeft>
    </TableHeader>
  );

  let allTrucks = [...existingRecords.current.filter(x => !x.deleted)];
  allTrucks.sort((a, b) => autoCompare(a.internalIndex, b.internalIndex));
  if (newRecords.current.length > 0) allTrucks = [...allTrucks, ...newRecords.current];

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <TruckTableCard title={searchHeaderSection}>
        {deleteModal}
        <StyledTable
          loading={loading}
          dataSource={allTrucks}
          columns={getTruckColumns(!canEdit, editMode, control, register, setIdToDelete)}
          pagination={false}
          rowKey='id'
          onRow={record => ({
            onDoubleClick: () => {
              history.push(`/trucks/${record.id}`);
            },
          })}
        />
      </TruckTableCard>
    </form>
  );
}

export default Trucks;
