import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Select, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { HeaderText } from 'components/common/card/Card';
import StyledInput from 'components/common/input/BaseInput';
import StyledSelect from 'components/common/input/BaseSelect';
import { ContactType } from 'models/customer';
import theme from 'theme';
import {
  FormLabel,
  CustomerRow,
  CustomerHzPad40Row,
  CustomerContentCard,
  CustomerPlusButton,
  CustomerFlexCol,
  NationalAccountHeaderTextBox,
  CustomerHeaderTextBox,
  CustomerButton,
} from './customer.styled';
import CustomerContactInfoForm from './CustomerContactInfoForm';
import ContactPreferenceForm from './CustomerContactPreferenceForm';
import CustomerAddressForm from './CustomerAddressForm';
import { hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';
import { FormSectionTitle } from 'components/common/styled';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { RHFWrapper } from 'components/common/react-hook-form/RHFWrapper';
import { deepFilterTypename } from 'services/formattingService';
import { isDate } from 'date-fns/esm';

const { Option } = Select;

function CustomerDetailsForm({
  customer,
  submitCustomerEdit,
  editmode,
  setEditmode,
  validator,
}: {
  customer: any;
  submitCustomerEdit: any;
  editmode: boolean;
  setEditmode: any;
  validator: any;
}) {
  const canEdit = hasPermission(DbConstants.Permission.CustomerDetailsCrud);

  /*
   * OK COUPLE OF THINGS
   * Backend checks whether new contact ID exists or not to either trigger update or create
   * Backend ALSO validates whether new contact ID fits UUID format (32 or 36 characters in length)
   * New contacts need a temp ID even before the backend generates their 'actual' id, in order to handle state changes
   * This is the simplest way to create a 'temp uuid' without bringing in more dependencies
   */
  const [tempContactId, setTempContactId] = useState(100);

  const params: any = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const { fields: contactFields, prepend: prependContacts } = useFieldArray({
    name: 'customerContacts',
    control,
  });

  const contactFieldsWithIndex = contactFields.map((contact, index) => ({ contact, index }));

  // collect the different types of contacts.
  const billingContacts = (contactFieldsWithIndex as any)
    .filter(item => item.contact !== undefined)
    .filter(item => item.contact?.contactTypes?.includes(ContactType.BILLING));

  const relationshipContacts = (contactFieldsWithIndex as any)
    .filter(item => item.contact !== undefined)
    .filter(item => item.contact?.contactTypes?.includes(ContactType.RELATIONSHIP));

  const evenNumberOfRelContacts = relationshipContacts.length % 2 === 0;

  const handleNewContact = (type: 'billing' | 'relationship') => {
    const newContactId = `00000000-0000-0000-0000-000000000${tempContactId}`;
    prependContacts({
      id: newContactId,
      contactTypes: [type],
      preferredContactMethod: [],
      contactCategories: [],
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      jobTitle: '',
      primary: false,
    });

    setTempContactId(tempContactId + 1);
  };

  const onSubmitSuccess = formData => {
    const { name, inactive, customerContacts } = deepFilterTypename(formData);

    let inactiveComputed: any = null;

    if (isDate(inactive)) inactiveComputed = inactive;
    else if (inactive === 'inactive') inactiveComputed = new Date();

    submitCustomerEdit({
      variables: {
        data: {
          name,
          inactive: inactiveComputed,
          customerContacts,
        },
        where: { id: formData.id },
      },
    });
    setEditmode(!editmode);
    console.log('SUCCESS!:', deepFilterTypename(formData));
  };

  const onSubmitError = error => {
    console.log('ERROR!:', error);
  };

  const formHeader = (
    <Row gutter={36}>
      <CustomerFlexCol>
        <Space size='middle'>
          <CustomerHeaderTextBox>
            <HeaderText level={2}>COMPANY DETAILS</HeaderText>
          </CustomerHeaderTextBox>
          {canEdit && (
            <CustomerButton
              disabled={editmode && Object.keys(errors).length !== 0}
              size='medium'
              $color={theme[editmode ? '@green' : '@blue']}
              onClick={() => {
                if (editmode) {
                  console.log('SUBMITTED!');
                  return handleSubmit(onSubmitSuccess, onSubmitError)();
                }
                setEditmode(!editmode);
              }}
            >
              {editmode ? 'SAVE' : 'EDIT'}
            </CustomerButton>
          )}
          {canEdit && editmode && (
            <CustomerButton
              size='medium'
              $color={theme['@red']}
              onClick={() => {
                setEditmode(false);
              }}
            >
              CANCEL
            </CustomerButton>
          )}
        </Space>
      </CustomerFlexCol>
      <Col flex='auto' />
      <CustomerFlexCol>
        {customer.national ? (
          <NationalAccountHeaderTextBox>
            <HeaderText level={2}>NATIONAL ACCOUNT</HeaderText>
          </NationalAccountHeaderTextBox>
        ) : undefined}
      </CustomerFlexCol>
    </Row>
  );

  return (
    <CustomerContentCard title={formHeader}>
      <CustomerHzPad40Row gutter={36}>
        <CustomerFlexCol xl={12} xs={12}>
          <FormSectionTitle>Company Name:</FormSectionTitle>
          <RHFWrapper name='name' rules={{ required: 'Company Name is required' }}>
            <StyledInput placeholder='Company Name' $editmode={editmode} readOnly={!editmode} />
          </RHFWrapper>
        </CustomerFlexCol>
        <CustomerFlexCol xl={12} xs={12}>
          <Space>
            <FormLabel>STATUS</FormLabel>
            <RHFWrapper name='inactive' value={formValue => (formValue === null ? 'active' : 'inactive')} debug>
              <StyledSelect $editmode={editmode}>
                <Option value='active'>Active</Option>
                <Option value='inactive'>Inactive</Option>
              </StyledSelect>
            </RHFWrapper>
          </Space>
        </CustomerFlexCol>
      </CustomerHzPad40Row>

      <CustomerRow className='contact-card-row-header'>
        <FormLabel>RELATIONSHIP CONTACTS</FormLabel>
        <CustomerPlusButton
          size='medium'
          shape='circle'
          icon={<PlusOutlined />}
          onClick={() => handleNewContact('relationship')}
        />
      </CustomerRow>

      {relationshipContacts.map((item, i) => (
        <CustomerHzPad40Row
          gutter={36}
          key={item.contact.id}
          className={`contact-card-row${i % 2 ? ' contact-card-row-even' : ''}${
            i === 0 ? ' contact-card-row-first' : ''
          }`}
        >
          <Col xl={12} xs={24}>
            <CustomerContactInfoForm
              index={item.index}
              contact={item.contact}
              editmode={editmode}
              validator={validator}
            />
          </Col>
          <Col xl={12} xs={24}>
            <ContactPreferenceForm editmode={editmode} index={item.index} />
          </Col>
        </CustomerHzPad40Row>
      ))}

      <CustomerRow
        className={`billing-contact-card-row-header${
          (evenNumberOfRelContacts ? 0 : 1) % 2 ? ' contact-card-row-even' : ''
        }`}
      >
        <FormLabel>BILLING CONTACTS</FormLabel>
        <CustomerPlusButton
          size='medium'
          shape='circle'
          icon={<PlusOutlined />}
          onClick={() => handleNewContact('billing')}
        />
      </CustomerRow>

      {billingContacts.map((item, i) => (
        <CustomerHzPad40Row
          gutter={36}
          key={item.contact.id}
          className={`contact-card-row${(i + (evenNumberOfRelContacts ? 0 : 1)) % 2 ? ' contact-card-row-even' : ''}${
            i === 0 ? ' contact-card-row-first' : ''
          }`}
        >
          <Col xl={12} xs={24}>
            <CustomerContactInfoForm
              contact={item.contact}
              editmode={editmode}
              validator={validator}
              index={item.index}
            />
          </Col>
          <Col xl={12} xs={24}>
            <CustomerAddressForm
              id={item.contact.id}
              address={item.contact.address}
              editmode={editmode}
              validator={validator}
              index={item.index}
            />
          </Col>
        </CustomerHzPad40Row>
      ))}
    </CustomerContentCard>
  );
}

export default CustomerDetailsForm;
