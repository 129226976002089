import { gql } from '@apollo/client';

export const GET_INVOICE_SUMMARY_RECORDS = gql`
  query GetCustomerInvoiceSummaryRecords($where: InvoiceSummaryViewWhereInput) {
    invoiceSummaryViews(where: $where) {
      invoiceId
      agreementId
      locationId
      franchiseId
      customerId
      number
      locationName
      sent
      invoiceStatus
      dateIssued
      dateDue
      totalCharge
      totalPayment
      totalOwed
      serviceType
    }
  }
`;
