import styled from 'styled-components';
import theme from '../../theme';

const LoadingIndicatorContainer = styled.div`
  display: inline-block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${theme['@primary-color']};
    border-radius: 50%;
    animation: loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme['@primary-color']} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes loadingRing {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

function LoadingIndicator() {
  return (
    <LoadingIndicatorContainer>
      <div />
      <div />
      <div />
      <div />
    </LoadingIndicatorContainer>
  );
}

export default LoadingIndicator;
