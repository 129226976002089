import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Row, Col, notification, Space } from 'antd';
import { CardRow, CardCol } from 'components/common/card/Card';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { StyledDeleteButtonNoMargin, ViewFieldLabel } from 'components/common/styled';
import { UPDATE_LOCATION_BROKER } from 'graphql/mutation/customer';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { formatPhone } from 'services/formattingService';
import { getIsValidPhoneValidator, isValidPhone, getIsValidEmailValidator } from 'services/validationService';
import { ChildFormActivator, CustomerBrokerForm } from '../equipment.models';
import { mapBrokerFormObject } from '../equipment.service';
import { FormSectionTitleUpper } from '../equipment.styled';

function DumpsterBroker({
  readOnly,
  broker,
  locationId,
  editMode,
  setFormActions,
  brokerUpdated,
}: {
  readOnly: boolean;
  broker: CustomerBrokerForm;
  locationId: string;
  editMode: boolean;
  setFormActions?: (actions: ChildFormActivator) => void;
  brokerUpdated?: (broker: CustomerBrokerForm) => void;
}) {
  let formObj = broker;
  const formRef = useRef(null);
  const saving = useRef(false);

  // set up the mutation for upserting the broker
  const [updateLocationBroker, { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_LOCATION_BROKER);

  // set up react hook forms
  const {
    handleSubmit,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  if (setFormActions) {
    // because the buttons are not in this component, we need to give the parent component a way to send us messages.
    setFormActions({
      canAdd: false,
      add: () => false,
      submit: () => {
        // this submits the form by triggering the form submit event (the button isn't in the form)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formRef?.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      },
      reset: () => reset(),
      validate: async () => trigger(),
    } as ChildFormActivator);
  }

  if (saving.current && !mutationLoading) {
    if (mutationData && !mutationError) {
      // if the
      saving.current = false;
      formObj = mapBrokerFormObject(mutationData?.updateLocationBroker);

      if (brokerUpdated) {
        // update the parent component async - after the render has completed.
        const copyOfObjToSave = { ...mutationData?.updateLocationBroker };
        setTimeout(() => {
          brokerUpdated(copyOfObjToSave);
          notification.open({
            message: 'Saved',
            description: 'Updated Broker Successfully',
            duration: 5,
          });
          reset();
        }, 0);
      }
    } else if (mutationError) {
      saving.current = false;
      // for now, tell the user that there was an error saving this record
      notification.open({
        message: 'Error Updating Record.',
        description: mutationError.message,
        duration: 5,
      });
    }
  }

  const onSubmit = data => {
    // when the form is submitted, call the mutation with the current data.
    updateLocationBroker({
      variables: {
        data: { ...data },
        where: { locationId },
      },
    });
    saving.current = true;
  };

  const isNew = broker?.deleted || !broker?.contactFirstName;
  if (broker?.deleted) {
    formObj = mapBrokerFormObject({ locationId });
  }
  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <CardRow>
        <CardCol>
          {editMode || isNew ? (
            <FormSectionTitleUpper>DUMPSTER BROKER</FormSectionTitleUpper>
          ) : (
            <Space size='small' style={{ marginLeft: readOnly ? undefined : '-1.5em' }}>
              {!readOnly && (
                <StyledDeleteButtonNoMargin
                  size='small'
                  shape='circle'
                  icon={<DeleteOutlined />}
                  onClick={async () => {
                    // TODO: Use antd modal
                    // eslint-disable-next-line no-alert
                    if (window.confirm(`Are you sure you want to delete this record?`))
                      onSubmit({ ...mapBrokerFormObject(broker), deleted: new Date() });
                  }}
                />
              )}
              <FormSectionTitleUpper>DUMPSTER BROKER</FormSectionTitleUpper>
            </Space>
          )}
        </CardCol>
      </CardRow>
      <CardRow>
        <CardCol md={12} xs={24}>
          <Row>
            <Col>
              <ViewFieldLabel>Broker Contact:</ViewFieldLabel>
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFInput
                emptyLabel
                name='contactFirstName'
                editMode={editMode}
                control={control}
                defaultValue={formObj?.contactFirstName}
                rules={{ required: 'First Name is required' }}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'First Name*',
                }}
              />
            </Col>
            <Col
              xl={12}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFInput
                emptyLabel
                name='contactLastName'
                editMode={editMode}
                control={control}
                defaultValue={formObj?.contactLastName}
                rules={{ required: 'Last Name is required' }}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Last Name*',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={10}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFInput
                emptyLabel
                name='contactPhone'
                editMode={editMode}
                control={control}
                defaultValue={formatPhone(formObj?.contactPhone)}
                rules={{
                  required: 'Phone Number is required',
                  validate: getIsValidPhoneValidator('Invalid Phone Number'),
                }}
                attributes={{
                  onBlur: (e: Event) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const ctrl = e?.currentTarget as any;
                    const v = ctrl?.value;
                    if (v && isValidPhone(v)) {
                      setValue('contactPhone', formatPhone(v));
                    }
                  },
                  style: { width: '100%' },
                  placeholder: 'Phone Number*',
                }}
              />
            </Col>
            <Col
              xl={14}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFInput
                emptyLabel
                name='contactEmail'
                editMode={editMode}
                control={control}
                defaultValue={formObj?.contactEmail}
                rules={{ required: 'Email is required', validate: getIsValidEmailValidator('Invalid Email Address') }}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Email*',
                }}
              />
            </Col>
          </Row>
        </CardCol>
        <CardCol md={12} xs={24}>
          <Row>
            <Col
              xl={4}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <ViewFieldLabel>Broker:</ViewFieldLabel>
            </Col>
            <Col
              xl={20}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFInput
                name='brokerName'
                editMode={editMode}
                control={control}
                defaultValue={formObj?.brokerName}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Broker Name',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={4}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <ViewFieldLabel>Notes:</ViewFieldLabel>
            </Col>
            <Col
              xl={20}
              sm={24}
              xs={24}
              style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.4em', paddingBottom: '0.4em' }}
            >
              <RHFTextArea
                name='notes'
                editMode={editMode}
                control={control}
                defaultValue={formObj?.notes}
                attributes={{
                  style: { width: '100%' },
                  rows: 2,
                  placeholder: 'Notes',
                }}
              />
            </Col>
          </Row>
        </CardCol>
      </CardRow>
    </form>
  );
}

export default DumpsterBroker;
