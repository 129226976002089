import styled from 'styled-components';
import { Switch } from 'antd';
import { useState } from 'react';
import brandStandardColors from 'theme';

const SwitchLabel = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const StyledSwitch = styled(Switch)`
  margin: 0 16px;
  background: ${props => props.$color};
  display: inline;
`;

function ActiveToggleSwitch({ active, toggleActiveData }) {
  const onColor = brandStandardColors['@green'];
  const offColor = brandStandardColors['@red'];
  const [color, setColor] = useState(true);
  const changeColor = () => {
    setColor(!color);
  };

  const returnColor = () => {
    if (color) {
      return onColor;
    }
    return offColor;
  };

  return (
    <div>
      <SwitchLabel>INACTIVE</SwitchLabel>
      <StyledSwitch
        size='small'
        onClick={() => toggleActiveData()}
        defaultChecked={active}
        onChange={changeColor}
        $color={returnColor}
      />
      <SwitchLabel>ACTIVE</SwitchLabel>
    </div>
  );
}

export default ActiveToggleSwitch;
