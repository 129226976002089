/* eslint-disable react/destructuring-assignment */
import styled from 'styled-components';
import { StyledSearch, StyledTable, TableHeaderRight } from 'components/common/card/TableCard';
import StyledSelect from 'components/common/input/BaseSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
/*
 * Some ugly CSS resets only currently necessary because our common components
 * come with the baggage of magic pixel margins. In the future we should probably
 * refactor so that spacing is handled in the layout abstractions, and not in the components
 * themselves. It will make our components more reusable.
 */
export const StyledTableWithPaging = styled<typeof StyledTable>(StyledTable)`
  .ant-pagination {
    padding-right: 10px !important;
  }
`;

export const StyledSelectNoMargin = styled<typeof StyledSelect>(StyledSelect)`
  margin: 0px;
`;

export const StyledSearchNoMargin = styled<typeof StyledSearch>(StyledSearch)`
  margin-left: 0px;
  height: auto;
`;

export function TableSearch(props) {
  return (
    <StyledSearchNoMargin
      size='large'
      suffix={<FontAwesomeIcon icon={faMagnifyingGlass} />}
      placeholder={props.placeholder || 'Search'}
      onChange={e => {
        props.onChange(e.target.value);
      }}
      style={{ ...props.style }}
    />
  );
}

export const TableHeaderRightWithSpacing = styled<typeof TableHeaderRight>(TableHeaderRight)`
  display: flex;
  gap: 24px;
`;
