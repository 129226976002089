import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { CardColumn, CardRow } from 'components/common/card/Card';
import { AssetInput, GetAssetLookupsQuery, UpdateLocationAssetInput } from 'generated/graphql';
import { UPDATE_LOCATION_ASSETS } from 'graphql/mutation/customer';
import { GET_ASSET_LOOKUPS } from 'graphql/query/lead';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { uuidGenerator } from 'services/dataGenerationService';
import { autoCompare } from 'services/sortingService';
import { ChildFormActivator, CustomerAssetForm, LocationLookupRecord } from '../equipment.models';
import { mapAssetFormArray, mapAssetFormObject, populateAssetLookups } from '../equipment.service';
import OtherEquipment from './OtherEquipment';

function OtherEquipmentList({
  readOnly,
  locationId,
  assets,
  editMode,
  setFormActions,
  assetsUpdated,
}: {
  readOnly: boolean;
  locationId: string;
  assets: CustomerAssetForm[];
  editMode: boolean;
  setFormActions: (actions: ChildFormActivator) => void;
  assetsUpdated?: (assets: CustomerAssetForm[]) => void;
}) {
  let formList = mapAssetFormArray(assets);

  const formRef = useRef(null);
  const saving = useRef(false);
  const newRecords = useRef<CustomerAssetForm[]>([]);
  const [newRecordMode, setNewRecordMode] = useState<number>(0);
  const wasteStreams: LocationLookupRecord[] = [];
  const assetTypes: LocationLookupRecord[] = [];

  // set up the update assets mutation
  const [updateLocationAssets, { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_LOCATION_ASSETS);

  const { loading, data } = useQuery<GetAssetLookupsQuery>(GET_ASSET_LOOKUPS);
  if (!loading && data) populateAssetLookups(data, wasteStreams, assetTypes);

  // set up react-hook-form
  const {
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    register,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  if (setFormActions) {
    setFormActions({
      canAdd: true,
      add: () => {
        // if the parent form presses the add button, this is fired. We add a record to the new records collection.
        const newRecord = mapAssetFormObject({
          locationId,
          id: uuidGenerator.generate(),
          name: `Asset ${(assets?.filter(x => !x.deleted)?.length || 0) + (newRecords.current?.length || 0) + 1}`,
        });
        newRecords.current = [...newRecords.current, newRecord];
        setNewRecordMode(newRecordMode + 1);
        return true;
      },
      submit: () => {
        // trigger the form submit (because the button isn't in the form)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formRef?.current as any)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      },
      reset: () => {
        // reset the form to the way it was before.
        newRecords.current = [];
        reset();
      },
      validate: async () => trigger(),
    });
  }

  if (saving.current && !mutationLoading) {
    if (mutationData && !mutationError) {
      saving.current = false;

      if (assetsUpdated) {
        newRecords.current = [];

        // update the parent async, after the render has completed.
        formList = mapAssetFormArray(mutationData?.updateLocationAssets?.locationAssets || []);
        const copyOfObjToSave = [...formList];
        setTimeout(() => {
          assetsUpdated(copyOfObjToSave);
          notification.open({
            message: 'Saved',
            description: 'Updated Equipment Successfully',
            duration: 5,
          });
          reset();
        }, 0);
      }
    } else if (mutationError) {
      saving.current = false;

      notification.open({
        message: 'Error Updating Record.',
        description: mutationError.message,
        duration: 5,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const onSubmit = (formData: any) => {
    const assetsToSave = formData.assets.map(x => {
      const tmp = {
        ...x,
        assetTypeId:
          typeof x?.assetTypeId === 'number' || x?.assetTypeId?.length > 0 ? Number(x.assetTypeId) : undefined,
        wasteStreamId:
          typeof x?.wasteStreamId === 'number' || x?.wasteStreamId?.length > 0 ? Number(x.wasteStreamId) : undefined,
        underContract: x?.underContract === 'true',
      } as AssetInput;
      if (newRecords.current.length > 0 && newRecords.current.find(y => y.id === x.id))
        return {
          ...tmp,
          locationId,
          id: undefined,
        } as AssetInput;
      return tmp;
    });

    const outgoingRequestArgs = {
      variables: {
        data: {
          locationAssets: assetsToSave,
          locationId,
        } as unknown as UpdateLocationAssetInput,
        where: { locationId },
      },
    };

    updateLocationAssets(outgoingRequestArgs);
    saving.current = true;
  };

  // combine the new assets with the existing ones and show them to the user.
  let allAssets = [...formList.filter(x => !x.deleted)];
  allAssets.sort((a, b) => autoCompare(a.name, b.name));
  if (newRecords.current.length > 0) allAssets = [...allAssets, ...newRecords.current];

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      {allAssets.length > 0 ? (
        allAssets.map((asset, idx) => (
          <React.Fragment key={asset.id}>
            <OtherEquipment
              index={idx}
              asset={asset}
              readOnly={readOnly}
              editMode={editMode}
              wasteStreams={wasteStreams}
              assetTypes={assetTypes}
              control={control}
              deleteAsset={assetId => {
                const assetToDelete = formList.find(d => d.id === assetId);
                if (assetToDelete) {
                  assetToDelete.deleted = new Date();
                  onSubmit({ assets: formList });
                }
              }}
            />
            <input type='hidden' {...register(`assets[${idx}].id`)} value={asset.id} />
            <input type='hidden' {...register(`assets[${idx}].locationId`)} value={locationId} />
          </React.Fragment>
        ))
      ) : (
        <CardRow>
          <CardColumn>There is no equipment for this lead.</CardColumn>
        </CardRow>
      )}
    </form>
  );
}

export default OtherEquipmentList;
