import styled from 'styled-components';
import { TableAddButton } from 'components/common/card/TableCard';
import { StyledDropdown } from 'components/common/styled';
import theme from 'theme';

export const TableAddFranchiseButton = styled(TableAddButton)`
  margin-top: 5px;
  color: ${theme['@blue']};
`;

export const StateFilterDropdown = styled(StyledDropdown)`
  display: inline-block;
  width: 150px;
  font-weight: 600;
  option {
    display: inline-block;
    width: 150px;
    font-weight: 600;
  }
`;
