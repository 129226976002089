// customization list
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

const antdTheme = {
  // '@primary-color': '#ff0000',
  '@primary-color': '#3B5998',
  '@info-color': '#0173A8',
  '@success-color': '#27AE60',
  '@warning-color': '#E2B93B',
  '@error-color': '#EB5757',
  '@progress-bar-empty-color': '#d9d9d9',
  '@another-gray-df': '#dfdfdf',

  '@border-radius-base': '4px',
  '@card-radius': '11px',
  '@component-background': '#ffffff',

  '@body-background': '#fff',
  '@font-family': "'Open Sans', 'Montserrat', 'DejaVu Sans', 'Verdana', 'sans‑serif'",
  '@layout-footer-background': '#494949',
  '@layout-footer-padding': '11px 20px',
  '@text-color': '#5B5B5B',
  '@white': '#fff',
  '@link-color': '#3B5998',
  '@menu-highlight-color': '#3B5998',
  '@menu-item-boundary-margin': '0',
  '@table-header-bg': 'linear-gradient(360deg, #D9D9D9 -88.33%, rgba(217, 217, 217, 0) 71.67%);',
  '@card-head-background': '#f2f2f2',
  '@card-head-font-size': '20px',
  '@card-head-padding': '12px',
  '@card-shadow': '2px 4px 6px rgba(0, 0, 0, 0.20)',
};

// custom variables (non existent in ant design)
const newVariables = {
  '@header-font-family': "'Oswald', 'Avenir Next Condensed', 'Arial Narrow', 'MS UI Gothic', 'sans‑serif'",
};

const brandStandardColors = {
  '@green': '#79BC7F',
  '@red': '#BB1E10',
  '@blue': '#3B5998',
  '@grey': '#F2F2F2',
};

export default {
  ...antdTheme,
  ...newVariables,
  ...brandStandardColors,
};
