/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql } from '@apollo/client';
import { Card, Col, Input, notification, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { apolloClient } from 'apollo/client';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { getSignedInUser } from 'services/userService';

function GraphQLTool() {
  const currentUser = getSignedInUser();
  if (!currentUser.superAdmin) window.document.location = '/';

  const result = useRef<string>('');
  const formObj: {
    query: string;
    params: string;
  } = {
    query: `query Users($where: UserWhereInput) {
  users(where: $where) {
    id
    email
    firstName
    lastName
    userType
    roleId
  }
}`,
    params: `{
  "where": {
    "superAdmin": {
      "equals": true
    }
  }
}`,
  };

  const {
    handleSubmit,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    reset,
    trigger,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = async formData => {
    try {
      if (formData?.query?.startsWith('mutation')) {
        const response = await apolloClient.mutate({
          mutation: gql`
            ${formData.query}
          `,
          variables: JSON.parse(formData.params),
        });
        result.current = JSON.stringify(response, null, 2);
      } else {
        const response = await apolloClient.query({
          query: gql`
            ${formData.query}
          `,
          variables: JSON.parse(formData.params),
        });
        result.current = JSON.stringify(response, null, 2);
      }
    } catch (e) {
      result.current = JSON.stringify(e, null, 2);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <Card>
        <Row>
          <Col md={20} xs={24} style={{ padding: '0.25em' }}>
            <Title level={2}>GraphQL Query Utility</Title>
          </Col>
          <Col md={4} xs={24} style={{ padding: '0.25em' }}>
            <Input type='submit' size='large' value='EXECUTE QUERY' style={{ alignSelf: 'flex-end' }} />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col span={24}>
            <Typography>Operation:</Typography>
            <RHFTextArea
              name='query'
              editMode
              control={control}
              defaultValue={formObj?.query}
              attributes={{
                style: {
                  width: '100%',
                  color: 'lightGreen',
                  backgroundColor: 'black',
                  fontFamily: 'consolas, courier new',
                },
                placeholder: 'Operation',
                rows: 6,
              }}
            />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col span={24}>
            <Typography>Variables:</Typography>
            <RHFTextArea
              name='params'
              editMode
              control={control}
              defaultValue={formObj?.params}
              attributes={{
                style: {
                  width: '100%',
                  color: 'lightGreen',
                  backgroundColor: 'black',
                  fontFamily: 'consolas, courier new',
                },
                placeholder: 'Variables',
                rows: 6,
              }}
            />
          </Col>
        </Row>
        {result.current && (
          <Row>
            <Col span={24} style={{ overflowX: 'auto', overflowY: 'auto', height: '600px' }}>
              <Typography>Output:</Typography>
              <pre
                style={{
                  width: '100%',
                  height: '20em',
                  color: 'lightGreen',
                  backgroundColor: 'black',
                  fontFamily: 'consolas, courier new',
                }}
              >
                {result.current}
              </pre>
            </Col>
          </Row>
        )}
      </Card>
    </form>
  );
}

export default GraphQLTool;
