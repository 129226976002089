/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control, FieldValues } from 'react-hook-form';

export function processAttributesAndValidator(
  name: string,
  control: Control<FieldValues, any>,
  editMode?: boolean,
  attributes?: any
) {
  let validator: JSX.Element | undefined;
  let attrCopy: any = {};
  if (editMode) {
    attrCopy = attributes ? { ...attributes } : {};
    const state = control.getFieldState(name);
    if (!attrCopy?.status) {
      // eslint-disable-next-line no-debugger
      // if (state.error) debugger;
      attrCopy.status = state.error ? 'error' : undefined;
    }

    validator = state.error ? (
      <div className='ant-form-explain'>
        {state?.error?.message?.toString() || state.error?.type?.toString() || 'invalid'}
      </div>
    ) : undefined;
  }
  return { validator, attributes: attrCopy };
}
