import React, { useEffect, useState } from 'react';
import { Route, Router, useHistory, useLocation } from 'react-router-dom';
import { ScheduleProgressBar } from 'components/common/multi-form/FormProgressBar';
import { ButtonContainer, ScheduleBackButton, ScheduleNextButton } from './form.styled';

export function FormRouter({
  children,
  selectedFlow,
  formState,
  rootRoute,
  setFormStep,
}: {
  children: React.ReactNode;
  selectedFlow: string;
  formState: any;
  rootRoute: string;
  setFormStep: (step: number) => void;
}) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const skipTo = queryParams.get('skipTo') || null;

  const [flows, setFlows] = useState<any>(() => {
    const flowsTemp = {};

    React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        child.props.flows.forEach(f => {
          flowsTemp[f] = flowsTemp[f] || [];
          flowsTemp[f].push({
            name: child.props.name,
            validate: child.props.validate,
            validation: child.props.validation,
            progressLabel: child.props.progressLabel,
            submission: child.props.submission || null,
            confirmation: child.props.confirmation || false,
          });
        });
      }
    });

    return flowsTemp;
  });

  const currentFlow = flows[selectedFlow];

  const skipToValid = currentFlow.findIndex(flow => flow.name === skipTo) > -1;

  const firstInFlowIndex = skipToValid ? currentFlow.findIndex(flow => flow.name === skipTo) : 0;
  // TODO: refactor with useParam()
  const currentNodeName = history.location.pathname.split(`${rootRoute}/`)[1] || currentFlow[firstInFlowIndex].name;
  const currentStep = currentFlow.findIndex(flow => flow.name === currentNodeName);

  useEffect(() => {
    setFormStep(currentStep);
  }, [currentStep, setFormStep]);

  const doStep = (increment: boolean) => {
    const currentNode = currentFlow[currentStep];
    const nextStep = currentStep + (increment ? 1 : -1);

    if (!currentNode.validate || !increment || currentNode.validate(formState)) {
      const nextNode = currentFlow[nextStep];

      if (nextNode.validation && !formState.selections[nextNode.validation]) return;

      if (nextStep < firstInFlowIndex) return;

      history.push(`/${rootRoute}/${currentFlow[nextStep].name}${history.location.search}`);
    }
  };

  const routes = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const { name } = child.props;

      const isFirstInFlow = currentFlow[firstInFlowIndex].name === name;

      return (
        <>
          {isFirstInFlow && (
            <Route exact path={`/${rootRoute}/`}>
              {child}
            </Route>
          )}
          <Route path={`/${rootRoute}/${name}`}>{child}</Route>
        </>
      );
    }
  });

  return (
    <>
      <ScheduleProgressBar flow={currentFlow} step={currentStep} />
      <Router history={history}>{routes}</Router>
      {!currentFlow[currentStep].confirmation && (
        <ButtonContainer>
          <ScheduleBackButton onClick={() => doStep(false)} $formStep={currentStep}>
            Back
          </ScheduleBackButton>
          <ScheduleNextButton onClick={() => doStep(true)} $submission={currentFlow[currentStep].submission}>
            {currentFlow[currentStep].submission ? currentFlow[currentStep].submission : 'Next'}
          </ScheduleNextButton>
        </ButtonContainer>
      )}
    </>
  );
}
