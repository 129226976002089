import { Col, Row, Space, Spin } from 'antd';
import { TableCard } from 'components/common/card/TableCard';
import { ViewFieldLabel } from 'components/common/styled';
import styled from 'styled-components';

export const LeadFlexCol = styled(Col)`
  display: flex;
`;

export const LeadEquipmentRow = styled(Row)`
  padding: 1em;
`;

export const LeadSpaceWithLeftMargin = styled(Space)`
  margin-left: 1em;
`;
export const LeadSpaceWithSmallLeftGutter = styled(Space)`
  ${props => (props.$noGutter ? '' : 'margin-left: -2em;')}
`;

export const LoadingContainer = styled<typeof Spin>(Spin)`
  max-height: 100% !important;
  position: fixed !important;
`;

export const LeadListTableCard = styled(TableCard)`
  min-width: 1090px;
`;

export const ViewFieldLabelNoWrapRP = styled(ViewFieldLabel)`
  white-space: nowrap;
  margin-right: 1em;
`;

export const FormSectionTitleUpper = styled(ViewFieldLabel)`
  font-family: 'Montserrat-Bold';
  text-transform: uppercase;
`;
