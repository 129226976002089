/* eslint-disable @typescript-eslint/no-explicit-any */
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Input, notification, Row, Space, Switch, Typography } from 'antd';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFSwitch } from 'components/common/react-hook-form/RHFSwitch';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { useChangeUserToolInfoMutation, useGetUserUtilityLookupsQuery } from 'generated/graphql';
import { DbConstants } from 'generated/smt-constants';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { autoCompare } from 'services/sortingService';
import { getSignedInUser, isCorporateUser, resetUserSessionData } from 'services/userService';

const { Title } = Typography;

function CustomizeUserForm() {
  let regions: any[] = [];
  let franchises: any[] = [];
  let allRoles: any[] = [];
  let availableRoles: any[] = [];
  const currentUser = getSignedInUser();
  const [showInactive, setShowInactive] = useState(false);

  if (!(currentUser?.superAdmin || false)) window.document.location = '/';

  const [changeUserRole] = useChangeUserToolInfoMutation({
    onCompleted: () => {
      notification.info({
        message: 'Saved',
        description: 'Updated User Successfully - Logout and Login, or do a hard refresh',
        duration: 5,
      });

      resetUserSessionData();

      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  });

  const { loading, error, data } = useGetUserUtilityLookupsQuery();
  if (!loading && data && !error) {
    franchises = data.franchises.map(x => ({ id: x.id, value: x.name || x.dba, inactive: x.inactive }));
    franchises.sort((a, b) => autoCompare(a.value, b.value));

    regions = data.franchiseRegions.map(x => ({ id: x.id, value: x.description }));
    regions.sort((a, b) => autoCompare(a.value, b.value));

    allRoles = data.roles.map(x => ({ id: x.id, userType: x.userType, value: x.description }));
    allRoles.sort((a, b) => autoCompare(a.value, b.value));
  }

  const formObj: {
    userId: string;
    emailAddress: string;
    roleIds: string[];
    isCorporate: boolean;
    regionId?: number;
    franchiseIds: string[];
    franchises: string[];
    permissions: string[];
  } = {
    userId: currentUser?.id,
    emailAddress: currentUser?.email,
    roleIds: currentUser?.roles?.slice(0),
    isCorporate: isCorporateUser(),
    regionId: currentUser?.regionId,
    franchiseIds: currentUser?.franchises.map(f => f.id),
    franchises: currentUser?.franchises.map(f => f.name),
    permissions: currentUser?.permissions.map(
      p => DbConstants.Permission._KeyValuePairs.find(x => x.key === p)?.value || p
    ),
  };

  const {
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { touchedFields, isValid, isDirty, dirtyFields, isValidating, errors },
    watch,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = formData => {
    if (!formData.isCorporate && formData.franchiseIds.length === 0) {
      notification.info({
        message: 'No Franchises!',
        description: "You don't want to save a franchise user without any franchises!",
        duration: 5,
      });
    } else {
      // when the form is submitted, call the mutation with the current data.
      changeUserRole({
        variables: {
          userChanges: {
            userType: formData.isCorporate ? DbConstants.UserType.Corporate : DbConstants.UserType.Franchise,
            franchiseRegionId: formData.regionId ? Number(formData.regionId) : undefined,
            roleIds: formData.roleIds,
            franchiseIds: formData.franchiseIds,
          },
        },
      });
    }
  };

  let [isCorporate] = watch(['isCorporate']);

  if (isCorporate === undefined) isCorporate = formObj?.isCorporate || false;

  const roleUserTypeToMatch = isCorporate ? DbConstants.UserType.Corporate : DbConstants.UserType.Franchise;
  availableRoles = allRoles.filter(r => !r.userType || r.userType === roleUserTypeToMatch);

  return (
    <form onSubmit={handleSubmit(onSubmit /* , onInvalid */)}>
      <Card>
        <Row>
          <Col md={20} xs={24} style={{ padding: '0.25em' }}>
            <Title level={2}>Change Current User Utility</Title>
          </Col>
          <Col md={4} xs={24} style={{ padding: '0.25em' }}>
            <Space size='middle' style={{ alignSelf: 'flex-end' }}>
              <Switch
                checkedChildren='Show Inactive'
                unCheckedChildren='Hide Inactive'
                onChange={val => {
                  setShowInactive(val);
                }}
              />
              <Input type='submit' size='large' value='SAVE' />
            </Space>
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col md={10} xs={24} style={{ padding: '0.25em' }}>
            <Typography>Current User:</Typography>
            <RHFInput
              name='emailAddress'
              editMode
              control={control}
              defaultValue={formObj?.emailAddress}
              attributes={{
                style: { width: '100%' },
                placeholder: 'Email Address',
                disabled: true,
              }}
            />
          </Col>
          <Col md={14} xs={24} style={{ padding: '0.25em' }}>
            <Typography>Role(s):</Typography>
            <RHFSelect
              name='roleIds'
              editMode
              control={control}
              defaultValue={formObj?.roleIds}
              options={availableRoles.map<RHFOption>(o => ({ label: o.value, value: o.id }))}
              attributes={{
                mode: 'multiple',
                style: { width: '100%' },
                placeholder: 'Roles',
              }}
            />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col md={4} xs={24} style={{ padding: '0.25em' }}>
            <Typography>User Type:</Typography>
            <RHFSwitch
              name='isCorporate'
              editMode
              control={control}
              defaultValue={formObj?.isCorporate || false}
              checkedText='Corporate'
              uncheckedText='Franchise'
            />
          </Col>
          <Col md={6} xs={24} style={{ padding: '0.25em' }}>
            <Typography>Region:</Typography>
            <RHFSelect
              name='regionId'
              editMode
              control={control}
              defaultValue={formObj?.regionId || ''}
              options={[{ label: '', value: '' }, ...regions.map<RHFOption>(o => ({ label: o.value, value: o.id }))]}
              attributes={{
                disabled: !isCorporate,
                style: { width: '100%' },
                placeholder: 'Select Region',
              }}
            />
          </Col>
          <Col md={14} xs={24} style={{ padding: '0.25em' }}>
            <Typography>Franchise(s):</Typography>
            <RHFSelect
              name='franchiseIds'
              editMode
              control={control}
              defaultValue={formObj?.franchiseIds}
              options={franchises
                .filter(f => (showInactive ? true : !f.inactive))
                .map<RHFOption>(o => ({ label: `${o.value}${o.inactive ? ' [INACTIVE]' : ''}`, value: o.id }))}
              attributes={{
                disabled: isCorporate,
                mode: 'multiple',
                style: { width: '100%' },
                placeholder: 'Franchises',
                autoComplete: 'none',
                optionFilterProp: 'children',
              }}
            />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col span={24} style={{ padding: '0.25em' }}>
            <Typography>Franchises:</Typography>
            <RHFTextArea
              name='franchisesText'
              editMode
              control={control}
              defaultValue={
                formObj.isCorporate && !formObj.regionId ? 'ALL FRANCHISES' : formObj?.franchises?.join(', ')
              }
              attributes={{
                style: { width: '100%' },
                disabled: true,
                rows: 6,
              }}
            />
          </Col>
        </Row>
        <Row justify='space-evenly'>
          <Col span={24} style={{ padding: '0.25em' }}>
            <Typography>Permissions:</Typography>
            <RHFTextArea
              name='permissionsText'
              editMode
              control={control}
              defaultValue={formObj?.permissions?.join(', ')}
              attributes={{
                style: { width: '100%' },
                disabled: true,
                rows: 6,
              }}
            />
          </Col>
        </Row>
      </Card>
    </form>
  );
}

export default CustomizeUserForm;
