import styled from 'styled-components';
import { TableCard } from 'components/common/card/TableCard';
import { Col, Row } from 'antd';
import { ContentCard, HeaderTextBox } from 'components/common/card/Card';
import { StyledButton } from 'components/common/styled';

export const EmployeeListTableCard = styled(TableCard)`
  min-width: 1090px;
`;

export const EmployeeFlexCol = styled(Col)`
  display: flex;
`;

export const EmployeeHeaderTextBox = styled(HeaderTextBox)``;

export const EmployeeButton = styled(StyledButton)`
  margin-bottom: 2px;
`;

export const EmployeeContentCard = styled(ContentCard)`
  /* add any classes here */
`;

export const EmployeeCardRow = styled(Row)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;
