import { Checkbox, Col, Grid } from 'antd';
import StyledCheckbox from 'components/common/input/BaseCheckbox';
import { FormLabel, CustomerRow } from './customer.styled';
import { RHFCheckboxGroupWrapper } from 'components/common/react-hook-form/RHFCheckboxGroupWrapper';
import CustomerContactInfoForm from './CustomerContactInfoForm';

function ContactPreferenceForm({
  editmode,
  locationContact = false,
  index,
}: {
  editmode: boolean;
  locationContact?: boolean;
  index?: number;
}) {
  const path =
    index !== undefined
      ? `${locationContact ? 'locationContacts' : 'customerContacts'}.${index}.${
          locationContact ? 'customerContact.' : ''
        }`
      : '';

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const CheckboxGroup = Checkbox.Group;

  const customerContactCategoryOptions = ['billing', 'booking', 'hauling', 'on-site'];
  const locationContactCategoryOptions = ['upcoming-smash', 'on-site', 'post-smash'];

  // not a huge fan of this but there's got to be a decent way to reduce these checkboxes to a map function
  const formattedNames = {
    billing: 'Billing',
    booking: 'Booker',
    hauling: 'Hauling',
    'on-site': 'On-Site',
    'upcoming-smash': 'Upcoming Smash',
    'post-smash': 'Post Smash',
  };

  return (
    <>
      <CustomerRow>
        <Col span={24}>
          <FormLabel>CONTACT PREFERENCES</FormLabel>
        </Col>
      </CustomerRow>
      <CustomerRow>
        <Col span={24}>
          <RHFCheckboxGroupWrapper debug name={`${path}preferredContactMethod`}>
            <CheckboxGroup style={{ display: 'flex' }}>
              <Col span={!screens.xs && screens.xl ? 4 : 8}>
                <StyledCheckbox $editmode={editmode} value='email'>
                  Email
                </StyledCheckbox>
              </Col>
              <Col span={!screens.xs && screens.xl ? 4 : 8}>
                <StyledCheckbox $editmode={editmode} value='sms'>
                  Text
                </StyledCheckbox>
              </Col>
            </CheckboxGroup>
          </RHFCheckboxGroupWrapper>
        </Col>
      </CustomerRow>
      <CustomerRow>
        <Col span={24}>
          <FormLabel>COMMUNICATIONS</FormLabel>
        </Col>
      </CustomerRow>
      <CustomerRow>
        <RHFCheckboxGroupWrapper debug name={`${path}contactCategories`}>
          <CheckboxGroup style={{ display: 'flex' }}>
            {(locationContact ? locationContactCategoryOptions : customerContactCategoryOptions).map(category => (
              <Col span={8} key={category}>
                <StyledCheckbox $editmode={editmode} value={category}>
                  {formattedNames[category]}
                </StyledCheckbox>
              </Col>
            ))}
          </CheckboxGroup>
        </RHFCheckboxGroupWrapper>
      </CustomerRow>
    </>
  );
}

export default ContactPreferenceForm;
