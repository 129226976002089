import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  StyledSelectableTable,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from 'components/common/card/TableCard';

import { EmployeeGridRow, EmployeeSearchState } from './employee.models';
import { getEmployeeColumns, getEmployeeScrollData, getFilteredEmployeeResults } from './employee.service';
import { EmployeeListTableCard } from './employee.styled';
import { useGetFranchiseEmployeeListQuery } from 'generated/graphql';
import { getSelectedFranchise, hasPermission } from 'services/userService';
import { DbConstants } from 'generated/smt-constants';
import useWindowSizeWatcher from 'hooks/useWindowSizeWatcher';

function EmployeeList({
  redirect = true,
  gridHeight,
  selectedLocationId,
}: {
  redirect?: boolean;
  gridHeight?: number;
  selectedLocationId?: string;
}) {
  const canMaipulateEmployees = hasPermission(DbConstants.Permission.SettingsEmployeesCrud);
  const dimensions = useWindowSizeWatcher();
  const currentFranchise = getSelectedFranchise();
  const [searchState, setSearchState] = useState<EmployeeSearchState>({
    filter: '',
    selectedRowKey: selectedLocationId || null,
  });

  // get the search state, which includes everything
  const { loading, error, data } = useGetFranchiseEmployeeListQuery({
    variables: {
      id: currentFranchise?.id,
    },
  });

  const dataIsLoaded = !loading && !error;

  const displayRecords = dataIsLoaded ? getFilteredEmployeeResults(searchState, data) : [];

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>EMPLOYEES</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, filter: value });
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  const history = useHistory();

  // return the final chunk of jsx html for the component
  return (
    <EmployeeListTableCard title={header}>
      <StyledSelectableTable
        rowKey='id'
        loading={loading}
        dataSource={displayRecords}
        columns={getEmployeeColumns()}
        pagination={false}
        scroll={getEmployeeScrollData(loading, displayRecords, {
          height: gridHeight || dimensions.height,
          width: dimensions.width,
        })}
        onRow={record => ({
          onDoubleClick: () => {
            if (redirect) history.push(`/employees/${record.id}`);
          },
        })}
      />
    </EmployeeListTableCard>
  );
}

export default EmployeeList;
