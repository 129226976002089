import { FormSection, StyledButton, ViewField, ViewFieldLabel } from 'components/common/styled';
import { LeftCircleFilled, RightCircleFilled, EnvironmentOutlined } from '@ant-design/icons';
import { CardRow } from 'components/common/card/Card';
import theme from '../../theme';
import { Select } from 'antd';
import styled, { css } from 'styled-components';

export const DetailsViewFieldLabel = styled(ViewFieldLabel)`
  margin-right: 1em;
  width: auto;
`;

export const DetailsFormSection = styled(FormSection)`
  justify-content: start;
`;

export const DetailsViewField = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  color: #a3a3a3;
`;

export const ScheduleFormSection = styled(FormSection)`
  justify-content: space-around;
`;

export const ScheduleTopFormSection = styled(FormSection)`
  justify-content: space-between;
`;

export const ScheduleWeekSelectionSection = styled.span`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
`;

export const ScheduleWeekSelectionLeftButton = styled(LeftCircleFilled)`
  color: #3b5998;
  margin-right: 1em;
  font-size: 200%;
`;

export const ScheduleWeekSelectionRightButton = styled(RightCircleFilled)`
  color: #3b5998;
  margin-left: 1em;
  font-size: 200%;
`;

export const ScheduleWeekRangeSection = styled.div`
  text-align: center;
  min-width: 250px;
  margin: auto;
`;

export const ScheduleTableTitle = styled.span`
  font-family: 'Montserrat';
  font-weight: 600;
  color: #000000;
  display: block;
`;

export const ScheduleTableHeaderP = styled.p`
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  display: block;
`;

export const CardRowBlock = styled(CardRow)`
  display: block;
`;

export const ScheduleSelectButton = styled(StyledButton)`
  :focus {
    background-color: #a1a1aa;
    border-color: #a1a1aa;
    color: white;
  }

  width: 80%;
  background-color: #a1a1aa;
  border-color: #a1a1aa;

  ${props =>
    props.$selected &&
    css`
      background-color: ${theme['@blue']};
      border-color: ${theme['@blue']};
      :focus {
        background-color: ${theme['@blue']};
        border-color: ${theme['@blue']};
      }
    `}
`;

export const ScheduleSelect = styled(Select)``;

export const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmationDetailsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 6em 0 6em 4em;
`;

export const ConfirmationTimeContainer = styled.h2`
  color: ${theme['@blue']};
`;

export const ConfirmationIcon = styled(EnvironmentOutlined)`
  font-size: 8em;
`;

export const FormStepContainer = styled.div`
  ${props =>
    !props.$stepsToRenderOn?.includes(props?.$step) &&
    css`
      display: none;
    `}
`;

export const ScheduleSelectsContainer = styled.div`
  display: flex;
  column-gap: 2em;
`;
