/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnProps } from 'antd/lib/table';
import { InvoiceSummaryView, InvoiceSummaryViewWhereInput } from 'generated/graphql';
import { getSelectedFranchise } from 'services/userService';
import { InvoiceGridRow } from './invoice.models';
import styled from 'styled-components';
import { Typography } from 'antd';
import { formatCurrency } from 'services/formattingService';
import { InvoiceStatuses } from 'services/invoiceService';

const { Text } = Typography;

export function buildWhereClause(): InvoiceSummaryViewWhereInput {
  let franchiseId = '00000000-0000-0000-0000-000000000000';
  const franchise = getSelectedFranchise();
  if (franchise) {
    franchiseId = franchise.id;
  }
  return {
    franchiseId: {
      equals: franchiseId,
    },
  } as InvoiceSummaryViewWhereInput;
}

const SendSuccessIndicator = styled.span`
  height: 21px;
  width: 21px;
  background-color: #79bc7f;
  border-radius: 50%;
  display: inline-block;
`;

export function getColumns(url: string): ColumnProps<InvoiceGridRow>[] {
  const columns = [
    {
      title: 'SENT',
      dataIndex: 'sent',
      render: (sent: boolean, record: InvoiceGridRow) => (sent ? <SendSuccessIndicator /> : ''),
      width: '10%',
      sorter: (a, b) => Number(a.sent) - Number(b.sent),
    },
    {
      title: 'INVOICE ID',
      dataIndex: 'number',
      render: (text: string, record: InvoiceGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: 'SERVICE LOCATIONS',
      dataIndex: 'location',
      render: (text: string, record: InvoiceGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: 'ISSUED',
      dataIndex: 'issued',
      render: (text: string, record: InvoiceGridRow) => <Text>{text}</Text>,
      sorter: (a, b) => a.issued.localeCompare(b.issued),
    },
    {
      title: 'DUE',
      dataIndex: 'due',
      render: (due: number, record: InvoiceGridRow) => <Text>{due}</Text>,
      sorter: (a, b) => a.due.localeCompare(b.due),
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      render: (total: number, record: InvoiceGridRow) => <Text>{formatCurrency(total)}</Text>,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      render: (balance: number, record: InvoiceGridRow) => <Text>{formatCurrency(balance)}</Text>,
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (text: string, record: InvoiceGridRow) => {
        const invoiceStatus = InvoiceStatuses.find(x => x?.key?.toLowerCase() === record?.status) || InvoiceStatuses[0];
        return (
          <div
            style={{
              fontFamily: 'Open Sans Bold',
              color: invoiceStatus.color || '#9f9f9f',
            }}
          >
            {invoiceStatus.status || 'none'}
          </div>
        );
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
  ] as ColumnProps<InvoiceGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredResults(
  state: { searchString: string; dateFilter: string; serviceTypeFilter: string },
  records: InvoiceSummaryView[]
): InvoiceGridRow[] {
  let results: InvoiceGridRow[] = [];
  if (records && records.length > 0) {
    const filterString = (state.searchString || '').toLowerCase();
    const { dateFilter, serviceTypeFilter } = state;
    results = records.map(
      view =>
        ({
          sent: view.sent,
          number: view.number,
          location: view.locationName,
          issued: new Date(view.dateIssued).toLocaleDateString(),
          due: new Date(view.dateDue).toLocaleDateString(),
          total: view.totalCharge,
          balance: view.totalOwed,
          status: view.invoiceStatus,
          serviceType: view.serviceType,
        } as InvoiceGridRow)
    );

    results = results.filter(record => {
      const filterConditions =
        record.number?.toString().toLowerCase().includes(filterString) ||
        record.location?.toLowerCase()?.includes(filterString) ||
        // record.issued?.includes(filterString) ||
        record.due?.includes(filterString) ||
        record.total?.toString().includes(filterString) ||
        record.balance?.toString().includes(filterString) ||
        record.status?.toString().includes(filterString);
      return filterConditions;
    });

    if (dateFilter !== '') {
      results = results.filter(record => new Date(record.issued).getDate() === new Date(dateFilter).getDate());
    }

    if (serviceTypeFilter !== '') {
      results = results.filter(
        record =>
          // console.log(`${record.serviceType} VS. ${serviceTypeFilter}`);
          record.serviceType === serviceTypeFilter
      );
    }
  }
  return results;
}
