import {
  CardActionButton,
  CardColumn,
  CardHeader,
  CardHeaderLeft,
  CardHeaderText,
  CardRow,
  ContentCard,
} from 'components/common/card/Card';
import { FormSection, ViewField, FormSectionTitle } from 'components/common/styled';
import { Customer } from 'generated/graphql';
import { ContactType } from 'models/customer';
import React from 'react';
import { formatDate } from 'services/formattingService';

export type LeadDetailForm = {
  locationId: string;
  customerId: string;
  name: string;
  lastFollowUp?: Date;
  salesRepFullName?: string;
  leadNotes?: string;
  contact: {
    id: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
  };
};

function LeadDetails({ lead }: { lead: LeadDetailForm }) {
  const title = (
    <CardHeader>
      <CardHeaderLeft style={{ columnGap: '1em' }}>
        <CardHeaderText>DETAILS</CardHeaderText>
        <CardActionButton size='medium' onClick={null}>
          EDIT
        </CardActionButton>
      </CardHeaderLeft>
    </CardHeader>
  );
  return (
    <ContentCard title={title}>
      <CardRow>
        <CardColumn xl={14} xs={24}>
          <FormSection style={{ paddingRight: '4em' }}>
            <FormSectionTitle style={{ whiteSpace: 'nowrap' }} className='asterisk'>
              Company Name:
            </FormSectionTitle>
            <ViewField>{lead?.name}</ViewField>
          </FormSection>
          <FormSectionTitle>LAST FOLLOW UP:</FormSectionTitle>
          <FormSection>
            <ViewField style={{ width: '150px' }}>{formatDate(lead?.lastFollowUp, 'M/d/yyyy')}</ViewField>
          </FormSection>
        </CardColumn>
        <CardColumn xl={10} xs={24}>
          <FormSection style={{ paddingRight: '4em' }}>
            <FormSectionTitle>REP:</FormSectionTitle>
            <ViewField>{lead?.salesRepFullName}</ViewField>
          </FormSection>
          <FormSectionTitle>NOTES</FormSectionTitle>
          <FormSection>
            <ViewField>{lead?.leadNotes}</ViewField>
          </FormSection>
        </CardColumn>
      </CardRow>
      <CardRow span={24} style={{ borderTop: '1px solid #E5E5E5', paddingTop: '1em' }}>
        <CardColumn>
          <FormSectionTitle>RELATIONSHIP CONTACT</FormSectionTitle>
        </CardColumn>
      </CardRow>
      <CardRow span={24}>
        <CardColumn xl={7} xs={24}>
          <FormSection>
            <FormSectionTitle style={{ whiteSpace: 'nowrap' }} className='asterisk'>
              First:
            </FormSectionTitle>
            <ViewField>{lead?.contact?.firstName}</ViewField>
          </FormSection>
        </CardColumn>
        <CardColumn xl={12} xs={24}>
          <FormSection>
            <FormSectionTitle style={{ whiteSpace: 'nowrap' }} className='asterisk'>
              Last:
            </FormSectionTitle>
            <ViewField>{lead?.contact?.lastName}</ViewField>
          </FormSection>
        </CardColumn>
      </CardRow>
      <CardRow span={24}>
        <CardColumn xl={7} xs={24}>
          <FormSection>
            <FormSectionTitle style={{ whiteSpace: 'nowrap' }} className='asterisk'>
              Email:
            </FormSectionTitle>
            <ViewField>{lead?.contact?.phone}</ViewField>
          </FormSection>
        </CardColumn>
        <CardColumn xl={7} xs={24}>
          <FormSection>
            <FormSectionTitle style={{ whiteSpace: 'nowrap' }} className='asterisk'>
              Phone:
            </FormSectionTitle>
            <ViewField>{lead?.contact?.email}</ViewField>
          </FormSection>
        </CardColumn>
      </CardRow>
    </ContentCard>
  );
}

export default LeadDetails;
