import FranchisesMain from './FranchisesMain';
import { getSignedInUser, isCorporateUser } from 'services/userService';

function FranchiseManagement({ setUpdatedFranchise }: { setUpdatedFranchise: (FranchiseIdentifier) => void }) {
  const user = getSignedInUser();
  if (isCorporateUser() || user.franchises.length > 1) {
    return <FranchisesMain setUpdatedFranchise={setUpdatedFranchise} />;
  }
  return <div>You are not authorized to view this page</div>;
}

export default FranchiseManagement;
