import { Layout } from 'antd';
import styled from 'styled-components';
import NavMenu from './NavMenu';
import theme from 'theme';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';

const { Content, Footer } = Layout;

const ContentArea = styled(Layout)`
  // margin-left: 150px;
  background: transparent;
`;

const PageContent = styled(Content)`
  margin: 0px 0px 50px 26px;
  padding: 0px 0px;
  background: transparent;
`;

const StyledSystemWrapper = styled(Layout)`
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, #e9e9e9 100%);
  .ant-layout {
    background: transparent;
  }
`;

const StyledContentWrapper = styled(Layout)`
  padding: 24px;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  color: ${theme['@white']};
`;

function SystemLayout({
  franchise,
  children,
}: {
  franchise: FranchiseIdentifier | undefined;
  children?: JSX.Element | JSX.Element[];
}) {
  const year = new Date().getFullYear();
  return (
    <StyledSystemWrapper>
      <StyledContentWrapper>
        <NavMenu franchise={franchise} />
        <ContentArea>
          <PageContent>{children}</PageContent>
        </ContentArea>
      </StyledContentWrapper>
      <StyledFooter>
        ©{year} Smash My Trash®. All Rights Reserved. Each franchise is independently owned and operated.
      </StyledFooter>
    </StyledSystemWrapper>
  );
}

export default SystemLayout;
