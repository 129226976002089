import PageTitle from 'components/common/PageTitle';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getSelectedFranchise } from 'services/userService';
import Trucks from './Trucks';

function TruckManagement() {
  const franchise = getSelectedFranchise();
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <PageTitle>{franchise?.name?.toUpperCase()} OPERATION SETTINGS</PageTitle>
        <Trucks />
      </Route>
    </Switch>
  );
}

export default TruckManagement;
