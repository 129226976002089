import { Layout, Menu, SiderProps } from 'antd';
import styled from 'styled-components';
import { getMenuItems } from './menuItems';
import theme from 'theme';
import logoSvg from 'assets/logos/smt-stacked.svg';
import { getSelectedFranchise } from 'services/userService';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';
import { useState, useRef } from 'react';

const { Sider } = Layout;

const StyledSider = styled<SiderProps>(Sider)`
  // padding: 15px;
  // padding-right: 20px;
  display: flex;
  background: transparent;
  // overflow: auto;
  height: 100vh;
  // width: 150px;
  position: relative;
  // left: 0;
  // top: 0;
  // bottom: 0;
  padding: 7px;

  .ant-layout-sider-children {
    flex: auto;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
`;

const LogoWrapper = styled.div`
  margin: 0 0 1em 1.25em;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: left;
`;

const FranchiseNameWrapper = styled.div`
  // margin: 1em 0.9em 0.2em 0;
  padding: 0 0 0 0;
  max-width: 155px;
  display: flex;
  justify-content: center;
  // text-align: center;
  font-weight: 400;
  //color: #2c2c2c;
  color: ${theme['@red']};
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  //display: flex;
`;

const StyledMenu = styled<Menu>(Menu)`
  font-weight: 400;
  background-color: transparent;
  border: 0;

  .ant-menu-item {
    border-radius: 11px;
    padding: 0px 10px;

    // hover state
    &.ant-menu-item-active {
      background: rgba(255, 255, 255, 0.5);
    }

    // the usual 'active' state
    &.ant-menu-item-selected {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2);
      //filter: drop-shadow(3px 3px 20px black);
      background: ${theme['@white']};
      width: 155px;
      transition: all 0.5s;
    }
  }

  .menu-section-header {
    pointer-events: none;
    .ant-menu-title-content {
      font-family: 'Montserrat-Bold';
      font-weight: 600;
      color: black;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NavMenu({ franchise }: { franchise: FranchiseIdentifier | undefined }) {
  const logoRef = useRef();
  // ---------------------------- BEGIN DEV TOOLS LINK CODE (REMOVE LATER) ----------------------------
  const clickTracker = { count: 0, prev: 0, done: false, timer: 5.0, offset: 5.0 };
  const quickClicker = () => {
    if (clickTracker.done) {
      setTimeout(() => {
        if (clickTracker.timer >= 0) {
          const alpha = clickTracker.timer / 100.0;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (logoRef.current as any).style.backgroundColor = `rgba(255, 0, 0, ${alpha})`;
          clickTracker.timer += clickTracker.offset;
          if (clickTracker.timer >= 100) clickTracker.offset = -5.0;
          quickClicker();
        } else {
          document.location = '/utilities';
        }
      }, 5);
    } else {
      const currTime = Date.now();

      if (clickTracker.count > 0 && currTime - clickTracker.prev > 250) clickTracker.count = 0;

      clickTracker.prev = currTime;
      clickTracker.count += 1;
      clickTracker.done = clickTracker.count >= 4;
    }
  };
  // ---------------------------- END DEV TOOLS LINK CODE (REMOVE LATER) ----------------------------

  const [triggerState, setTriggerState] = useState<{ collapsed: boolean; type: string }>({
    collapsed: false,
    type: 'responsive',
  });
  return (
    <StyledSider
      collapsible
      collapsedWidth='0'
      breakpoint='lg'
      className={triggerState.collapsed || triggerState.type === 'clickTrigger' ? 'show-trigger' : 'hide-trigger'}
      onCollapse={(collapsed, type) => {
        setTriggerState({
          collapsed,
          type,
        });
      }}
    >
      <LogoWrapper>
        <Logo
          ref={logoRef}
          src={logoSvg}
          alt='Smash My Trash Logo'
          onClick={() => {
            quickClicker();
          }}
        />
      </LogoWrapper>
      <FranchiseNameWrapper>{getSelectedFranchise()?.name}</FranchiseNameWrapper>

      <StyledMenu items={getMenuItems()} defaultSelectedKeys={[window.location.pathname]} />
    </StyledSider>
  );
}

export default NavMenu;
