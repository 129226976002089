import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import CardExamples from './dashboard/CardExamples';
import ExampleManagement from './listAndEditExample/ExampleManagement';
import TablePagingAndScrolling from './tableStyles/TablePagingAndScrolling';
import VirtualTable from './tableStyles/VirtualTable';
import { Menu } from 'antd';
import InfinityTableView from './tableStyles/InfinityTableView';
import { getPathKey } from './listAndEditExample/example.service';
import { RHFPatternMain } from './rhf-pattern/RHFPatternMain';

function StyleGuideHome() {
  const match = useRouteMatch();
  const history = useHistory();
  const menuItemData = [
    { key: 'dashboard-cards', label: 'Dashboard Cards' },
    { key: 'list-and-edit', label: 'List and Edit Form with Validation' },
    { key: 'rhf-patterns', label: 'RHF Patterns' },
    { key: 'tables', label: 'Paging and Scrolling Tables' },
    // { key: 'virtual-table', label: 'Virtual Table' }, // the virtual table is buggy (uses non-strict mode features)
    { key: 'infinity-table', label: 'Infinity Table' },
    { key: '/utilities', label: 'Utilities', style: { backgroundImage: 'linear-gradient(white, lightGreen)' } },
  ];

  return (
    <div>
      <h2>Style Guide Examples</h2>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[getPathKey('utilities', 'dashboard-cards')]}
        onClick={i => {
          history.push(i.key.charAt(0) === '/' ? i.key : `${match.url}/${i.key}`);
        }}
        style={{ marginBottom: '1em ' }}
        items={menuItemData}
      />
      <Switch>
        <Route path={`${match.path}/tables`}>
          <TablePagingAndScrolling />
        </Route>
        <Route path={`${match.path}/virtual-table`}>
          <VirtualTable />
        </Route>
        <Route path={`${match.path}/infinity-table`}>
          <InfinityTableView />
        </Route>
        <Route path={`${match.path}/list-and-edit`}>
          <ExampleManagement />
        </Route>
        <Route path={[`${match.path}`, `${match.path}/rhf-patterns`]}>
          <RHFPatternMain />
        </Route>
        <Route path={[`${match.path}`, `${match.path}/dashboard-cards`]}>
          <CardExamples />
        </Route>
      </Switch>
    </div>
  );
}

export default StyleGuideHome;
