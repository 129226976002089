import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ExpiringAgreementRow, PastDueInvoiceRow } from './smashboard.models';
import { formatCurrency } from 'services/formattingService';
import theme from 'theme';
import {
  generateMockObject,
  ObjectDataClass,
  ObjectMetaData,
  PropertyDataClass,
  PropertyMetaData,
} from 'services/dataGenerationService';
import { format } from 'date-fns';

const { Text } = Typography;

export function getExpiringAgreementsColumns(url: string): ColumnProps<ExpiringAgreementRow>[] {
  const columns = [
    {
      title: 'Remaining Days',
      dataIndex: 'remainingDays',
      width: '15%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.remainingDays - b.remainingDays,
      render: (text: string, record: ExpiringAgreementRow) => (
        <Text style={{ color: theme[record.remainingDays <= 14 ? '@red' : '@text'] }}>{text}</Text>
      ),
    },
    {
      title: 'exp. date',
      dataIndex: 'expirationDate',
      width: '15%',
      sorter: (a, b) => (a?.expirationDate?.getTime() || 0) - (b?.expirationDate?.getTime() || 0),
      render: (text: string, record: ExpiringAgreementRow) => (
        <Text>{format(new Date(record?.expirationDate), 'M/d/yyyy')}</Text>
      ),
    },
    {
      title: '#',
      dataIndex: 'number',
      width: '5%',
      sorter: (a, b) => a.number - b.number,
      render: (text: string, record: ExpiringAgreementRow) => <Text>{text}</Text>,
    },
    {
      title: 'client',
      dataIndex: 'client',
      width: '25%',
      sorter: (a, b) => a?.client?.localeCompare(b?.client),
      render: (text: string, record: ExpiringAgreementRow) => <Text>{text}</Text>,
    },
    {
      title: 'service location',
      dataIndex: 'serviceLocation',
      width: '25%',
      sorter: (a, b) => a?.serviceLocation?.localeCompare(b?.serviceLocation),
      render: (text: string, record: ExpiringAgreementRow) => <Text>{text}</Text>,
    },
    {
      title: 'monthly revenue',
      dataIndex: 'status',
      width: '15%',
      sorter: (a, b) => a.number - b.number,
      render: (text: string, record: ExpiringAgreementRow) => <Text>{formatCurrency(record?.monthlyRevenue)}</Text>,
    },
  ] as ColumnProps<ExpiringAgreementRow>[];

  return columns;
}

export function getPastDueInvoicesColumns(url: string): ColumnProps<PastDueInvoiceRow>[] {
  const columns = [
    {
      title: 'sent',
      dataIndex: 'sent',
      width: '15%',
      sorter: (a, b) => (a?.sent?.getTime() || 0) - (b?.sent?.getTime() || 0),
      render: (text: string, record: PastDueInvoiceRow) => <Text>{format(new Date(record?.sent), 'M/d/yyyy')}</Text>,
    },
    {
      title: 'due',
      dataIndex: 'due',
      width: '15%',
      sorter: (a, b) => (a?.due?.getTime() || 0) - (b?.due?.getTime() || 0),
      render: (text: string, record: PastDueInvoiceRow) => <Text>{format(new Date(record?.due), 'M/d/yyyy')}</Text>,
    },
    {
      title: '#',
      dataIndex: 'number',
      width: '5%',
      sorter: (a, b) => a.number - b.number,
      render: (text: string, record: PastDueInvoiceRow) => <Text>{text}</Text>,
    },
    {
      title: 'client',
      dataIndex: 'client',
      width: '25%',
      sorter: (a, b) => a?.client?.localeCompare(b?.client),
      render: (text: string, record: PastDueInvoiceRow) => <Text>{text}</Text>,
    },
    {
      title: 'service location',
      dataIndex: 'serviceLocation',
      width: '25%',
      sorter: (a, b) => a?.serviceLocation?.localeCompare(b?.serviceLocation),
      render: (text: string, record: PastDueInvoiceRow) => <Text>{text}</Text>,
    },
    {
      title: 'balance due',
      dataIndex: 'balanceDue',
      width: '15%',
      sorter: (a, b) => a.balanceDue - b.balanceDue,
      render: (text: string, record: PastDueInvoiceRow) => <Text>{formatCurrency(record?.balanceDue)}</Text>,
    },
  ] as ColumnProps<PastDueInvoiceRow>[];

  return columns;
}

export function getFilteredExpiringAgreementResults(
  filter: string,
  records: ExpiringAgreementRow[]
): ExpiringAgreementRow[] {
  let results: ExpiringAgreementRow[] = [...records];
  if (filter && records && records.length > 0) {
    const nonNullFilter = (filter || '').toLowerCase();
    results = results.filter(
      rec =>
        rec.client?.toLowerCase()?.includes(nonNullFilter) ||
        rec.serviceLocation?.toLowerCase()?.includes(nonNullFilter) ||
        rec.number?.toString()?.includes(nonNullFilter)
    );
  }
  return results;
}

export function getFilteredPastDueInvoiceResults(filter: string, records: PastDueInvoiceRow[]): PastDueInvoiceRow[] {
  let results: PastDueInvoiceRow[] = [...records];
  if (filter && records && records.length > 0) {
    const nonNullFilter = (filter || '').toLowerCase();
    results = results.filter(
      rec =>
        rec.client?.toLowerCase()?.includes(nonNullFilter) ||
        rec.serviceLocation?.toLowerCase()?.includes(nonNullFilter) ||
        rec.number?.toString()?.includes(nonNullFilter)
    );
  }
  return results;
}

export const expiringAgreementData = (() => {
  const metaData: ObjectMetaData = { name: 'ExpiringAgreement', properties: [], dataClass: ObjectDataClass.Business };
  metaData.properties.push(
    ...[
      {
        name: 'number',
        dataType: 'number',
        dataClass: PropertyDataClass.NumericId,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'client',
        dataType: 'string',
        dataClass: PropertyDataClass.CompanyName,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'serviceLocation',
        dataType: 'string',
        dataClass: PropertyDataClass.CompanyName,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'monthlyRevenue',
        dataType: 'number',
        dataClass: PropertyDataClass.Money,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'expirationDate',
        dataType: 'Date',
        dataClass: PropertyDataClass.Date,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'remainingDays',
        dataType: 'number',
        dataClass: PropertyDataClass.Age,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'id',
        dataType: 'number',
        dataClass: PropertyDataClass.NumericId,
        isNullable: false,
      } as PropertyMetaData,
    ]
  );

  const objects: ExpiringAgreementRow[] = [];
  for (let i = 0; i < 20; i += 1) {
    const o = generateMockObject<ExpiringAgreementRow>(metaData) as ExpiringAgreementRow;
    o.number = i + 1;
    o.id = o.number.toString();
    o.remainingDays = 1 + Math.round(Math.random() * 60);
    objects.push(o);
  }
  return objects;
})();

export const pastDueInvoiceData = (() => {
  const metaData: ObjectMetaData = { name: 'PastDueInvoices', properties: [], dataClass: ObjectDataClass.Business };
  metaData.properties.push(
    ...[
      {
        name: 'number',
        dataType: 'number',
        dataClass: PropertyDataClass.NumericId,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'client',
        dataType: 'string',
        dataClass: PropertyDataClass.CompanyName,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'serviceLocation',
        dataType: 'string',
        dataClass: PropertyDataClass.CompanyName,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'balanceDue',
        dataType: 'number',
        dataClass: PropertyDataClass.Money,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'sent',
        dataType: 'Date',
        dataClass: PropertyDataClass.Date,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'due',
        dataType: 'Date',
        dataClass: PropertyDataClass.Date,
        isNullable: false,
      } as PropertyMetaData,
      {
        name: 'id',
        dataType: 'number',
        dataClass: PropertyDataClass.NumericId,
        isNullable: false,
      } as PropertyMetaData,
    ]
  );

  const objects: PastDueInvoiceRow[] = [];
  for (let i = 0; i < 20; i += 1) {
    const o = generateMockObject<PastDueInvoiceRow>(metaData) as PastDueInvoiceRow;
    o.number = i + 1;
    o.id = o.number.toString();
    objects.push(o);
  }
  return objects;
})();
