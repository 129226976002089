/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';

import { Select } from 'antd';
import { RHFSelectParams } from './rhf.models';
import { processAttributesAndValidator } from './rhf.service';

const { Option } = Select;

/**
 * An antd select control using a react-hook-form controller.
 * see: https://ant.design/components/select/ (AntD Select reference)
 *      https://react-hook-form.com/api/usecontroller/controller (react-hook-form Controller reference)
 *      https://codesandbox.io/s/react-hook-form-v6-controller-forked-2k6jt?file=/src/AntD.js (AntD & react-hook-form examples)
 * @param params Options for the antd select control using a react-hook-form controller.
 * @returns the react-hook-form/antd component
 */
export function RHFSelect(params: RHFSelectParams) {
  const { attributes, validator } = processAttributesAndValidator(
    params.name,
    params.control,
    params.editMode,
    params.attributes
  );

  const labelClassName =
    params.viewModeClassName ||
    (params.hideBorder ? 'rhf-label-noborder rhf-label-single-line' : 'rhf-label rhf-label-single-line');
  const defVal = params.defaultValue || params.control?._defaultValues[params.name];
  const viewModeValue = params.options?.find(statusObj => statusObj.value === defVal)?.label;
  const viewModeElement = viewModeValue ? (
    <div className={labelClassName}>{viewModeValue}</div>
  ) : (
    <div className={labelClassName}>&nbsp;</div>
  );
  return params.editMode ? (
    <>
      <Controller
        name={params.name}
        control={params.control}
        defaultValue={params.defaultValue}
        rules={params.rules}
        render={({ field }) => (
          <Select {...field} {...attributes}>
            {params.options?.map(statusObj => (
              <Option key={statusObj.value} value={statusObj.value}>
                {statusObj.label}
              </Option>
            ))}
          </Select>
        )}
      />
      {validator}
    </>
  ) : (
    viewModeElement
  );
}
