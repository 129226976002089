import { FormSectionTitle, Text } from '../common/styled';
import { Col, notification, Select, Space } from 'antd';
import React, { useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { HeaderText } from 'components/common/card/Card';
import { TableHeader, TableHeaderLeft, TableHeaderRight } from 'components/common/card/TableCard';
import { DbConstants } from 'generated/smt-constants';
import { LocationContactFormSectionTitle, LocationContactPlusButton, LocationContentCard } from './location.styled';
import { LocationDetailModel } from './location.models';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { hasPermission } from 'services/userService';
import {
  CustomerButton,
  CustomerFlexCol,
  CustomerHeaderTextBox,
  CustomerHzPad40Row,
} from 'components/customer/customer.styled';
import theme from 'theme';
import { useUpdateLocationDetailMutation } from 'generated/graphql';
import StyledInput from 'components/common/input/BaseInput';
import CustomerAddressForm from 'components/customer/CustomerAddressForm';
import CustomerContactInfoForm from 'components/customer/CustomerContactInfoForm';
import ContactPreferenceForm from 'components/customer/CustomerContactPreferenceForm';
import { RHFWrapper } from 'components/common/react-hook-form/RHFWrapper';
import { deepFilterTypename } from 'services/formattingService';
import StyledSelect from 'components/common/input/BaseSelect';

const { Option } = Select;

export enum LocationViewPerspective {
  Lead,
  Customer,
}

function LocationDetailView({
  location,
  editPermission,
  perspective,
  secondaryActions = <div />,
  locationOriginal,
}: {
  location: any;
  editPermission?: string;
  perspective?: LocationViewPerspective;
  secondaryActions?: JSX.Element;
  locationOriginal?: any;
}) {
  const formRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [tempContactId, setTempContactId] = useState(100);
  const canEdit = hasPermission(editPermission || DbConstants.Permission.CustomerLocationCrud);

  const [updateLocationDetail] = useUpdateLocationDetailMutation({
    onCompleted: data => {
      setTimeout(() => {
        notification.open({
          message: 'Saved',
          description: 'Updated Location Successfully',
          duration: 5,
        });
      }, 0);
    },
    onError: error => {
      notification.open({
        message: 'Error Updating Record.',
        description: error.message,
        duration: 5,
      });
    },
  });

  const formData: LocationDetailModel = {
    id: location?.id,
    name: location?.name,
    numberOfDumpsters: location?.numberOfDumpsters || 1,
    serviceType: location?.agreements?.find(agreement => !agreement?.deleted)?.agreementServiceType || 'none',
    addressLine1: location?.address?.line1,
    addressLine2: location?.address?.line2,
    city: location?.address?.city,
    state: location?.address?.state,
    zipCode: location?.address?.zipCode,
    inactive: !location?.inactive ? 'active' : 'inactive',
  };

  // set up react hook forms
  const formHandler = useFormContext();

  const {
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    control,
  } = formHandler;

  const { fields: contactFields, prepend: prependContacts } = useFieldArray({
    name: 'locationContacts',
    control,
  });

  const validator = (name: string, messages: { [x: string]: string }) => {
    if (formHandler.formState.errors[name]) {
      const type: string = formHandler.formState.errors[name]?.type?.toString() || '';
      const msg: string =
        (messages[type] ? messages[type] : formHandler.formState.errors[name]?.message?.toString()) || '';
      return <div className='ant-form-explain'>{msg}</div>;
    }
    return null;
  };

  const handleNewContact = () => {
    const newContactId = `00000000-0000-0000-0000-000000000${tempContactId}`;

    prependContacts({
      id: newContactId,
      preferredContactMethod: [],
      contactCategories: [],
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    });

    setTempContactId(tempContactId + 1);
  };

  const onSubmitSuccess = submittedData => {
    const { name, inactive, address, locationContacts }: any = deepFilterTypename(submittedData);

    const locationDetails = {
      name,
      inactive: inactive === 'inactive' ? location?.inactive || new Date() : null,
      address,
      locationContacts,
    };

    updateLocationDetail({
      variables: {
        data: locationDetails,
        where: { locationId: submittedData.id },
      },
    });
    setEditMode(!editMode);
    console.log('SUCCESS!:', locationDetails);
  };

  const onSubmitError = error => {
    console.log('ERROR!:', error);
  };

  const formHeader = (
    <TableHeader>
      <TableHeaderLeft>
        <Space size='middle'>
          <CustomerHeaderTextBox>
            <HeaderText level={2}>SERVICE LOCATION DETAILS</HeaderText>
          </CustomerHeaderTextBox>
          {canEdit && (
            <CustomerButton
              disabled={editMode && Object.keys(errors).length !== 0}
              size='medium'
              $color={theme[editMode ? '@green' : '@blue']}
              onClick={() => {
                if (editMode) {
                  return handleSubmit(onSubmitSuccess, onSubmitError)();
                }
                setEditMode(!editMode);
              }}
            >
              {editMode ? 'SAVE' : 'EDIT'}
            </CustomerButton>
          )}
          {canEdit && editMode && (
            <CustomerButton
              size='medium'
              $color={theme['@red']}
              onClick={() => {
                setEditMode(false);
              }}
            >
              CANCEL
            </CustomerButton>
          )}
        </Space>
      </TableHeaderLeft>
      <TableHeaderRight>{secondaryActions}</TableHeaderRight>
    </TableHeader>
  );

  function LocationDetail() {
    const activeInactiveOptions: RHFOption[] = [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ];
    // perspective
    return (
      <>
        <CustomerHzPad40Row gutter={36}>
          <CustomerFlexCol xl={12} xs={24}>
            <FormSectionTitle className='asterisk'>Location Name:</FormSectionTitle>
            <RHFWrapper name='name' rules={{ required: 'Location Name is required' }}>
              <StyledInput placeholder='Location Name' $editmode={editMode} readOnly={!editMode} />
            </RHFWrapper>
          </CustomerFlexCol>

          {/* {perspective === LocationViewPerspective.Lead && (
            <>
              <FormSectionTitle style={{ whiteSpace: 'nowrap' }}>Number of Dumpsters:</FormSectionTitle>
              <ViewField $hideBorder style={{ width: '2em' }}>
                {formData.numberOfDumpsters}
              </ViewField>
            </>
          )} */}
        </CustomerHzPad40Row>
        <CustomerHzPad40Row gutter={36}>
          <CustomerFlexCol xl={12} xs={24}>
            <FormSectionTitle>ADDRESS</FormSectionTitle>
            <CustomerAddressForm
              editmode={editMode}
              id={formData.id}
              address={formData}
              asterisks
              validator={validator}
              locationContact
            />
          </CustomerFlexCol>
          <CustomerFlexCol xl={12} xs={24}>
            {perspective !== LocationViewPerspective.Lead && (
              <>
                <CustomerFlexCol xl={12} xs={24}>
                  <Col span={24}>
                    <FormSectionTitle>SERVICE TYPE</FormSectionTitle>
                    <Text>{formData.serviceType}</Text>
                  </Col>
                </CustomerFlexCol>
                <CustomerFlexCol xl={12} xs={24}>
                  <Col span={24}>
                    <FormSectionTitle>STATUS</FormSectionTitle>
                    <RHFWrapper name='inactive' value={inactive => (inactive ? 'inactive' : 'active')}>
                      <StyledSelect $editmode={editMode}>
                        <Option value='active'>Active</Option>
                        <Option value='inactive'>Inactive</Option>
                      </StyledSelect>
                    </RHFWrapper>
                  </Col>
                </CustomerFlexCol>
              </>
            )}
          </CustomerFlexCol>
        </CustomerHzPad40Row>
      </>
    );
  }

  function ContactDetail() {
    return (
      <>
        {(contactFields || [])
          .filter(contact => contact !== undefined)
          .map((contact, i) => (
            <React.Fragment key={contact.id}>
              {i === 0 && (
                <CustomerHzPad40Row className='contact-row-top-border'>
                  <LocationContactFormSectionTitle>ON SITE CONTACT</LocationContactFormSectionTitle>
                  <LocationContactPlusButton
                    size='medium'
                    shape='circle'
                    icon={<PlusOutlined />}
                    onClick={() => handleNewContact()}
                  />
                </CustomerHzPad40Row>
              )}
              <CustomerHzPad40Row
                gutter={36}
                className={`contact-row${i % 2 ? ' contact-row-alt' : ''}${i === 1 ? ' contact-row-top-border' : ''}`}
              >
                <Col xl={12} xs={24}>
                  <CustomerContactInfoForm
                    contact={(contact as any).customerContact}
                    editmode={editMode}
                    validator={validator}
                    index={i}
                    locationContact
                  />
                </Col>
                <Col xl={12} xs={24}>
                  <ContactPreferenceForm editmode={editMode} index={i} locationContact />
                </Col>
              </CustomerHzPad40Row>
            </React.Fragment>
          ))}
      </>
    );
  }

  function DetailView() {
    return (
      <LocationContentCard title={formHeader}>
        <LocationDetail />
        <ContactDetail />
      </LocationContentCard>
    );
  }

  return <DetailView />;
}

export default LocationDetailView;
