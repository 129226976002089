import { useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import WorkOrdersCard from './WorkOrdersCard';
import CustomerDetailsForm from './CustomerDetailsForm';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CUSTOMER } from 'graphql/query/customer';
import { UPDATE_CUSTOMER } from 'graphql/mutation/customer';
import LocationTableView from 'components/location/LocationTableView';
import LocationDetails from 'components/location/LocationDetails';
import InvoicesCard from './InvoicesCard';

import { Customer, GetCustomerQuery } from 'generated/graphql';
import PageTitle from 'components/common/PageTitle';
import { useForm, FormProvider } from 'react-hook-form';

function CustomerDetails() {
  const match = useRouteMatch();
  const [editmode, setEditmode] = useState(false);

  const [updateCustomer, { data: d2, loading: l2, error: e2 }] = useMutation(UPDATE_CUSTOMER);

  const { customerId } = useParams<{ customerId }>();

  const formHandler = useForm<Customer>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {
      id: '',
      number: -1,
      name: '',
      national: false,
      inactive: '',
      customerContacts: [],
    },
  });

  const { loading, data } = useQuery<GetCustomerQuery>(GET_CUSTOMER, {
    variables: { id: customerId },
    onCompleted: response => {
      if (response.customer !== undefined) formHandler.reset(response.customer as Customer);
    },
  });

  const validator = (name: string, messages: { [x: string]: string }) => {
    if (formHandler.formState.errors[name]) {
      const type: string = formHandler.formState.errors[name]?.type?.toString() || '';
      const msg: string =
        (messages[type] ? messages[type] : formHandler.formState.errors[name]?.message?.toString()) || '';
      return <div className='ant-form-explain'>{msg}</div>;
    }
    return null;
  };

  let invoiceList: any[] = [];
  let workOrderList: any[] = [];
  let customerDetails = <div>Loading...</div>;

  if (!loading && data) {
    // const customerClone = { ...data.customer };
    invoiceList = [...data.invoiceSummaryViews];
    workOrderList = [...data.workOrderSummaryViews];

    customerDetails = (
      <CustomerDetailsForm
        validator={validator}
        customer={{ ...data.customer }} // eslint-disable no-shadow
        submitCustomerEdit={updateCustomer}
        editmode={editmode}
        setEditmode={setEditmode}
        key={data.customer?.id}
      />
    );
  }

  return (
    <Switch>
      <Route path={`${match.path}/:locationId`}>
        <LocationDetails />
      </Route>
      <Route path={`${match.path}`}>
        <PageTitle>{data?.customer?.name}</PageTitle>
        {!loading && data && <FormProvider {...formHandler}>{customerDetails}</FormProvider>}
        <LocationTableView />
        <WorkOrdersCard loading={loading} workOrders={workOrderList} />
        <InvoicesCard loading={loading} invoices={invoiceList} />
      </Route>
    </Switch>
  );
}

export default CustomerDetails;
