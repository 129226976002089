import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  StyledSelectableTable,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  TableHeaderText,
  TableSearch,
} from 'components/common/card/TableCard';

import { LeadGridRow, LeadSearchState } from './lead.models';
import { getLeadColumns, getFilteredLeadResults, buildLeadWhereClause } from './lead.service';
import { LeadListTableCard } from './lead.styled';
import { useGetLeadsForListQuery } from 'generated/graphql';

/**
 * This is a lead component that demonstrates one way to do grids cleanly
 * @returns a lead list component
 */
function LeadList({
  setSelectedGridRow,
  redirect = true,
  gridHeight,
  selectedLocationId,
}: {
  setSelectedGridRow: (row: LeadGridRow | null) => void;
  redirect?: boolean;
  gridHeight?: number;
  selectedLocationId?: string;
}) {
  const [searchState, setSearchState] = useState<LeadSearchState>({
    filter: '',
    active: true,
    selectedRowKey: selectedLocationId || null,
  });

  const { loading, error, data } = useGetLeadsForListQuery({
    variables: {
      where: buildLeadWhereClause(),
    },
    context: { timeout: 20000 },
  });

  const dataIsLoaded = !loading && !error;

  const displayRecords = dataIsLoaded ? getFilteredLeadResults(searchState, data) : [];

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>LEAD LIST</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRight>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, filter: value });
          }}
        />
      </TableHeaderRight>
    </TableHeader>
  );

  const history = useHistory();

  const scrollData =
    !loading && (displayRecords?.length || 0) > 6
      ? {
          y: gridHeight || 300,
        }
      : null;

  // return the final chunk of jsx html for the component
  return (
    <LeadListTableCard title={header}>
      <StyledSelectableTable
        rowSelection={{
          selectedRowKeys: [searchState.selectedRowKey],
          type: 'radio',
          onChange: selRowKeys =>
            setSearchState({ ...searchState, selectedRowKey: selRowKeys?.length > 0 ? selRowKeys[0] : null }),
        }}
        rowKey='id'
        loading={loading}
        dataSource={displayRecords}
        columns={getLeadColumns()}
        pagination={false}
        scroll={scrollData}
        onRow={record => ({
          onDoubleClick: () => {
            if (redirect) history.push(`/leads/${record.id}`);
          },
          onClick: () => {
            const isCurrentlySelected = searchState.selectedRowKey === record.id;
            setSearchState({
              ...searchState,
              selectedRowKey: isCurrentlySelected ? null : record.id,
            });
            if (record && setSelectedGridRow) {
              setSelectedGridRow(isCurrentlySelected ? null : (record as LeadGridRow));
            }
          },
        })}
      />
    </LeadListTableCard>
  );
}

export default LeadList;
