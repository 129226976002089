import { TableCard, TableHeader, TableHeaderLeft, TableHeaderText } from 'components/common/card/TableCard';
import {
  StyledTableWithPaging,
  StyledSelectNoMargin,
  TableSearch,
  TableHeaderRightWithSpacing,
} from './invoice.styled';
import { GetCustomerInvoiceSummaryRecordsQuery } from 'generated/graphql';
import { useQuery } from '@apollo/client';
import { GET_INVOICE_SUMMARY_RECORDS } from 'graphql/query/invoice';
import { useEffect, useRef, useState } from 'react';
import { buildWhereClause, getColumns, getFilteredResults } from './invoice.service';
import { InvoiceGridRow } from './invoice.models';
import { ColumnProps } from 'antd/lib/table';
import { DatePicker } from 'antd';
import StyledSelect from '../common/input/BaseSelect';
import { DbConstants } from 'generated/smt-constants';

const { Option } = StyledSelect;

function InvoiceTableView() {
  const [searchState, setSearchState] = useState({ searchString: '', dateFilter: '', serviceTypeFilter: '' });

  const columnsRef = useRef<ColumnProps<InvoiceGridRow>[]>();

  const displayRecordsRef = useRef<InvoiceGridRow[]>();

  const whereClause = buildWhereClause();

  const { loading, error, data } = useQuery<GetCustomerInvoiceSummaryRecordsQuery>(GET_INVOICE_SUMMARY_RECORDS, {
    variables: { where: whereClause },
  });

  if (!columnsRef.current) columnsRef.current = getColumns('');

  const dataIsLoaded = !loading && !error;
  displayRecordsRef.current = dataIsLoaded ? getFilteredResults(searchState, data?.invoiceSummaryViews || []) : [];

  useEffect(() => {
    displayRecordsRef.current = dataIsLoaded ? getFilteredResults(searchState, data?.invoiceSummaryViews || []) : [];
  }, [searchState, dataIsLoaded, data?.invoiceSummaryViews]);

  const header = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>INVOICES</TableHeaderText>
      </TableHeaderLeft>
      <TableHeaderRightWithSpacing>
        <DatePicker
          name='dateIssuedPicker'
          placeholder='Date Issued'
          onChange={value => {
            setSearchState({
              ...searchState,
              dateFilter: value?.toDate().toString() || '',
            });
          }}
        />
        <StyledSelectNoMargin
          placeholder='Service Type'
          size='large'
          $editmode
          allowClear
          onChange={value => {
            setSearchState({
              ...searchState,
              serviceTypeFilter: value || '',
            });
          }}
          value={searchState.serviceTypeFilter === '' ? undefined : searchState.serviceTypeFilter}
        >
          {DbConstants.ServiceType._KeyValuePairs.map(kvPair => (
            <Option value={kvPair.key} key={kvPair.key}>
              {kvPair.value}
            </Option>
          ))}
        </StyledSelectNoMargin>
        <TableSearch
          onChange={value => {
            setSearchState({ ...searchState, searchString: value });
          }}
        />
      </TableHeaderRightWithSpacing>
    </TableHeader>
  );

  return (
    <TableCard title={header} style={{ minWidth: '700px' }}>
      <StyledTableWithPaging
        loading={loading}
        dataSource={displayRecordsRef.current}
        columns={columnsRef.current}
        // pagination={false}
        rowKey='number'
        onRow={record => ({
          onDoubleClick: () => {
            // history.push(`/customers/${record.id}`);
            // eslint-disable-next-line no-console
            console.log(record);
          },
        })}
      />
    </TableCard>
  );
}

export default InvoiceTableView;
