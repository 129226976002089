import { DatePicker, Row } from 'antd';
import DashboardActionCard from 'components/common/card/DashboardActionCard';
import PageTitle from 'components/common/PageTitle';
import { ReactComponent as ScheduleIcon3 } from 'assets/icons/schedule-3.svg';
import { ReactComponent as ScheduleIcon7 } from 'assets/icons/schedule-7.svg';
import { ReactComponent as ScheduleIcon9 } from 'assets/icons/schedule-9.svg';
import { ReactComponent as ScheduleIcon11 } from 'assets/icons/schedule-11.svg';
import SmashList from './SmashList';
import TruckCards from './TruckCards';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { SMASH_LIST } from 'graphql/query/smash';
import { getSelectedFranchise, hasPermission } from 'services/userService';
import { useHistory } from 'react-router-dom';
import { DbConstants } from 'generated/smt-constants';

function Schedule() {
  const canCreateSmashFromLead = hasPermission(DbConstants.Permission.LeadSmashCrud);
  const canCreateSmashFromCustomer = hasPermission(DbConstants.Permission.CustomerSmashCrud);

  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { loading, data, refetch } = useQuery(SMASH_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        scheduleDate: {
          equals: selectedDate,
        },
        location: {
          is: {
            customer: {
              is: {
                franchiseId: {
                  equals: getSelectedFranchise()?.id,
                },
              },
            },
          },
        },
      },
      trucksWhere: {
        franchiseId: {
          equals: getSelectedFranchise()?.id,
        },
      },
    },
  });

  return (
    <>
      <PageTitle>SCHEDULE</PageTitle>
      <TruckCards data={data} />
      <SmashList
        selectedDate={selectedDate}
        refetch={refetch}
        setSelectedDate={setSelectedDate}
        loading={loading}
        data={data}
      />
      <Row>
        <DashboardActionCard
          text='SCHEDULE DEMO'
          icon={<ScheduleIcon3 />}
          enabled={canCreateSmashFromLead}
          onClick={() => {
            history.push(`/create-smash-order/?lead=true&orderType=demo`);
          }}
        />
        <DashboardActionCard
          text='SCHEDULE TRIAL'
          icon={<ScheduleIcon7 />}
          enabled={canCreateSmashFromLead}
          onClick={() => {
            history.push(`/create-smash-order/?lead=true&orderType=trial`);
          }}
        />
        <DashboardActionCard
          text='SCHEDULE ON DEMAND'
          icon={<ScheduleIcon9 />}
          enabled={canCreateSmashFromCustomer}
          onClick={() => {
            history.push(`/create-smash-order/?lead=false&orderType=on-demand`);
          }}
        />
        <DashboardActionCard
          text='SCHEDULE PROJECT'
          icon={<ScheduleIcon11 />}
          enabled={canCreateSmashFromCustomer}
          onClick={() => {
            history.push(`/create-smash-order/?lead=false&orderType=project`);
          }}
        />
      </Row>
    </>
  );
}

export default Schedule;
