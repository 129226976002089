import { MutableRefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';

import {
  StyledTable,
  TableCard,
  TableHeader,
  TableHeaderLeft,
  TableHeaderText,
} from 'components/common/card/TableCard';
import { VariableSizeGrid } from 'react-window';
import { SampleGridRow, SampleRecord } from './table.models';
import { getFilteredResults, getSampleRecords, getVirtualTableColumns } from './table.service';
import { LoadingContainer, VirtualDiv } from './table.styled';
import { ColumnProps } from 'antd/lib/table';
import { generateMockObject, getObjectMetaData, ObjectMetaData } from 'services/dataGenerationService';

/**
 * This is a sample component that demonstrates one way to do grids cleanly
 * @returns a sample list component
 */
function VirtualTable() {
  // get the search state, which includes everything
  const searchState = {
    filter: '',
    active: true,
    numberOfRecords: 1,
  };
  // get the columns reference (similar to a class member)
  const columnsRef = useRef<ColumnProps<SampleGridRow>[]>();
  // load the data from wherever and update the state.
  const loading = false;
  const sampleData = getSampleRecords();
  const metaData = getObjectMetaData(sampleData, undefined);
  const [dataState, setData] = useState<SampleRecord[]>([]);
  const data: SampleRecord[] = [];
  if (!dataState || dataState.length === 0) {
    for (let i = 0; i < 100000; i += 1) {
      const newObj = generateMockObject(metaData as ObjectMetaData) as SampleRecord;
      newObj.id = i + 1; // make sure the id is unique
      data.push(newObj);
    }
    setData(data);
  } else {
    data.push(...dataState);
  }
  // const { loading, error, data } = useMockData({
  //   sampleData: getSampleRecords(),
  //   numberOfRecordsToAdd: searchState.numberOfRecords,
  //   sleepMilliseconds: 2000,
  // });
  // only load the columns once? (probably overkill)
  if (!columnsRef.current) columnsRef.current = getVirtualTableColumns();
  // load the display records by filtering and mapping the data from the database
  const displayRecords = loading || data?.length === 0 ? data : getFilteredResults(searchState, data);
  // virtual table stuff
  const [tableWidth, setTableWidth] = useState(0);
  const gridRef: MutableRefObject<VariableSizeGrid> = useRef<VariableSizeGrid>();
  const widthColumnCount = columnsRef.current.filter(({ width }) => !width).length;
  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };
  useEffect(() => resetVirtualGrid, [tableWidth]);
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: scrollLeft => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });
  const virtualScrollConfig = {
    y: 300,
    x: '100vw',
  };
  const mergedColumns = columnsRef.current.map(column => {
    if (column.width) {
      return column;
    }
    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });
  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = connectObject;
    const totalHeight = rawData.length * 54;
    return (
      <VariableSizeGrid
        ref={gridRef}
        className='virtual-grid'
        columnCount={mergedColumns.length}
        columnWidth={index => {
          const { width } = mergedColumns[index];
          const w: number = width ? Number.parseInt(width?.toString(), 10) : 0;
          return totalHeight > virtualScrollConfig.y && index === mergedColumns.length - 1 ? w - scrollbarSize - 1 : w;
        }}
        height={virtualScrollConfig.y}
        rowCount={rawData.length}
        rowHeight={() => 54}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const dataIdx = mergedColumns[columnIndex].dataIndex as number;
          return (
            <VirtualDiv
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              })}
              style={style}
            >
              {mergedColumns[columnIndex].dataIndex !== undefined ? rawData[rowIndex][dataIdx] : ''}
            </VirtualDiv>
          );
        }}
      </VariableSizeGrid>
    );
  };
  const virtualListHeader = (
    <TableHeader>
      <TableHeaderLeft>
        <TableHeaderText>VIRTUAL LIST</TableHeaderText>
      </TableHeaderLeft>
    </TableHeader>
  );
  // VIRTUAL LIST
  // see: https://ant.design/components/table/#components-table-demo-virtual-list
  // see: https://codesandbox.io/s/op9ney?file=/demo.js:3341-3380
  // return the final chunk of jsx html for the component
  return (
    <LoadingContainer spinning={loading} delay={200}>
      <TableCard title={virtualListHeader}>
        <ResizeObserver
          onResize={({ width }) => {
            setTableWidth(width);
          }}
        >
          <StyledTable
            dataSource={displayRecords}
            scroll={{
              y: 400,
              x: '100vw',
            }}
            rowKey='id'
            className='virtual-table'
            columns={mergedColumns}
            pagination={false}
            components={{
              body: renderVirtualList,
            }}
          />
        </ResizeObserver>
      </TableCard>
    </LoadingContainer>
  );
}

export default VirtualTable;
