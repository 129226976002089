import PageTitle from 'components/common/PageTitle';
import ScrollToTop from 'components/common/ScrollToTop';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import CustomerMain from './CustomersMain';

function CustomerManagement() {
  const match = useRouteMatch();

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path={`${match.path}/:customerId`}>
          <CustomerDetails />
        </Route>
        <Route path={`${match.path}`}>
          <PageTitle>CUSTOMER</PageTitle>
          <CustomerMain activeToggle />
        </Route>
      </Switch>
    </>
  );
}

export default CustomerManagement;
