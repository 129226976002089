import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { CardRow, CardColumn } from 'components/common/card/Card';
import { RHFOption } from 'components/common/react-hook-form/rhf.models';
import { RHFDatePicker } from 'components/common/react-hook-form/RHFDatePicker';
import { RHFInput } from 'components/common/react-hook-form/RHFInput';
import { RHFInputNumber } from 'components/common/react-hook-form/RHFInputNumber';
import { RHFSelect } from 'components/common/react-hook-form/RHFSelect';
import { RHFTextArea } from 'components/common/react-hook-form/RHFTextArea';
import { StyledDeleteButtonNoMargin } from 'components/common/styled';
import { LocationLookupRecord, CustomerAssetForm } from '../equipment.models';
import {
  LeadSpaceWithSmallLeftGutter,
  FormSectionTitleUpper,
  LeadEquipmentRow,
  ViewFieldLabelNoWrapRP,
} from '../equipment.styled';

function OtherEquipment({
  index,
  asset,
  readOnly,
  editMode,
  control,
  wasteStreams,
  assetTypes,
  deleteAsset,
}: {
  index: number;
  asset: CustomerAssetForm;
  readOnly: boolean;
  editMode: boolean;
  control;
  wasteStreams: LocationLookupRecord[];
  assetTypes: LocationLookupRecord[];
  deleteAsset: (id?: string) => void;
}) {
  const yesNoOptions: RHFOption[] = [
    { value: true.toString(), label: 'Yes' },
    { value: false.toString(), label: 'No' },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDate = (d): any => (d ? new Date(d) : undefined);
  return (
    <LeadEquipmentRow
      gutter={[32, 0]}
      className={`card-form-row ${index === 0 ? 'card-form-row-first' : ''} ${index % 2 ? 'card-form-row-even' : ''}`}
    >
      <Col span={24}>
        <CardRow>
          <CardColumn xl={12} md={24} xs={24}>
            {editMode ? (
              <RHFInput
                name={`assets[${index}].name`}
                editMode={editMode}
                control={control}
                defaultValue={asset?.name}
                rules={{ required: true }}
                attributes={{
                  style: { width: '100%' },
                  placeholder: 'Name*',
                }}
              />
            ) : (
              <LeadSpaceWithSmallLeftGutter $noGutter={readOnly} size='small'>
                {!readOnly && (
                  <StyledDeleteButtonNoMargin
                    size='small'
                    shape='circle'
                    icon={<DeleteOutlined />}
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      if (deleteAsset && window.confirm(`Are you sure you want to delete this record?`)) {
                        deleteAsset(asset?.id);
                      }
                    }}
                  />
                )}
                <FormSectionTitleUpper>{asset?.name}</FormSectionTitleUpper>
              </LeadSpaceWithSmallLeftGutter>
            )}
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn xl={8} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP className='asterisk'>Equipment Type:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFSelect
                  name={`assets[${index}].assetTypeId`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.assetTypeId?.toString()}
                  options={assetTypes.map<RHFOption>(o => ({ label: o.description, value: o.id }))}
                  rules={{ required: true }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Equipment Type*',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={7} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Waste Stream:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFSelect
                  name={`assets[${index}].wasteStreamId`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.wasteStreamId?.toString()}
                  options={wasteStreams.map<RHFOption>(o => ({ label: o.description, value: o.id }))}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Waste Stream',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={4} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Size:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFInput
                  name={`assets[${index}].size`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.size}
                  rules={{ maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
                  attributes={{
                    style: { maxWidth: '8em', minWidth: '6em' },
                    placeholder: 'Size',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={5} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP className='asterisk'># of Units:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFInputNumber
                  name={`assets[${index}].unitCount`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.unitCount}
                  rules={{ required: true }}
                  attributes={{
                    style: { width: '4em' },
                    placeholder: 'Unit Count*',
                    min: 1,
                    max: 99,
                    precision: 0,
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn xl={6} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Provider:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFInput
                  name={`assets[${index}].provider`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.provider}
                  rules={{ maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Provider',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={5} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Under Contract:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFSelect
                  name={`assets[${index}].underContract`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.underContract?.toString()}
                  options={yesNoOptions}
                  attributes={{
                    style: { width: '6em' },
                  }}
                />
              </Col>
            </Row>
            {/* <FormSection>
              <ViewFieldLabel>Under Contract:</ViewFieldLabel>
              <ViewField>{asset.underContract ? 'Yes' : 'No'}</ViewField>
            </FormSection> */}
          </CardColumn>
          <CardColumn xl={6} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Exp. of Contract:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFDatePicker
                  name={`assets[${index}].contractExpiration`}
                  editMode={editMode}
                  control={control}
                  defaultValue={getDate(asset?.contractExpiration)}
                  attributes={{
                    placeholder: 'Exp. of Contract:',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={7} md={12} xs={24}>
            <Row>
              <Col>
                <ViewFieldLabelNoWrapRP>Price Details:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFInput
                  name={`assets[${index}].priceDetails`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.priceDetails}
                  rules={{ maxLength: { value: 25, message: 'Maximum of 25 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    placeholder: 'Price Details',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn xl={12} xs={24}>
            <Row>
              <Col style={{ paddingRight: '1em' }}>
                <ViewFieldLabelNoWrapRP>Location:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFTextArea
                  name={`assets[${index}].locationNotes`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.locationNotes}
                  rules={{ maxLength: { value: 50, message: 'Maximum of 50 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    rows: 2,
                    placeholder: 'Location Notes',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
          <CardColumn xl={12} xs={24}>
            <Row>
              <Col style={{ paddingRight: '1em' }}>
                <ViewFieldLabelNoWrapRP>Notes:</ViewFieldLabelNoWrapRP>
              </Col>
              <Col flex='auto'>
                <RHFTextArea
                  name={`assets[${index}].notes`}
                  editMode={editMode}
                  control={control}
                  defaultValue={asset?.notes}
                  rules={{ maxLength: { value: 50, message: 'Maximum of 50 characters' } }}
                  attributes={{
                    style: { width: '100%' },
                    rows: 2,
                    placeholder: 'Notes',
                  }}
                />
              </Col>
            </Row>
          </CardColumn>
        </CardRow>
      </Col>
    </LeadEquipmentRow>
  );
}

export default OtherEquipment;
