export function FormRoute({
  children,
  name,
  flows,
  validate,
  progressLabel,
  submission = null,
  confirmation = false,
}: {
  children: React.ReactNode;
  name: string;
  flows?: string[];
  validate?: (stepState: any) => boolean;
  progressLabel?: string;
  submission?: string | null;
  confirmation?: boolean;
}) {
  return <>{children}</>;
}
