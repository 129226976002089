import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { LocationGridRow, LocationSearchState } from './location.models';
import { getSelectedFranchise } from 'services/userService';
import { formatCurrency, formatDate } from 'services/formattingService';
import { GetCustomerLocationsQuery, LocationSummaryViewWhereInput } from 'generated/graphql';
import { autoCompare, compareNumbers } from 'services/sortingService';

const { Text } = Typography;

export function buildLocationsWhereClause(customerId?: string): LocationSummaryViewWhereInput {
  return {
    franchiseId: {
      equals: getSelectedFranchise()?.id || '00000000-0000-0000-0000-000000000000',
    },
    AND: [
      {
        customerId: {
          equals: customerId,
        },
      },
      {
        convertedFromLead: {
          not: null,
        },
      },
    ],
  };
}

/**
 * Gets the columns for the grid
 * @param url The url needed for links in the grid
 * @returns an array of columns
 */
export function getLocationColumns(): ColumnProps<LocationGridRow>[] {
  const columns = [
    {
      title: 'STREET ADDRESS',
      dataIndex: 'streetAddress',
      render: (text: string, record: LocationGridRow) => <Text>{record.streetAddress}</Text>,
      sorter: (a, b) => autoCompare(a.streetAddress, b.streetAddress),
    },
    {
      title: 'LOCATION NAME',
      dataIndex: 'location',
      render: (text: string, record: LocationGridRow) => <Text>{record.location}</Text>,
      sorter: (a, b) => autoCompare(a.location, b.location),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'AGREEMENT EXP',
      dataIndex: 'expirationDate',
      render: (text: string, record: LocationGridRow) => <Text>{formatDate(record?.expirationDate)}</Text>,
      sorter: (a, b) => autoCompare(a.expirationDate, b.expirationDate),
    },
    {
      title: 'LAST SERVICED',
      dataIndex: 'lastServicedDate',
      render: (text: string, record: LocationGridRow) => <Text>{formatDate(record?.lastServicedDate)}</Text>,
      sorter: (a, b) => autoCompare(a.lastServicedDate, b.lastServicedDate),
    },
    {
      title: 'LAST INVOICED',
      dataIndex: 'lastInvoicedDate',
      render: (text: string, record: LocationGridRow) => <Text>{formatDate(record?.lastInvoicedDate)}</Text>,
      sorter: (a, b) => autoCompare(a.lastInvoicedDate, b.lastInvoicedDate),
    },
    {
      title: 'PAST DUE',
      dataIndex: 'past due',
      render: (text: string, record: LocationGridRow) => <Text>{formatCurrency(record?.pastDueAmount)}</Text>,
      sorter: (a, b) => compareNumbers(a.pastDueAmount, b.pastDueAmount),
    },
    {
      title: 'YTD REVENUE',
      dataIndex: 'ytd revenue',
      render: (text: string, record: LocationGridRow) => <Text>{formatCurrency(record.yearToDateRevenue)}</Text>,
      sorter: (a, b) => compareNumbers(a.yearToDateRevenue, b.yearToDateRevenue),
    },
  ] as ColumnProps<LocationGridRow>[];

  return columns;
}

/**
 * Filters and maps the state data and returns the view model for the grid.
 * @param state the search state for this screen
 * @returns the displayed grid results
 */
export function getFilteredLocationResults(
  state: LocationSearchState,
  queryResults: GetCustomerLocationsQuery | undefined
): LocationGridRow[] {
  const records = queryResults?.locationSummaryViews;
  let results: LocationGridRow[] = [];
  if (records && records.length > 0) {
    const filter = (state.filter || '').toLowerCase();
    results = records.map(
      rec =>
        ({
          id: rec.locationId,
          customerId: rec?.customerId,
          streetAddress: rec.addressLine1,
          location: rec.name,
          expirationDate: rec?.agreementExpirationDate,
          lastInvoicedDate: rec?.lastInvoiced,
          lastServicedDate: rec?.lastServiced,
          pastDueAmount: rec?.pastDueAmount,
          yearToDateRevenue: rec?.yearToDateRevenueAmount,
          active: rec.inactive === undefined || rec.inactive === null,
        } as LocationGridRow)
    );
    results = results.filter(
      rec =>
        state.active === rec.active &&
        (rec.streetAddress?.toLowerCase()?.includes(filter) ||
          rec.location?.toLowerCase()?.includes(filter) ||
          rec.expirationDate?.toString()?.includes(filter) ||
          rec.lastInvoicedDate?.toString()?.includes(filter) ||
          rec.lastServicedDate?.toString()?.includes(filter) ||
          rec.pastDueAmount?.toString()?.includes(filter) ||
          rec.yearToDateRevenue?.toString()?.includes(filter))
    );
  }
  return results;
}

export function mapLocationGridRowFromObject(srcRow: any): LocationGridRow {
  return {
    id: srcRow.locationId,
    customerId: srcRow?.customerId,
    streetAddress: srcRow.addressLine1,
    location: srcRow.name,
    expirationDate: srcRow?.agreementExpirationDate,
    lastInvoicedDate: srcRow?.lastInvoiced,
    lastServicedDate: srcRow?.lastServiced,
    pastDueAmount: srcRow?.pastDueAmount,
    yearToDateRevenue: srcRow?.yearToDateRevenueAmount,
    active: srcRow.inactive === undefined || srcRow.inactive === null,
  } as LocationGridRow;
}
